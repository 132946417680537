import { memo, useState, useMemo, useEffect, type FunctionComponent, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { SkillWithLevel } from '../graphql/types';
import { TalentEmployeeObject, DevPlanEmployee } from '../graphql/customTypes';
import TbItemChip from '../elements/TbItemChip';
import EmployeeTalentBand from '../v3/EmployeeTalentBand';
// SCSS imports
import { reqSkillsTitle } from './EmployeeDetailsPopup.module.scss';

const contentSx = { py: 1 };

type EmployeeDetailsPopupProps = {
  title?: string;
  employee?: TalentEmployeeObject | DevPlanEmployee;
  targetSkills?: SkillWithLevel[] | null;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  route?: string | null;
  isMyPlan?: boolean;
}

const EmployeeDetailsPopupPropTypes = {
  // attributes
  title: PropTypes.string,
  employee: PropTypes.object as Validator<TalentEmployeeObject | DevPlanEmployee>,
  targetSkills: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  route: PropTypes.string,
  isMyPlan: PropTypes.bool
};

const EmployeeDetailsPopup: FunctionComponent<EmployeeDetailsPopupProps> = ({
  title,
  employee,
  targetSkills,
  isOpen = false,
  onClose,
  route,
  isMyPlan = false
}) => {
  const skills = targetSkills || employee?.skills as SkillWithLevel[];

  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  return mounted ? (
    <Dialog
        disableEnforceFocus
        maxWidth="lg"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose}/>
      {(isMyPlan && employee) || title ? <CardTitle title={title || 'dev_plans.skills'} withDivider/> : undefined}
      <DialogContent sx={contentSx}>
        {isMyPlan || !employee ? undefined : <EmployeeTalentBand employee={employee} route={route}/>}
        {size(skills) < 1 ? (
          <Alert severity="info" variant="standard">
            <FormattedMessage id="skills.no_skills_found"/>
          </Alert>
        ) : (
          <>
            <BoxTypography pt={2.5} pb={1.5} color="text.secondary" className={reqSkillsTitle}>
              <FormattedMessage id={targetSkills ? 'hr.dev_plan.target_skills' : 'hr.cohort.skills.required'}/>
            </BoxTypography>
            <Box display="flex" flexWrap="wrap">
              {map(skills, (skill, index) => (
                <Box key={index} pb={3} pr={3}>
                  <TbItemChip
                      item={skill}
                      satisfied={isNil(skill.is_satisfied) || skill.is_satisfied}
                      withLargeLevel
                      // TODO: onClick - navigate to relevant skill details screen
                  />
                </Box>
              ))}
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  ) : null;
};

EmployeeDetailsPopup.propTypes = EmployeeDetailsPopupPropTypes;

export default memo(EmployeeDetailsPopup);
