import map from 'lodash/map';
import size from 'lodash/size';
import trim from 'lodash/trim';
import join from 'lodash/join';
import replace from 'lodash/replace';
import transform from 'lodash/transform';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import axios from 'axios';
import { type IntlShape } from 'react-intl';
// local imports
import { SKILL_LEVEL_MAX, Skill, SkillLevel } from '../models/skill';
import logoUrl from '../customers/logo.svg';
import { CUSTOMER_FILES } from '../customers/getImages';
import { nonce } from '../config/contentSecurityPolicy';
import { getSkillCurrentLevel } from './models';

type IJsPdf = typeof import('jspdf');
type IHtml2Canvas = typeof import('html2canvas');
type ICanVG = typeof import('canvg');

// eslint-disable-next-line @typescript-eslint/init-declarations
let cachedJsPdf: IJsPdf | undefined;
// eslint-disable-next-line @typescript-eslint/init-declarations
let cachedHtml2Canvas: IHtml2Canvas | undefined;
// eslint-disable-next-line @typescript-eslint/init-declarations
let cachedCanvg: ICanVG | undefined;

const MARGIN_X = 14; // millimeters
const MARGIN_Y = 14; // millimeters

const nonceRegex = /\{nonce\}/gu;

function insertNonce(template: string): string {
  return replace(template, nonceRegex, nonce || '');
}

// eslint-disable-next-line max-lines-per-function
export const exportElemetToPDF = (
  filename: string,
  ignore: (Element | null)[],
  formatMessage: IntlShape['formatMessage'],
  elements: (HTMLElement | null | undefined)[],
  skillsWithGap?: Skill[] | null,
  title?: string | null
) => Promise.all([
  axios.get<string>(logoUrl),
  cachedJsPdf || import(/* webpackChunkName: "pdf" */ 'jspdf'),
  cachedHtml2Canvas || import(/* webpackChunkName: "pdf" */ 'html2canvas'),
  cachedCanvg || import(/* webpackChunkName: "pdf" */ 'canvg')
])
// eslint-disable-next-line max-statements, complexity, max-lines-per-function
.then(async ([logo, jspdf, html2canvas, canvg]) => {
  if (!elements[0] || !jspdf || !html2canvas || !canvg) return false;
  if (!cachedJsPdf) cachedJsPdf = jspdf;
  if (!cachedHtml2Canvas) cachedHtml2Canvas = html2canvas;
  if (!cachedCanvg) cachedCanvg = canvg;
  const JsPDF = jspdf.jsPDF;

  const hasLogo = Boolean(logo.data);
  const hasIntroPage = Boolean(CUSTOMER_FILES.devplanIntro);
  const hasDevplanNote = Boolean(CUSTOMER_FILES.devplanNote);
  const hasInDemandIntro = Boolean(CUSTOMER_FILES.devplanInDemandIntro);
  const hasInDemandNote = Boolean(CUSTOMER_FILES.devplanInDemandNote);
  const hasConclusionPage = Boolean(CUSTOMER_FILES.devplanConclusion);
  const hasPages = hasIntroPage || hasConclusionPage;
  const copyright = trim(formatMessage({ id: 'footer.copyright' }));
  const currentLevelMessage = trim(formatMessage({ id: 'export.my_dev_plan.current_level' }));
  const expectedLevelMessage = trim(formatMessage({ id: 'export.my_dev_plan.expected_level' }));
  const nextLevelMessage = trim(formatMessage({ id: 'export.my_dev_plan.next_level' }));
  const bottomLinkUrl = trim(formatMessage({ id: 'export.my_dev_plan.more_info_url' }));

  const hasInDemand = Boolean(elements[1]);

  const levelDescriptions = transform(skillsWithGap || [], (result, skill) => {
    const { title: skillTitle, level_description, expected_level } = skill;
    const currentLevel = getSkillCurrentLevel(skill);
    const isInDemand = isNil(expected_level);
    const expectedLevel = isInDemand && currentLevel < SKILL_LEVEL_MAX ? (currentLevel + 1) as SkillLevel
      : expected_level;
    if (title && level_description && expectedLevel && currentLevel < expectedLevel) {
      const currentLevelDescription = trim(level_description[currentLevel]);
      const expectedLevelDescription = trim(level_description[expectedLevel]);
      if (currentLevelDescription && expectedLevelDescription) result[isInDemand ? 1 : 0].push({
        skillTitle, currentLevel, currentLevelDescription, expectedLevel, expectedLevelDescription
      });
    }
  }, [[], []] as {
    skillTitle: string;
    currentLevel: SkillLevel;
    expectedLevel: SkillLevel;
    currentLevelDescription: string;
    expectedLevelDescription: string;
  }[][]);
  const hasLevelDescription = size(levelDescriptions[0]) >= 1 || size(levelDescriptions[1]) >= 1;

  const doc = new JsPDF();
  const defaultWidth = doc.internal.pageSize.getWidth() - 2 * MARGIN_X;

  const printCopyright = () => {
    if (copyright) {
      doc.setFontSize(9);
      doc.setTextColor('#002175'); // palette.secondary.dark1; WG: #5050a0
      doc.text(copyright, MARGIN_X, doc.internal.pageSize.getHeight() - MARGIN_Y - 2);
      doc.setTextColor('#000');
    }
  };

  let pageNumber = 0;
  const printPageNumber = () => {
    doc.setFontSize(10);
    doc.setTextColor('#000');
    pageNumber += 1;
    doc.text(
      toString(pageNumber),
      doc.internal.pageSize.getWidth() - MARGIN_X - 6,
      doc.internal.pageSize.getHeight() - MARGIN_Y - 2
    );
  };

  // capture Job Skills Gap and In-Demand Skills screenshots
  const canvases = [
    await html2canvas.default(elements[0], {
      ignoreElements: (el) => ignore.includes(el),
      windowWidth: 1600
    }),
    elements[1] ? await html2canvas.default(elements[1], {
      ignoreElements: (el) => ignore.includes(el),
      windowWidth: 1600
    }) : undefined
  ];
  const screenshotStartYs = [
    MARGIN_Y + 8 + (title ? 6 : 0),
    MARGIN_Y
  ];
  const screenshotBottomMargins = [
    (hasPages || hasLevelDescription || hasInDemand || copyright ? 10 : 6) + MARGIN_Y + (hasDevplanNote ? 13 : 0),
    (hasPages || hasLevelDescription || hasInDemand || copyright ? 10 : 6) + MARGIN_Y + (hasInDemandNote ? 32 : 0)
  ];
  const screenshotWidths = [defaultWidth, defaultWidth];
  const screenshotHeights = [
    doc.internal.pageSize.getHeight() - screenshotStartYs[0] - screenshotBottomMargins[0],
    doc.internal.pageSize.getHeight() - screenshotStartYs[1] - screenshotBottomMargins[1]
  ];
  for (let idx = 0; idx <= canvases.length; idx += 1) {
    const canvas = canvases[idx];
    if (canvas) {
      const aspectRatio = canvas.width / canvas.height;
      if (screenshotWidths[idx] / aspectRatio > screenshotHeights[idx]) {
        screenshotWidths[idx] = screenshotHeights[idx] * aspectRatio;
      } else {
        screenshotHeights[idx] = screenshotWidths[idx] / aspectRatio;
      }
    }
  }

  // PAGES:

  if (hasLogo) {
    // Customer logo
    const logoCanvas = document.createElement('canvas');
    logoCanvas.width = 1500;
    logoCanvas.height = 188;
    const ctx = logoCanvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, logoCanvas.width, logoCanvas.height);
      const options = { ignoreMouse: true, ignoreAnimation: true, ignoreDimensions: true };
      canvg.Canvg.fromString(ctx, logo.data, options).render(options);
      doc.addImage(
        logoCanvas.toDataURL('image/png'),
        'PNG',
        doc.internal.pageSize.getWidth() - MARGIN_X - 64,
        MARGIN_Y - 4,
        64,
        8
      );
    }
    // doc.addSvgAsImage(logo.data, doc.internal.pageSize.getWidth() - MARGIN_X - 64, MARGIN_Y - 4, 64, 8); // does not work
  }

  if (hasPages || hasInDemand || hasLevelDescription) printPageNumber();
  printCopyright();

  let screenshotOnFirstPage = false;
  if (hasIntroPage) {
    // workaround from https://github.com/parallax/jsPDF/issues/2926#issuecomment-716113998
    let yAfterIntro = 0;
    const subscription = doc.internal.events.subscribe('preProcessText', (payload) => {
      const nextY = payload.y + doc.getLineHeight() * payload.text.length;
      if (yAfterIntro < nextY) yAfterIntro = nextY;
    });
    await doc.html(insertNonce(CUSTOMER_FILES.devplanIntro), {
      // eslint-disable-next-line id-length
      x: 0,
      // eslint-disable-next-line id-length
      y: MARGIN_Y + 6,
      width: defaultWidth,
      windowWidth: 800,
      margin: [0, MARGIN_X, 0, MARGIN_X]
    });
    doc.internal.events.unsubscribe(subscription);

    screenshotOnFirstPage = yAfterIntro + screenshotHeights[0] + screenshotBottomMargins[0] <=
      doc.internal.pageSize.getHeight();

    // PAGE #2
    if (screenshotOnFirstPage) {
      screenshotStartYs[0] = yAfterIntro - 11;
    } else {
      doc.addPage();
    }
  }

  // Job Title
  if (!screenshotOnFirstPage && title) {
    doc.setFontSize(16);
    doc.setTextColor('#000');
    doc.text(title, MARGIN_X, MARGIN_Y + 8, hasIntroPage || !hasLogo ? undefined : {
      maxWidth: doc.internal.pageSize.getWidth() - MARGIN_X - 70
    });
  }

  // add Job Skills Gap screenshot
  if (canvases[0]) doc.addImage(
    canvases[0].toDataURL('image/png'),
    'PNG',
    MARGIN_X,
    screenshotStartYs[0],
    screenshotWidths[0],
    screenshotHeights[0]
  );
  // doc.setDrawColor('#05438f'); // palette.secondary.dark; WG: #323264
  // doc.setLineWidth(0.25);
  // doc.roundedRect(MARGIN_X, screenshotStartYs[0], screenshotWidths[0], screenshotHeights[0], 1, 1, 'S');
  if (hasDevplanNote) {
    const pageStartY = screenshotOnFirstPage ? 0 : doc.internal.pageSize.getHeight();
    await doc.html(insertNonce(CUSTOMER_FILES.devplanNote), {
      // eslint-disable-next-line id-length
      x: 0,
      // eslint-disable-next-line id-length
      y: pageStartY + screenshotStartYs[0] + screenshotHeights[0] - 2,
      width: defaultWidth,
      windowWidth: 800,
      margin: [0, MARGIN_X, 0, MARGIN_X]
    });
  }

  if (hasPages && !screenshotOnFirstPage) {
    printPageNumber();
    printCopyright();
  }

  if (hasInDemand && canvases[1]) {
    doc.addPage();
    const pageStartY = pageNumber * doc.internal.pageSize.getHeight();

    if (hasInDemandIntro) {
      let yInDemandAfterIntro = 0;
      const subscription = doc.internal.events.subscribe('preProcessText', (payload) => {
        const nextY = payload.y + doc.getLineHeight() * payload.text.length;
        if (yInDemandAfterIntro < nextY) yInDemandAfterIntro = nextY;
      });
      await doc.html(insertNonce(CUSTOMER_FILES.devplanInDemandIntro), {
        // eslint-disable-next-line id-length
        x: 0,
        // eslint-disable-next-line id-length
        y: pageStartY + MARGIN_Y,
        width: defaultWidth,
        windowWidth: 800,
        margin: [0, MARGIN_X, 0, MARGIN_X]
      });
      doc.internal.events.unsubscribe(subscription);
      screenshotStartYs[1] += yInDemandAfterIntro - MARGIN_Y - 22;
    }

    doc.addImage(
      canvases[1].toDataURL('image/png'),
      'PNG',
      MARGIN_X,
      screenshotStartYs[1],
      screenshotWidths[1],
      screenshotHeights[1]
    );
    // doc.setDrawColor('#05438f');
    // doc.setLineWidth(0.25);
    // doc.roundedRect(MARGIN_X, screenshotStartYs[1], screenshotWidths[1], screenshotHeights[1], 1, 1, 'S');
  
    if (hasInDemandNote) {
      await doc.html(insertNonce(CUSTOMER_FILES.devplanInDemandNote), {
        // eslint-disable-next-line id-length
        x: 0,
        // eslint-disable-next-line id-length
        y: pageStartY + screenshotStartYs[1] + screenshotHeights[1],
        width: defaultWidth,
        windowWidth: 800,
        margin: [0, MARGIN_X, 0, MARGIN_X]
      });
    }

    printPageNumber();
    printCopyright();
  }

  if (hasConclusionPage) {
    doc.addPage();
    const pageStartY = pageNumber * doc.internal.pageSize.getHeight();

    // workaround from https://github.com/parallax/jsPDF/issues/2926#issuecomment-716113998
    let yNext = MARGIN_Y;
    const subscription = doc.internal.events.subscribe('preProcessText', (payload) => {
      const nextY = payload.y + doc.getLineHeight() * payload.text.length;
      if (yNext < nextY) yNext = nextY;
    });
    await doc.html(insertNonce(CUSTOMER_FILES.devplanConclusion), {
      // eslint-disable-next-line id-length
      x: 0,
      // eslint-disable-next-line id-length
      y: pageStartY + MARGIN_Y,
      width: defaultWidth,
      windowWidth: 800,
      autoPaging: 'text',
      margin: [0, MARGIN_X, 0, MARGIN_X]
    });
    doc.internal.events.unsubscribe(subscription);
    if (bottomLinkUrl) {
      const rectW = defaultWidth + 6;
      const rectH = 16;
      const rectX = MARGIN_X - 3;
      const rectY = yNext - 94 - rectH;
      // To DEBUG link area uncomment the following lines:
      // doc.setDrawColor('#ddd');
      // doc.setLineWidth(0.25);
      // doc.rect(rectX, rectY, rectW, rectH, 'S');
      doc.link(rectX, rectY, rectW, rectH, { url: bottomLinkUrl });
    }

    printPageNumber();
    printCopyright();
  }

  if (hasLevelDescription) {
    for (let seq = 0; seq < size(levelDescriptions); seq += 1) {
      if (size(levelDescriptions[seq]) >= 1) {
        const docPre = new JsPDF();
        let pagesCount = 1;
        let lastY = 0;
        const subscription = docPre.internal.events.subscribe('preProcessText', (payload) => {
          if (lastY > payload.y + 180) pagesCount += 1;
          lastY = payload.y;
        });

        for (let doRender: boolean | null = false; !isNull(doRender); doRender = doRender ? null : true) {
          // eslint-disable-next-line max-depth
          if (doRender) for (let page = 0; page < pagesCount; page += 1) doc.addPage(); // add pages
          // eslint-disable-next-line no-await-in-loop
          await (doRender ? doc : docPre).html(insertNonce(`<html><body>${
            (seq === 0 && CUSTOMER_FILES.devplanJobSkills) ||
            (seq === 1 && CUSTOMER_FILES.devplanInDemandSkills) || ''
          }<style nonce="{nonce}">
            *.h4 { margin: 1.5rem 0 0 0; font-size: 1.15rem; font-weight: 700; }
            *.section { font-weight: 700; padding: 1rem 0 0.5rem 3rem; }
            *.desc { padding-left: 3rem; }
            </style>
            ${join(
              map(levelDescriptions[seq], ({
                skillTitle, currentLevel, expectedLevel, currentLevelDescription, expectedLevelDescription
              }) => `
                <h4 class="h4">${skillTitle}</h4>
                <div class="section">${currentLevelMessage}: ${currentLevel} ${
                  formatMessage({ id: 'common.skill_level.text' }, { level: currentLevel })
                }</div>
                <div class="desc">${currentLevelDescription}</div>
                <div class="section">${seq === 0 ? expectedLevelMessage : nextLevelMessage}: ${expectedLevel} ${
                  formatMessage({ id: 'common.skill_level.text' }, { level: expectedLevel })
                }</div>
                <div class="desc">${expectedLevelDescription}</div>
              `), ''
            )}
          `), {
            // eslint-disable-next-line id-length
            x: 0,
            // eslint-disable-next-line id-length
            y: doRender ? pageNumber * (doc.internal.pageSize.getHeight() - 2 * MARGIN_Y - 10) : 0,
            width: defaultWidth,
            windowWidth: 800,
            autoPaging: 'text',
            margin: [MARGIN_Y, 1, MARGIN_Y + 10, MARGIN_X]
          });
        }
        docPre.internal.events.unsubscribe(subscription);

        // print page numbers
        for (let page = 0; page < pagesCount; page += 1) {
          doc.setPage(pageNumber + 1);
          printPageNumber();
          printCopyright();
        }
        // DEBUG: docPre.save(`${filename}_.pdf`);
      }
    }
  }

  // FINISHED

  doc.save(`${filename}.pdf`);

  return true;
})
.catch((_error) => false);
