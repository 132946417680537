import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_SKILL } from '../config/api';

export const EMPLOYEE_SKILL_QUERY = gql`
  query EmployeeSkill(
    $skill_id: String!
  ) {
    employeeSkill(
      skill_id: $skill_id
    )
    @rest(
      type: "EmployeeSkillResources",
      path: "${API_EMPLOYEE_SKILL}"
    ) {
      id
      title
      abbr
      current_level
      inferred_level
      is_inference_newer
      mentors_count
      activities {
        id
        employee_activity_id
        code
        name
        activity_type
        skill_proficiency_level
        description
        activity_link
        development_area
        field_support
        store
        education_style
        is_selected
        is_complete
        completed_at
      }
    }
  }
`;
