import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_PROGRESS } from '../config/api';

export const EMPLOYEE_PROGRESS_QUERY = gql`
  query EmployeeProgress(
    $devplan_id: Int!
    $selected_employee_id: String
  ) {
    employeeProgress(
      devplan_id: $devplan_id,
      selected_employee_id: $selected_employee_id
    )
    @rest(
      type: "DevPlanExpanded",
      path: "${API_EMPLOYEE_PROGRESS}?{args}"
    ) {
      id
      title
      initial_match_rate
      current_match_rate
      skills {
        id
        title
        abbr
        current_level
        inferred_level
        is_inference_newer
        expected_level
        is_satisfied
      }
      courses {
        id
        title
        code
        abbr
        kind
        level
        rating
        link
        level_growth_in_quarters
        external_url
        duration_value
        duration_unit
        certification_program
        video_url
        credit_hours
        is_self_placed
        description
        is_complete
        started_at
        completed_at
        provider {
          id
          title
          code
        }
        covered_skills {
          id
          title
          abbr
          current_level
        }
      }
      advisors {
        id
        code
        first_name
        last_name
        email
        is_complete
        requested_at
        confirmed_at
        current_job {
          id
          title
          code
        }
        location {
          id
          title
          city
          state
          country
        }
        advisory_skills {
          id
          title
          abbr
          current_level
        }
      }
      opportunities {
        id
        opportunity_match_id
        title
        duration_unit
        duration_value
        description
        start_date
        status
        scope_manager_id
        onsite
        sidegig
        timezone_minutes
        published_at
        updated_at
        started_at
        archived_at
        timestamp @client
        location {
          id
          city
          state
          country
          title @client
        }
        owner {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          title
          abbr
          skill_proficiency_level
          status
        }
      }
      activities {
        id
        status
        created_at
        updated_at
        activity {
          id
          code
          name
          activity_type
          description
          activity_link
          development_area
          field_support
          store
          education_style
        }
        skills {
          id
          abbr
          title
          skill_proficiency_level
        }
      }
    }
  }
`;
