import {
  memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import xor from 'lodash/xor';
import size from 'lodash/size';
import find from 'lodash/find';
import unionBy from 'lodash/unionBy';
import transform from 'lodash/transform';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useDnD from '@empathco/ui-components/src/hooks/useDnD';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import Groups from '../icons/Groups';
import { Employee } from '../models/employee';
import { DraggableType } from '../constants/draggableTypes';
import { PATH_HR_TEAM_BUILDER_REVIEW } from '../config/paths';
import { GlobalContext, SupervisorContext } from '../context';
import EmployeeDropTarget from '../elements/EmployeeDropTarget';
import EmployeeDraggableCard from '../v3/EmployeeDraggableCard';
import CardsGrid from '../v3/CardsGrid';
import TbTeamItems from '../v3/TbTeamItems';
import TbBestMatches from '../components/TbBestMatches';
// SCSS imports
import { left, right, teamTitle, teamIcon, teamTitleText } from './TeamBuilderTeam.module.scss';

const EMPTY_EMPOYEES = [] as Employee[];

type TeamBuilderTeamProps = {
  teamId: number;
}

const TeamBuilderTeamPropTypes = {
  teamId: PropTypes.number.isRequired
};

// eslint-disable-next-line complexity
const TeamBuilderTeam: FunctionComponent<TeamBuilderTeamProps> = ({
  teamId
}) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);
  const {
    tbTeam: { data: team, pending, failed }, requireTbTeam,
    tbBestMatches: { data: bestMatches, pending: bestMatchesPending },
    tbTeamJobAdd: { pending: jobAddPending },
    tbTeamJobDelete: { pending: jobDeletePending },
    tbTeamSkillAdd: { pending: skillAddPending },
    tbTeamSkillDelete: { pending: skillDeletePending },
    tbTeamSkillUpdate: { pending: skillUpdatePending },
    tbTeamSkillsReset: { pending: skillsResetPending },
    tbTeamEmployeeAdd: { pending: employeeAddPending, failed: addFailed }, tbTeamAddEmployee,
    tbTeamEmployeeDelete: { pending: employeeDeletePending, failed: deleteFailed }, tbTeamDeleteEmployee
  } = useContext(SupervisorContext);
  const { title, jobs, skills, employees: teamEmployees } = team || {};

  const [allEmployees, setAllEmployees] = useState<Employee[]>();
  useLayoutEffect(() => {
    if (!pending) {
      setAllEmployees((prevEmployees) => unionBy(
        teamEmployees || [],
        (!bestMatchesPending && bestMatches) || prevEmployees || [],
        'id'
      ));
    }
  }, [bestMatches, teamEmployees, pending, bestMatchesPending]);

  const teamEmployeeIds = useMemo(() => map(teamEmployees, 'id'), [teamEmployees]);
  const [employeeIds, setEmployeeIds] = useState<number[]>(teamEmployeeIds);
  useEffect(() => {
    setEmployeeIds(teamEmployeeIds);
  }, [teamEmployeeIds]);

  useEffect(() => {
    if (teamId) requireTbTeam?.({ team_id: teamId });
  }, [teamId, requireTbTeam]);

  const onSelectionChange = useCallback((ids: number[]) => {
    const newIds = xor(ids, teamEmployeeIds);
    const [employee_id] = newIds;
    if (size(newIds) === 1 && employee_id) {
      if (size(ids) > size(teamEmployeeIds)) {
        const employee = find(allEmployees, ['id', employee_id]);
        if (employee) tbTeamAddEmployee?.({ team_id: teamId, employee_id, employee });
      } else if (size(ids) < size(teamEmployeeIds)) {
        tbTeamDeleteEmployee?.({ team_id: teamId, employee_id });
      }
    }
  }, [teamEmployeeIds, allEmployees, tbTeamAddEmployee, tbTeamDeleteEmployee, teamId]);

  const disabled = pending || !allEmployees ||
    jobAddPending || jobDeletePending ||
    skillAddPending || skillDeletePending || skillUpdatePending || skillsResetPending ||
    employeeAddPending || employeeDeletePending;

  const {
    items: employees,
    moveItem,
    // Drag and Drop state
    isDragging,
    drop,
    isActive,
    selectedDrop
  } = useDnD<Employee, DraggableType>({
    accept: 'employee',
    allItems: allEmployees || EMPTY_EMPOYEES,
    ids: employeeIds,
    setIds: setEmployeeIds,
    onSelectionChange,
    disabled
  });

  const empls = useMemo(
    () => employees ? transform(employeeIds, (result, id) => {
      const empl = employees[id];
      if (empl) result.push(empl);
    }, [] as Employee[]) : EMPTY_EMPOYEES,
    [employeeIds, employees]
  );

  const componentProps: Partial<GetComponentProps<typeof EmployeeDraggableCard>> = useMemo(() => ({
    avatarVariant: 'grey',
    route: supvEmplPath,
    withZoomInSkills: true,
    moveEmployee: moveItem
  }), [moveItem, supvEmplPath]);

  const hasTeamMembers = size(teamEmployees) >= 1;

  return (failed && <FetchFailedAlert flat/>) || (
    <>
      <Box display="flex">
        <CardSection shady className={left}>
          <TbTeamItems
              teamId={teamId}
              type="jobs"
              items={jobs}
              pending={pending}
              disabled={disabled}
          />
          <TbTeamItems
              teamId={teamId}
              type="skills"
              items={skills}
              pending={pending}
              disabled={disabled}
              withSatisfied={hasTeamMembers}
          />
        </CardSection>
        <CardSection className={right}>
          <Box
              display="flex"
              alignItems="center"
              pb={pending ? 2 : undefined}
              className={teamTitle}
          >
            <Avatar className={teamIcon}>
              <Groups fontSize="large"/>
            </Avatar>
            <Box
                pl={1.5}
                color={pending ? 'action.disabled' : undefined}
                flex="1 1 0"
                className={teamTitleText}
            >
              {pending ? <CircularProgress size={28} color="inherit"/> : title}
            </Box>
            <Button
                color="primary"
                variant="contained"
                disableElevation
                component={RouterLink}
                to={injectParams(PATH_HR_TEAM_BUILDER_REVIEW, { team_id: teamId })}
                disabled={disabled || !hasTeamMembers}
            >
              <FormattedMessage id="hr.teambuilder.confirm_roster"/>
            </Button>
          </Box>
          <Box ref={selectedDrop}>
            <CardsGrid
                items={empls}
                variant="white"
                pending={pending || !team}
                // failed={failed} // handled above (on top level)
                disabled={disabled}
                component={EmployeeDraggableCard}
                ComponentProps={componentProps}
                itemsSuffix={isActive || (!pending && /* !failed && */ team) ? (
                  <>
                    {isActive ? <EmployeeDropTarget active/> : undefined}
                    <EmployeeDropTarget/>
                  </>
                ) : undefined}
            />
          </Box>
          <Box ref={drop}>
            <TbBestMatches
                teamId={teamId}
                moveEmployee={moveItem}
                disabled={disabled}
                dragging={isDragging}
            />
          </Box>
        </CardSection>
      </Box>
      <ActionFailedAlert
          message="hr.teambuilder.employee_add_error"
          open={addFailed}
      />
      <ActionFailedAlert
          message="hr.teambuilder.employee_delete_error"
          open={deleteFailed}
      />
    </>
  );
};

TeamBuilderTeam.propTypes = TeamBuilderTeamPropTypes;

export default memo(TeamBuilderTeam);
