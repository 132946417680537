import { memo, useState, useLayoutEffect, type FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
// local imports
import useNonReducedUI from '../constants/managementLevel';
import { HASH_EXPORT_SUMMARY } from '../config/params';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import EmployeeDashboard from '../components/EmployeeDashboard';
import DashboardFooter from '../elements/DashboardFooter';
import DataStatusPoller from '../v3/DataStatusPoller';

const EmployeeDashboardScreen: FunctionComponent = () => {
  const { hash } = useLocation();
  const { showNonReducedUI } = useNonReducedUI();
  const { content, user } = useVerifyUser({ employee: true });

  const [exportSummary, setExportSummary] = useState(false);
  useLayoutEffect(() => {
    if (hash === `#${HASH_EXPORT_SUMMARY}`) setExportSummary(true);
  }, [hash]);

  return content || (
    <Screen
        dashboard
        fullHeight={!showNonReducedUI(user)}
        footer={<DashboardFooter/>}
    >
      <EmployeeDashboard exportSummary={exportSummary}/>
      <DataStatusPoller/>
    </Screen>
  );
};

export default memo(EmployeeDashboardScreen);
