/* eslint-disable max-lines */
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import take from 'lodash/take';
import every from 'lodash/every';
import transform from 'lodash/transform';
import toSafeInteger from 'lodash/toSafeInteger';
import ceil from 'lodash/ceil';
import join from 'lodash/join';
import { type CallbackDataParams } from 'echarts/types/src/util/types';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
// EmPath UI Components
import { percentageOptions } from '@empathco/ui-components/src/common/intl';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { getTruncatedTitle } from '@empathco/ui-components/src/helpers/strings';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import { type EChartsMouseEvent } from '@empathco/ui-components/src/helpers/echarts';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { DAJobMovementSkills, DASkillsDevelopment, DASkillsGrowth, DASkillSupply } from '../graphql/types';
import { EMPLOYEES_COUNT_KEYS } from '../models/employeeCounts';
import { Skill, SKILL_LEVEL_MAX } from '../models/skill';
import { GlobalEChartsStyles, MAX_MANAGER_DASHBOARD_ITEMS } from '../config/params';
import Chart from '../elements/Chart';
// SCSS imports
import {
  s0L1, s0L2, s0L3, s0L4,
  s1L1, s1L2, s1L3, s1L4,
  s2L1, s2L2, s2L3, s2L4,
  s3L1, s3L2, s3L3, s3L4,
  s4L1, s4L2, s4L3, s4L4,
  s5L1, s5L2, s5L3, s5L4,
  s6L1, s6L2, s6L3, s6L4
} from '../styles/modules/Chart.module.scss';
import {
  chart, chartPreview, chartNarrow, tooltip, header, headerMgr, headline, percent, total,
  totalCount, tooltipSubheader, totalMessage, openReqDays,
  line, marker, markerTop, count, label, courseTitle
} from './TopChartStacked.module.scss';

const defaultColorCls = [s0L1, s0L2, s0L3, s0L4];
const barColorCls = [
  [s1L1, s1L2, s1L3, s1L4],
  [s2L1, s2L2, s2L3, s2L4],
  [s3L1, s3L2, s3L3, s3L4],
  [s4L1, s4L2, s4L3, s4L4],
  [s5L1, s5L2, s5L3, s5L4],
  [s6L1, s6L2, s6L3, s6L4]
];

const VARIANT_DEFAULT = 'default' as const;
const SKILL_SUPPLY_DEMAND = 'supply_demand' as const;
const SKILLS_GROWTH = 'skills_growth' as const;
const SKILL_ADVISORS = 'skill_advisors' as const;
const SKILL_COURSES = 'skill_courses' as const;
const SKILLS_DEVELOPMENT = 'skills_development' as const;
const SKILLS_JOB_MOVEMENTS_UP = 'upward_job_movement' as const;
const SKILLS_JOB_MOVEMENTS_ADJ = 'adj_job_movement' as const;
const STACKED_VARIANTS = [
  VARIANT_DEFAULT,
  SKILL_SUPPLY_DEMAND, SKILLS_GROWTH, SKILL_ADVISORS, SKILL_COURSES, SKILLS_DEVELOPMENT,
  SKILLS_JOB_MOVEMENTS_UP, SKILLS_JOB_MOVEMENTS_ADJ
] as const;
export type StackedVariant = typeof STACKED_VARIANTS[number];

type TopChartStackedProps = {
  preview?: boolean;
  variant: StackedVariant;
  hrbp?: boolean;
  data?: (Skill | DASkillSupply | DASkillsDevelopment | DASkillsGrowth | DAJobMovementSkills)[];
  totalEmployees?: number | null;
  path?: string;
  pending?: boolean | null;
}

const TopChartStackedPropTypes = {
  // attributes
  preview: PropTypes.bool,
  variant: PropTypes.oneOf(STACKED_VARIANTS).isRequired,
  hrbp: PropTypes.bool,
  data: PropTypes.array,
  totalEmployees: PropTypes.number,
  path: PropTypes.string,
  pending: PropTypes.bool
};

// eslint-disable-next-line max-lines-per-function
const TopChartStacked = forwardRef<EChartsReactCore, TopChartStackedProps>(({
  preview = false,
  variant,
  hrbp = false,
  data,
  totalEmployees,
  path,
  pending = false
}, ref) => {
  const navigate = useNavigate();
  const wide = variant !== SKILLS_GROWTH;
  const percentage = hrbp && variant !== SKILL_SUPPLY_DEMAND;

  const theme = useTheme();
  const skillNameWidth = spacing(21);

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const [activeId, setActiveId] = useState<number>();

  const barColor = useMemo(() => variant === VARIANT_DEFAULT ? undefined : [
    // Series 1 - levels: 1-4
    [
      theme.palette.chart.series1level1, theme.palette.chart.series1level2,
      theme.palette.chart.series1level3, theme.palette.chart.series1level4
    ],
    [
      // Series 2 - levels: 1-4
      theme.palette.chart.series2level1, theme.palette.chart.series2level2,
      theme.palette.chart.series2level3, theme.palette.chart.series2level4
    ],
    [
      // Series 3 - levels: 1-4
      theme.palette.chart.series3level1, theme.palette.chart.series3level2,
      theme.palette.chart.series3level3, theme.palette.chart.series3level4
    ],
    [
      // Series 4 - levels: 1-4
      theme.palette.chart.series4level1, theme.palette.chart.series4level2,
      theme.palette.chart.series4level3, theme.palette.chart.series4level4
    ],
    [
      // Series 5 - levels: 1-4
      theme.palette.chart.series5level1, theme.palette.chart.series5level2,
      theme.palette.chart.series5level3, theme.palette.chart.series5level4
    ],
    [
      // Series 6 - levels: 1-4
      theme.palette.chart.series6level1, theme.palette.chart.series6level2,
      theme.palette.chart.series6level3, theme.palette.chart.series6level4
    ]
  ], [variant, theme]);

  const [labels, noEmployees] = useMemo(() => {
    if (preview) return [[], ''];
    const lbls = variant === SKILL_ADVISORS || variant === SKILL_COURSES || variant === SKILLS_DEVELOPMENT ? []
      : EMPLOYEES_COUNT_KEYS.map((key) => formatMessage({ id: `board.skill.${key}` }));
    if (variant === SKILL_SUPPLY_DEMAND) lbls.push(formatMessage({ id: 'hr.dashboard.supply_demand.open_reqs' }));
    return [
      lbls,
      formatMessage({ id: 'employees.no_employees' })
    ];
  }, [variant, preview, formatMessage]);

  const [xLabel, x2Label] = useMemo(() => preview ? [] : [
    formatMessage({ id: percentage
      ? (variant === SKILLS_GROWTH && 'hr.dashboard.label.skills_growth') ||
        ((variant === SKILL_ADVISORS || variant === SKILL_COURSES || variant === SKILLS_DEVELOPMENT) &&
        'hr.dashboard.label.skills_development') || 'hr.employees.label'
      : 'supervisor.employees.label' }),
    variant === SKILL_ADVISORS || variant === SKILL_COURSES ? formatMessage({ id: `hr.dashboard.label.${variant}` }) : ''
  ], [percentage, variant, preview, formatMessage]);

  const totalValue = useMemo(
    () => preview ? '' : formatMessage({ id: 'supervisor.employees.total' }, { total: totalEmployees || 0 }),
    [totalEmployees, preview, formatMessage]
  );

  const categories = useMemo(() => {
    if (preview) return ['1', '2', '3', '4', '5', '6'];
    const cats = map(take(data, MAX_MANAGER_DASHBOARD_ITEMS), ({ id, abbr, title }) => ({
      value: JSON.stringify({
        id,
        abbr,
        label: getTruncatedTitle(title)
      }),
      ...activeId && id === activeId ? {
        textStyle: {
          backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)
        }
      } : {}
    }));
    while (cats.length < MAX_MANAGER_DASHBOARD_ITEMS) cats.push('' as unknown as typeof cats[0]);
    return cats;
  }, [activeId, data, preview, theme]);

  // eslint-disable-next-line complexity
  const { counts, labelCounts, employeeCounts } = useMemo(() => {
    const isSupplyDemand = variant === SKILL_SUPPLY_DEMAND;
    const isDevelopment = variant === SKILL_ADVISORS || variant === SKILL_COURSES || variant === SKILLS_DEVELOPMENT;

    const pushCounts = (counters: (number | object)[][], itemRatio: number, dataIndex: number) =>
      (value: number, index: number) => {
        const cnt = isDevelopment && index > 0 ? toSafeInteger(value) : ceil(itemRatio * toSafeInteger(value), 2);
        const color = index >= SKILL_LEVEL_MAX ? undefined : barColor?.[
          dataIndex % barColor.length
        ]?.[
          isDevelopment ? SKILL_LEVEL_MAX - 1 : index
        ];
        counters[index]?.push(color ? {
          value: cnt,
          itemStyle: variant === SKILL_ADVISORS && index === 2 ? {
            borderColor: color,
            color: theme.palette.background.paper
          } : { color }
        } : cnt);
      };

    if (preview) {
      const isSkillsGrowth = variant === SKILLS_GROWTH;
      const counters = (
        (isSupplyDemand && [[], [], [], [], []]) ||
        (variant === SKILL_ADVISORS && [[], [], []]) ||
        (variant === SKILL_COURSES && [[], []]) ||
        (variant === SKILLS_DEVELOPMENT && [[]]) ||
        [[], [], [], []]
      ) as (number | object)[][];
      [
        (isSupplyDemand && [60, 50, 40, 30, 75]) || (isSkillsGrowth && [60, 58, 56, 54]) || [50, 40, 30, 25],
        (isSupplyDemand && [50, 40, 30, 25, 55]) || (isSkillsGrowth && [50, 48, 46, 44]) || [53, 43, 33, 28],
        (isSupplyDemand && [40, 30, 25, 20, 33]) || (isSkillsGrowth && [55, 53, 51, 49]) || [30, 20, 15, 15],
        (isSupplyDemand && [30, 20, 15, 15, 15]) || (isSkillsGrowth && [35, 33, 31, 29]) || [40, 30, 25, 20],
        (isSupplyDemand && [25, 15, 12, 10, 20]) || (isSkillsGrowth && [45, 43, 41, 39]) || [15, 12, 10, 7],
        (isSupplyDemand && [15, 12, 10, 7, 10]) || (isSkillsGrowth && [40, 38, 36, 34]) || [25, 15, 12, 10]
      ].forEach((lvls, dataIndex) => {
        const pushFunc = pushCounts(counters, 1, dataIndex);
        lvls.forEach(pushFunc);
      });
      return {
        counts: counters,
        labelCounts: [],
        employeeCounts: []
      };
    }

    const ratio = percentage ? 100 / (toSafeInteger(totalEmployees) || 100) : 1;
    return transform(
      take(data, MAX_MANAGER_DASHBOARD_ITEMS),
      // eslint-disable-next-line complexity
      ({ counts: cnts, labelCounts: lbls, employeeCounts: emplCounts }, record, dataIndex) => {
        const { counts_per_level, employees_count } = (variant === VARIANT_DEFAULT ? record : {}) as Skill;
        const { level1 } = (isSupplyDemand ? record : {}) as DASkillSupply;
        const { level2, level3, level4, total_employees } =
          (isSupplyDemand || variant === SKILLS_GROWTH ? record : {}) as DASkillSupply | DASkillsGrowth;
        const {
          upward_level_1, upward_level_2, upward_level_3, upward_level_4, total_upward
        } = (variant === SKILLS_JOB_MOVEMENTS_UP ? record : {}) as DAJobMovementSkills;
        const {
          adjacent_level_1, adjacent_level_2, adjacent_level_3, adjacent_level_4, total_adjacent
        } = (variant === SKILLS_JOB_MOVEMENTS_ADJ ? record : {}) as DAJobMovementSkills;
        const { employee_advisor_count, target_skill_count, employee_skill_count, advisor_count, skill_course_hours } =
          (isDevelopment ? record : {}) as DASkillsDevelopment;
        const lvls = (variant === SKILL_ADVISORS && [
          employee_skill_count || 0,
          employee_advisor_count || 0,
          advisor_count || 0
        ]) || (variant === SKILL_COURSES && [
          employee_skill_count || 0,
          skill_course_hours || 0
        ]) || (variant === SKILLS_DEVELOPMENT &&
          [employee_skill_count || 0]
        ) || [
          counts_per_level?.level1 || level1 || upward_level_1 || adjacent_level_1 || 0,
          counts_per_level?.level2 || level2 || upward_level_2 || adjacent_level_2 || 0,
          counts_per_level?.level3 || level3 || upward_level_3 || adjacent_level_3 || 0,
          counts_per_level?.level4 || level4 || upward_level_4 || adjacent_level_4 || 0
        ];
        if (isSupplyDemand) lvls.push((record as DASkillSupply).job_open_req_count || 0);
        lbls.push(lvls);
        const itemRatio = isDevelopment && percentage ? 100 / (toSafeInteger(target_skill_count) || 100) : ratio;
        const pushFunc = pushCounts(cnts, itemRatio, dataIndex);
        lvls.forEach(pushFunc);
        emplCounts.push(employees_count || total_employees || target_skill_count || total_upward || total_adjacent || 0);
      },
      {
        counts: (
          (isSupplyDemand && [[], [], [], [], []]) ||
          (variant === SKILL_ADVISORS && [[], [], []]) ||
          (variant === SKILL_COURSES && [[], []]) ||
          (variant === SKILLS_DEVELOPMENT && [[]]) ||
          [[], [], [], []]
        ) as (number | object)[][],
        labelCounts: [] as number[][],
        employeeCounts: [] as number[]
      }
    );
  }, [data, barColor, totalEmployees, percentage, variant, preview, theme]);

  const hasNoConfirmed = variant === SKILL_ADVISORS && (preview ||
    every(counts[1] as { value: number; }[], ({ value }) => !value || value < 1)
  );

  const onEvents = useMemo(() => !preview && path ? {
    click: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') try {
        const { abbr } = JSON.parse(value);
        if (abbr) navigate(injectParams(path, { skill_id: abbr }));
      } catch (_err) {
        // nothing to do
      }
    },
    mouseover: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') try {
        setActiveId(JSON.parse(value).id);
      } catch (_err) {
        setActiveId(undefined);
      }
    },
    mouseout: ({ componentType }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') setActiveId(undefined);
    }
  } : undefined, [path, preview, navigate]);

  const xAxisLabelFormatter = useCallback(
    (value?: number | null) => (
      variant === SKILL_ADVISORS || variant === SKILL_COURSES || variant === SKILLS_DEVELOPMENT
    ) && (!value || value === 0) ? '0' : formatNumber((value || 0) / 100, { ...percentageOptions, maximumFractionDigits: 4 }),
    [variant, formatNumber]
  );

  const yAxisLabelFormatter = useCallback((value: string) => {
    try {
      return JSON.parse(value).label;
    } catch (_err) {
      return value;
    }
  }, []);

  const valueLabelFormatter = useCallback(({ dataIndex }: CallbackDataParams) => formatMessage(
    { id: 'supervisor.employees.count' }, { count: employeeCounts[dataIndex] }
  ), [employeeCounts, formatMessage]);

  const value2LabelFormatter = useCallback(({ value }: CallbackDataParams) => formatMessage({
    id: (variant === SKILL_ADVISORS && 'hr.dashboard.confirmed.value') ||
    (variant === SKILL_COURSES && 'hr.dashboard.hours.value') || ''
  }, { value: value as number }), [variant, formatMessage]);

  // eslint-disable-next-line complexity
  const tooltipFormatter = useCallback((values: CallbackDataParams[]) => {
    const { dataIndex: idx, seriesIndex, name: jsonValue } = values[0] || {};
    const isDevelopment = variant === SKILL_ADVISORS || variant === SKILL_COURSES || variant === SKILLS_DEVELOPMENT;
    const isDevelopmentRight = Boolean(seriesIndex) && (variant === SKILL_ADVISORS || variant === SKILL_COURSES);
    const record = data?.[idx] || { title: undefined };
    const { title } = record;
    let name = title;
    try {
      name = JSON.parse(jsonValue).label || title;
    } catch (_err) {
      // nothing to do
    }
    const { job_open_req_count, days_to_fill } = (variant === SKILL_SUPPLY_DEMAND ? record : {}) as DASkillSupply;
    const { level2, level3, level4 } = (variant === SKILLS_GROWTH ? record : {}) as DASkillsGrowth;
    const {
      employee_skill_count, target_skill_count, top_courses, advisor_count, employee_advisor_count
    } = (isDevelopment ? record : {}) as DASkillsDevelopment;
    const isCoursesList = isDevelopmentRight && variant === SKILL_COURSES;
    return `<div class="${tooltip}"><div class="${hrbp ? header : headerMgr}">${isDevelopment
      ? `<div class="${markerTop} ${barColorCls[idx]?.[SKILL_LEVEL_MAX - 1] || ''}">\u00A0</div>` : ''
      }<div class="${headline}">${name}</div>${isDevelopment ? '' : `<div class="${percent}">${
      formatNumber(
        ceil(
          (variant === SKILLS_GROWTH ? (level2 || 0) + (level3 || 0) + (level4 || 0) : employeeCounts[idx]) /
          ((hrbp && totalEmployees) || 1),
          hrbp ? 2 : 0
        ),
        hrbp ? percentageOptions : undefined
      )
    }</div>`}</div>${hrbp
      ? (variant === VARIANT_DEFAULT && `<div class="${total}">${totalValue}</div>`) ||
        `<div class="${(isCoursesList && tooltipSubheader) || (isDevelopment && totalMessage) || totalCount}">${
          formatMessage({ id: `hr.dashboard.employees.${
            isDevelopment && !isDevelopmentRight ? 'skills_development' : variant
          }` },
          (isDevelopmentRight && variant === SKILL_ADVISORS && {
            total: advisor_count || 0,
            connected: hasNoConfirmed ? null : employee_advisor_count || 0
          }) ||
          (isCoursesList && {}) ||
          (isDevelopment && {
            targeted: target_skill_count || 0,
            targeted_percent: ceil((target_skill_count || 0) / (totalEmployees || 1), 2),
            attained: employee_skill_count || 0,
            attained_percent: ceil((employee_skill_count || 0) / (target_skill_count || 1), 2)
          }) || {
            total: employeeCounts[idx] || 0,
            all: totalEmployees || 0,
            open_reqs: job_open_req_count || 0
          })
        }</div>${isCoursesList ? `${size(top_courses) >= 1
          ? `<li class="${courseTitle}">${join(map(top_courses, 'title'), `</li><li class="${courseTitle}">`)}</li>` : '—'
        }` : ''}`
      : ''}${isDevelopment ? '' : join(map(values, ({ dataIndex, seriesName, componentIndex }, index) =>
        (variant === SKILLS_GROWTH && index === 0) || index >= SKILL_LEVEL_MAX ? ''
        : `<div class="${line}"><div class="${marker} ${
              (variant === VARIANT_DEFAULT ? defaultColorCls[componentIndex] : barColorCls[idx]?.[componentIndex]) || ''
            }">\u00A0</div><div class="${label}">${
            seriesName}</div><div class="${count}">${
            labelCounts[dataIndex]?.[componentIndex] || '0'
          }</div></div>`
      ), ''
    )}${variant === SKILL_SUPPLY_DEMAND && days_to_fill && days_to_fill >= 1 ? `<div class="${openReqDays}">${
      formatMessage({ id: 'hr.dashboard.supply_demand.open_reqs.tooltip' }, { title, days: days_to_fill })
    }</div>` : ''}</div>`;
  }, [
    data, labelCounts, employeeCounts, totalEmployees, totalValue, hasNoConfirmed, hrbp, variant,
    formatNumber, formatMessage
  ]);

  // eslint-disable-next-line complexity, max-lines-per-function
  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    const isDefault = variant === VARIANT_DEFAULT;
    const isSupplyDemand = variant === SKILL_SUPPLY_DEMAND;
    const isDevelopmentFull = variant === SKILL_ADVISORS || variant === SKILL_COURSES;

    const bar = {
      type: 'bar',
      stack: 'skills',
      barWidth: '27%'
    };

    const barLabel = preview || variant === VARIANT_DEFAULT ? undefined : {
      show: true,
      color: theme.palette.text.primary,
      position: 'insideLeft',
      distance: 0,
      offset: [0, -spacing(2.25)],
      align: 'left',
      verticalAlign: 'middle',
      formatter: valueLabelFormatter,
      fontSize: theme.typography.body2.fontSize
    };

    const style = {
      borderColor: 'transparent',
      borderWidth: theme.shape.borderWidth,
      borderRadius: theme.shape.smallBorderRadius
    };

    const colors = isDefault ? [
      // levels: 1-4
      theme.palette.primary.lightest as string,
      theme.palette.primary.light,
      theme.palette.secondary.main,
      theme.palette.secondary.dark2 as string
    ] : undefined;

    let grid: object | object[] = preview ? {
      left: 0, top: 0, right: 0, bottom: 0
    } : {
      left: skillNameWidth + spacing(6),
      top: hrbp && isDefault ? '1%' : '5%',
      right: spacing(8),
      bottom: spacing((isDefault && 9.5) || (isSupplyDemand && 7) || 5),
      containLabel: true
    };

    let xAxis: object | object[] = {
      type: 'value',
      name: xLabel,
      minInterval: percentage || hrbp ? undefined : 1,
      interval: percentage || hrbp ? undefined : 1,
      splitLine: preview ? null : {
        lineStyle: {
          color: theme.palette.chart.grid
        }
      },
      axisLabel: preview ? null : {
        fontSize: 14,
        color: theme.palette.text.label,
        formatter: percentage ? xAxisLabelFormatter : undefined
      },
      nameTextStyle: preview ? null : {
        fontSize: 13.5,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.info.caption,
        align: isDevelopmentFull ? 'left' : 'right',
        verticalAlign: 'top',
        padding: [spacing(isSupplyDemand ? 5 : 4), 0, 0, isDevelopmentFull ? spacing(1.5) : 0]
      }
    };

    let yAxis: object | object[] = {
      type: 'category',
      inverse: true,
      triggerEvent: Boolean(!preview && path),
      data: categories,
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: preview ? null : {
        inside: true,
        interval: 0,
        width: skillNameWidth,
        margin: -(skillNameWidth + spacing(4.5)),
        overflow: 'break',
        lineHeight: 17,
        fontSize: 17,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.secondary.text,
        padding: [spacing(0.375), spacing(1.5), spacing(0.375), spacing(1.5)],
        borderRadius: theme.shape.borderRadius,
        formatter: yAxisLabelFormatter
      },
      ...preview || variant === VARIANT_DEFAULT ? {} : {
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.background.paper,
            shadowColor: theme.palette.chart.gridColored,
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: -spacing((isSupplyDemand && 3.75) || (variant === SKILLS_GROWTH ? 3.125 : 4))
          }
        }
      }
    };

    if (isDevelopmentFull) {
      grid = [
        {
          ...grid,
          right: '45%'
        },
        {
          ...grid,
          left: '60%'
        }
      ];
      xAxis = [
        preview ? xAxis : {
          ...xAxis,
          nameLocation: 'start'
        },
        {
          ...xAxis,
          gridIndex: 1,
          inverse: true,
          ...preview ? {} : {
            name: x2Label,
            axisLabel: {
              ...(xAxis as { axisLabel: object }).axisLabel,
              formatter: undefined
            },
            nameLocation: 'start',
            nameTextStyle: {
              ...(xAxis as { nameTextStyle: object }).nameTextStyle,
              align: 'right',
              padding: [spacing(4), spacing(1.5), 0, 0]
            }
          }
        }
      ];
      yAxis = [
        yAxis,
        {
          ...yAxis,
          gridIndex: 1,
          position: 'right',
          triggerEvent: false,
          data: categories,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false }
        }
      ];
    }

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      silent: Boolean(preview || pending),
      ...preview || totalEmployees ? {} : {
        title: {
          text: noEmployees,
          left: hrbp ? '50%' : '45%',
          top: '42%',
          textStyle: {
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight
          }
        }
      },
      grid,
      ...!preview && !pending && totalEmployees ? {
        tooltip: {
          show: true,
          trigger: 'axis',
          confine: true,
          formatter: tooltipFormatter,
          borderColor: theme.palette.misc.selectedBorder,
          borderWidth: theme.shape.borderWidth,
          backgroundColor: theme.palette.background.tooltip,
          extraCssText: `box-shadow: ${theme.shadows[5]}`,
          axisPointer: totalEmployees ? {
            type: 'none'
          } : undefined
        }
      } : {},
      legend: !preview && (isDefault || isSupplyDemand) ? {
        type: isDefault ? 'scroll' : 'plain',
        bottom: 0,
        padding: spacing(2.5),
        icon: 'roundRect',
        itemWidth: spacing(isDefault ? 1.75 : 3.5),
        itemHeight: spacing(isDefault ? 1.75 : 1),
        itemGap: spacing(4.5),
        textStyle: {
          fontSize: 15,
          ...isDefault ? {
            color: theme.palette.chart.category
          } : {
            fontStyle: 'italic',
            color: theme.palette.info.caption
          },
          padding: spacing(1)
        },
        ...isDefault ? {
          pageButtonItemGap: spacing(1.25),
          pageFormatter: ' ',
          pageIconSize: 18,
          pageIconColor: theme.palette.action.active,
          pageIconInactiveColor: theme.palette.action.disabled,
          pageTextStyle: {
            color: 'transparent',
            fontSize: 1,
            width: 0,
            height: 0
          }
        } : {
          left: skillNameWidth + spacing(3.5),
          data: ['', '', '', '', labels[4]]
        }
      } : undefined,
      xAxis,
      yAxis,
      // eslint-disable-next-line complexity
      series: counts.map((record, idx) => ({
        ...bar,
        ...barLabel && idx === 0 ? { label: barLabel } : {},
        ...(isDevelopmentFull && {
          stack: undefined,
          ...idx > 0 ? {
            silent: true,
            xAxisIndex: 1,
            yAxisIndex: 1
          } : {},
          ...barLabel && idx === 1 ? {
            label: {
              ...barLabel,
              position: 'insideRight',
              align: 'right',
              ...variant === SKILL_ADVISORS ? {
                offset: [0, -spacing(1.625)],
                fontSize: theme.typography.caption.fontSize
              } : {},
              formatter: value2LabelFormatter
            }
          } : {},
          ...(variant === SKILL_COURSES && {
            barGap: '-100%'
          }) || (idx === 0 && {
            barGap: '-100%',
            barCategoryGap: '-100%'
          }) || (idx === 1 && {
            barWidth: '12.5%'
          }) || {
            barWidth: '24%',
            label: preview ? null : {
              show: true,
              color: theme.palette.text.primary,
              distance: spacing(1.5),
              position: 'left',
              fontSize: theme.typography.body2.fontSize
            }
          }
        }) || (idx >= SKILL_LEVEL_MAX && {
          stack: undefined,
          barWidth: '12.5%'
        }) || {},
        name: labels[idx],
        data: hasNoConfirmed && idx === 1 ? [] : record,
        itemStyle: (colors && { ...style, color: colors[idx] }) ||
          (idx === 2 && variant === SKILL_ADVISORS && {
            ...style,
            borderWidth: theme.shape.borderWidth,
            borderRadius: theme.shape.smallBorderRadius
          }) ||
          (idx >= SKILL_LEVEL_MAX && {
            ...style,
            color: theme.palette.background.card,
            borderColor: theme.palette.chart.infoBarBorder,
            borderWidth: theme.shape.thinBorderWidth,
            borderRadius: theme.shape.smallBorderRadius
          }) || style
      }))
    }, true);
    echartInstance.resize();
  }, [
    categories, counts, totalEmployees, pending,
    labels, noEmployees, xLabel, x2Label, hasNoConfirmed, hrbp, percentage, path, skillNameWidth, variant, preview, theme,
    tooltipFormatter, xAxisLabelFormatter, yAxisLabelFormatter, valueLabelFormatter, value2LabelFormatter, formatNumber
  ]);

  return (
    <Chart
        ref={chartRef}
        option={GlobalEChartsStyles}
        className={(preview && chartPreview) || (wide && chart) || chartNarrow}
        onEvents={pending ? undefined : onEvents}
    />
  );
});

TopChartStacked.displayName = 'TopChartStacked';

TopChartStacked.propTypes = TopChartStackedPropTypes;

export default memo(TopChartStacked);
