import { forwardRef, memo, useContext, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// EmPath UI Components
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { LookupDelegateItem } from '../models/lookupItem';
import { LookupContext } from '../context';
import { LimitParams } from '../context/commonParams';
import { DelegatesParams, LeadersParams } from '../context/lookups';
// SCSS imports
import { root } from './DelegateSearch.module.scss';

type DelegateSearchProps = {
  value?: LookupDelegateItem | null;
  leaderId?: number | null;
  onChange: (item: LookupDelegateItem | null) => void;
  exclude?: number[] | null;
  disabled?: boolean | null;
  fullWidth?: boolean;
  className?: string;
}

const DelegateSearchPropTypes = {
  // attributes
  value: PropTypes.object as Validator<LookupDelegateItem>,
  leaderId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string
};

const DelegateSearch = forwardRef<HTMLDivElement, DelegateSearchProps>(({
  value,
  leaderId,
  onChange,
  exclude,
  disabled = false,
  fullWidth = false,
  className
}, ref) => {
  const {
    [leaderId ? 'delegates' : 'leaders']: { data, pending, failed, params },
    [leaderId ? 'requireDelegates' : 'requireLeaders']: fetch
  } = useContext(LookupContext);

  const fetchParams = useMemo(() => leaderId
    ? { leader_id: leaderId } as DelegatesParams & LeadersParams & LimitParams
    : undefined
  , [leaderId]);

  return fetch ? (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type={leaderId ? 'delegates' : 'leaders'}
        className={clsx(root, className)}
        fetched={data}
        pending={pending}
        failed={failed}
        params={params as DelegatesParams & LeadersParams & LimitParams}
        fetch={fetch}
        fetchParams={fetchParams}
        exclude={exclude}
        value={value}
        onChange={onChange}
        disabled={disabled}
        popupIcon={<SearchIcon/>}
    />
  ) : null;
});

DelegateSearch.displayName = 'DelegateSearch';

DelegateSearch.propTypes = DelegateSearchPropTypes;

export default memo(DelegateSearch);
