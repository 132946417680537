/* eslint-disable max-lines */
import { memo, useMemo, useState, useCallback, useEffect, useRef, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import size from 'lodash/size';
import map from 'lodash/map';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import toSafeInteger from 'lodash/toSafeInteger';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage, type MessageDescriptor } from 'react-intl';
// Material UI imports
import { type Theme, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// EmPath UI Components
import { type EChartsMouseEvent } from '@empathco/ui-components/src/helpers/echarts';
import { type Values } from '@empathco/ui-components/src/helpers/intl';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { Job } from '../models/job';
import useNonReducedUI, { EmployeeManagementLevel } from '../constants/managementLevel';
import { GlobalEChartsStyles } from '../config/params';
import { PATH_JOB } from '../config/paths';
import Chart from '../elements/Chart';
// SCSS imports
import { chart, reset } from './JobMatch.module.scss';

const cloneCurrentRole = (currentRole: Job): Job => ({
  ...cloneDeep(currentRole),
  management_level: toSafeInteger(currentRole.management_level) as EmployeeManagementLevel,
  effort_level: toSafeInteger(currentRole.effort_level)
});

// return top movement_rate
const setTopMatches = (data: Job[]) => {
  const targetNode = maxBy(data,
    // find the job with maximum `movement_rate`, but if there is a number of jobs with the same `movement_rate`,
    // we prefer the one with highest `match_rate`; minus 1M for `is_current` makes the current job the least
    // possible choice (and we are not going to set its `is_target` to true anyway - see `forEach` below)
    ({ movement_rate, match_rate, is_current }) => 1000 * (movement_rate || 0) + (match_rate || 0) - (is_current ? 1000000 : 0)
  ) || ({} as Job);
  return forEach(data, (jobItem) => {
    jobItem.is_target = jobItem.code === targetNode.code && !jobItem.is_current;
  });
};

const cloneData = (data: Job[], currentRole: Job) => {
  const clonedData = setTopMatches(cloneDeep(uniqBy(data, 'code')));
  if (currentRole && findIndex(clonedData, ['code', currentRole.code]) < 0) {
    clonedData.push(cloneCurrentRole(currentRole));
  }
  return clonedData;
};

const getLabel = (
  is_current: boolean | null | undefined,
  is_target: boolean | null | undefined,
  theme: Theme,
  formatMessage: (descriptor: MessageDescriptor, values?: Values) => ReactNode
) => {
  const color = is_current ? theme.palette.secondary.main : theme.palette.primary.contrastText;
  const label = {
    show: true,
    formatter: (params: unknown) => {
      const matchRate = get(params, 'data.value[3]');
      return isNil(matchRate) ? '' : formatMessage(
        { id: 'close_match_jobs.match_rate' },
        { match: toSafeInteger(matchRate) / 100 }
      );
    },
    rich: {
      value: {
        color,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold as 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
      },
      subtitle: {
        fontSize: 12,
        color
      }
    },
    color: undefined as string | undefined
  };
  if (is_target) {
    label.color = theme.palette.primary.contrastText;
  }
  return label;
};

const getDataItemStyle = (
  is_current: boolean | null | undefined,
  is_target: boolean | null | undefined,
  theme: Theme
) => {
  if (is_current) {
    return {
      color: theme.palette.background.paper,
      borderColor: theme.palette.primary.lighter,
      borderWidth: 2
    };
  }
  if (!is_target) {
    return {
      color: theme.palette.primary.light,
      borderWidth: 0
    };
  }
  return undefined;
};

interface FormattedData {
  data: object[];
  links: object[];
  markPointsData: Record<string, object | string | Function> & {
    data: object[];
  };
  source: Job | null;
  sourceX: number;
  sourceY: number;
}

const processGrid = (
  xStart: 0 | 1 | 2,
  yStart: 0 | 1 | 2 | 3,
  gridData: Job[],
  { data, links, markPointsData, source, sourceX, sourceY }: FormattedData,
  theme: Theme,
  formatMessage: (descriptor: MessageDescriptor, values?: Values) => ReactNode,
  showNonReducedUI: (role: Job) => boolean
  // eslint-disable-next-line max-params
) => {
  const { code: sourceCode } = source || {};
  let [xx, yy] = [xStart + 0.1, yStart + 0.85];

  // eslint-disable-next-line complexity
  forEach(gridData, (role) => {
    const { code, title, match_rate, movement_rate, is_target, is_current } = role;
    const showMatchRate = showNonReducedUI(role);
    const selected = is_target || is_current;
    const [actualX, actualY] = [
      is_current ? sourceX + 0.38 : xx,
      is_current ? sourceY + 0.28 : yy
    ];
    if (selected) {
      markPointsData.data.push({
        name: is_current ? `${title}{current|\n${formatMessage({ id: 'common.current_role' })}}` : title,
        value: code,
        coord: [actualX + 0.08, actualY - 0.14]
      });
    }
    if (is_target) {
      links.push({
        tooltip: {
          show: true,
          confine: true,
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.greys.popupBorder,
          borderWidth: 1,
          padding: 10,
          extraCssText: `box-shadow: ${theme.shadows[8]}`,
          textStyle: {
            fontSize: 16,
            color: theme.palette.text.label
          },
          formatter: (param: { data: { value?: number; }}) => formatMessage(
            { id: 'close_match_jobs.movement_rate' },
            { value: toSafeInteger(param.data.value) / 100 }
          )
        },
        source: sourceCode,
        target: code,
        value: movement_rate,
        symbolSize: [5, 20],
        label: {
          show: false
        },
        lineStyle: {
          opacity: is_target ? 1 : 0,
          // range 1-8, use movement rate percentage
          width: 1 + (toSafeInteger(movement_rate) / 100) * 8
        }
      });
    }

    data.push({
      // fixed: true, // instruct the 'force' layout to keep this node intact
      name: code, // name must be unique!
      value: [
        actualX,
        actualY,
        title,
        // any additional data we need
        showMatchRate ? toSafeInteger(match_rate) : null,
        code
      ],
      symbolSize: (is_current && 60) || (is_target && 65) || 20,
      tooltip: selected ? {} : {
        show: true,
        confine: true,
        trigger: 'item',
        position: is_current ? 'right' : 'bottom',
        borderWidth: 0,
        formatter: (params: unknown) => get(params, 'data.value[2]'),
        backgroundColor: theme.palette.background.card80,
        textStyle: {
          color: theme.palette.secondary.main,
          fontSize: 18,
          fontWeight: theme.typography.fontWeightBold
        },
        extraCssText: 'box-shadow: none'
      },
      label: selected ? getLabel(is_current, is_target, theme, formatMessage) : { show: false },
      itemStyle: getDataItemStyle(is_current, is_target, theme),
      emphasis: {
        itemStyle: {
          borderColor: theme.palette.primary.main,
          ...is_target && !is_current ? {
            color: theme.palette.primary.main,
            borderWidth: 0
          } : {
            color: theme.palette.background.paper,
            borderColor: theme.palette.primary.main
          },
          ...is_target || is_current ? {} : {
            borderWidth: 2,
            shadowColor: theme.palette.singleShadowColor,
            shadowBlur: 4,
            shadowOffsetX: 3,
            shadowOffsetY: 3
          }
        }
      }
    });
    if (!is_current) {
      yy -= 0.2;
      if (yy <= (yStart + 0.1 + (xStart === sourceX && yStart === sourceY ? 0.2 : 0))) {
        yy = yStart + 0.85;
        xx += 0.15; // 0.08;
      }
    }
  });
};

const formatJobData = (
  data: Job[],
  currentRole: Job,
  theme: Theme,
  formatMessage: (descriptor: MessageDescriptor, values?: Values) => ReactNode,
  showNonReducedUI: (role: Job) => boolean
) => {
  const formattedData: FormattedData = {
    data: [],
    markPointsData: {
      tooltip: {
        show: false
      },
      symbol: 'rect',
      symbolSize: (_value: unknown, { name }: { name: string; }) => [9 * size(name), 32],
      itemStyle: {
        color: 'transparent'
      },
      emphasis: {
        label: {
          color: theme.palette.secondary.hover
        }
      },
      label: {
        show: true,
        width: spacing(36),
        overflow: 'break',
        align: 'center',
        position: 'bottom',
        distance: -spacing(1.5),
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.card80,
        fontSize: 18,
        fontWeight: theme.typography.fontWeightBold,
        formatter: (params?: { name: string; } | null) => params ? params.name : '',
        rich: {
          current: {
            display: 'block',
            align: 'center',
            verticalAlign: 'bottom',
            color: theme.palette.info.light,
            fontSize: 15,
            fontStyle: 'oblique',
            fontWeight: theme.typography.fontWeightRegular
          }
        }
      },
      data: []
    },
    links: [],
    source: currentRole ? cloneCurrentRole(currentRole) : null,
    // find anchor point for current job
    sourceX: (minBy(data, (jobItem) => jobItem.effort_level)?.effort_level || 0) - 1,
    sourceY: (minBy(data, (jobItem) => jobItem.management_level)?.management_level || 0) - 1
  };

  // filter data by grid location
  const grid = [
    filter(data, ({ effort_level, management_level }) => effort_level === 1 && management_level === 1),
    filter(data, ({ effort_level, management_level }) => effort_level === 2 && management_level === 1),
    filter(data, ({ effort_level, management_level }) => effort_level === 3 && management_level === 1),
    filter(data, ({ effort_level, management_level }) => effort_level === 1 && management_level === 2),
    filter(data, ({ effort_level, management_level }) => effort_level === 2 && management_level === 2),
    filter(data, ({ effort_level, management_level }) => effort_level === 3 && management_level === 2),
    filter(data, ({ effort_level, management_level }) => effort_level === 1 && management_level === 3),
    filter(data, ({ effort_level, management_level }) => effort_level === 2 && management_level === 3),
    filter(data, ({ effort_level, management_level }) => effort_level === 3 && management_level === 3),
    filter(data, ({ effort_level, management_level }) => effort_level === 1 && management_level === 4),
    filter(data, ({ effort_level, management_level }) => effort_level === 2 && management_level === 4),
    filter(data, ({ effort_level, management_level }) => effort_level === 3 && management_level === 4)
  ];

  // row 0
  processGrid(0, 0, grid[0], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(1, 0, grid[1], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(2, 0, grid[2], formattedData, theme, formatMessage, showNonReducedUI);

  // row 1
  processGrid(0, 1, grid[3], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(1, 1, grid[4], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(2, 1, grid[5], formattedData, theme, formatMessage, showNonReducedUI);

  // row 2
  processGrid(0, 2, grid[6], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(1, 2, grid[7], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(2, 2, grid[8], formattedData, theme, formatMessage, showNonReducedUI);

  // row 3
  processGrid(0, 3, grid[9], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(1, 3, grid[10], formattedData, theme, formatMessage, showNonReducedUI);
  processGrid(2, 3, grid[11], formattedData, theme, formatMessage, showNonReducedUI);

  return formattedData;
};

const updateData = (prevData: Job[], chartEvent: { name?: string; }) => {
  const { name } = chartEvent || {};
  if (!name) return prevData;
  const node = find(prevData, ({ code }) => code === name);
  if (!node) return prevData;
  if (node.is_current || node.is_target) {
    const role_id = get(chartEvent, 'value[4]');
    if (!isEmptyString(role_id)) return injectParams(PATH_JOB, { role_id });
    return prevData;
  }
  return map(prevData, (jobItem) => {
    const is_target = jobItem.code === name;
    return jobItem.is_target === is_target ? jobItem : { ...jobItem, is_target };
  });
};

type JobMatchProps = {
  title?: string | null;
  data: Job[];
  currentRole: Job;
}

const JobMatchPropTypes = {
  // attributes
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  currentRole: PropTypes.object.isRequired as Validator<Job>
};

// eslint-disable-next-line max-lines-per-function
const JobMatch: FunctionComponent<JobMatchProps> = ({
  title,
  data: jobsData,
  currentRole
}) => {
  const { showNonReducedUI } = useNonReducedUI();

  const theme = useTheme();
  const navigate = useNavigate();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const chartRef = useRef<EChartsReactCore>(null);

  const [key, setKey] = useState(0);
  const [origData, setOrigData] = useState<Job[]>(cloneData(jobsData, currentRole));
  const [sourceData, setSourceData] = useState<Job[]>(cloneDeep(origData));
  const [data, setData] = useState<FormattedData>(
    formatJobData(sourceData, currentRole, theme, formatMessage, showNonReducedUI));
  const [changed, setChanged] = useState(false);
  const [goto, setGoto] = useState<string | null>(null);

  const handleClearJobsData = useCallback(() => {
    const newSourceData = cloneDeep(origData);
    setSourceData(newSourceData);
    setData(formatJobData(newSourceData, currentRole, theme, formatMessage, showNonReducedUI));
    setChanged(false);
    setKey((prevKey) => prevKey + 1);
  }, [currentRole, origData, theme, formatMessage, showNonReducedUI]);

  const onEvents = useMemo(() => ({
    click: (event: EChartsMouseEvent & { name?: string; data?: { value?: string; } }) => {
      if (event?.componentType === 'markPoint') {
        const role_id = event.data?.value;
        if (!isEmptyString(role_id)) setGoto(injectParams(PATH_JOB, { role_id }));
      } else if (event?.componentType === 'series') {
        setSourceData((prevSourceData) => {
          const newSourceData = updateData(prevSourceData, event);
          if (isString(newSourceData)) {
            setGoto(newSourceData);
            return prevSourceData;
          }
          const isChanged = !isEqual(origData, newSourceData);
          setChanged(isChanged);
          if (isChanged) setKey((prevKey) => prevKey + 1);
          setData(formatJobData(newSourceData, currentRole, theme, formatMessage, showNonReducedUI));
          return newSourceData;
        });
      }
    }
  }), [currentRole, origData, theme, formatMessage, showNonReducedUI]);

  useEffect(() => {
    const newOrigData = cloneData(jobsData, currentRole);
    setOrigData(newOrigData);
    const newSourceData = cloneDeep(newOrigData);
    setSourceData(newSourceData);
    setData(formatJobData(newSourceData, currentRole, theme, formatMessage, showNonReducedUI));
    setChanged(false);
    setKey((prevKey) => prevKey + 1);
  }, [currentRole, jobsData, theme, formatMessage, showNonReducedUI]);

  useEffect(() => {
    if (goto) navigate(goto);
  }, [goto, navigate]);

  const [jobMatchesText, levelOfEffortText, jobLevelText] = useMemo(() => [
    formatMessage({ id: 'close_match_jobs.title' }, { uid: null }),
    formatMessage({ id: 'close_match_jobs.level_of_effort' }),
    formatMessage({ id: 'close_match_jobs.job_level' })
  ], [formatMessage]);

  const effortLevels = useMemo(() => ({
    1: formatMessage({ id: 'common.effort_level.number' }, { level: 1 }),
    2: formatMessage({ id: 'common.effort_level.number' }, { level: 2 }),
    3: formatMessage({ id: 'common.effort_level.number' }, { level: 3 })
  }), [formatMessage]);

  const jobLevels = useMemo(() => ({
    1: formatMessage({ id: 'common.job_level.number' }, { level: 1 }),
    2: formatMessage({ id: 'common.job_level.number' }, { level: 2 }),
    3: formatMessage({ id: 'common.job_level.number' }, { level: 3 }),
    4: formatMessage({ id: 'common.job_level.number' }, { level: 4 })
  }), [formatMessage]);

  useEffect(() => {
    if (!chartRef.current) return;

    const echartInstance = chartRef.current.getEchartsInstance();

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      tooltip: {
        show: true,
        confine: true
      },
      xAxis: {
        show: true,
        axisTick: false,
        type: 'value',
        splitNumber: 3,
        scale: true,
        minInterval: 1,
        interval: 1,
        offset: 10,
        name: levelOfEffortText,
        nameLocation: 'end',
        nameTextStyle: {
          align: 'right',
          verticalAlign: 'top',
          padding: [45, 25, 0, 0],
          fontSize: 17,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.text.label
        },
        axisLine: {
          show: false
        },
        splitLine: {
          lineStyle: {
            width: 3,
            color: theme.palette.background.paper
          }
        },
        axisLabel: {
          padding: [0, 250, 0, 0],
          fontSize: 16,
          formatter: (value: 1 | 2 | 3) => effortLevels[value]
        }
      },
      yAxis: {
        axisTick: false,
        name: jobLevelText,
        nameLocation: 'end',
        nameTextStyle: {
          padding: [0, 0, 0, 100],
          fontSize: 17,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.text.label
        },
        type: 'value',
        offset: 10,
        minInterval: 1,
        splitNumber: 4,
        scale: true,
        axisLine: {
          show: false
        },
        splitLine: {
          lineStyle: {
            width: 3,
            color: theme.palette.background.paper
          }
        },
        axisLabel: {
          padding: [300, 0, 0, 0],
          align: 'right',
          fontSize: 16,
          formatter: (value: 1 | 2 | 3 | 4) => jobLevels[value]
        }
      },
      grid: {
        show: true,
        top: 50,
        bottom: 80,
        left: 60,
        right: 25,
        backgroundColor: theme.palette.background.card
      },
      series: [
        {
          name: jobMatchesText,
          type: 'graph',
          layout: 'force', // layout 'none' does not work with storybook
          animation: false,
          emphasis: {
            scale: false
          },
          // force: {
          //   repulsion: 50,
          //   gravity: 0.1,
          //   edgeLength: 30,
          //   layoutAnimation: true,
          //   friction: 0.6
          // },
          coordinateSystem: 'cartesian2d',
          symbolSize: 50,
          label: {
            show: false
            // TODO: fancy label format with Typography and formatMessage
            // formatter: (params) => (params.data.value[3] + '%\n match')
          },
          itemStyle: {
            borderColor: theme.palette.primary.contrastText,
            borderWidth: 2,
            color: {
              type: 'linear',
              // eslint-disable-next-line id-length
              x: 1,
              // eslint-disable-next-line id-length
              y: 1,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: theme.palette.primary.shifted // color at 0% position
                }, {
                  offset: 1,
                  color: theme.palette.primary.main // color at 100% position
                }
              ],
              global: false // false by default
            }
          },
          tooltip: {
            show: false
          },
          markPoint: data.markPointsData,
          data: data.data,
          links: data.links,
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 10],
          edgeLabel: {
            fontSize: 20
          },
          lineStyle: {
            color: {
              type: 'linear',
              // eslint-disable-next-line id-length
              x: 1,
              // eslint-disable-next-line id-length
              y: 1,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: theme.palette.success.light // color at 0% position
                }, {
                  offset: 1,
                  color: theme.palette.success.dark // color at 100% position
                }
              ],
              global: false
            },
            opacity: 0.9,
            width: 1,
            curveness: 0.3
          }
        }
      ]
    }, true);
    echartInstance.resize();
  }, [data, effortLevels, jobLevelText, jobLevels, jobMatchesText, levelOfEffortText, theme]);

  return (
    <>
      {title ? (
        <BoxTypography
            flexGrow={1}
            pl={8}
            pr={3.5}
            pt={2}
            pb={0.5}
            variant="body1"
            fontStyle="italic"
            color="text.label"
        >
          <FormattedMessage id={title} defaultMessage={title}/>
        </BoxTypography>
      ) : undefined}
      <Box display="flex" flexGrow={1} position="relative">
        <Chart key={key} ref={chartRef} option={GlobalEChartsStyles} className={chart} onEvents={onEvents}/>
        {changed ? (
          <Box className={reset}>
            <Button
                color="primary"
                variant="contained"
                disableElevation
                size="small"
                onClick={handleClearJobsData}
            >
              <FormattedMessage id="close_match_jobs.button.text"/>
            </Button>
          </Box>
        ) : undefined}
      </Box>
    </>
  );
};

JobMatch.propTypes = JobMatchPropTypes;

export default memo(JobMatch);
