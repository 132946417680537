// polyfill imports
import 'react-app-polyfill/stable';
// React imports
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import includes from 'lodash/includes';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
// Material UI imports
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// EmPath UI Components
import { themeOptions } from '@empathco/ui-components/src/styles/themeOptions';
import SvgGradients from '@empathco/ui-components/src/common/SvgGradients';
// local imports
import { nonce } from './config/contentSecurityPolicy';
import LocalizationProvider from './config/LocalizationProvider';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// SCSS imports
import './styles/index.scss';

const cache = createCache({
  key: 'css',
  prepend: true,
  nonce
});

const tssCache = createCache({
  key: 'tss',
  nonce
});

const theme = createTheme(themeOptions);

// Suppress EvalError exceptions reporting to Sentry
window.onunhandledrejection = (event) => {
  if (!includes(event?.reason, 'unsafe-eval')) throw event;
};

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <CookiesProvider>
        <LocalizationProvider>
          <CacheProvider value={cache}>
            <TssCacheProvider value={tssCache}>
              <ThemeProvider theme={theme}>
                <CssBaseline/>
                <App/>
                <SvgGradients/>
              </ThemeProvider>
            </TssCacheProvider>
          </CacheProvider>
        </LocalizationProvider>
      </CookiesProvider>
    </StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
