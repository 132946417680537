/* eslint-disable max-lines */
import { useReducer, useEffect, useContext, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import transform from 'lodash/transform';
import map from 'lodash/map';
import size from 'lodash/size';
import trim from 'lodash/trim';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import replace from 'lodash/replace';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isSafeInteger from 'lodash/isSafeInteger';
import { useIntl, type IntlShape } from 'react-intl';
// EmPath UI Components
import { getFullName, getStringifiedIds } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { Org } from '../graphql/types';
import {
  SET_ACTIONS, UNAUTHENTICATED, APP_ONLINE, NAVIGATION, FETCHING, FETCHED, PARAMS, CommonActions
} from '../constants/actionTypes';
import {
  API_MY_SEARCH, API_EMPLOYEE_SKILLS, API_JOBS, API_COUNTRIES, API_STATES,
  API_SKILLS, API_ORGS, API_CAREER_LADDERS, API_JOB_CATEGORIES, API_COURSE_PROVIDERS, API_SKILLS_IN_DEMAND,
  API_LOOKUP_LEADERS, API_LOOKUP_DELEGATES
} from '../config/api';
import { PATH_HOME } from '../config/paths';
import { MAX_COUNTRIES_OPTIONS, MAX_LOOKUP_OPTIONS } from '../config/params';
import { history } from '../config/history';
import { ContextEntity, ContextEntityProp } from '../models/contextEntity';
import { LookupAnything, LookupItem, LookupSkill, LookupDelegateItem } from '../models/lookupItem';
import { Anything } from '../models/anything';
import { Skill } from '../models/skill';
import { Job } from '../models/job';
import { State } from '../models/state';
import { Employee } from '../models/employee';
import { getSelectedLeaderId } from '../models/user';
import { getInitialState, getPendingState, getFetchedState, clearActionStates, updateStateParams } from '../helpers/context';
import { fetchFactory } from '../helpers/actions';
import useModels from '../helpers/models';
import { GlobalContext, LookupContext } from '.';
import { LimitParams, LeaderParams } from './commonParams';

export interface AnythingParams {
  search?: string;
  // offset?: number;
}

export interface JobsSearchParams {
  search?: string;
  with_skills_only?: boolean;
  // offset?: number;
}

export interface CountriesParams {
  local?: boolean;
}

export interface StatesParams {
  country_id?: number;
  local?: boolean;
}

export interface SklsParams {
  supervisor?: boolean;
  exclude_ids?: number[];
  search?: string;
  exclude_existing_skills?: boolean;
  // exclude_skills_for_employee_id?: number;
  limit?: number;
  // offset?: number;
}

export interface LeadersParams {
  search?: string;
}

export interface DelegatesParams {
  leader_id?: number;
  search?: string;
}

export interface InDemandParams {
  org_id: number;
  onSuccess?: (data: Skill[]) => void;
}

export interface ILookupsState {
  orgs: ContextEntity<Org, LeaderParams & LimitParams>;
  jobCategories: ContextEntity<LookupItem, LimitParams>;
  careerLadders: ContextEntity<LookupItem, LimitParams>;
  courseProviders: ContextEntity<LookupItem, LimitParams>;
  anything: ContextEntity<LookupAnything, AnythingParams & LimitParams>;
  jobsSearch: ContextEntity<Job & LookupItem, JobsSearchParams & LimitParams>;
  countries: ContextEntity<LookupItem, CountriesParams & LimitParams>;
  states: ContextEntity<LookupItem, StatesParams & LimitParams>;
  // TODO: separate lookups for Employee (<Skill>) and for Supervisor (<LookupSkill>)
  skls: ContextEntity<LookupSkill, SklsParams & LimitParams>;
  leaders: ContextEntity<LookupDelegateItem, LeadersParams & LimitParams>;
  delegates: ContextEntity<LookupDelegateItem, DelegatesParams & LimitParams>;
  // Not lookup data really, but some common entities:
  inDemand: ContextEntity<Skill, InDemandParams & LimitParams>;

  requireOrgs?: () => void;
  requireJobCategories?: () => void;
  requireCareerLadders?: () => void;
  requireCourseProviders?: () => void;
  requireAnything?: (params: AnythingParams) => void;
  requireJobsSearch?: (params: JobsSearchParams) => void;
  requireCountries?: (params?: CountriesParams) => void;
  requireStates?: (params: StatesParams) => void;
  requireSkls?: (params: SklsParams) => void;
  requireLeaders?: (params: LeadersParams) => void;
  requireDelegates?: (params: DelegatesParams) => void;
  requireInDemand?: (params: InDemandParams) => void;
  clearAnything?: () => void;
  clearJobsSearch?: () => void;
}

export const LookupsStatePropTypes = PropTypes.shape({
  orgs: ContextEntityProp,
  jobCategories: ContextEntityProp,
  careerLadders: ContextEntityProp,
  courseProviders: ContextEntityProp,
  anything: ContextEntityProp,
  jobsSearch: ContextEntityProp,
  countries: ContextEntityProp,
  states: ContextEntityProp,
  skls: ContextEntityProp,
  leaders: ContextEntityProp,
  delegates: ContextEntityProp,
  inDemand: ContextEntityProp,
  requireAnything: PropTypes.func,
  requireJobsSearch: PropTypes.func,
  requireCountries: PropTypes.func,
  requireStates: PropTypes.func,
  requireSkls: PropTypes.func,
  requireLeaders: PropTypes.func,
  requireDelegates: PropTypes.func,
  requireInDemand: PropTypes.func,
  clearAnything: PropTypes.func,
  clearJobsSearch: PropTypes.func
}) as Validator<ILookupsState>;

export const initialLookupsState: ILookupsState = {
  // fetched data
  orgs: getInitialState(),
  jobCategories: getInitialState(),
  careerLadders: getInitialState(),
  courseProviders: getInitialState(),
  anything: getInitialState(),
  jobsSearch: getInitialState(),
  countries: getInitialState(),
  states: getInitialState(),
  skls: getInitialState(),
  leaders: getInitialState(),
  delegates: getInitialState(),
  inDemand: getInitialState()
};

// Transformation Functions - must NOT mutate the objects!

export const transformAnything = (anything: Anything[]) => map(anything, ({ item_type, item_id, item_code, title }) => ({
  id: `${item_type}-${item_id}`,
  title,
  item_code,
  item_type
} as LookupAnything));

export const generateTransformStates = (isInternational: (country?: string) => boolean) =>
  (states: State[]) => transform(states, (result, state) => {
    const { id, title, country } = state || {};
    if (!isInternational(country?.title)) result.push({ id, title } as LookupItem);
  }, [] as LookupItem[]);

export const generateTransformLookupDelegates = (intl: IntlShape) => (delegates: Employee[]) =>
  map(delegates, (employee) => {
    const { id, code, email, first_name, last_name, delegated_for } = employee;
    const { code: d_code, first_name: d_first_name, last_name: d_last_name } = delegated_for || {};
    const pure_name = first_name || last_name ? `${getFullName(first_name, last_name)} - ${code}` : code;
    const is_delegated = Boolean(delegated_for);
    const delegated_for_name = is_delegated
      ? ((d_first_name || d_last_name) && `${getFullName(d_first_name, d_last_name)}`) || d_code
      : undefined;
    const title = intl
      ? intl.formatMessage({ id: 'delegates.delegate_name' }, { pure_name, is_delegated, delegated_for_name })
      : pure_name;
    return { id, code, email, title, pure_name, is_delegated } as LookupDelegateItem;
  });

const ORGS_FETCH = 'ORGS' as const;
const ORGS_FETCHING = `${ORGS_FETCH}${FETCHING}` as const;
const ORGS_FETCHED = `${ORGS_FETCH}${FETCHED}` as const;
const ORGS_PARAMS = `${ORGS_FETCH}${PARAMS}` as const;

const JOB_CATEGORIES_FETCH = 'JOB_CATEGORIES' as const;
const JOB_CATEGORIES_FETCHING = `${JOB_CATEGORIES_FETCH}${FETCHING}` as const;
const JOB_CATEGORIES_FETCHED = `${JOB_CATEGORIES_FETCH}${FETCHED}` as const;
const JOB_CATEGORIES_PARAMS = `${JOB_CATEGORIES_FETCH}${PARAMS}` as const;

const CAREER_LADDERS_FETCH = 'CAREER_LADDERS' as const;
const CAREER_LADDERS_FETCHING = `${CAREER_LADDERS_FETCH}${FETCHING}` as const;
const CAREER_LADDERS_FETCHED = `${CAREER_LADDERS_FETCH}${FETCHED}` as const;
const CAREER_LADDERS_PARAMS = `${CAREER_LADDERS_FETCH}${PARAMS}` as const;

const COURSE_PROVIDERS_FETCH = 'COURSE_PROVIDERS' as const;
const COURSE_PROVIDERS_FETCHING = `${COURSE_PROVIDERS_FETCH}${FETCHING}` as const;
const COURSE_PROVIDERS_FETCHED = `${COURSE_PROVIDERS_FETCH}${FETCHED}` as const;
const COURSE_PROVIDERS_PARAMS = `${COURSE_PROVIDERS_FETCH}${PARAMS}` as const;

const ANYTHING_FETCH = 'ANYTHING' as const;
const ANYTHING_FETCHING = `${ANYTHING_FETCH}${FETCHING}` as const;
const ANYTHING_FETCHED = `${ANYTHING_FETCH}${FETCHED}` as const;
const ANYTHING_PARAMS = `${ANYTHING_FETCH}${PARAMS}` as const;
const ANYTHING_CLEAR = 'ANYTHING_CLEAR' as const;

const JOBS_SEARCH_FETCH = 'JOBS_SEARCH' as const;
const JOBS_SEARCH_FETCHING = `${JOBS_SEARCH_FETCH}${FETCHING}` as const;
const JOBS_SEARCH_FETCHED = `${JOBS_SEARCH_FETCH}${FETCHED}` as const;
const JOBS_SEARCH_PARAMS = `${JOBS_SEARCH_FETCH}${PARAMS}` as const;
const JOBS_SEARCH_CLEAR = 'JOBS_SEARCH_CLEAR' as const;

const SKLS_FETCH = 'SKLS' as const;
const SKLS_FETCHING = `${SKLS_FETCH}${FETCHING}` as const;
const SKLS_FETCHED = `${SKLS_FETCH}${FETCHED}` as const;
const SKLS_PARAMS = `${SKLS_FETCH}${PARAMS}` as const;

const COUNTRIES_FETCH = 'COUNTRIES' as const;
const COUNTRIES_FETCHING = `${COUNTRIES_FETCH}${FETCHING}` as const;
const COUNTRIES_FETCHED = `${COUNTRIES_FETCH}${FETCHED}` as const;
const COUNTRIES_PARAMS = `${COUNTRIES_FETCH}${PARAMS}` as const;

const STATES_FETCH = 'STATES' as const;
const STATES_FETCHING = `${STATES_FETCH}${FETCHING}` as const;
const STATES_FETCHED = `${STATES_FETCH}${FETCHED}` as const;
const STATES_PARAMS = `${STATES_FETCH}${PARAMS}` as const;

const LEADERS_FETCH = 'LEADERS' as const;
const LEADERS_FETCHING = `${LEADERS_FETCH}${FETCHING}` as const;
const LEADERS_FETCHED = `${LEADERS_FETCH}${FETCHED}` as const;
const LEADERS_PARAMS = `${LEADERS_FETCH}${PARAMS}` as const;

const DELEGATES_FETCH = 'DELEGATES' as const;
const DELEGATES_FETCHING = `${DELEGATES_FETCH}${FETCHING}` as const;
const DELEGATES_FETCHED = `${DELEGATES_FETCH}${FETCHED}` as const;
const DELEGATES_PARAMS = `${DELEGATES_FETCH}${PARAMS}` as const;

const IN_DEMAND_FETCH = 'IN_DEMAND' as const;
const IN_DEMAND_FETCHING = `${IN_DEMAND_FETCH}${FETCHING}` as const;
const IN_DEMAND_FETCHED = `${IN_DEMAND_FETCH}${FETCHED}` as const;
const IN_DEMAND_PARAMS = `${IN_DEMAND_FETCH}${PARAMS}` as const;

export type LookupActions =
  | { type: typeof ORGS_FETCHING; payload?: null; params: LeaderParams & LimitParams | null; }
  | { type: typeof ORGS_FETCHED; payload: Org[] | null; params: LeaderParams & LimitParams; }
  | { type: typeof ORGS_PARAMS; params: LeaderParams & LimitParams; }
  | { type: typeof JOB_CATEGORIES_FETCHING; payload?: null; params: LimitParams | null; }
  | { type: typeof JOB_CATEGORIES_FETCHED; payload: LookupItem[] | null; params: LimitParams; }
  | { type: typeof JOB_CATEGORIES_PARAMS; params: LimitParams; }
  | { type: typeof CAREER_LADDERS_FETCHING; payload?: null; params: LimitParams | null; }
  | { type: typeof CAREER_LADDERS_FETCHED; payload: LookupItem[] | null; params: LimitParams; }
  | { type: typeof CAREER_LADDERS_PARAMS; params: LimitParams; }
  | { type: typeof COURSE_PROVIDERS_FETCHING; payload?: null; params: LimitParams | null; }
  | { type: typeof COURSE_PROVIDERS_FETCHED; payload: LookupItem[] | null; params: LimitParams; }
  | { type: typeof COURSE_PROVIDERS_PARAMS; params: LimitParams; }
  | { type: typeof ANYTHING_FETCHING; payload?: null; params: AnythingParams & LimitParams | null; }
  | { type: typeof ANYTHING_FETCHED; payload: LookupAnything[] | null; params: AnythingParams & LimitParams; }
  | { type: typeof ANYTHING_PARAMS; params: AnythingParams & LimitParams; }
  | { type: typeof ANYTHING_CLEAR; }
  | { type: typeof JOBS_SEARCH_FETCHING; payload?: null; params: JobsSearchParams & LimitParams | null; }
  | { type: typeof JOBS_SEARCH_FETCHED; payload: (Job & LookupItem)[] | null; params: JobsSearchParams & LimitParams; }
  | { type: typeof JOBS_SEARCH_PARAMS; params: JobsSearchParams & LimitParams; }
  | { type: typeof JOBS_SEARCH_CLEAR; }
  | { type: typeof SKLS_FETCHING; payload?: null; params: SklsParams & LimitParams | null; }
  | { type: typeof SKLS_FETCHED; payload: LookupSkill[] | null; params: SklsParams & LimitParams; }
  | { type: typeof SKLS_PARAMS; params: SklsParams & LimitParams; }
  | { type: typeof COUNTRIES_FETCHING; payload?: null; params: CountriesParams & LimitParams | null; }
  | { type: typeof COUNTRIES_FETCHED; payload: LookupItem[] | null; params: CountriesParams & LimitParams; }
  | { type: typeof COUNTRIES_PARAMS; params: CountriesParams & LimitParams; }
  | { type: typeof STATES_FETCHING; payload?: null; params: StatesParams & LimitParams | null; }
  | { type: typeof STATES_FETCHED; payload: LookupItem[] | null; params: StatesParams & LimitParams; }
  | { type: typeof STATES_PARAMS; params: StatesParams & LimitParams; }
  | { type: typeof LEADERS_FETCHING; payload?: null; params: LeadersParams & LimitParams | null; }
  | { type: typeof LEADERS_FETCHED; payload: LookupDelegateItem[] | null; params: LeadersParams & LimitParams; }
  | { type: typeof LEADERS_PARAMS; params: LeadersParams & LimitParams; }
  | { type: typeof DELEGATES_FETCHING; payload?: null; params: DelegatesParams & LimitParams | null; }
  | { type: typeof DELEGATES_FETCHED; payload: LookupDelegateItem[] | null; params: DelegatesParams & LimitParams; }
  | { type: typeof DELEGATES_PARAMS; params: DelegatesParams & LimitParams; }
  | { type: typeof IN_DEMAND_FETCHING; payload?: null; params: InDemandParams & LimitParams | null; }
  | { type: typeof IN_DEMAND_FETCHED; payload: Skill[] | null; params: InDemandParams & LimitParams; }
  | { type: typeof IN_DEMAND_PARAMS; params: InDemandParams & LimitParams; }
  | CommonActions;

// eslint-disable-next-line complexity
const lookupReducer = (state: ILookupsState, action: LookupActions): ILookupsState => {
  switch (action.type) {

    case SET_ACTIONS: return { ...state, ...action.payload };

    case ORGS_FETCHING: return { ...state, orgs: getPendingState(action.params) };
    case ORGS_FETCHED: return { ...state, orgs: getFetchedState(action.payload, action.params) };
    case ORGS_PARAMS: return { ...state, orgs: updateStateParams(state.orgs, action.params) };

    case JOB_CATEGORIES_FETCHING: return { ...state, jobCategories: getPendingState(action.params) };
    case JOB_CATEGORIES_FETCHED: return { ...state, jobCategories: getFetchedState(action.payload, action.params) };
    case JOB_CATEGORIES_PARAMS: return { ...state, jobCategories: updateStateParams(state.jobCategories, action.params) };

    case CAREER_LADDERS_FETCHING: return { ...state, careerLadders: getPendingState(action.params) };
    case CAREER_LADDERS_FETCHED: return { ...state, careerLadders: getFetchedState(action.payload, action.params) };
    case CAREER_LADDERS_PARAMS: return { ...state, careerLadders: updateStateParams(state.careerLadders, action.params) };

    case COURSE_PROVIDERS_FETCHING: return { ...state, courseProviders: getPendingState(action.params) };
    case COURSE_PROVIDERS_FETCHED: return { ...state, courseProviders: getFetchedState(action.payload, action.params) };
    case COURSE_PROVIDERS_PARAMS: return { ...state, courseProviders: updateStateParams(state.courseProviders, action.params) };

    case ANYTHING_FETCHING: return { ...state, anything: getPendingState(action.params) };
    case ANYTHING_FETCHED: return { ...state, anything: getFetchedState(action.payload, action.params) };
    case ANYTHING_PARAMS: return { ...state, anything: updateStateParams(state.anything, action.params) };
    case ANYTHING_CLEAR: return { ...state, anything: getFetchedState([], { search: '' }) };

    case JOBS_SEARCH_FETCHING: return { ...state, jobsSearch: getPendingState(action.params) };
    case JOBS_SEARCH_FETCHED: return { ...state, jobsSearch: getFetchedState(action.payload, action.params) };
    case JOBS_SEARCH_PARAMS: return { ...state, jobsSearch: updateStateParams(state.jobsSearch, action.params) };
    case JOBS_SEARCH_CLEAR: return { ...state, jobsSearch: getFetchedState([], { search: '' }) };

    case SKLS_FETCHING: return { ...state, skls: getPendingState(action.params) };
    case SKLS_FETCHED: return { ...state, skls: getFetchedState(action.payload, action.params) };
    case SKLS_PARAMS: return { ...state, skls: updateStateParams(state.skls, action.params) };

    case COUNTRIES_FETCHING: return { ...state, countries: getPendingState(action.params) };
    case COUNTRIES_FETCHED: return { ...state, countries: getFetchedState(action.payload, action.params) };
    case COUNTRIES_PARAMS: return { ...state, countries: updateStateParams(state.countries, action.params) };

    case STATES_FETCHING: return { ...state, states: getPendingState(action.params) };
    case STATES_FETCHED: return { ...state, states: getFetchedState(action.payload, action.params) };
    case STATES_PARAMS: return { ...state, states: updateStateParams(state.states, action.params) };

    case LEADERS_FETCHING: return { ...state, leaders: getPendingState(action.params) };
    case LEADERS_FETCHED: return { ...state, leaders: getFetchedState(action.payload, action.params) };
    case LEADERS_PARAMS: return { ...state, leaders: updateStateParams(state.leaders, action.params) };

    case DELEGATES_FETCHING: return { ...state, delegates: getPendingState(action.params) };
    case DELEGATES_FETCHED: return { ...state, delegates: getFetchedState(action.payload, action.params) };
    case DELEGATES_PARAMS: return { ...state, delegates: updateStateParams(state.delegates, action.params) };

    case IN_DEMAND_FETCHING: return { ...state, inDemand: getPendingState(action.params) };
    case IN_DEMAND_FETCHED: return { ...state, inDemand: getFetchedState(action.payload, action.params) };
    case IN_DEMAND_PARAMS: return { ...state, inDemand: updateStateParams(state.inDemand, action.params) };

    case APP_ONLINE:
    case NAVIGATION:
      return clearActionStates(state);

    case UNAUTHENTICATED: return { ...state, ...initialLookupsState };

    default: return state;
  }
};

const leaderParams: LeaderParams = {};

type LookupsProviderProps = {
  children?: ReactNode | ReactNode[];
  // for Storybook only
  state?: ILookupsState;
  // for Jest specs only
  initState?: ILookupsState;
};

const LookupsProviderPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  // for Storybook only
  state: LookupsStatePropTypes,
  // for Jest specs only
  initState: LookupsStatePropTypes
};

// eslint-disable-next-line max-lines-per-function, max-statements
export const LookupsProvider: FunctionComponent<LookupsProviderProps> = ({
  state,
  initState,
  children
}) => {
  const intl = useIntl();
  const { isInternational } = useModels();
  const transformStates = useMemo(() => generateTransformStates(isInternational), [isInternational]);
  const transformLookupDelegates = useMemo(() => generateTransformLookupDelegates(intl), [intl]);

  const [lookupsState, dispatch] = useReducer(lookupReducer, initState
    ? { ...initialLookupsState, ...initState } : initialLookupsState
  );
  const { online, token, user: { data: user }, unauthenticate } = useContext(GlobalContext);
  leaderParams.selected_leader_id = getSelectedLeaderId(user);

  useEffect(() => {
    if (!token) dispatch({ type: UNAUTHENTICATED });
  }, [token]);

  useEffect(() => {
    if (online === true) dispatch({ type: APP_ONLINE, payload: true });
  }, [online]);

  useEffect(() => {
    history.listen(({ location: { pathname } }) => {
      if (pathname && pathname !== PATH_HOME) dispatch({ type: NAVIGATION });
    });
  }, []);

  const actualState = state || lookupsState;
  const {
    orgs, jobCategories, careerLadders, courseProviders, anything,
    skls, leaders, delegates, countries, states, jobsSearch, inDemand
  } = actualState;

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        clearAnything: () => dispatch({ type: ANYTHING_CLEAR }),
        clearJobsSearch: () => dispatch({ type: JOBS_SEARCH_CLEAR })
      }
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireOrgs: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          routeParams: leaderParams,
          params: () => ({ limit: MAX_LOOKUP_OPTIONS }),
          type: ORGS_FETCH,
          entity: orgs,
          api: API_ORGS
        })
      }
    });
  }, [orgs, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireJobCategories: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: () => ({ limit: MAX_LOOKUP_OPTIONS }),
          type: JOB_CATEGORIES_FETCH,
          entity: jobCategories,
          api: API_JOB_CATEGORIES
        })
      }
    });
  }, [jobCategories, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireCareerLadders: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: () => ({ limit: MAX_LOOKUP_OPTIONS }),
          type: CAREER_LADDERS_FETCH,
          entity: careerLadders,
          api: API_CAREER_LADDERS
        })
      }
    });
  }, [careerLadders, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireCourseProviders: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: () => ({ limit: MAX_LOOKUP_OPTIONS }),
          type: COURSE_PROVIDERS_FETCH,
          entity: courseProviders,
          api: API_COURSE_PROVIDERS
        })
      }
    });
  }, [courseProviders, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireAnything: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ search /* , offset */ }) => ({
            search: search && isString(search) ? search : null,
            // offset,
            limit: MAX_LOOKUP_OPTIONS
          } as AnythingParams & LimitParams),
          type: ANYTHING_FETCH,
          entity: anything,
          api: API_MY_SEARCH,
          transformation: transformAnything
        })
      }
    });
  }, [anything, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireJobsSearch: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ search, with_skills_only /* , offset */ }) => ({
            search: search && isString(search) ? search : null,
            with_skills_only: with_skills_only === true ? true : null,
            // offset,
            limit: MAX_LOOKUP_OPTIONS
          } as JobsSearchParams & LimitParams),
          type: JOBS_SEARCH_FETCH,
          entity: jobsSearch,
          api: API_JOBS
        })
      }
    });
  }, [jobsSearch, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireSkls: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({
            supervisor, limit, exclude_ids,
            search, exclude_existing_skills // , exclude_skills_for_employee_id, offset
          }) => ({
            supervisor: supervisor === true ? supervisor : null,
            exclude_ids: isArray(exclude_ids) ? filter(exclude_ids, (id) => id && id >= 1 && isSafeInteger(id)) : null,
            search: isString(search) && size(trim(search)) >= 1 ? search : null,
            // This parameter is only supported by `/v3/manager/skills/` endpoint:
            // exclude_skills_for_employee_id: isSafeInteger(exclude_skills_for_employee_id) &&
            //   exclude_skills_for_employee_id as number >= 1 ? exclude_skills_for_employee_id : null,
            exclude_existing_skills: exclude_existing_skills === true ? true : null,
            // offset,
            limit: limit || MAX_LOOKUP_OPTIONS
          } as SklsParams & LimitParams),
          type: SKLS_FETCH,
          entity: skls,
          api: ({ supervisor }) => supervisor ? API_SKILLS : API_EMPLOYEE_SKILLS,
          dropParams: (params) => ({
            ...omit(params, 'supervisor'),
            ...params.exclude_ids ? { exclude_ids: getStringifiedIds(params.exclude_ids as number[]) } : {}
          })
        })
      }
    });
  }, [skls, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireCountries: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ local }) => ({
            local: local === true ? true : null,
            limit: MAX_COUNTRIES_OPTIONS
          } as CountriesParams & LimitParams),
          type: COUNTRIES_FETCH,
          entity: countries,
          api: API_COUNTRIES
        })
      }
    });
  }, [countries, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireStates: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          type: STATES_FETCH,
          entity: states,
          params: ({ country_id, local }) => ({
            ...isSafeInteger(country_id) && country_id as number >= 1 ? { country_id } : {},
            local: local === true ? true : null,
            limit: MAX_COUNTRIES_OPTIONS
          } as StatesParams & LimitParams),
          api: API_STATES,
          transformation: transformStates
        })
      }
    });
  }, [states, online, token, unauthenticate, transformStates]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireLeaders: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ search }) => ({
            search: search && isString(search) ? search : null,
            limit: MAX_LOOKUP_OPTIONS
          } as LeadersParams & LimitParams),
          type: LEADERS_FETCH,
          entity: leaders,
          api: API_LOOKUP_LEADERS,
          transformation: transformLookupDelegates
        })
      }
    });
  }, [leaders, online, token, unauthenticate, transformLookupDelegates]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireDelegates: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ leader_id, search }) => ({
            leader_id,
            search: search && isString(search) ? replace(search, /\s*\([^)]*?\)\s*/gu, '') : null,
            limit: MAX_LOOKUP_OPTIONS
          } as DelegatesParams & LimitParams),
          validator: ({ leader_id }) => isSafeInteger(leader_id) && leader_id as number >= 1,
          type: DELEGATES_FETCH,
          entity: delegates,
          api: API_LOOKUP_DELEGATES,
          transformation: transformLookupDelegates
        })
      }
    });
  }, [delegates, online, token, unauthenticate, transformLookupDelegates]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireInDemand: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          type: IN_DEMAND_FETCH,
          entity: inDemand,
          params: ({ org_id, onSuccess }) => ({
            org_id,
            onSuccess,
            limit: MAX_LOOKUP_OPTIONS
          }),
          validator: ({ org_id }) => isSafeInteger(org_id) && org_id as number >= 1,
          api: API_SKILLS_IN_DEMAND,
          dropParams: ['onSuccess']
        })
      }
    });
  }, [inDemand, online, token, unauthenticate]);

  return (
    <LookupContext.Provider value={actualState}>
      {children}
    </LookupContext.Provider>
  );
};

LookupsProvider.propTypes = LookupsProviderPropTypes;
