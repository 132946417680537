/* eslint-disable max-lines */
import {
  memo, useState, useCallback, useEffect, useMemo, useContext, type FunctionComponent, type MouseEventHandler
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import endsWith from 'lodash/endsWith';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMutation, useApolloClient, type ApolloCache } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// EmPath UI Components
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { getName } from '@empathco/ui-components/src/helpers/strings';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import YesNoButtonGroup from '@empathco/ui-components/src/elements/YesNoButtonGroup';
import ExportButton from '@empathco/ui-components/src/elements/ExportButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { ACTIVE_EMPLOYEE_DEV_PLAN } from '../graphql/ActiveEmployeeDevPlan';
import { SEND_MENTORSHIP_REQUEST } from '../graphql/SendMentorshipRequest';
import { COMPLETE_EMPLOYEE_COURSE } from '../graphql/CompleteEmployeeCourse';
import { UPDATE_ACTIVITY } from '../graphql/UpdateActivity';
import { UPDATE_EMPLOYEE_ADVISOR } from '../graphql/UpdateEmployeeAdvisor';
import { EMPLOYEE_DEV_PLAN_QUERY } from '../graphql/EmployeeDevPlan';
import { EMPLOYEE_PROGRESS_QUERY } from '../graphql/EmployeeProgress';
import {
  OpportunityStatus, SkillActivity, EmployeeActivityStatus, EmployeeAdvisorStatus,
  ActiveEmployeeDevPlanDocument, SendMentorshipRequestDocument,
  CompleteEmployeeCourseDocument, UpdateActivityDocument, UpdateEmployeeAdvisorDocument,
  EmployeeDevPlanQuery, EmployeeProgressQuery
} from '../graphql/types';
import {
  DevPlanProgress, DevPlanDetails, EmployeeDevPlanDetails, EmployeeDevPlanProgress,
  DevPlanTarget, DevPlanCourse, DevPlanAdvisor, DevPlanOpportunity
} from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
import { PATH_MY_DEV_PLAN, PATH_MY_SKILLS } from '../config/paths';
import { getActualActivities, getTargetSkills, updateCachedResult } from '../helpers/graphql';
import { RouterLinkState } from '../helpers/routerParams';
import { GlobalContext } from '../context';
import useExport from '../hooks/useExport';
import DevPlanAvatar from '../elements/DevPlanAvatar';
import CohortAvatar from '../elements/CohortAvatar';
import TbItemChip from '../elements/TbItemChip';
import LargeButton from '../elements/LargeButton';
import DevPlanTimestampStatus from '../elements/DevPlanTimestampStatus';
import EmployeeProgressDetails from '../elements/EmployeeProgressDetails';
import CoursesAndAdvisorsGrid from '../v3/CoursesAndAdvisorsGrid';
// SCSS imports
import { header, content, completed, details, detailsButton } from './EmployeeProgressPopup.module.scss';

const updateAdvisors = (cache: ApolloCache<unknown>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeAdvisors' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkillAdvisors' });
};

const contentSx = { py: 1 };
const linkState: RouterLinkState = { viewDevPlans: true };

const EMPTY_COURSES: DevPlanCourse[] = [];
const EMPTY_ADVISORS: DevPlanAdvisor[] = [];
const EMPTY_OPPORTUNITIES: DevPlanOpportunity[] = [];

type EmployeeProgressPopupProps = {
  individual?: boolean;
  isMyPlan?: boolean;
  supervisor?: boolean | null;
  employeeName?: string;
  employee?: DevPlanProgress | EmployeeDevPlanProgress | null;
  devplan?: DevPlanDetails | EmployeeDevPlanDetails | null;
  pending?: boolean | null;
  failed?: boolean | null;
  route?: string | null;
  isOpen?: boolean;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  onExport?: (format: ExportFormat, title: string) => Promise<number | boolean> | null;
  exportDisabled?: boolean;
  // for Storybook only
  testAction?: number;
}

const EmployeeProgressPopupPropTypes = {
  // attributes
  individual: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  supervisor: PropTypes.bool,
  employeeName: PropTypes.string,
  employee: PropTypes.object as Validator<DevPlanProgress | EmployeeDevPlanProgress>,
  devplan: PropTypes.object as Validator<DevPlanDetails | EmployeeDevPlanDetails>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  route: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onExport: PropTypes.func,
  exportDisabled: PropTypes.bool,
  // for Storybook only
  testAction: PropTypes.number
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const EmployeeProgressPopup: FunctionComponent<EmployeeProgressPopupProps> = ({
  individual = false,
  isMyPlan = false,
  supervisor = false,
  employeeName,
  employee,
  devplan,
  pending = false,
  failed = false,
  route,
  isOpen = false,
  onClose,
  onExport,
  exportDisabled = false,
  testAction
}) => {
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();
  const { cache: apolloCache } = useApolloClient();

  const { user: { data: user } } = useContext(GlobalContext);
  const {
    id: devplan_id, title, created_at, updated_at, owner, job, opportunity, activity_ids, target_skills
  } = devplan || {};
  const { skills: emplSkills } = employee || {};
  const firstName = getName((employee as DevPlanProgress)?.first_name);
  const { completed_courses, completed_advisors, completed_opportunities, activities: completed_activities } =
    (!individual && (employee as DevPlanProgress)) || {};
  const { is_active } = (individual && (devplan as EmployeeDevPlanDetails)) || {};
  const { courses, advisors, opportunities, activities } = (individual && (employee as EmployeeDevPlanProgress)) || {};
  const targetTitle = job?.title || opportunity?.title;
  const target: DevPlanTarget | null = (job && 'job') || (opportunity && 'opportunity') || null;

  const targetSkills = useMemo(() => getTargetSkills(target_skills), [target_skills]);

  const titleValues = useMemo(() => ({
    name: firstName,
    endsWithS: endsWith(firstName, 's')
  }), [firstName]);

  const { mutate: activateDevPlan, loading: activatePending, failed: activateFailed } = useMutationMethod({
    key: 'activeEmployeeDevPlan',
    mutation: useMutation(ACTIVE_EMPLOYEE_DEV_PLAN as typeof ActiveEmployeeDevPlanDocument)
  });
  const { mutate: sendMentorshipRequest, loading: requestPending, failed: requestFailed } = useMutationMethod({
    key: 'sendMentorshipRequest',
    mutation: useMutation(SEND_MENTORSHIP_REQUEST as typeof SendMentorshipRequestDocument)
  });
  const { mutate: completeCourse, loading: completeCoursePending, failed: completeCourseFailed } = useMutationMethod({
    key: 'completeEmployeeCourse',
    mutation: useMutation(COMPLETE_EMPLOYEE_COURSE as typeof CompleteEmployeeCourseDocument)
  });
  const { mutate: completeActivity, loading: completeActivityPending, failed: completeActivityFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_ACTIVITY as typeof UpdateActivityDocument)
  });
  const { mutate: completeAdvisor, loading: completeAdvisorPending, failed: completeAdvisorFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_EMPLOYEE_ADVISOR as typeof UpdateEmployeeAdvisorDocument)
  });

  const [courseCompletingId, setCourseCompletingId] = useState<number>();
  const [activityCompletingId, setActivityCompletingId] = useState<number>();
  const [advisorCompletingId, setAdvisorCompletingId] = useState<number>();
  const [advisorRequestingId, setAdvisorRequestingId] = useState<number>();

  const employees = useMemo(() => employee ? [employee] : undefined, [employee]);

  const selectedActivities = useMemo(
    () => getActualActivities(targetSkills, null, employees, activity_ids, true, true),
    [activity_ids, employees, targetSkills]);

  const completedCourses = useMemo(() => (!HAS_COURSES && EMPTY_COURSES) || (
    individual ? filter(courses, 'is_complete') : completed_courses || EMPTY_COURSES
  ), [courses, completed_courses, individual, HAS_COURSES]);
  const completedAdvisors = useMemo(() => (!HAS_MENTORING && EMPTY_ADVISORS) || (
    individual ? filter(advisors, 'is_complete') : completed_advisors || EMPTY_ADVISORS
  ), [advisors, completed_advisors, individual, HAS_MENTORING]);
  const completedOpportunities = useMemo(() => (!HAS_DEV_PLAN_OPPORTUNITIES && EMPTY_OPPORTUNITIES) || (individual
    ? filter(opportunities, ({ status, archived_at }) => status === OpportunityStatus.archived && Boolean(archived_at))
    : completed_opportunities || EMPTY_OPPORTUNITIES
  ), [opportunities, completed_opportunities, individual, HAS_DEV_PLAN_OPPORTUNITIES]);
  const completedActivities = useMemo(
    () => map(
      individual ? filter(activities, ['status', EmployeeActivityStatus.completed]) : completed_activities,
      ({ id, activity, skills, status, updated_at: completed_at }) => ({
        ...activity,
        skills,
        is_complete: status === EmployeeActivityStatus.completed,
        completed_at,
        employee_activity_id: id
      })),
    [activities, completed_activities, individual]
  );

  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const handleActivation = useCallback((value: boolean) => {
    if (devplan_id && Boolean(is_active) !== Boolean(value)) activateDevPlan?.({
      variables: { devplan_id, input: { is_active: Boolean(value) } },
      update: (cache, result) => {
        if (result.data?.activeEmployeeDevPlan?.optimistic) {
          cache.updateQuery({
            query: EMPLOYEE_DEV_PLAN_QUERY, variables: { devplan_id }
          }, (data: EmployeeDevPlanQuery | null) => updateCachedResult(data, 'employeeDevplan', (plan) => ({
            ...(plan || {}) as EmployeeDevPlanDetails, is_active: Boolean(value)
          } as EmployeeDevPlanDetails)));
        } else {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplan' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplans' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' }); // just to be on the safe side
        }
      },
      optimisticResponse: { activeEmployeeDevPlan: {
        success: true, optimistic: true, __typename: 'MutationResponse'
      } }
    });
  }, [devplan_id, is_active, activateDevPlan]);

  const handleCompleteCourse = useCallback((course?: DevPlanCourse) => {
    const course_id = course?.id;
    if (devplan_id && course_id) {
      setCourseCompletingId(course_id);
      completeCourse?.({
        variables: { course_id, input: {} },
        onCompleted: () => setCourseCompletingId(undefined),
        onError: () => setCourseCompletingId(undefined),
        update: (cache, result) => {
          if (result.data?.completeEmployeeCourse?.optimistic) {
            cache.updateQuery({
              query: EMPLOYEE_PROGRESS_QUERY, variables: { devplan_id }
            }, (data: EmployeeProgressQuery | null) => updateCachedResult(
              data, 'employeeProgress', (empl) => ({
                ...(empl || {}) as EmployeeDevPlanProgress,
                courses: map((empl as EmployeeDevPlanProgress)?.courses, (crs) => ({
                  ...crs, is_complete: crs.is_complete || crs.id === course_id
                }))
              } as EmployeeDevPlanProgress)
            ));
          } else cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
        },
        optimisticResponse: { completeEmployeeCourse: {
          success: true, optimistic: true, __typename: 'MutationResponse'
        } }
      });
    }
  }, [devplan_id, completeCourse]);

  const handleCompleteActivity = useCallback((activity?: SkillActivity) => {
    const activity_id = activity?.employee_activity_id;
    if (devplan_id && activity_id) {
      setActivityCompletingId(activity_id);
      completeActivity?.({
        variables: { activity_id, input: { status: EmployeeActivityStatus.completed } },
        update: (cache) => {
          // TODO: optimistic response
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'myActivities' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkill' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
        },
        onCompleted: () => setActivityCompletingId(undefined),
        onError: () => setActivityCompletingId(undefined)
      });
    }
  }, [completeActivity, devplan_id]);

  const handleRequestAdvisor = useMemo(() => advisors ? (id: number) => {
    if (id && sendMentorshipRequest) {
      const skillIds = map(find(advisors, ['id', id])?.advisory_skills, 'id');
      if (size(skillIds) >= 1 && head(skillIds)) {
        setAdvisorRequestingId(id);

        // recursive function to send requests for all advisor's skills
        const sendRequests = () => {
          const skill_id = skillIds.shift();
          const isLast = size(skillIds) < 1;
          if (skill_id) {
            sendMentorshipRequest({
              variables: { input: { skill_id, mentor_id: id } },
              update: isLast ? updateAdvisors : undefined,
              onError: () => setAdvisorRequestingId(undefined),
              onCompleted: () => {
                if (isLast) setAdvisorRequestingId(undefined);
                else sendRequests();
              }
            });
          } else if (isLast) {
            updateAdvisors(apolloCache);
            setAdvisorRequestingId(undefined);
          } else sendRequests();
        };

        sendRequests();
      }
    }
  } : undefined, [advisors, sendMentorshipRequest, apolloCache]);

  const handleCompleteAdvisor = useCallback((advisor?: DevPlanAdvisor) => {
    const advisor_id = advisor?.id;
    if (!advisor_id) return;
    setAdvisorCompletingId(advisor_id);
    completeAdvisor({
      variables: { advisor_id, input: { status: EmployeeAdvisorStatus.completed } },
      // TODO: optimistic response
      update: updateAdvisors,
      onCompleted: () => setAdvisorCompletingId(undefined),
      onError: () => setAdvisorCompletingId(undefined)
    });
  }, [completeAdvisor]);

  const getExport = useCallback((format: ExportFormat, _token: string) => {
    if (exportDisabled || !onExport) return null;
    return onExport(format, '');
  }, [exportDisabled, onExport]);

  const exp = useExport(getExport);

  const link = useMemo(() => devplan_id && individual && !supervisor && user?.id && user.id === owner?.id
    ? injectParams(PATH_MY_DEV_PLAN, { plan_id: devplan_id }) : undefined,
    [devplan_id, user?.id, owner?.id, individual, supervisor]
  );

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testAction === 1) activateDevPlan?.({ variables: { devplan_id: 1, input: { is_active: true } } });
    else if (testAction === 2 || testAction === 3) {
      if (testAction === 2) setCourseCompletingId(6204);
      completeCourse?.({ variables: { course_id: 6204, input: {} } });
    } else if (testAction === 4 || testAction === 5) {
      if (testAction === 4) setAdvisorCompletingId(2);
      completeAdvisor?.({ variables: { advisor_id: 2, input: { status: EmployeeAdvisorStatus.completed } } });
    } else if (testAction === 6 || testAction === 7) {
      if (testAction === 6) setAdvisorRequestingId(3);
      sendMentorshipRequest?.({ variables: { input: { skill_id: 1112, mentor_id: 3 } } });
    }
  }, [testAction, activateDevPlan, completeCourse, completeAdvisor, sendMentorshipRequest]);

  const hasCompletedItems = (!individual || is_active) && (
    size(completedCourses) >= 1 || size(completedAdvisors) >= 1 || size(completedOpportunities) >= 1 ||
    size(completedActivities) >= 1
  );

  const notReady = Boolean(failed || pending) || !employee || !devplan;
  const pendingAction = activatePending || requestPending ||
    completeCoursePending || completeActivityPending || completeAdvisorPending ||
    Boolean(courseCompletingId || activityCompletingId || advisorCompletingId || advisorRequestingId);

  const contentPanel = !onClose || mounted ? (notReady && (
    <Box pt={onClose ? 7.5 : undefined}>
      {(failed && <FetchFailedAlert flat/>) || <LoadingPlaceholder flat/>}
    </Box>
  )) || (
    <>
      {individual ? (
        <>
          <CardSection top>
            <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-start"
                pt={5}
            >
              <DevPlanAvatar/>
              <BoxTypography flexGrow={supervisor ? undefined : 1} pl={1.25} pr={2} variant="h3">
                {title}
              </BoxTypography>
              {supervisor ? (
                <TagLabel variant={is_active ? 'active' : 'inactive'}/>
              ) : (
                <>
                  {link ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        component={RouterLink}
                        to={link}
                        disabled={pending || failed ? true : undefined}
                        className={detailsButton}
                    >
                      <FormattedMessage id="dev_plans.button.view"/>
                    </Button>
                  ) : undefined}
                  <YesNoButtonGroup
                      small
                      value={Boolean(is_active)}
                      yesLabel="common.tag.active"
                      noLabel="common.tag.inactive"
                      onChange={handleActivation}
                      disabled={pendingAction}
                      pendingValue={activatePending ? Boolean(is_active) : undefined}
                  />
                </>
              )}
            </Box>
            <Box
                display="flex"
                justifyContent="flex-start"
            >
              <DevPlanTimestampStatus
                  compact
                  createdAt={created_at}
                  updatedAt={updated_at}
                  owner={owner}
                  myOwn={isMyPlan || individual ? user?.id === owner?.id : undefined}
              />
            </Box>
            <Divider/>
          </CardSection>
          <CoursesAndAdvisorsGrid
              isMyPlan={isMyPlan}
              courses={HAS_COURSES ? courses : EMPTY_COURSES}
              advisors={HAS_MENTORING ? advisors : EMPTY_ADVISORS}
              opportunities={HAS_DEV_PLAN_OPPORTUNITIES ? opportunities : EMPTY_OPPORTUNITIES}
              activities={selectedActivities}
              disabled={pendingAction}
              notFoundMessage="hr.dev_plan.empty"
              isSelected
              withCompletedState={!supervisor && is_active ? true : undefined}
              onCompleteCourse={!supervisor && is_active ? handleCompleteCourse : undefined}
              onCompleteActivity={!supervisor && is_active ? handleCompleteActivity : undefined}
              onCompleteAdvisor={!supervisor && is_active ? handleCompleteAdvisor : undefined}
              onRequestAdvisor={!supervisor && is_active ? handleRequestAdvisor : undefined}
              courseCompletingId={courseCompletingId}
              activityCompletingId={activityCompletingId}
              advisorCompletingId={advisorCompletingId}
              advisorRequestingId={advisorRequestingId}
          />
        </>
      ) : (
        <CardTitle
            title={isMyPlan ? 'dev_plans.progress' : 'hr.dev_plans.employee_title'}
            values={titleValues}
            className={onExport ? header : undefined}
            action={onExport ? (
              <ExportButton
                  pending={exp.failed === false}
                  disabled={pendingAction || exportDisabled || exp.failed === false || !exp.enabled}
                  onExport={exp.handleExport}
              />
            ) : undefined}
        />
      )}
      <DialogContent
          sx={contentSx}
          className={!onClose && !hasCompletedItems && !notReady && (!individual || is_active)
            ? content : (hasCompletedItems && completed) || undefined}
      >
        {individual ? (
          <>
            {targetTitle ? (
              <Box pb={2} className={details}>
                <FormattedMessage
                    id="hr.dev_plan.target"
                    values={{ target, title: targetTitle }}
                />
              </Box>
            ) : undefined}
            {is_active ? (
              <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  py={2}
              >
                <CohortAvatar grey/>
                <BoxTypography flexGrow={1} pl={1.25} pr={2} variant="subtitle1">
                  <FormattedMessage
                      id={supervisor ? 'dev_plans.employee_progress' : 'dev_plans.progress'}
                      values={supervisor ? {
                        name: employeeName,
                        endsWithS: endsWith(employeeName, 's')
                      } : undefined}
                  />
                </BoxTypography>
              </Box>
            ) : undefined}
          </>
        ) : (
          <EmployeeProgressDetails
              target={target}
              isMyPlan={isMyPlan}
              employee={employee as DevPlanProgress}
              devplan={devplan as DevPlanDetails}
              route={route}
          />
        )}
        {!individual || is_active ? <Divider/> : undefined}
        {size(emplSkills) >= 1 && (
          <>
            <BoxTypography variant="h6" pt={2.5} pb={1.5}>
              <FormattedMessage id="hr.cohort.skills_criteria"/>
            </BoxTypography>
            <Box display="flex" flexWrap="wrap">
              {map(emplSkills, (skill, index) => (
                <Box key={index} pb={3} pr={3}>
                  <TbItemChip
                      flat
                      item={skill}
                      satisfied={isNil(skill.is_satisfied) || skill.is_satisfied}
                      withLargeLevel
                      disabled={pendingAction}
                      // TODO: onClick - navigate to relevant skill details screen
                  />
                </Box>
              ))}
            </Box>
            <Divider/>
          </>
        )}
        {individual ? (
          <>
            <EmployeeProgressDetails
                target={target}
                individual
                active={is_active}
                employee={employee as DevPlanProgress}
                devplan={devplan as DevPlanDetails}
            />
            {is_active ? <Divider/> : undefined}
          </>
        ) : undefined}
        {!individual || is_active ? (
          <>
            <BoxTypography variant="h6" pt={2.5} pb={hasCompletedItems ? undefined : 1.5}>
              <FormattedMessage id="hr.dev_plan.completed_activity"/>
            </BoxTypography>
            {!hasCompletedItems && (
              <BoxTypography variant="body2">
                <FormattedMessage id="hr.dev_plan.completed_empty"/>
              </BoxTypography>
            )}
          </>
        ) : undefined}
      </DialogContent>
      {hasCompletedItems ? (
        <>
          <CoursesAndAdvisorsGrid
              isMyPlan={isMyPlan}
              courses={completedCourses}
              advisors={completedAdvisors}
              opportunities={completedOpportunities}
              activities={completedActivities}
              isSelected
              withCompletedState
              disabled={pendingAction}
          />
          {onClose ? undefined : <Box className={content}/>}
        </>
      ) : undefined}
      {isMyPlan && !individual ? (
        <CardFooter compact>
          <LargeButton
              title="dev_plans.button.all"
              link={PATH_MY_SKILLS}
              linkState={linkState}
              disabled={pending}
          />
        </CardFooter>
      ) : undefined}
    </>
  ) : undefined;

  return !onClose || mounted ? (
    <>
      {onClose ? (
        <Dialog
            disableEnforceFocus
            maxWidth="lg"
            fullWidth
            scroll="body"
            open={isOpen}
            onClose={pendingAction ? undefined : onClose}
            TransitionProps={transitionProps}
            PaperProps={notReady || (individual && !is_active) ? undefined : { className: content }}
        >
          <CloseIconButton onClick={onClose} disabled={pendingAction}/>
          {contentPanel}
        </Dialog>
      ) : contentPanel}
      {individual && !supervisor ? (
        <>
          <ActionFailedAlert
              message="hr.dev_plan.activate_error"
              open={activateFailed}
          />
          <ActionFailedAlert
              message="skill.advisors.request_error"
              open={requestFailed}
          />
          <ActionFailedAlert
              message="hr.dev_plan.course_complete_error"
              open={completeCourseFailed}
          />
          <ActionFailedAlert
              message="skill.development.activity.error"
              open={completeActivityFailed}
          />
          <ActionFailedAlert
              message="skill.advisors.update_error"
              open={completeAdvisorFailed}
          />
        </>
      ) : undefined}
    </>
  ) : null;
};

EmployeeProgressPopup.propTypes = EmployeeProgressPopupPropTypes;

export default memo(EmployeeProgressPopup);
