import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const DEV_PLAN_QUERY = gql`
  query DevPlan(
    $devplan_id: Int!
  ) {
    devplan(
      devplan_id: $devplan_id
    )
    @rest(
      type: "DevPlanExpanded",
      path: "${API_DEV_PLAN}"
    ) {
      id
      title
      created_at
      updated_at
      activity_ids
      owner {
        id
        code
        first_name
        last_name
        email
      }
      job {
        id
        title
        code
      }
      opportunity {
        id
        title
        description
        duration_unit
        duration_value
        status
      }
      target_skills {
        id
        title
        abbr
        skill_proficiency_level
        is_selected
        activities {
          id
          code
          name
          activity_type
          skill_proficiency_level
          description
          activity_link
          development_area
          field_support
          store
          education_style
        }
      }
      employee {
        id
        code
        first_name
        last_name
        email
        is_profile_accessible
        performance_rating
        years_on_job
        current_job {
          id
          title
          code
        }
        location {
          id
          title
          city
          state
          country
        }
        manager {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
        }
      }
      cohort {
        id
        title
        created_at
        updated_at
        employees_count
        owner {
          id
          code
          first_name
          last_name
          email
        }
        cohort_skills {
          id
          status
          skill_proficiency_level
          skill {
            id
            title
            abbr
            type
          }
        }
      }
    }
  }
`;
