import { memo, useCallback, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import findIndex from 'lodash/findIndex';
import toLower from 'lodash/toLower';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
// EmPath UI Components
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import LevelDescription from '@empathco/ui-components/src/elements/LevelDescription';
// local imports
import { SkillEmployees } from '../models/skillEmployees';
import { SkillLevel, SKILL_LEVEL_FIRST, SKILL_LEVEL_MIN } from '../models/skill';
import { getLeaderId } from '../models/user';
import { MIN_EMPLOYEES_WITH_SKILL } from '../config/params';
import useModels, {
  getDefaultManager, getSkillCurrentLevel, sanitizeLookup, sanitizeLookupString
} from '../helpers/models';
import { getSettingsIntValue, getSettingsStrValue } from '../helpers/context';
import { DataContext, GlobalContext, LookupContext, SupervisorContext } from '../context';
import EmployeesWithSkillFilters from '../v3/EmployeesWithSkillFilters';
import EmployeesWithSkillChart from './EmployeesWithSkillChart';
import EmployeesWithSkillList from './EmployeesWithSkillList';
import SkillActivities from './SkillActivities';
// SCSS imports
import { chartContainer, levelDescription } from './EmployeesWithSkill.module.scss';

type EmployeesWithSkillProps = {
  isEmployee?: boolean;
  supervisor?: boolean;
  hrbp?: boolean;
  uid?: string | null;
  skillId?: string | null;
  isInternational?: boolean | null;
  reducedUI?: boolean;
  resetFilters?: boolean;
  // for Storybook only
  testUid?: string;
  testHasTeams?: boolean;
}

const EmployeesWithSkillPropTypes = {
  isEmployee: PropTypes.bool,
  supervisor: PropTypes.bool,
  hrbp: PropTypes.bool,
  uid: PropTypes.string,
  skillId: PropTypes.string,
  isInternational: PropTypes.bool,
  reducedUI: PropTypes.bool,
  resetFilters: PropTypes.bool,
  // for Storybook only
  testUid: PropTypes.string,
  testHasTeams: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const EmployeesWithSkill: FunctionComponent<EmployeesWithSkillProps> = ({
  isEmployee = false,
  supervisor = false,
  hrbp = false,
  uid,
  skillId,
  isInternational = false,
  reducedUI = false,
  resetFilters = false,
  testUid,
  testHasTeams = false
}) => {
  const { getDomesticCountryId, isDomesticCountryId } = useModels();
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    coc: { data: cocData, pending: cocPending }, requireCoC,
    skill: { data: skill, pending: pendingSkill, failed: failedSkill },
    skillEmployees: { data: skillEmployees, pending, failed, params }, requireSkillEmployees,
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const {
    countries: { data: countriesData, pending: countriesPending, failed: countriesFailed }, requireCountries,
    states: { data: statesData, pending: statesPending, failed: statesFailed }, requireStates
  } = useContext(LookupContext);
  const {
    hierarchy: { data: hierarchy, pending: hierarchyPending }, requireHierarchy
  } = useContext(SupervisorContext);
  const { level_description, activities } = skill || {};
  const { all_employees_count } = skillEmployees || {};
  const withHierarchy = !reducedUI && (supervisor || hrbp);
  const currentLevel = (hrbp && !supervisor && SKILL_LEVEL_FIRST) || (
    supervisor ? SKILL_LEVEL_MIN : getSkillCurrentLevel((pendingSkill === false && failedSkill === false &&
      skill && toLower(skill.abbr || '') === skillId && skill) || null
    )
  );
  const countries = countriesPending ? null : countriesData;
  const states = statesPending ? null : statesData;
  const managers = withHierarchy
    ? (!hierarchyPending && hierarchy) || null
    : (!cocPending && cocData) || null;

  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsId = supervisor ? 'skill_supply' : 'skill_employees';
  const settingsCountry = resetFilters ? 0 : getSettingsIntValue(settings, `${settingsId}__country`);
  const settingsState = resetFilters ? 0 : getSettingsIntValue(settings, `${settingsId}__state`);
  const settingsManager = resetFilters ? '' : getSettingsStrValue(settings, `${settingsId}__manager`);

  const skills = useMemo(() => skill ? [skill] : undefined, [skill]);

  const defaultManager = useMemo(() => (reducedUI && '0') || (withHierarchy
    ? getDefaultManager(uid, managers, false, size(managers) >= 1 ? testUid : undefined)?.id
    : sanitizeLookupString(settingsManager, managers)
  ), [reducedUI, withHierarchy, settingsManager, testUid, uid, managers]);

  const [country, setCountry] = useState<number | undefined>(reducedUI
    ? getDomesticCountryId(countries)
    : sanitizeLookup(settingsCountry, countries)
  );
  const [state, setState] = useState<number>(sanitizeLookup(settingsState, states));
  const [manager, setManager] = useState(defaultManager);
  const [hasTeams, setHasTeams] = useState(testHasTeams);

  const isDomestic = useMemo(() => isDomesticCountryId(country, countries), [countries, country, isDomesticCountryId]);
  const local = reducedUI || (!hrbp && !isInternational);

  const [selectedLevel, setSelectedLevel] = useState<SkillLevel>(currentLevel);
  useEffect(() => {
    if (isEmployee) setSelectedLevel(
      // with present implementation of EmployeesWithSkillChart it only actually works if changing from 0 to 1+
      (prevSelectedLevel) => prevSelectedLevel < SKILL_LEVEL_FIRST && currentLevel >= SKILL_LEVEL_FIRST
        ? currentLevel
        : prevSelectedLevel
    );
  }, [currentLevel, isEmployee]);

  useEffect(() => {
    requireCountries?.({ local });
  }, [local, requireCountries]);

  useEffect(() => {
    if (isDomestic) requireStates?.({ country_id: country });
  }, [country, isDomestic, requireStates]);

  useEffect(() => {
    if (!reducedUI && !withHierarchy) requireCoC?.();
  }, [reducedUI, withHierarchy, requireCoC]);

  useEffect(() => {
    if (withHierarchy) requireHierarchy?.();
  }, [withHierarchy, requireHierarchy]);

  useEffect(() => {
    if (resetFilters && settingsLoaded) updateSettings?.({
      [`${settingsId}__country`]: 0,
      [`${settingsId}__state`]: 0,
      [`${settingsId}__manager`]: ''
    });
  }, [resetFilters, settingsId, settingsLoaded, updateSettings]);

  useEffect(() => {
    if (withHierarchy && !isUndefined(defaultManager)) setManager(
      (prevManager) => isUndefined(prevManager) ? defaultManager : prevManager
    );
  }, [withHierarchy, defaultManager]);

  useEffect(() => {
    if (!resetFilters && settingsLoaded && !pendingSettingsUpdate) {
      setCountry(reducedUI
        ? getDomesticCountryId(countries)
        : sanitizeLookup(getSettingsIntValue(settings, `${settingsId}__country`), countries)
      );
      setState(sanitizeLookup(getSettingsIntValue(settings, `${settingsId}__state`), states));
      if (!withHierarchy) setManager(sanitizeLookupString(getSettingsStrValue(settings, `${settingsId}__manager`), managers));
    }
  }, [
    settingsLoaded, pendingSettingsUpdate, countries, states, managers, settings,
    reducedUI, withHierarchy, settingsId, resetFilters, getDomesticCountryId
  ]);

  useEffect(() => {
    if (reducedUI) {
      const countryId = getDomesticCountryId(countries);
      if (countryId && countryId >= 1) setCountry(countryId);
    }
  }, [countries, reducedUI, getDomesticCountryId]);

  const filtersReady = settingsLoaded && (reducedUI || Boolean(managers)) && Boolean(countries) &&
    (!isDomestic || Boolean(states));

  useEffect(() => {
    if (filtersReady && skillId && !isUndefined(manager)) requireSkillEmployees?.({
      skill_id: skillId,
      state_id: isDomestic ? state : null,
      country_id: country,
      manager_id: manager
    });
  }, [filtersReady, skillId, manager, country, isDomestic, state, requireSkillEmployees]);

  const handleCountry = useCallback((value: number) => {
    const val = sanitizeLookup(value, countries);
    setCountry(val);
    if (settingsCountry !== val) updateSettings?.({ [`${settingsId}__country`]: val });
  }, [countries, settingsCountry, settingsId, updateSettings]);

  const handleState = useCallback((value: number) => {
    const val = sanitizeLookup(value, states);
    setState(val);
    if (settingsState !== val) updateSettings?.({ [`${settingsId}__state`]: val });
  }, [states, settingsState, updateSettings, settingsId]);

  const handleManager = useCallback((value: string, hasChildren?: boolean) => {
    const val = withHierarchy ? value : sanitizeLookupString(value, managers);
    setManager(val);
    if (withHierarchy) setHasTeams(Boolean(hasChildren));
    else if (settingsManager !== val) updateSettings?.({ [`${settingsId}__manager`]: val });
  }, [managers, settingsId, settingsManager, withHierarchy, updateSettings]);

  const pendingAll = pending || !params || toLower(params.skill_id) !== skillId ||
    pendingSettings || countriesPending || statesPending;
  const failedAny = failed || failedSettings || countriesFailed || statesFailed;
  const noCounts = (isEmployee || supervisor) && (!all_employees_count || all_employees_count < MIN_EMPLOYEES_WITH_SKILL) &&
    (!supervisor || manager !== uid);

  const disabled = pendingAll || pendingSettingsUpdate || countriesFailed || statesFailed;

  return (
    <>
      {settingsLoaded && !reducedUI ? (
        <CardSection flex>
          <EmployeesWithSkillFilters
              uid={(hrbp && defaultManager) || (supervisor && uid) || undefined}
              me={withHierarchy ? user?.code : undefined}
              leader={withHierarchy ? getLeaderId(user) : undefined}
              withHierarchy={withHierarchy}
              withLevel={withHierarchy}
              withLevelSearch={supervisor}
              countries={countries}
              country={country}
              setCountry={handleCountry}
              states={states}
              state={state}
              setState={handleState}
              managers={managers}
              manager={manager}
              setManager={handleManager}
              level={selectedLevel}
              setLevel={setSelectedLevel}
              disabled={disabled}
              disabledState={!isDomestic}
          />
        </CardSection>
      ) : undefined}
      {(failedAny && <FetchFailedAlert flat/>) ||
      ((!skillId || pendingAll || !skillEmployees) && <LoadingPlaceholder flat/>) || (
        <>
          <CardSection dark className={chartContainer}>
            {noCounts ? (
              <Alert severity="info" variant="standard">
                <FormattedMessage id="skill.no_employees_with_this_skill"/>
              </Alert>
            ) : (
              <EmployeesWithSkillChart
                  level={selectedLevel}
                  setLevel={setSelectedLevel}
                  employeeCounts={skillEmployees as SkillEmployees}
              />
            )}
          </CardSection>
          {(hrbp || (isEmployee && supervisor)) && selectedLevel >= SKILL_LEVEL_FIRST && (
            (isArray(level_description) && isString(level_description[selectedLevel])) ||
            (size(activities) >= 1 && findIndex(activities, ({ code, activity_type, name, skill_proficiency_level }) =>
              Boolean(code && activity_type && name) &&
              (!skill_proficiency_level || skill_proficiency_level === selectedLevel)
            ) >= 0)
          ) ? (
            <Box
                pt={4}
                px={4}
                pb={1}
                display="flex"
                justifyContent="center"
            >
              <Box
                  display="flex"
                  flexDirection="column"
                  className={levelDescription}
              >
                {level_description && isString(level_description?.[selectedLevel]) ? (
                  <div>
                    <LevelDescription description={level_description[selectedLevel]} level={selectedLevel}/>
                  </div>
                ) : undefined}
                {skills ? (
                  <div className={levelDescription}>
                    <SkillActivities accordion skills={skills} selectedLevel={selectedLevel} disabled={disabled}/>
                  </div>
                ) : undefined}
              </Box>
            </Box>
          ) : undefined}
          {skillId && supervisor && (manager === '0' || (hasTeams && manager !== uid) || (
            manager === uid && !countriesPending && !statesPending &&
            countries && (!isDomestic || states)
          )) ? (
            <EmployeesWithSkillList
                skillId={skillId}
                asTeams={manager !== uid}
                managerId={manager}
                level={selectedLevel}
                stateId={isDomestic ? state : undefined}
                countryId={country}
            />
          ) : undefined}
        </>
      )}
    </>
  );
};

EmployeesWithSkill.propTypes = EmployeesWithSkillPropTypes;

export default memo(EmployeesWithSkill);
