import { memo, FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
// local imports
import { SkillActivity } from '../graphql/types';
import {
  DevPlanAdvisor, DevPlanCompletedAdvisor, EmployeeDevPlanAdvisor,
  DevPlanCourse, EmployeeDevPlanCourse, DevPlanCompletedCourse,
  DevPlanOpportunity, EmployeeDevPlanOpportunity, DevPlanCompletedOpportunity
} from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
import DevPlanCourseCard from './DevPlanCourseCard';
import EmployeeCard from './EmployeeCard';
import OpportunityCard from './OpportunityCard';
import ActivityCard from './ActivityCard';

type CoursesAndAdvisorsGridProps = {
  courses?: (DevPlanCourse | EmployeeDevPlanCourse | DevPlanCompletedCourse)[] | null;
  advisors?: (DevPlanAdvisor | EmployeeDevPlanAdvisor | DevPlanCompletedAdvisor)[] | null;
  opportunities?: (DevPlanOpportunity | EmployeeDevPlanOpportunity | DevPlanCompletedOpportunity)[] | null;
  activities?: SkillActivity[] | null;
  pendingCourses?: boolean | null;
  pendingAdvisors?: boolean | null;
  pendingOpportunities?: boolean | null;
  failedCourses?: boolean | null;
  failedAdvisors?: boolean | null;
  failedOpportunities?: boolean | null;
  disabled?: boolean | null;
  notFoundMessage?: string;
  isSelected?: boolean;
  withoutLoadingPlaceholders?: boolean;
  withCompletedState?: boolean;
  isMyPlan?: boolean;
  onSelectCourse?: (item: DevPlanCourse) => void;
  onSelectAdvisor?: (item: DevPlanAdvisor) => void;
  onSelectOpportunity?: (item: DevPlanOpportunity) => void;
  onSelectActivity?: (item: SkillActivity) => void;
  onRemoveCourse?: (item: DevPlanCourse) => void;
  onRemoveAdvisor?: (item: DevPlanAdvisor) => void;
  onRemoveOpportunity?: (item: DevPlanOpportunity) => void;
  onRemoveActivity?: (item: SkillActivity) => void;
  onCompleteCourse?: (item: DevPlanCourse) => void;
  onCompleteActivity?: (item: SkillActivity) => void;
  onCompleteAdvisor?: (item: DevPlanAdvisor) => void;
  onRequestAdvisor?: (id: number) => void;
  courseCompletingId?: number;
  activityCompletingId?: number;
  advisorCompletingId?: number;
  advisorRequestingId?: number;
}

const CoursesAndAdvisorsGridPropTypes = {
  courses: PropTypes.array as Validator<(DevPlanCourse | EmployeeDevPlanCourse | DevPlanCompletedCourse)[]>,
  advisors: PropTypes.array as Validator<(DevPlanAdvisor | EmployeeDevPlanAdvisor | DevPlanCompletedAdvisor)[]>,
  opportunities: PropTypes.array as
    Validator<(DevPlanOpportunity | EmployeeDevPlanOpportunity | DevPlanCompletedOpportunity)[]>,
  activities: PropTypes.array as Validator<SkillActivity[]>,
  pendingCourses: PropTypes.bool,
  pendingAdvisors: PropTypes.bool,
  pendingOpportunities: PropTypes.bool,
  failedCourses: PropTypes.bool,
  failedAdvisors: PropTypes.bool,
  failedOpportunities: PropTypes.bool,
  disabled: PropTypes.bool,
  notFoundMessage: PropTypes.string,
  isSelected: PropTypes.bool,
  withoutLoadingPlaceholders: PropTypes.bool,
  withCompletedState: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  onSelectCourse: PropTypes.func,
  onSelectAdvisor: PropTypes.func,
  onSelectOpportunity: PropTypes.func,
  onSelectActivity: PropTypes.func,
  onRemoveCourse: PropTypes.func,
  onRemoveAdvisor: PropTypes.func,
  onRemoveOpportunity: PropTypes.func,
  onRemoveActivity: PropTypes.func,
  onCompleteCourse: PropTypes.func,
  onCompleteActivity: PropTypes.func,
  onCompleteAdvisor: PropTypes.func,
  onRequestAdvisor: PropTypes.func,
  courseCompletingId: PropTypes.number,
  activityCompletingId: PropTypes.number,
  advisorCompletingId: PropTypes.number,
  advisorRequestingId: PropTypes.number
};

// eslint-disable-next-line complexity
const CoursesAndAdvisorsGrid: FunctionComponent<CoursesAndAdvisorsGridProps> = ({
  courses,
  advisors,
  opportunities,
  activities,
  pendingCourses = false,
  pendingAdvisors = false,
  pendingOpportunities = false,
  failedCourses = false,
  failedAdvisors = false,
  failedOpportunities = false,
  disabled = false,
  notFoundMessage,
  isSelected,
  withoutLoadingPlaceholders = false,
  withCompletedState = false,
  isMyPlan = false,
  onSelectCourse,
  onSelectAdvisor,
  onSelectOpportunity,
  onSelectActivity,
  onRemoveCourse,
  onRemoveAdvisor,
  onRemoveOpportunity,
  onRemoveActivity,
  onCompleteCourse,
  onCompleteActivity,
  onCompleteAdvisor,
  onRequestAdvisor,
  courseCompletingId,
  activityCompletingId,
  advisorCompletingId,
  advisorRequestingId
}) => {
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();
  return (
    (!HAS_COURSES || failedCourses) &&
    (!HAS_MENTORING || failedAdvisors) &&
    (!HAS_DEV_PLAN_OPPORTUNITIES || failedOpportunities) &&
      <FetchFailedAlert flat/>
  ) || (
    (!HAS_COURSES || pendingCourses) &&
    (!HAS_MENTORING || pendingAdvisors) &&
    (!HAS_DEV_PLAN_OPPORTUNITIES || pendingOpportunities) &&
      <LoadingPlaceholder flat/>
  ) || (
    (!HAS_COURSES || size(courses) < 1) &&
    (!HAS_MENTORING || size(advisors) < 1) &&
    (!HAS_DEV_PLAN_OPPORTUNITIES || size(opportunities) < 1) &&
    size(activities) < 1 && (
      <Alert severity="info" variant="standard">
        <FormattedMessage id={notFoundMessage || 'hr.dev_plan.nothing_found'}/>
      </Alert>
  )) || (
    <>
      {(failedCourses && <FetchFailedAlert flat/>) ||
      (pendingCourses && !withoutLoadingPlaceholders && <LoadingPlaceholder flat/>)}
      <CardDeck compact={withCompletedState}>
        {map(courses, (course) => (
          <DevPlanCourseCard
              key={course.id}
              item={course}
              disabled={disabled}
              isSelected={isSelected}
              onSelect={onSelectCourse}
              onRemove={onRemoveCourse}
              onComplete={onCompleteCourse}
              onCompletePending={courseCompletingId === course.id}
              withCompletedState={withCompletedState}
          />
        ))}
        {map(advisors, (advisor) => (
          <EmployeeCard
              key={advisor.id}
              withSkills
              avatarVariant="devplanadvisor"
              item={advisor}
              disabled={disabled}
              isSelected={isSelected}
              onSelect={onSelectAdvisor}
              onRemove={onRemoveAdvisor}
              onComplete={onCompleteAdvisor}
              withCompletedState={withCompletedState}
              onCompletePendingId={advisorCompletingId}
              onAction={onRequestAdvisor}
              actionLabel={onRequestAdvisor ? 'skill.advisors.button.request' : undefined}
              actionFinishedLabel={onRequestAdvisor ? 'skill.advisors.button.requested' : undefined}
              actionPendingId={advisorRequestingId}
          />
        ))}
        {map(opportunities, (opportunity) => (
          <OpportunityCard
              key={opportunity.id}
              devplan
              item={opportunity}
              disabled={disabled}
              isSelected={isSelected}
              isMyPlan={isMyPlan}
              onSelect={onSelectOpportunity}
              onRemove={onRemoveOpportunity}
              withCompletedState={withCompletedState}
          />
        ))}
        {(
          (!failedAdvisors && pendingAdvisors) ||
          (!failedCourses && pendingCourses) ||
          (!failedOpportunities && pendingOpportunities)
        ) && withoutLoadingPlaceholders ? (
          <GridBox
              item
              container
              xs={12}
              sm={6}
              md={4}
              lg={3}
              py={8}
              display="flex"
              alignItems="center"
              justifyContent="center"
          >
            <CircularProgress/>
          </GridBox>
        ) : undefined}
        {map(activities, (activity) => (
          <ActivityCard
              key={activity.id}
              item={activity}
              onSelect={onSelectActivity}
              onRemove={onRemoveActivity}
              onComplete={onCompleteActivity}
              disabled={disabled}
              isSelected={isSelected}
              withCompletedState={withCompletedState}
              onCompletePendingId={isSelected ? undefined : activityCompletingId}
          />
        ))}
      </CardDeck>
      {((failedAdvisors || failedOpportunities) && <FetchFailedAlert flat/>) ||
      ((pendingAdvisors || pendingOpportunities) && !withoutLoadingPlaceholders && <LoadingPlaceholder flat/>)}
    </>
  );
};

CoursesAndAdvisorsGrid.propTypes = CoursesAndAdvisorsGridPropTypes;

export default memo(CoursesAndAdvisorsGrid);
