// extracted by mini-css-extract-plugin
var _1 = "EmployeeCard_avatar__qzldg";
var _2 = "EmployeeCard_delegatorRow__-Oa45";
var _3 = "EmployeeCard_infoContainer__cxIow";
var _4 = "EmployeeCard_infoContainer2__8DLRV";
var _5 = "EmployeeCard_jobRow__0LlHd";
var _6 = "EmployeeCard_jobRow2__gdDMe";
var _7 = "EmployeeCard_titleContainer__DsJ1v";
var _8 = "EmployeeCard_titleRow__VJVVr";
var _9 = "EmployeeCard_zoomInBtn__2eLOw";
export { _1 as "avatar", _2 as "delegatorRow", _3 as "infoContainer", _4 as "infoContainer2", _5 as "jobRow", _6 as "jobRow2", _7 as "titleContainer", _8 as "titleRow", _9 as "zoomInBtn" }
