import { memo, useCallback, useMemo, useContext, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import range from 'lodash/range';
import indexOf from 'lodash/indexOf';
import { useNavigate } from 'react-router-dom';
// Material UI imports
import Dialog from '@mui/material/Dialog';
// EmPath UI Components
import useCurrentPath from '@empathco/ui-components/src/hooks/useCurrentPath';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import useBuilderSteps, {
  STEP_CURRENT_JOB, STEP_ADDITION, STEP_TARGET, STEP_COMPLETED, BUILDER_STEPS
} from '../constants/builder';
import usePathConfig, { PATHS_MY_PROFILE_BUILDER } from '../config/paths';
import { GlobalContext } from '../context';
import Screen from '../v3/Screen';
import BuilderHeader from '../elements/BuilderHeader';
import BuilderStepper from '../elements/BuilderStepper';
import BuilderInferredStep from './BuilderInferredStep';
import BuilderTargetsStep from './BuilderTargetsStep';
import BuilderCompleteStep from './BuilderCompleteStep';
// SCSS imports
import { paper } from './ProfileBuilder.module.scss';

const paperProps = {
  className: paper
};

type ProfileBuilderProps = {
  // for Storybook only
  testResume?: boolean;
  skipResumeCheck?: boolean;
};

const ProfileBuilderPropTypes = {
  testResume: PropTypes.bool,
  skipResumeCheck: PropTypes.bool
};

const ProfileBuilder: FunctionComponent<ProfileBuilderProps> = ({
  testResume,
  skipResumeCheck
}) => {
  const navigate = useNavigate();
  const { ROUTES } = usePathConfig();
  const { getNextIncompleteStep, getStepProgress, getDefaultStep, isValidStep } = useBuilderSteps();
  const path = useCurrentPath(ROUTES);
  const { user: { data: user } } = useContext(GlobalContext);

  const [resumeUploading, setResumeUploading] = useState(Boolean(testResume));
  const handleUploadStart = useCallback(() => setResumeUploading(true), []);
  const handleUploadEnd = useCallback(() => setResumeUploading(false), []);

  const [navigateTo, setNavigateTo] = useState<string>();
  const onNagivate = useCallback(() => {
    if (navigateTo) {
      const navTo = navigateTo;
      setNavigateTo(undefined);
      navigate(navTo);
    }
  }, [navigateTo, navigate]);

  const {
    confirmOpen,
    confirmMounted,
    handleAction,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(onNagivate);

  const handleClicks = useMemo(() => map(range(0, BUILDER_STEPS.length), (idx) => () => {
    setNavigateTo(PATHS_MY_PROFILE_BUILDER[idx]);
    handleAction();
  }), [handleAction]);

  const step = useMemo(() => {
    const index = indexOf(PATHS_MY_PROFILE_BUILDER, path);
    return index < 0 || !isValidStep(user?.has_current_job_skills, user?.has_in_demand_skills, index + STEP_CURRENT_JOB)
      ? getDefaultStep(user) : index + STEP_CURRENT_JOB;
  }, [path, user, getDefaultStep, isValidStep]);

  const handleNextStep = useCallback((confirmed: boolean) => {
    if (step < STEP_COMPLETED) {
      const navTo = PATHS_MY_PROFILE_BUILDER[getNextIncompleteStep(user, step) - 1];
      if (confirmed || user?.onboarding_steps?.[BUILDER_STEPS[step - 1]]) navigate(navTo);
      else {
        setNavigateTo(navTo);
        handleAction();
      }
    }
  }, [step, user, handleAction, navigate, getNextIncompleteStep]);

  return (
    <Screen fullHeight>
      <Dialog
          disableEscapeKeyDown
          disableEnforceFocus
          fullWidth
          maxWidth="xl"
          scroll="body"
          open
          keepMounted
          id="profile-builder-dialog"
          aria-labelledby="profile-builder-dialog"
          PaperProps={paperProps}
      >
        <BuilderHeader progress={getStepProgress(user, step)}/>
        <BuilderStepper
            user={user}
            activeStep={step < STEP_COMPLETED ? step : 0}
            steps={user?.onboarding_steps}
            disabled={step === STEP_ADDITION ? resumeUploading : undefined}
            handleClicks={handleClicks}
        />
        {(step < STEP_TARGET && (
          <BuilderInferredStep
              key={step}
              step={step}
              onNext={handleNextStep}
              onUploadStart={step === STEP_ADDITION ? handleUploadStart : undefined}
              onUploadEnd={step === STEP_ADDITION ? handleUploadEnd : undefined}
              disabled={step === STEP_ADDITION ? resumeUploading : undefined}
              resumeUploading={step === STEP_ADDITION ? resumeUploading : undefined}
              skipResumeCheck={skipResumeCheck}
              testResume={testResume}
          />
        )) ||
        (step === STEP_TARGET && <BuilderTargetsStep onNext={handleNextStep}/>) ||
          <BuilderCompleteStep/>}
      </Dialog>
      {confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            title="builder.skip.confirm.title"
            text="builder.skip.confirm.text"
            withCancelButton
            confirmLabel="builder.skip.confirm.yes"
            cancelLabel="common.button.back"
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
    </Screen>
  );
};

ProfileBuilder.propTypes = ProfileBuilderPropTypes;

export default memo(ProfileBuilder);
