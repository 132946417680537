import { useCallback, useMemo } from 'react';
import toSafeInteger from 'lodash/toSafeInteger';
import { type ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { type FieldPolicy, type FieldReadFunction, InMemoryCache } from '@apollo/client';
// EmPath UI Components
import { getFullName, getName } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { OpportunityStatus, StrictTypedTypePolicies } from './types';
import useModels from '../helpers/models';

const employeeTitle = (codeInBrackets: boolean) => ({
  read: (_title: string, { readField }: { readField: ReadFieldFunction }) => {
    const code = readField('code');
    const first_name = readField('first_name') as string;
    const last_name = readField('last_name') as string;
    return first_name || last_name
      ? `${getFullName(first_name, last_name)} ${codeInBrackets ? '(' : '- '}${code}${codeInBrackets ? ')' : ''}`
      : (codeInBrackets && `(${code})`) || code;
  }
} as FieldPolicy | FieldReadFunction);

function useCustomizedApolloCache() {
  const { getLocationStr } = useModels();

  const typePolicies: StrictTypedTypePolicies = useMemo(() => ({
    Location: {
      fields: {
        title: {
          read: (_title: string, { readField }) => getLocationStr({
            city: readField('city'),
            state: readField('state'),
            country: readField('country')
          })
        }
      }
    },
    CourseAdvisorItem: {
      fields: {
        id: {
          read: (_id: number, { readField }) => {
            const itemType = readField('item_type');
            return 10 * toSafeInteger(readField('item_id')) + (
              (itemType === 'advisor' && 1) ||
              (itemType === 'opportunity' && 2) || 0
            );
          }
        },
        title: {
          read: (title: string, { readField }) => readField('item_type') === 'advisor' ? getName(title) : title
        }
      }
    },
    EmployeeSearchItem: {
      fields: {
        title: employeeTitle(false)
      }
    },
    OpportunityOwner: {
      fields: {
        title: employeeTitle(true)
      }
    },
    Opportunity: {
      fields: {
        timestamp: {
          read: (_timestamp: string, { readField }) => {
            const status = readField('status');
            return (status === OpportunityStatus.draft && readField('updated_at')) ||
              (status === OpportunityStatus.published && readField('published_at')) ||
              (status === OpportunityStatus.started && readField('started_at')) ||
              (status === OpportunityStatus.archived && readField('archived_at')) ||
              (status === OpportunityStatus.deleted && readField('updated_at')) ||
              null;
          }
        }
      }
    }
  }), [getLocationStr]);

  const createCache = useCallback(() => new InMemoryCache({
    typePolicies
  }), [typePolicies]);

  return useMemo(() => ({ createCache }), [createCache]);
}

export default useCustomizedApolloCache;
