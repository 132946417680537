import { memo, useContext, useMemo, type FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams, Navigate } from 'react-router-dom';
// EmPath UI Components
import useCurrentPath from '@empathco/ui-components/src/hooks/useCurrentPath';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import ExportButton, { type ExportParams } from '@empathco/ui-components/src/elements/ExportButton';
// local imports
import { getDelegator, isManager } from '../models/user';
import useCustomerSettings from '../config/customer';
import { API_SUPV_TEAM_EXPORT } from '../config/api';
import usePathConfig, { PATH_HR_TEAMS, PATH_HR_TEAM_BUILDER, PATH_HR_TEAM_BUILDER_REVIEW } from '../config/paths';
import { getTeamId } from '../helpers/routerParams';
import useRedirects from '../helpers/redirect';
import { GlobalContext, SupervisorContext } from '../context';
import useVerifyUser from '../hooks/useVerifyUser';
import LeaderHeader from '../elements/LeaderHeader';
import Screen from '../v3/Screen';
import TeamView from '../components/TeamView';
import TeamBuilder from '../panels/TeamBuilder';
import TeamBuilderTeam from '../panels/TeamBuilderTeam';
import TeamBuilderReview from '../panels/TeamBuilderReview';

// eslint-disable-next-line complexity
const HRTeamsScreen: FunctionComponent = () => {
  const { HAS_TEAM_BUILDER } = useCustomerSettings();
  const { ROUTES } = usePathConfig();
  const { getDefaultPath } = useRedirects();

  const team_id = getTeamId(useParams());
  const path = useCurrentPath(ROUTES);

  const { token } = useContext(GlobalContext);
  const exportParams = useMemo(() => token ? { token: token as string } as ExportParams : undefined, [token]);

  const { content, user } = useVerifyUser({ hr: true });
  const { tbTeam: { data: team } } = useContext(SupervisorContext);
  const leader = getDelegator(user);
  const isSupervisor = isManager(user);

  const title = team_id === team?.id ? team.title : undefined;

  const breadcrumbs = useMemo(() =>
    (title && [{ title }]) ||
    (path === PATH_HR_TEAMS && [
      {
        title: HAS_TEAM_BUILDER
          ? (isSupervisor && 'breadcrumbs.hr.teams') || 'breadcrumbs.hr.teambuilder'
          : 'breadcrumbs.my_team'
      }
    ]) ||
    undefined, [title, path, isSupervisor, HAS_TEAM_BUILDER]);

  const items = useMemo(() => HAS_TEAM_BUILDER && isSupervisor ? [
    {
      title: 'supervisor.my_team.title',
      link: PATH_HR_TEAMS,
      action: (
        <ExportButton
            title="supervisor.my_team.export"
            params={exportParams}
            endpoint={API_SUPV_TEAM_EXPORT}
        />
      )
    },
    {
      title: 'hr.teambuilder',
      active: Boolean(team_id),
      link: PATH_HR_TEAM_BUILDER
    }
  ] : undefined, [team_id, isSupervisor, exportParams, HAS_TEAM_BUILDER]);

  if (!content && !HAS_TEAM_BUILDER && user && !isSupervisor) return <Navigate replace to={getDefaultPath(user)}/>;

  const teamBuilder = content ? undefined : (team_id && (
    path === PATH_HR_TEAM_BUILDER_REVIEW ? (
      <TeamBuilderReview key={isSupervisor ? 2 : undefined} teamId={team_id}/>
    ) : (
      <DndProvider key={isSupervisor ? 3 : undefined} backend={HTML5Backend} context={window}>
        <TeamBuilderTeam teamId={team_id}/>
      </DndProvider>
    )
  )) || <TeamBuilder key={isSupervisor ? 4 : undefined}/>;

  return content || (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      {leader ? <LeaderHeader leader={leader}/> : undefined}
      <ContentCard withoutTopMargin={Boolean(leader)}>
        {items ? (
          <CardTabbar
              withoutScroll
              wideAction
              items={items}
          >
            {[
              <TeamView key={1}/>,
              teamBuilder
            ]}
          </CardTabbar>
        ) : (
          <>
            <CardTitle title={HAS_TEAM_BUILDER ? 'hr.teambuilder' : 'supervisor.my_team.title'} withDivider/>
            {HAS_TEAM_BUILDER ? teamBuilder : <TeamView/>}
          </>
        )}
      </ContentCard>
    </Screen>
  );
};

export default memo(HRTeamsScreen);
