import { memo, forwardRef, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { useIntl } from 'react-intl';
// Material UI imports
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import PlusOne from '@mui/icons-material/PlusOne';
// local imports
import { Skill } from '../models/skill';

const iconSx = { ml: -1, mr: 1 };

type AddGrowthButtonProps = {
  skill: Skill;
  onClick: (skill: Skill) => void;
  disabled?: boolean;
}

const AddGrowthButtonPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const AddGrowthButton = forwardRef<HTMLButtonElement, AddGrowthButtonProps>(({
  skill,
  onClick,
  disabled = false
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const handleClick = useCallback(() => onClick(skill), [skill, onClick]);

  return (
    <IconButton
        ref={ref}
        aria-label={formatMessage({ id: 'opportunities.button.add_growth' })}
        color="primary"
        size="small"
        disabled={disabled}
        onClick={handleClick}
        sx={iconSx}
    >
      <PlusOne color="inherit" fontSize="medium"/>
    </IconButton>
  );
});

AddGrowthButton.displayName = 'AddGrowthButton';

AddGrowthButton.propTypes = AddGrowthButtonPropTypes;

export default memo(AddGrowthButton);
