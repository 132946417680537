import {
  memo, useState, useEffect, useContext, useCallback, useMemo,
  type FunctionComponent, type MouseEventHandler, type MouseEvent, type ReactNode
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import unionBy from 'lodash/unionBy';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import map from 'lodash/map';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import transform from 'lodash/transform';
import findIndex from 'lodash/findIndex';
import xor from 'lodash/xor';
import toString from 'lodash/toString';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import { getStringifiedIds } from '@empathco/ui-components/src/helpers/strings';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { Job } from '../models/job';
import { Skill, SKILL_LEVEL_ADVANCED, SKILL_LEVEL_REGULAR } from '../models/skill';
import { HRJob } from '../models/hrJob';
import { HRJobVote } from '../models/hrJobVote';
import { isAdmin } from '../models/user';
import { MIN_JOB_SKILLS, MAX_JOB_SKILLS } from '../config/params';
import { GlobalContext, SupervisorContext } from '../context';
import { JobSkillLevelData } from '../context/supervisor';
import EditJobSkills from './EditJobSkills';
import EditJobAdvancedSkills from './EditJobAdvancedSkills';
// SCSS imports
import { content, footer, button } from './EditJobSkillsDialog.module.scss';

function getSkillIds(ids?: number[] | null, skills?: Skill[] | null): number[] {
  if (size(ids) >= 1) return ids as number[];
  if (size(skills) >= 1) return map(skills, 'id');
  return [];
}

function getAdvancedIds(
  selectedSkills?: Skill[] | null,
  ids?: number[] | null,
  skills?: Skill[] | null
): Record<string, boolean> {
  return (selectedSkills && ids && transform(ids, (result, id) => {
    result[toString(id)] = true;
  }, {} as Record<string, boolean>)) ||
  (selectedSkills && size(skills) >= 1 && transform(skills as Skill[], (result, { id, expected_level }) => {
    if (expected_level && expected_level >= SKILL_LEVEL_ADVANCED && findIndex(selectedSkills, ['id', id]) >= 0) {
      result[toString(id)] = true;
    }
  }, {} as Record<string, boolean>)) ||
  {};
}

type EditJobSkillsDialogProps = {
  role: Job;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  onExited: (node: HTMLElement) => void;
  // for Storybook only
  showAdvanced?: boolean;
  userAddedSkillIds?: number[];
}

const EditJobSkillsDialogPropTypes = {
  // attributes
  role: PropTypes.object.isRequired as Validator<Job>,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  // for Storybook only
  showAdvanced: PropTypes.bool,
  userAddedSkillIds: PropTypes.array
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const EditJobSkillsDialog: FunctionComponent<EditJobSkillsDialogProps> = ({
  role,
  isOpen = false,
  onClose,
  onExited,
  showAdvanced = false,
  userAddedSkillIds
}) => {
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    job: { data, pending: jobPending, failed: jobFailed }, requireJob,
    jobVote: { data: voteData, pending: votePending, failed: voteFailed }, requireJobVote,
    addedSkills: { data: addedSkills, pending: addedSkillsPending, failed: addedSkillsFailed }, requireAddedSkills,
    hrJobVote: { pending: votingPending, failed: votingFailed }, voteHrJob,
    hrJobRefine: { pending: refiningPending, failed: refiningFailed }, refineHrJob,
    hrJobSave: { pending: savePending, failed: saveFailed }, saveHrJob,
    clearJob, clearJobVote
  } = useContext(SupervisorContext);
  const { id, code, title, skills_with_gap, skills: skills_without_gap } = role || {};
  const {
    current_skills, potentially_matched_skills, self_added_skills, selected_skill_ids
  } = !jobPending && !jobFailed && data?.id === id ? data : {} as HRJob;
  const { skills, advanced_skill_ids } = !votePending && !voteFailed && (voteData?.id === id || voteData?.job?.id === id)
    ? voteData : {} as HRJobVote;

  const is_admin = isAdmin(user);

  // if `true`, then show Step 2: Advanced Level Selection
  const [advanced, setAdvanced] = useState(showAdvanced);
  useEffect(() => {
    if (isOpen) setAdvanced(showAdvanced);
  }, [isOpen, showAdvanced]);

  const { ids: defaultSkillIds, skls: roleSkills } = useMemo(() => {
    const skls = [...skills_with_gap || [], ...skills_without_gap || []];
    return {
      skls,
      ids: getSkillIds(null, skls)
    };
  }, [skills_with_gap, skills_without_gap]);

  const initialSkillIds = useMemo(() => getSkillIds(selected_skill_ids, current_skills), [current_skills, selected_skill_ids]);

  const defaultAdvancedIds = useMemo(() => getAdvancedIds(skills, null, roleSkills), [skills, roleSkills]);

  const initialAdvancedIds = useMemo(
    () => getAdvancedIds(advanced ? skills : null, advanced_skill_ids, current_skills),
    [advanced, advanced_skill_ids, current_skills, skills]
  );

  // selected skill ids (dropped to the skills drop area)
  const [skillIds, setSkillIds] = useState<number[]>(initialSkillIds);
  // update on original arrays change:
  useEffect(() => {
    if (selected_skill_ids || current_skills) setSkillIds(getSkillIds(selected_skill_ids, current_skills));
  }, [selected_skill_ids, current_skills]);

  // advanced skill ids
  const [advancedIds, setAdvancedIds] = useState<Record<string, boolean>>(initialAdvancedIds);
  // update on original arrays change:
  useEffect(() => {
    if (advanced && (advanced_skill_ids || current_skills)) setAdvancedIds(
      getAdvancedIds(advanced ? skills : null, advanced_skill_ids, current_skills)
    );
  }, [advanced, advanced_skill_ids, current_skills, skills]);

  const isSelectedDefault = useMemo(() => size(xor(skillIds, defaultSkillIds)) <= 0, [defaultSkillIds, skillIds]);

  const isAdvancedDefault = useMemo(
    () => size(xor(keys(advancedIds), keys(defaultAdvancedIds))) <= 0,
    [advancedIds, defaultAdvancedIds]
  );

  // TODO: isModified? => do call API on Next? disable Submit Vote?

  const [allSkills, setAllSkills] = useState<Skill[]>();
  const [addedSkillIds, setAddedSkillIds] = useState<number[]>(userAddedSkillIds || []);

  useEffect(() => {
    if (code) requireJob?.({ role_id: code, is_admin });
  }, [code, is_admin, requireJob]);

  useEffect(() => {
    if (advanced && code) requireJobVote?.({
      role_id: code,
      is_admin,
      skill_ids: is_admin ? skillIds : null
    });
  }, [advanced, code, is_admin, skillIds, requireJobVote]);

  useEffect(() => {
    if (size(addedSkillIds) >= 1) requireAddedSkills?.({ ids: getStringifiedIds(addedSkillIds) });
  }, [addedSkillIds, requireAddedSkills]);

  useEffect(() => {
    if (!jobPending && role && (size(addedSkillIds) < 1 || addedSkills || isNil(allSkills))) {
      setAllSkills(unionBy(
        current_skills || [],
        potentially_matched_skills || [],
        self_added_skills || [],
        roleSkills,
        (size(addedSkillIds) >= 1 && addedSkills) || [],
        'id'
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // we do not need act on `allSkills` change here
    current_skills, potentially_matched_skills, self_added_skills, addedSkills, jobPending, addedSkillIds, roleSkills
  ]);

  const handleClose = useCallback((event: MouseEvent<HTMLButtonElement>, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') onClose(event);
  }, [onClose]);

  const transitionProps = useMemo(() => ({ onExited: (node: HTMLElement) => {
    clearJob?.();
    clearJobVote?.();
    onExited(node);
  } }), [clearJob, clearJobVote, onExited]);

  const handleCancel = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (advanced) setAdvanced(false);
    else onClose(event);
  }, [advanced, onClose]);

  const handleNext = useCallback(() => {
    if (id) {
      if (is_admin) {
        setAdvanced(true);
      } else if (voteHrJob) {
        const count = size(skillIds);
        if (count >= MIN_JOB_SKILLS && count <= MAX_JOB_SKILLS) voteHrJob({
          job_id: id,
          skill_ids: skillIds,
          onSuccess: () => setAdvanced(true)
        });
      }
    }
  }, [id, is_admin, voteHrJob, skillIds]);


  const handleResetSkills = useCallback(() => {
    setSkillIds(getSkillIds(null, roleSkills));
  }, [roleSkills]);

  const handleResetAdvanced = useCallback(() => {
    setAdvancedIds(getAdvancedIds(skills, null, roleSkills));
  }, [skills, roleSkills]);

  const handleAdvancedSkill = useCallback((skillId: number) => {
    const idStr = toString(skillId);
    setAdvancedIds((prevAdvancedIds) => prevAdvancedIds[idStr] ? omit(prevAdvancedIds, idStr) : {
      ...prevAdvancedIds,
      [idStr]: true
    });
  }, []);

  const handleSubmit = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (id) {
      if (is_admin) {
        saveHrJob?.({
          job_id: id,
          skills: map(skills, ({ id: skill_id }) => ({
            skill_id,
            level: advancedIds[skill_id] ? SKILL_LEVEL_ADVANCED : SKILL_LEVEL_REGULAR
          } as JobSkillLevelData))
        });
      } else refineHrJob?.({
        job_id: id,
        skill_ids: transform(advancedIds, (result, value, key) => {
          if (value) result.push(toSafeInteger(key));
        }, [] as number[]),
        onSuccess: () => onClose(event)
      });
    }
  }, [id, is_admin, skills, advancedIds, refineHrJob, saveHrJob, onClose]);

  const sup = useCallback((chunks?: ReactNode | ReactNode[]): ReactNode => (
    <sup>
      {mapChunks(chunks)}
    </sup>
  ), []);

  const titleSx = useMemo(() => ({ pt: 5, pb: 3, pr: 7 }), []); // TODO: check padding values!

  const pending = jobPending || !current_skills || !potentially_matched_skills || !allSkills || (
    advanced && (votePending || !skills)
  );
  const disabledAddSkill = size(addedSkillIds) >= 1 && (addedSkillsPending || !addedSkills);
  const pendingAction = advanced ? refiningPending || savePending : votingPending;
  const disabled = pending || pendingAction || disabledAddSkill;
  const failed = jobFailed || (advanced && voteFailed) || (size(addedSkillIds) >= 1 && addedSkillsFailed);

  const selected = size(skillIds);
  const warning = advanced ? undefined
    : (selected < MIN_JOB_SKILLS && 'hr.job_skills.warning.too_few') ||
      (selected >= MAX_JOB_SKILLS && 'hr.job_skills.warning.too_many') ||
      undefined;

  return (
    <>
      <Dialog
          disableEscapeKeyDown
          disableEnforceFocus
          maxWidth="lg"
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={pendingAction ? undefined : handleClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={onClose} disabled={pendingAction ? true : undefined}/>
        <DialogTitle sx={titleSx} component="div">
          <Typography variant="h3">
            <FormattedMessage
                id={advanced ? 'hr.job_skills.advanced.title' : 'hr.job_skills.title'}
                values={{ role: title }}
            />
          </Typography>
          <BoxTypography pt={2.5} variant="subtitle2">
            <FormattedMessage
                id={advanced ? 'hr.job_skills.advanced.info' : 'hr.job_skills.info'}
                values={{
                  br: <br/>,
                  sup,
                  role: title,
                  count: selected >= MAX_JOB_SKILLS ? 0 : MAX_JOB_SKILLS - selected
                }}
            />
          </BoxTypography>
        </DialogTitle>
        <Divider/>
        <DialogContent className={content}>
          {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (advanced ? (
            <CardSection shady>
              <EditJobAdvancedSkills
                  skills={skills as Skill[]}
                  advancedIds={advancedIds}
                  onActivate={handleAdvancedSkill}
                  onReset={isAdvancedDefault ? undefined : handleResetAdvanced}
                  disabled={disabled}
              />
            </CardSection>
          ) : (
            <EditJobSkills
                title={title}
                allSkills={allSkills as Skill[]}
                skillIds={skillIds}
                setSkillIds={setSkillIds}
                userAddedSkillIds={addedSkillIds}
                onAddedSkillsChange={setAddedSkillIds}
                onSelectionChange={setSkillIds}
                onReset={isSelectedDefault ? undefined : handleResetSkills}
                disabled={disabled}
                disabledAddSkill={disabledAddSkill}
                warning={warning}
            />
          ))}
        </DialogContent>
        <DialogActions className={footer}>
          <Button
              onClick={handleCancel}
              color="primary"
              variant="contained"
              disableElevation
              size="small"
              className={button}
          >
            <FormattedMessage id={advanced ? 'common.button.back' : 'common.button.cancel'}/>
          </Button>
          <Button
              onClick={advanced ? handleSubmit : handleNext}
              color="primary"
              variant="contained"
              disableElevation
              size="small"
              startIcon={pendingAction ? <CircularProgress size={18} color="inherit"/> : undefined}
              disabled={disabled || selected < MIN_JOB_SKILLS || selected > MAX_JOB_SKILLS}
              className={button}
          >
            <FormattedMessage
                id={advanced
                  ? (is_admin && 'common.button.save') || 'hr.job_skills.button.submit'
                  : 'common.button.next'}
            />
          </Button>
        </DialogActions>
        {advanced ? (
          <BoxTypography
              pt={2}
              pb={7}
              px="25%"
              variant="subtitle2"
              color="text.label"
              textAlign="center"
          >
            <FormattedMessage
                id="hr.job_skills.advanced.hint"
                values={{ sup }}
            />
          </BoxTypography>
        ) : undefined}
      </Dialog>
      <ActionFailedAlert
          open={votingFailed}
          message="hr.job_skills.error.next"
      />
      {is_admin ? (
        <ActionFailedAlert
            open={saveFailed}
            message="hr.job_skills.error.save"
        />
      ) : (
        <ActionFailedAlert
            open={refiningFailed}
            message="hr.job_skills.error.submit"
        />
      )}
    </>
  );
};

EditJobSkillsDialog.propTypes = EditJobSkillsDialogPropTypes;

export default memo(EditJobSkillsDialog);
