import { useCallback, useMemo } from 'react';
import toSafeInteger from 'lodash/toSafeInteger';
// EmPath UI Components
import * as ML from '@empathco/ui-components/src/models/managementLevel';
// local imports
import { AdminJob } from '../graphql/types';
import { User } from '../models/user';
import { Job } from '../models/job';
import { Employee } from '../models/employee';
import useCustomerSettings from '../config/customer';

// Employee & Job Management Levels (could be also `0` or `null`)
export const {
  MANAGEMENT_LEVEL_FIRST,
  MANAGEMENT_LEVEL_LAST // max level for Employee role
} = ML;
export type EmployeeManagementLevel = ML.EmployeeManagementLevel;

export type ManagementLevel = ML.ManagementLevel; // User Management Level (including Top Management)

function useNonReducedUI() {
  const { HAS_REDUCED_UI } = useCustomerSettings();
  const showNonReducedUI = useCallback((entity?: User | Employee | Job | AdminJob | null): boolean =>
    !HAS_REDUCED_UI || Boolean(entity && toSafeInteger(entity.management_level) >= MANAGEMENT_LEVEL_FIRST),
    [HAS_REDUCED_UI]);
  return useMemo(() => ({ showNonReducedUI }), [showNonReducedUI]);
}

export default useNonReducedUI;