import find from 'lodash/find';
import toString from 'lodash/toString';
// EmPath UI Components
import { getCurrentUtc } from '@empathco/ui-components/src/helpers/datetime';
// local imports
import { CustomerTicker } from '../config/customer';

export const getTicker = (
  supervisor?: boolean | null,
  leader?: boolean | null,
  employee?: boolean | null,
  tickers?: CustomerTicker[] | null
): CustomerTicker => {
  const curUtcISO = toString(getCurrentUtc().toISO());
  return (tickers && find(tickers, ({
    ticker,
    popup,
    valid_from,
    valid_till,
    supervisor_only,
    leader_only,
    employee_only
  }: CustomerTicker) => Boolean(ticker || popup) &&
    (!supervisor_only || Boolean(supervisor)) &&
    (!leader_only || Boolean(leader)) &&
    (!employee_only || Boolean(employee)) &&
    (!valid_from || valid_from <= curUtcISO) &&
    (!valid_till || valid_till >= curUtcISO)
  )) || {
    id: 0,
    ticker: null,
    popup: null
  } as CustomerTicker;
};
