import { memo, type FunctionComponent, type SVGProps } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
// local imports
import { CourseProvider } from '../models/courseProvider';
import useCustomerSettings from '../config/customer';
// Media and SCSS imports
import { ReactComponent as CourseraLogo } from '../images/course-providers/coursera.svg';
import { ReactComponent as UdemyLogo } from '../images/course-providers/udemy.svg';
import { ReactComponent as UoPxLogo } from '../images/course-providers/uopx.svg';
import { ReactComponent as SkillsoftLogo } from '../images/course-providers/skillsoft.svg';
import { ReactComponent as CompanyLogo } from '../customers/logo.svg';
// import { ReactComponent as Logo } from '../customers/gf/logo.svg'; // for testing of customer's logo
import { uopx, coursera, udemy, skillsoft, text, empty, internal } from './CourseProviderLogo.module.scss';

export type LogoComponent = FunctionComponent<SVGProps<SVGSVGElement> & {
  title?: string | undefined;
}>

type CourseProviderLogoProps = {
  provider?: CourseProvider | null;
  logo?: LogoComponent;
};

const CourseProviderLogoPropTypes = {
  provider: PropTypes.object as Validator<CourseProvider>,
  logo: PropTypes.elementType as Validator<LogoComponent>
};

const CourseProviderLogo: FunctionComponent<CourseProviderLogoProps> = ({
  provider,
  logo: Logo = CompanyLogo
}) => {
  const { HAS_INTERNAL_COURSE_LOGO } = useCustomerSettings();
  switch (provider?.code) {
    // Course Providers with SVG logos:
    case 'uopx': return <UoPxLogo className={uopx}/>;
    case 'coursera': return <CourseraLogo className={coursera}/>;
    case 'udemy': return <UdemyLogo className={udemy}/>;
    case 'percipio': return <SkillsoftLogo className={skillsoft}/>;

    // Customer's internal Course Provider:
    case 'internal':
    // case null:
    // case undefined:
      return HAS_INTERNAL_COURSE_LOGO ? <Logo className={internal}/> : <div className={empty}/>;

    // other Course Providers (text logo):
    default: return provider?.title ? (
      <Box className={text}>
        <TruncatedTextLink variant="inherit" plain color="text.label" text={provider?.title} length={20}/>
      </Box>
    ) : <div className={empty}/>;
  }
};

CourseProviderLogo.propTypes = CourseProviderLogoPropTypes;

export default memo(CourseProviderLogo);
