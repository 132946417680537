import { forwardRef, memo, useContext, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// EmPath UI Components
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { Job } from '../models/job';
import { ILookupItem } from '../models/lookupItem';
import { LookupContext } from '../context';
import { JobsSearchParams } from '../context/lookups';
// SCSS imports
import { root } from './JobSearch.module.scss';

export type JobLookupItem = Job & ILookupItem;

export type JobSearchVariant = 'search' | 'target' | 'skills' | 'filter';

type JobSearchProps = {
  value?: JobLookupItem | null;
  onChange: (skill: JobLookupItem | null) => void;
  disabled?: boolean | null;
  exclude?: number[] | null;
  fullWidth?: boolean;
  withSkills?: boolean;
  variant?: JobSearchVariant;
  className?: string;
}

const JobSearchPropTypes = {
  // attributes
  value: PropTypes.object as Validator<JobLookupItem>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  fullWidth: PropTypes.bool,
  withSkills: PropTypes.bool,
  variant: PropTypes.string as Validator<JobSearchVariant>,
  className: PropTypes.string
};

const JobSearch = forwardRef<HTMLDivElement, JobSearchProps>(({
  value,
  onChange,
  disabled = false,
  exclude,
  fullWidth = false,
  withSkills = false,
  variant,
  className
}, ref) => {
  const {
    jobsSearch: { data: jobsSearch, pending, failed, params },
    requireJobsSearch, clearJobsSearch
  } = useContext(LookupContext);

  const fetchParams = useMemo(() => withSkills
    ? { with_skills_only: true } as JobsSearchParams
    : undefined
  , [withSkills]);

  return requireJobsSearch ? (
    <Lookup
        ref={ref}
        withCodeOrAbbr
        fullWidth={fullWidth}
        type={variant ? `${variant}_job` : 'jobs'}
        className={clsx(root, className)}
        fetched={jobsSearch}
        pending={pending}
        failed={failed}
        params={params}
        fetch={requireJobsSearch}
        fetchParams={fetchParams}
        clear={clearJobsSearch} // TODO: do we need it?
        exclude={exclude}
        onChange={onChange}
        value={value}
        disabled={disabled}
        popupIcon={<SearchIcon/>}
    />
  ) : null;
});

JobSearch.displayName = 'JobSearch';

JobSearch.propTypes = JobSearchPropTypes;

export default memo(JobSearch);
