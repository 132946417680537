import { memo, useContext, useEffect, useState, useLayoutEffect, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import SkillLevelSelector from '@empathco/ui-components/src/elements/SkillLevelSelector';
import FilterSearch from '@empathco/ui-components/src/elements/FilterSearch';
// local imports
import { ADMIN_EMPLOYEES_QUERY } from '../graphql/AdminEmployees';
import {
  AdminEmployee, AdminEmployeesDocument, AdminEmployeesQueryVariables, AdminEmployeesSort, SortDirection
} from '../graphql/types';
import {
  TEmployeeSortExt, TalentEmployeeObject,
  ADMIN_EMPLOYEES_SORT, DEFAULT_ADMIN_EMPLOYEES_DIRECTION
} from '../graphql/customTypes';
import { SkillLevel } from '../models/skill';
// import { EmployeeSortExt } from '../constants/employeeSort';
import useCustomerSettings from '../config/customer';
import { DataContext } from '../context';
import PaginationControls from '../v3/PaginationControls';
import EmployeesTable from '../v3/EmployeesTable';
import DashboardFilters, { DashboardFilterValues } from '../v3/DashboardFilters';
import AdminEmployeeDialog from '../widgets/AdminEmployeeDialog';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';

type AdminEmployeesProps = {
  // for Storybook only
  testPending?: boolean;
}

const AdminEmployeesPropTypes = {
  testPending: PropTypes.bool
};

// eslint-disable-next-line max-statements
const AdminEmployees: FunctionComponent<AdminEmployeesProps> = ({
  testPending
}) => {
  const { HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);
  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);

  // lazy load employees
  const {
    query: getEmployees, pending: pendingEmployees, failed, count, results: employees, variables: prevVars
  } = useQueryCounted({
    data: undefined as unknown as AdminEmployee,
    key: 'adminEmployees',
    lazyQuery: useLazyQuery(ADMIN_EMPLOYEES_QUERY as typeof AdminEmployeesDocument)
  });
  const pending = pendingEmployees || testPending;

  const [filters, setFilters] = useState<DashboardFilterValues>();
  const [search, setSearch] = useState('');
  const [level, setLevel] = useState<SkillLevel>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const [defaultSort] = ADMIN_EMPLOYEES_SORT;

  // eslint-disable-next-line react/hook-use-state
  const [{ sort, dir }, setSort] = useState<{
    sort: AdminEmployeesSort;
    dir: SortDirection;
  }>({
    sort: defaultSort,
    dir: DEFAULT_ADMIN_EMPLOYEES_DIRECTION[defaultSort]
  });
  useLayoutEffect(() => {
    setSort({ sort: defaultSort, dir: DEFAULT_ADMIN_EMPLOYEES_DIRECTION[defaultSort] });
  }, [defaultSort]);

  const [employee, setEmployee] = useState<AdminEmployee>();

  const handleSort = useCallback((sortValue: TEmployeeSortExt, newDir: SortDirection) => {
    if (sortValue && newDir) setSort({ sort: sortValue as AdminEmployeesSort, dir: newDir });
  }, []);

  const handleFiltersReset = useCallback(() => {
    setSearch('');
    setLevel(0);
    setSort({ sort: defaultSort, dir: DEFAULT_ADMIN_EMPLOYEES_DIRECTION[defaultSort] });
  }, [defaultSort]);

  const handleClick = useCallback((empl?: TalentEmployeeObject | AdminEmployee) => {
    setEmployee(empl as AdminEmployee);
  }, []);

  const handleClose = useCallback(() => {
    setEmployee(undefined);
  }, []);

  useEffect(() => {
    if (settingsLoaded && filters && getEmployees && !isNil(pageSize)) {
      const variables: AdminEmployeesQueryVariables = {
        search: trim(search) || null,
        manager_id: filters.manager_id || null,
        org_id: filters.org_id || null,
        country_id: filters.country_id || null,
        state_id: filters.state_id || null,
        management_level: level || null,
        direction: dir,
        sort_by: sort,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getEmployees({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // only filter values are monitored here:
    filters, search, level, sort, dir, pageSize, currentPage, getEmployees, settingsLoaded
  ]);

  const pendingAll = !settingsLoaded || !filters || pending;
  const loading = pendingAll || !employees;
  const failedAny = failed; // || failedSettings;
  const disabled = loading || failedAny || pendingSettingsUpdate ? true : undefined;

  const reloading = Boolean(pending && employees);

  const content = (
    <CardSection top>
      <EmployeesTable
          admin
          data={employees}
          pending={pending}
          failed={failed}
          sortBy={sort}
          direction={dir}
          changeSort={handleSort}
          onClick={handleClick}
          disabled={disabled}
          withReloading
      />
    </CardSection>
  );

  return (
    <>
      <CardSection top>
        <DashboardFilters
            settingsId="admin_employees"
            withOrg={HAS_INDEMAND_SKILLS}
            requireOrg={false}
            orgFilterPlacement="simple"
            withReset
            onChange={setFilters}
            onReset={search || level || sort !== defaultSort || dir !== DEFAULT_ADMIN_EMPLOYEES_DIRECTION[defaultSort]
              ? handleFiltersReset : undefined}
            uid="0"
            disabled={disabled}
            extraFilters={(
              <>
                <Box pb={1} pr={1.25} display="flex" justifyContent="flex-start">
                  <SkillLevelSelector
                      job
                      search
                      value={level}
                      onChange={setLevel}
                      disabled={disabled}
                  />
                </Box>
                <Box pb={1} display="flex" justifyContent="flex-start">
                  <FilterSearch
                      type="employee"
                      value={search}
                      onChange={setSearch}
                      disabled={disabled}
                  />
                </Box>
              </>
            )}
        />
      </CardSection>
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      <CardSection>
        <PaginationControls
            settingsId="admin_employees"
            loaded={Boolean(employees)}
            pending={pending}
            loading={pendingAll}
            total={count}
            currentPage={currentPage}
            onPageSelected={setCurrentPage}
            onPageSize={setPageSize}
            disabled={disabled || pendingSettingsUpdate}
        />
      </CardSection>
      <AdminEmployeeDialog
          employee={employee}
          onClose={handleClose}
      />
    </>
  );
};

AdminEmployees.propTypes = AdminEmployeesPropTypes;

export default memo(AdminEmployees);
