import {
  memo, useState, useCallback, useEffect, useLayoutEffect, useContext, useMemo,
  type FunctionComponent, type MouseEventHandler
} from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import { useLazyQuery, useQuery, useReactiveVar, useApolloClient } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import ViewSwitch, { TABLE_VIEW } from '@empathco/ui-components/src/elements/ViewSwitch';
import ExportButton, { type ExportParams } from '@empathco/ui-components/src/elements/ExportButton';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import FilterSearch from '@empathco/ui-components/src/elements/FilterSearch';
// local imports
import { SKILL_CATEGORIES_QUERY } from '../graphql/SkillCategories';
import { EMPLOYEE_SKILLS_QUERY } from '../graphql/EmployeeSkills';
import {
  SkillCategoriesDocument, EmployeeSkillsDocument, EmployeeSkillsQueryVariables,
  EmployeeSkillsSort, SortDirection, EmployeeSkill, SkillCategory
} from '../graphql/types';
import {
  SkillSort, DEFAULT_SORT_DIRECTION, SKILL_SORT_UNDEFINED, SKILL_SORTS, isValidSortBy, isValidSortParam, isValidSortDirection
} from '../constants/skillsSort';
import { Skill } from '../models/skill';
import { CategoriesGroup } from '../models/categoriesGroup';
import { API_EMPLOYEE_SKILLS_EXPORT } from '../config/api';
import { SKILL_CATEGORIES_OPTIONS } from '../helpers/graphql';
import { sanitizeLookup } from '../helpers/models';
import { useMixpanel, currentEmployeeDetails } from '../context/analytics';
import { employeeSkillsVar, employeeSkillsViewVar } from '../context/variables';
import { GlobalContext } from '../context';
import CategoriesMenu from '../elements/CategoriesMenu';
import SortSelector from '../elements/SortSelector';
import PaginationControls from '../v3/PaginationControls';
import SkillsGrid from '../v3/SkillsGrid';
// SCSS imports
import { content, container, tableTitle } from './EmployeeSkillsBrowser.module.scss';

const paperProps = { className: content };

type EmployeeSkillsExportParams = Omit<
  EmployeeSkillsQueryVariables, 'offset' | 'limit'
> & {
  token: string;
};

type EmployeeSkillsBrowserProps = {
  supervisor?: boolean;
  reducedUI?: boolean;
  uid?: string;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  // for Storybook only
  testPending?: boolean;
}

const EmployeeSkillsBrowserPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  reducedUI: PropTypes.bool,
  uid: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  // for Storybook only
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const EmployeeSkillsBrowser: FunctionComponent<EmployeeSkillsBrowserProps> = ({
  supervisor = false,
  reducedUI = false,
  uid,
  isOpen = false,
  onClose,
  testPending
}) => {
  const mixpanel = useMixpanel();
  const { cache } = useApolloClient();
  const settingsId = supervisor ? 'skills_browser' : 'my_skills_browser';

  const { token } = useContext(GlobalContext);

  // skill categories
  const { pending: categoriesPending, failed: categoriesFailed, results: categories } = useQueryCounted({
    data: undefined as unknown as SkillCategory,
    key: 'skillCategories',
    query: useQuery(SKILL_CATEGORIES_QUERY as typeof SkillCategoriesDocument, SKILL_CATEGORIES_OPTIONS)
  });
  const hasCategories = categoriesPending ? undefined : !categoriesFailed && size(categories) >= 1;

  // lazy load skills
  const { query: getSkills, pending: pendingSkills, failed, count, results: skills, variables: prevVars } = useQueryCounted({
    data: undefined as unknown as EmployeeSkill,
    key: 'employeeSkills',
    lazyQuery: useLazyQuery(EMPLOYEE_SKILLS_QUERY as typeof EmployeeSkillsDocument)
  });
  const pending = pendingSkills || testPending;

  const view = useReactiveVar(employeeSkillsViewVar);
  const settings = useReactiveVar(employeeSkillsVar);
  const { skill_category_id } = settings || {};
  const settingsSort = (isValidSortBy(settings?.sort_by as string) && settings?.sort_by) || SKILL_SORT_UNDEFINED;

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState(sanitizeLookup(skill_category_id, categories));
  const [inferredOnly, setInferredOnly] = useState(Boolean(!reducedUI && settings?.inferences_only));
  const [sortOrder, setSortOrder] = useState<SkillSort>(settingsSort);
  const [direction, setDirection] = useState<boolean>(isValidSortDirection(settings?.direction as string)
    ? settings?.direction === SortDirection.ascending
    : DEFAULT_SORT_DIRECTION[settingsSort]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const [mounted, setMounted] = useState(isOpen);

  useLayoutEffect(() => {
    if (isOpen) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkills' });
      setMounted(true);
      // reset search filter on open
      setSearch('');
    }
  }, [isOpen, cache]);

  useLayoutEffect(() => {
    setCategory(sanitizeLookup(skill_category_id, categories));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]); // ignore changes of `skill_category_id`

  const getVariables = useCallback(() => ({
    ...supervisor && uid ? { selected_employee_id: uid } : {},
    ...isEmptyString(search) ? {} : { search: trim(search) },
    ...hasCategories && category ? { skill_category_id: category } : {},
    ...inferredOnly ? { inferences_only: inferredOnly } : {},
    ...isValidSortParam(sortOrder) ? {
      sort_by: sortOrder as EmployeeSkillsSort,
      direction: direction ? SortDirection.ascending : SortDirection.descending
    } : {}
  } as EmployeeSkillsQueryVariables), [hasCategories, search, category, inferredOnly, sortOrder, direction, uid, supervisor]);

  const exportParams: EmployeeSkillsExportParams | undefined = useMemo(() => token ? {
    ...getVariables(),
    token: token as string
  } : undefined, [token, getVariables]);

  const categoryGroups = useMemo(() => hasCategories
    ? [{ header: 'skills.browse.categories', categories } as CategoriesGroup] : undefined,
    [hasCategories, categories]);

  useEffect(() => {
    if (isOpen && !isNil(pageSize) && getSkills) {
      const variables: EmployeeSkillsQueryVariables = {
        ...getVariables(),
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      employeeSkillsVar(variables);
      getSkills({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, getVariables, currentPage, pageSize, getSkills]); // ignoring `prevVars` changes

  useEffect(() => {
    if (isOpen) mixpanel.track('EmployeeSkillsBrowser', currentEmployeeDetails);
  }, [isOpen, mixpanel]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const handleCategory = useCallback((value: number) => setCategory(sanitizeLookup(value, categories)), [categories]);
  const handleInferredOnly = useCallback((value: boolean) => setInferredOnly(Boolean(value)), []);

  const handleSort = useCallback((sortValue: string, newDir?: boolean | null) => {
    if (isValidSortBy(sortValue)) {
      setSortOrder(sortValue as SkillSort);
      setDirection(isBoolean(newDir) ? newDir : DEFAULT_SORT_DIRECTION[sortValue]);
    }
  }, []);

  const pendingAll = categoriesPending || pending;
  const loading = pendingAll || !skills;
  const disabled = loading || failed;
  const disabledFilters = loading || isNil(pageSize);
  const exportDisabled = disabled || !skills || !count || size(skills) < 1;

  const pagination = (
    <PaginationControls
        settingsId={settingsId}
        loaded={Boolean(mounted && skills)}
        pending={mounted && pendingAll ? true : undefined}
        loading={pendingAll}
        total={count}
        currentPage={currentPage}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={disabled}
    />
  );

  const skillsGrid = mounted ? (
    <SkillsGrid
        source="my_skills"
        withReloading
        isEmployee={!supervisor}
        supervisor={supervisor}
        withLink={supervisor ? true : undefined}
        skills={skills as Skill[]}
        failed={failed}
        pending={pendingAll}
        narrowGrid={hasCategories}
        table={view === TABLE_VIEW}
        sortBy={view === TABLE_VIEW ? sortOrder : undefined}
        direction={view === TABLE_VIEW ? direction : undefined}
        changeSort={handleSort}
        sortDisabled={view !== TABLE_VIEW || loading}
        disabled={disabled}
        pagination={pagination}
    />
  ) : undefined;

  return mounted ? (
    <Dialog
        disableEnforceFocus
        disablePortal
        maxWidth={hasCategories ? 'xl' : 'lg'}
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={onClose}
        TransitionProps={transitionProps}
        PaperProps={paperProps}
    >
      <CloseIconButton onClick={onClose}/>
      <CardSection
          flex
          className={hasCategories === false && ((!(pending && !skills) && !failed) || view === TABLE_VIEW)
            ? tableTitle : undefined}
      >
        <BoxTypography flexGrow={1} pr={2} variant="h3">
          <FormattedMessage id="skills.browse.title"/>
        </BoxTypography>
        <Box pr={2} py={1.25} display="flex" justifyContent="flex-start">
          <FilterSearch
              type="skill"
              value={search}
              onChange={setSearch}
              disabled={disabledFilters}
          />
        </Box>
        <Box pr={3} py={1.25} display="flex" justifyContent="flex-start">
          <SortSelector
              variant="skills"
              value={sortOrder}
              onChange={handleSort}
              disabled={disabledFilters}
              values={SKILL_SORTS}
              defaultValue={SKILL_SORT_UNDEFINED}
          />
        </Box>
        {reducedUI ? undefined : (
          <Box pl={2} py={1.25} display="flex" justifyContent="flex-start">
            <OnOffSwitch
                label="edit_skills.inferences_only"
                value={Boolean(inferredOnly)}
                onChange={handleInferredOnly}
                disabled={disabledFilters ? true : undefined}
            />
          </Box>
        )}
        <Box pr={2}>
          <ViewSwitch
              tiles
              value={view}
              onChange={employeeSkillsViewVar}
              disabled={disabledFilters}
          />
        </Box>
        <Box pr={1.25} display="flex" justifyContent="flex-end">
          <ExportButton
              disabled={exportDisabled}
              params={exportParams as ExportParams}
              endpoint={API_EMPLOYEE_SKILLS_EXPORT}
          />
        </Box>
      </CardSection>
      {hasCategories ? (
        <Box display="flex" className={container}>
          <CategoriesMenu
              groups={categoryGroups}
              value={category}
              onSelect={handleCategory}
          />
          {skillsGrid}
        </Box>
      ) : skillsGrid}
    </Dialog>
  ) : pagination;
};

EmployeeSkillsBrowser.propTypes = EmployeeSkillsBrowserPropTypes;

export default memo(EmployeeSkillsBrowser);
