import { memo, useReducer, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import endsWith from 'lodash/endsWith';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
// local imports
import useCustomerSettings from '../config/customer';
import {
  PATH_MY_SKILLS, PATH_MY_SKILLS_LEARNING, PATH_MY_SKILLS_MENTORSHIP, PATH_MY_SKILLS_ACTIVITIES,
  PATH_MY_SKILLS_IN_DEMAND, PATH_MY_SKILLS_IN_DEMAND_LEARNING,
  PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP, PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES,
  PATH_SUPERVISOR_EMPLOYEE, PATH_SUPERVISOR_LEARNING, PATH_SUPERVISOR_MENTORSHIP, PATH_SUPERVISOR_ACTIVITIES,
  PATH_SUPERVISOR_IN_DEMAND, PATH_SUPERVISOR_IN_DEMAND_LEARNING,
  PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP, PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES
} from '../config/paths';
import { toggleReducer } from '../helpers/reducers';
import RecommendedSkillsList from './RecommendedSkillsList';
import InDemandSkillsList from './InDemandSkillsList';
import EmployeeSkillsBrowser from './EmployeeSkillsBrowser';

type EmployeeSkillsRecommendedProps = {
  supervisor?: boolean;
  reducedUI?: boolean;
  uid?: string;
  employeeName?: string;
  userOrgId?: number;
  onOpen?: () => void;
  onClose?: () => void;
  savedPathname?: string;
}

const EmployeeSkillsRecommendedPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  reducedUI: PropTypes.bool,
  uid: PropTypes.string,
  employeeName: PropTypes.string,
  userOrgId: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  savedPathname: PropTypes.string
};

// eslint-disable-next-line complexity
const EmployeeSkillsRecommended: FunctionComponent<EmployeeSkillsRecommendedProps> = ({
  supervisor = false,
  reducedUI = false,
  uid,
  employeeName = '',
  userOrgId,
  onOpen,
  onClose,
  savedPathname
}) => {
  const { HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS, HAS_MENTORING } = useCustomerSettings();

  const [popupOpen, togglePopupOpen] = useReducer(toggleReducer, false);

  const browseButton = (
    <Button
        variant="outlined"
        color="primary"
        onClick={onOpen || togglePopupOpen}
    >
      <FormattedMessage id="skills.browse.button"/>
    </Button>
  );

  const browser = (
    <EmployeeSkillsBrowser
        reducedUI={reducedUI}
        uid={uid}
        supervisor={supervisor}
        isOpen={onOpen ? Boolean(savedPathname) : popupOpen}
        onClose={onClose || togglePopupOpen}
    />
  );

  const [values, helpValues] = useMemo(() => [
    supervisor ? {
      name: employeeName,
      endsWithS: endsWith(employeeName, 's')
    } : undefined,
    { supervisor }
  ], [supervisor, employeeName]);

  const tab11 = supervisor
    ? injectParams(PATH_SUPERVISOR_EMPLOYEE, { employee_id: uid })
    : PATH_MY_SKILLS;
  const tab12 = (HAS_COURSES_READONLY && (
    supervisor
      ? injectParams(PATH_SUPERVISOR_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_LEARNING
  )) || '';
  const tab13 = (HAS_MENTORING && (
    supervisor
      ? injectParams(PATH_SUPERVISOR_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_MENTORSHIP
  )) || '';
  const tab14 = supervisor
    ? injectParams(PATH_SUPERVISOR_ACTIVITIES, { employee_id: uid })
    : PATH_MY_SKILLS_ACTIVITIES;
  const tab21 = supervisor
    ? injectParams(PATH_SUPERVISOR_IN_DEMAND, { employee_id: uid })
    : PATH_MY_SKILLS_IN_DEMAND;
  const tab22 = (HAS_COURSES_READONLY && (
    supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_LEARNING
  )) || '';
  const tab23 = (HAS_MENTORING && (
    supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP
  )) || '';
  const tab24 = supervisor
    ? injectParams(PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES, { employee_id: uid })
    : PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES;

  const recommendedAction = useMemo(() => (
    <InfoButton
        help={supervisor ? 'help.skills_recommendations_supv' : 'help.skills_recommendations'}
        placement="top"
        enablePortal
    />
  ), [supervisor]);

  const items = useMemo(() => [
    {
      title: supervisor ? 'skills.employees_recommendations.title' : 'skills.recommendations.title',
      values,
      link: {
        [tab11]: tab11,
        [tab21]: tab11,
        ...HAS_COURSES_READONLY ? {
          [tab12]: tab12,
          [tab22]: tab12
        } : {},
        ...HAS_MENTORING ? {
          [tab13]: tab13,
          [tab23]: tab13
        } : {},
        [tab14]: tab14,
        [tab24]: tab14
      },
      action: recommendedAction
    },
    {
      title: supervisor ? 'skills.employees_in_demand.title' : 'skills.in_demand.title',
      values,
      link: {
        [tab11]: tab21,
        [tab21]: tab21,
        ...HAS_COURSES_READONLY ? {
          [tab12]: tab22,
          [tab22]: tab22
        } : {},
        ...HAS_MENTORING ? {
          [tab13]: tab23,
          [tab23]: tab23
        } : {},
        [tab14]: tab24,
        [tab24]: tab24
      },
      action: (
        <InfoButton
            help="help.in_demand_skills"
            values={helpValues}
            placement="top"
            enablePortal
        />
      )
    }
  ], [
    supervisor, values, helpValues, tab11, tab12, tab13, tab14, tab21, tab22, tab23, tab24, recommendedAction,
    HAS_COURSES_READONLY, HAS_MENTORING
  ]);

  return reducedUI ? (
    <>
      <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={1.5}
          pt={supervisor ? 2 : undefined}
      >
        {browseButton}
      </Box>
      {browser}
    </>
  ) : (
    <>
      <ContentCard>
        {HAS_INDEMAND_SKILLS ? (
          <CardTabbar
              savedPathname={savedPathname}
              withoutScroll
              items={items}
              wideAction // 12rem
              action={browseButton}
          >
            <RecommendedSkillsList uid={uid} supervisor={supervisor} employeeName={employeeName}/>
            <InDemandSkillsList uid={uid} supervisor={supervisor} userOrgId={userOrgId}/>
          </CardTabbar>
        ) : (
          <>
            <CardTitle
                title={supervisor ? 'skills.employees_recommendations.title' : 'skills.recommendations.title'}
                subheader={recommendedAction}
                values={values}
                action={browseButton}
                withDivider
            />
            <RecommendedSkillsList uid={uid} supervisor={supervisor} employeeName={employeeName}/>
          </>
        )}
      </ContentCard>
      {browser}
    </>
  );
};

EmployeeSkillsRecommended.propTypes = EmployeeSkillsRecommendedPropTypes;

export default memo(EmployeeSkillsRecommended);
