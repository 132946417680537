import { type Dispatch } from 'react';
import isSafeInteger from 'lodash/isSafeInteger';
import isArray from 'lodash/isArray';
import toString from 'lodash/toString';
import { type AxiosResponse } from 'axios';
// EmPath UI Components
import { getStringifiedIds } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { APIActionResponse } from '../models/apiResponse';
import { axiosInstance as axios, API_HR_JOBS_ADVANCED, API_HR_JOBS_VOTE, getApiAdminHrRoleSave } from '../config/api';
import { getRequestHeaders, optimizeParams } from '../helpers/context';
import { Token, Online } from './global';
import { SupervisorActions, HRJobVotingParams, HRJobSaveParams } from './supervisor';

export const voteHrJob = (
  token: Token,
  operation: 'VOT' | 'REFIN',
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  hrJobVotePending: boolean | null
) => async ({
  job_id,
  skill_ids,
  onSuccess
}: HRJobVotingParams = {} as HRJobVotingParams) => {
  if (
    !token || hrJobVotePending || !job_id || !isSafeInteger(job_id) || job_id < 1 || !skill_ids || !isArray(skill_ids)
  ) return;
  const params = optimizeParams({
    job_id,
    skill_ids // TODO: improve `skill_ids` verification
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: `HRJOB_${operation}ING` as const,
      params
    });
    const { status, data } = await axios.request<
      Omit<HRJobVotingParams, 'skill_ids' | 'onSuccess'> & { skill_ids: string; },
      AxiosResponse<APIActionResponse>
    >({
      method: 'POST',
      url: operation === 'VOT' ? API_HR_JOBS_VOTE : API_HR_JOBS_ADVANCED,
      data: {
        job_id,
        skill_ids: getStringifiedIds(skill_ids)
      },
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    if (onSuccess) onSuccess();
    dispatch({
      type: `HRJOB_${operation}ED` as const,
      payload: true,
      params
    });
  } catch (error) {
    dispatch({
      type: `HRJOB_${operation}ED` as const,
      payload: false,
      params
    });
  }
};

export const saveHrJob = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  hrJobSavePending: boolean | null
) => async ({
  job_id,
  skills,
  onSuccess
}: HRJobSaveParams = {} as HRJobSaveParams) => {
  if (
    !token || hrJobSavePending || !job_id || !isSafeInteger(job_id) || job_id < 1 || !skills || !isArray(skills)
  ) return;
  const params = optimizeParams({
    job_id,
    skills // TODO: improve `skills` verification
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: 'HRJOB_SAVING' as const,
      params
    });
    const { status, data } = await axios.request<Omit<HRJobSaveParams, 'job_id'>, AxiosResponse<APIActionResponse>>({
      method: 'POST',
      url: getApiAdminHrRoleSave(toString(job_id)),
      data: { skills },
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    if (onSuccess) onSuccess();
    dispatch({
      type: 'HRJOB_SAVED' as const,
      payload: true,
      params
    });
  } catch (error) {
    dispatch({
      type: 'HRJOB_SAVED' as const,
      payload: false,
      params
    });
  }
};
