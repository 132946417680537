import {
  forwardRef, memo, useCallback, useContext, useMemo, useState, useEffect, type ReactNode, type ChangeEvent
} from 'react';
import PropTypes from 'prop-types';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import { type Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete, {
  type AutocompleteRenderInputParams, type AutocompleteInputChangeReason
} from '@mui/material/Autocomplete';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// EmPath UI Components
import { API_CALL_LOOKUP_WAIT } from '@empathco/ui-components/src/config/params';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { LookupAnything } from '../models/lookupItem';
import { PATH_JOB, PATH_SKILL } from '../config/paths';
import { LookupContext } from '../context';
// SCSS imports
import { root, placeholder } from '@empathco/ui-components/src//styles/modules/Lookup.module.scss';

const useStyles = makeStyles()((theme: Theme) => ({
  popupIndicatorOpen: {
    transform: 'none'
  },
  popper: {
    minWidth: '28rem',
    maxWidth: '32rem'
  },
  paper: {
    paddingRight: spacing(0.5),
    paddingBottom: spacing(0.25)
  },
  groupLabel: {
    color: theme.palette.misc.searchGroup,
    textTransform: 'uppercase',
    paddingLeft: spacing(3)
  },
  listbox: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    maxHeight: '62vh',

    // custom scroll bar
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.card}`,
    '&::-webkit-scrollbar': {
      width: '1.1rem'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.card,
      borderRadius: '0.55rem'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.misc.selectedBorder,
      borderRadius: '0.55rem'
    },
    '&::-webkit-scrollbar-button': {
      background: `no-repeat ${theme.palette.background.paper}`,
      backgroundSize: '1.1rem',
      backgroundPosition: 'center center'
    },
    '&::-webkit-scrollbar-button:vertical:start:decrement': {
      height: '1.25rem',
      // eslint-disable-next-line max-len
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><polygon fill='${encodeURIComponent(theme.palette.misc.selectedBorder)}' points='0,75 100,75 50,25'/></svg>")`
    },
    '&::-webkit-scrollbar-button:vertical:end:increment': {
      height: '1.25rem',
      // eslint-disable-next-line max-len
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><polygon fill='${encodeURIComponent(theme.palette.misc.selectedBorder)}' points='0,25 100,25 50,75'/></svg>")`
    },
    '&::-webkit-scrollbar-button:vertical:end:decrement': {
      width: 0,
      height: 0
    },
    '&::-webkit-scrollbar-button:vertical:start:increment': {
      width: 0,
      height: 0
    }
  }
}));

type SkillsAndJobsSearchProps = {
  // for Storybook only
  testOpen?: boolean;
  testValue?: string;
}

const SkillsAndJobsSearchPropTypes = {
  // for Storybook only
  testOpen: PropTypes.bool,
  testValue: PropTypes.string
};

// eslint-disable-next-line complexity, max-statements
const SkillsAndJobsSearch = forwardRef<HTMLDivElement, SkillsAndJobsSearchProps>(({
  testOpen = false,
  testValue = ''
}, ref) => {
  const lookupType = 'anything' as const;

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const {
    anything: { data: anything, pending, failed, params: prevParams },
    requireAnything, clearAnything
  } = useContext(LookupContext);
  const { search: lastSearch } = prevParams || {};

  const [inputValue, setInputValue] = useState<string>(testValue || '');
  const [open, setOpen] = useState<boolean>(testOpen);

  const loading = open && Boolean(pending);

  const fetchOptions = useMemo(
    () => requireAnything ? throttle(requireAnything, API_CALL_LOOKUP_WAIT, { leading: true }) : null,
    [requireAnything]
  );
  const clearOptions = useMemo(
    () => clearAnything ? throttle(clearAnything, API_CALL_LOOKUP_WAIT, { leading: true }) : null,
    [clearAnything]
  );

  const onInputChange = useCallback((
    _event: ChangeEvent<{}>,
    newInputValue: string,
    _reason: AutocompleteInputChangeReason
  ) => {
    setInputValue(testValue || newInputValue);
  }, [testValue]);

  const filtered = useMemo(() => anything ? [
    ...filter(anything, ({ item_code, item_type }) => item_type === 'job' && !isEmptyString(item_code)),
    ...filter(anything, ({ item_code, item_type }) => item_type === 'skill' && !isEmptyString(item_code))
    ] : anything || [],
    [anything]
  );

  useEffect(() => {
    // do nothing if already fetching options
    if (pending) return;
    // do nothing if search string is unchanged
    const search = toLower(trim(inputValue));
    if (isEqual(search, lastSearch)) return;
    // try to fetch options
    if (fetchOptions) {
      fetchOptions.cancel();
      if (search || !clearOptions) {
        fetchOptions({ search });
      } else if (size(filtered) > 0) {
        clearOptions();
      }
    }
  }, [filtered, pending, clearOptions, lastSearch, fetchOptions, inputValue]);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const isOptionEqualToValue = useCallback(
    (option: LookupAnything, val: LookupAnything): boolean => option.id === val.id, []
  );
  const getOptionLabel = useCallback((option: LookupAnything): string => option.title || '', []);

  const handleChange = useCallback((
    _event: ChangeEvent<{}>,
    result: LookupAnything | null
  ) => {
    if (!result || !isPlainObject(result)) return;
    const { item_code, item_type } = result;
    if (!isString(item_type) || isEmptyString(item_code)) return;
    if (item_type === 'job') navigate(injectParams(PATH_JOB, { role_id: item_code }));
    else navigate(injectParams(PATH_SKILL, { skill_id: item_code }));
  }, [navigate]);

  const filterOptions = useCallback((opts: LookupAnything[]) => opts, []);

  const [clearText, openText, closeText, label, loadingText, noOptionsText, jobsGroup, skillsGroup] = useMemo(() => [
    formatMessage({ id: 'lookup.text.clear' }),
    formatMessage({ id: 'lookup.text.open' }),
    formatMessage({ id: 'lookup.text.close' }),
    formatMessage({ id: `lookup.${lookupType}.select` }),
    formatMessage({ id: `lookup.${lookupType}.loading` }),
    formatMessage({ id: `lookup.${lookupType}.nothing` }),
    formatMessage({ id: `lookup.${lookupType}.jobs` }),
    formatMessage({ id: `lookup.${lookupType}.skills` })
  ], [formatMessage]);

  const groupBy = useCallback(
    ({ item_type }: LookupAnything): string => item_type === 'job' ? jobsGroup : skillsGroup,
    [jobsGroup, skillsGroup]
  );

  const renderInput = useCallback(({ InputProps, ...params }: AutocompleteRenderInputParams): ReactNode => (
    <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        label={label}
        variant="outlined"
        size="small"
        InputProps={{
          ...InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress size={20} className={placeholder}/> : null}
              {InputProps.endAdornment}
            </>
          )
        }}
    />
  ), [label, loading]);

  const renderOption = useCallback((props: object, option: LookupAnything): ReactNode => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <li {...props} key={option.id}>
      {getOptionLabel(option)}
    </li>
  ), [getOptionLabel]);

  return requireAnything ? (
    <>
      <FormControl
          ref={ref}
          variant="outlined"
          size="small"
          className={root}
      >
        <Autocomplete
            id={`${lookupType}-lookup-select`}
            classes={classes}
            autoComplete
            clearText={clearText}
            openText={openText}
            closeText={closeText}
            loadingText={loadingText}
            noOptionsText={inputValue !== lastSearch && !loading ? loadingText : noOptionsText}
            open={open && size(trim(inputValue)) > 0 ? true : undefined}
            onOpen={onOpen}
            loading={loading}
            onClose={onClose}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            options={filtered}
            renderInput={renderInput}
            renderOption={renderOption}
            filterOptions={filterOptions}
            groupBy={groupBy}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={onInputChange}
            popupIcon={<SearchIcon/>}
        />
      </FormControl>
      <ActionFailedAlert
          message={`lookup.${lookupType}.error`}
          open={failed}
      />
    </>
  ) : null;
});

SkillsAndJobsSearch.displayName = 'SkillsAndJobsSearch';

SkillsAndJobsSearch.propTypes = SkillsAndJobsSearchPropTypes;

export default memo(SkillsAndJobsSearch);
