export const HASH_SKIP_PROFILE_BUILDER = 'skip_profile_builder' as const; // URL hash code to skip Profile Builder
export const HASH_EXPORT_SUMMARY = 'export_summary' as const; // URL hash code to start Export My Skills Summary
export const HASH_OPEN_REQS = 'openreqs' as const;
export const HASH_DEFAULT = 'default' as const;
export const HASH_INFERRED = 'inferred' as const;
export const VALID_HASHES = [
  HASH_SKIP_PROFILE_BUILDER,
  HASH_EXPORT_SUMMARY,
  HASH_OPEN_REQS,
  HASH_DEFAULT,
  HASH_INFERRED
] as const;

export const MAX_CLOSE_MATCH_JOBS = 24 as const;

export const MAX_INFERRED_SKILLS = 24 as const; // for Profile Builder; originally was: 12

export const MAX_TEAM_EMPLOYEES = 100 as const;

export const TALENT_FOLDED_EMPLOYEES = 8 as const;

// Max number of unread notifications to be displayed as number, otherwise `header.max_unread` label is used:
export const MAX_UNREAD_NOTIFICATIONS = 99 as const;
export const NOTIFICATIONS_POLLING_INTERVAL = 30000 as const; // 30 seconds

// Compute Updates & job status polling interval and timeout (i.e. forcing error state)
export const POLLING_INTERVAL = 5000 as const; // 5 seconds
export const POLLING_ATTEMPTS = 25 as const; // ~2 minutes

export const MAX_UPLOAD_SIZE = 1048576 as const; // 1 MB

// Success Stories Autoplay Speed
export const STORIES_AUTOPLAY_SPEED = 8000 as const; // 8 seconds

// Skill Development Plan
export const SKILL_DEV_PLAN_QUARTERS = 8 as const; // 2 years

// Development Plan
export const DEV_PLAN_DEFAULT_SKILLS = 4 as const;

// Job match rate for search
export const MIN_MATCH_RATE = 50 as const; // percents
export const MAX_MATCH_RATE = 100 as const; // percents

// When user types in lookup-select input, only call API at most once per every specified number of milliseconds
export const MAX_LOOKUP_OPTIONS = 50 as const;
export const MAX_COUNTRIES_OPTIONS = 150 as const;

// Default max items to load thru Apollo GraphQL REST link
export const MAX_ITEMS = 100 as const;

// Dashboard
export const BOARD_MAX_SKILLS = 5 as const;
export const BOARD_MAX_JOBS = 5 as const;

// Employees with this skill
export const MIN_EMPLOYEES_WITH_SKILL = 25 as const; // minimum total to display a chart

// Manager Dashboard
export const MAX_MANAGER_DASHBOARD_ITEMS = 6 as const;
export const MAX_DASHBOARD_SKILLS_GAP_JOBS = 20 as const;
export const MAX_MANAGER_DASHBOARD_EXPORT = 500 as const; // disable Export if `employees_count` > 500
export const MAX_DURATION = 36 as const; // months

// Text Trucation
export const MAX_COURSE_TITLE_LENGTH = 58 as const;

// Export
export const MAX_ITEMS_EXPORT = 1000 as const; // maximum number of Skills/Jobs to export
export const MAX_JOB_MATCH_EXPORT = 1000 as const; // maximum number of Employees to export

// Max suggested by manager skills
export const MAX_SUGGESTED_SKILLS = 3 as const;

// Job Related Skills limits
export const MIN_JOB_SKILLS = 4 as const;
export const MAX_JOB_SKILLS = 20 as const;

// In-Demand Skills Editor
export const MAX_INDEMAND_SKILLS = 20 as const;
export const MAX_SUGGESTED_INDEMAND_SKILLS = 8 as const;

export const MAX_DELEGATES = 3 as const;

// ECharts
export const GlobalEChartsStyles = {
  textStyle: {
    fontFamily: 'Roboto'
  }
} as const;
