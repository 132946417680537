import {
  memo, useState, useCallback, useLayoutEffect, useContext, useMemo, useEffect, type FunctionComponent, type MouseEventHandler
} from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import includes from 'lodash/includes';
import transform from 'lodash/transform';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
// Material Icon imports
import PushPinRounded from '@mui/icons-material/PushPinRounded';
// EmPath UI Components
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import ScopeSelector from '@empathco/ui-components/src/elements/ScopeSelector';
// local imports
import { SkillType } from '../graphql/types';
import { SKILL_TYPES, SKILL_TYPES_JOB_ONLY, SKILL_TYPES_WITHOUT_INDEMAND } from '../graphql/customTypes';
import { hasDelegationOnly, getDefaultLeaderId, getRootLeaderId, getSelectedLeaderId } from '../models/user';
import { CategoriesGroup, CategoryItem } from '../models/categoriesGroup';
import { CONST_YEARS, toValidConst } from '../constants/constValues';
import { DA_GROUP_SKILLS, DA_GROUP_JOBS, DA_GROUP_EMPLOYEES, DA_GROUP_BY_CATEGORY } from '../constants/dashboardAnalytics';
import useCustomerSettings from '../config/customer';
import { sanitizeLookupString } from '../helpers/models';
import { managerDashboardParams } from '../context/supervisor';
import { DataContext, GlobalContext } from '../context';
import CategoriesMenu from '../elements/CategoriesMenu';
import ConstSelector from '../elements/ConstSelector';
import DashboardEmployeesCount from '../elements/DashboardEmployeesCount';
import ChainOfCommandSelector from '../v3/ChainOfCommandSelector';
import DashboardFilters, { DashboardFilterValues } from '../v3/DashboardFilters';
import DashboardAnalyticsSkills from './DashboardAnalyticsSkills';
import DashboardAnalyticsJobs from './DashboardAnalyticsJobs';
import DashboardAnalyticsEmployees from './DashboardAnalyticsEmployees';
// SCSS imports
import { content, container } from './DashboardAnalyticsBrowser.module.scss';

const paperProps = { className: content };

type DashboardAnalyticsBrowserProps = {
  readonly supervisor?: boolean;
  chartCategories: CategoryItem[];
  openChart?: number;
  pinning?: boolean;
  pinned?: number[];
  onPin: (value: number) => void;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

const DashboardAnalyticsBrowserPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  chartCategories: PropTypes.array.isRequired,
  openChart: PropTypes.number,
  pinning: PropTypes.bool,
  pinned: PropTypes.array,
  onPin: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

// eslint-disable-next-line complexity, max-statements
const DashboardAnalyticsBrowser: FunctionComponent<DashboardAnalyticsBrowserProps> = ({
  supervisor = false,
  chartCategories,
  openChart,
  pinning = false,
  pinned,
  onPin,
  onClose
}) => {
  const { HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const { fontsLoaded, user: { data: user } } = useContext(GlobalContext);
  const {
    coc: { data: cocData, pending: cocPending }, requireCoC,
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);

  const defaultSkillType = SKILL_TYPES[HAS_INDEMAND_SKILLS ? 0 : 1];
  const managers = (supervisor && !cocPending && cocData) || null;

  const [chartId, setChartId] = useState<number>();
  const [mounted, setMounted] = useState(Boolean(openChart));

  const [totalEmployees, setTotalEmployees] = useState<number | null>();

  const [filters, setFilters] = useState<DashboardFilterValues>();
  const [years, setYears] = useState<number>(CONST_YEARS[1]);
  const [skillType, setSkillType] = useState(defaultSkillType);
  const [manager, setManager] = useState('0');

  const jobId = filters?.job_id;
  useLayoutEffect(() => {
    if (!jobId) setSkillType((prevSkillType) => prevSkillType === SkillType.job_skills ? defaultSkillType : prevSkillType);
  }, [jobId, defaultSkillType]);
  const effectiveSkillType = jobId || skillType !== SkillType.job_skills ? skillType : defaultSkillType;

  const managerDashboardParamsFunc = useMemo(managerDashboardParams, []);

  const [rootUid, uid, leaderUid, delegate] = useMemo(() => [
    getRootLeaderId(user),
    getDefaultLeaderId(user),
    getSelectedLeaderId(user),
    hasDelegationOnly(user)
  ], [user]);

  const effectiveFilters = useMemo(() => filters ? {
    ...supervisor ? { manager_id: manager } : pick(
      managerDashboardParamsFunc(filters),
      ['country_id', 'state_id', 'manager_id', 'org_id', 'job_id', 'job_levels']
    ),
    ...leaderUid ? { selected_leader_id: leaderUid } : {},
    // not supported by `managerDashboardParams`:
    ...!supervisor && filters.job_category ? { job_category: filters.job_category } : {},
    ...HAS_INDEMAND_SKILLS && !supervisor && skillType === SkillType.indemand && filters.skill_ids
      ? { skill_ids: filters.skill_ids } : {}
  } : undefined, [filters, skillType, leaderUid, manager, managerDashboardParamsFunc, supervisor, HAS_INDEMAND_SKILLS]);

  const handleFilters = useCallback((newFilters: DashboardFilterValues) => setFilters((prevFilters) => {
    if (isEqual(prevFilters, newFilters)) return prevFilters;
    return newFilters;
  }), []);

  const handleYearsChange = useCallback((event: SelectChangeEvent<number>) =>
    setYears(toValidConst(event?.target?.value, CONST_YEARS[0], CONST_YEARS)), []);

  const handleManager = useCallback((value: string) => {
    setManager(sanitizeLookupString(value, managers));
  }, [managers]);

  const handleTotalEmployees = useCallback((total?: number | null) => {
    // eslint-disable-next-line no-nested-ternary
    setTotalEmployees((prev) => isUndefined(total) // total is failed to load
      ? isNil(prev)
        ? undefined // prev total is unknown
        : prev
      : isNull(total) && !isUndefined(prev)
        ? prev // loading new total (display prev total for now)
        : total
    );
  }, []);

  useEffect(() => {
    if (supervisor) requireCoC?.();
  }, [supervisor, requireCoC]);

  useLayoutEffect(() => {
    if (openChart) {
      setMounted(true);
      setChartId(openChart);
    }
  }, [openChart]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const chartGroups = useMemo(() => {
    const groups = transform(chartCategories, (result, chart) => {
      result[includes(pinned, chart.id) ? 0 : 1].categories.push(chart);
    }, [
      { header: 'hr.dashboard.pinned', categories: [], icon: (
        <PushPinRounded
            color="secondary"
            fontSize="inherit"
        />
      ) },
      { header: 'hr.dashboard.all', categories: [] }
    ] as CategoriesGroup[]);
    if (groups[0].categories.length === 0) groups.shift();
    if (groups[groups.length - 1].categories.length === 0) groups.pop();
    return groups;
  }, [pinned, chartCategories]);

  const filtersReady = Boolean(effectiveFilters);
  const exportDisabled = !filtersReady;
  const pending = !filtersReady || !fontsLoaded;
  const disabled = exportDisabled || pendingSettingsUpdate ? true : undefined;

  const groupId = chartId ? DA_GROUP_BY_CATEGORY[chartId] : 0;

  return mounted ? (
    <Dialog
        disableEnforceFocus
        disablePortal
        maxWidth="xl"
        fullWidth
        scroll="body"
        open={Boolean(openChart)}
        onClose={onClose}
        TransitionProps={transitionProps}
        PaperProps={paperProps}
    >
      <CloseIconButton onClick={onClose}/>
      <CardSection>
        <DashboardFilters
            // TODO: disable filters which are not applicable to the selected chart!
            settingsId="hr_dashboard"
            withScope={!supervisor}
            withLevels={!supervisor}
            withOrg={HAS_INDEMAND_SKILLS && !supervisor ? skillType === SkillType.indemand || 'disabled' : undefined}
            withOrgSkills={HAS_INDEMAND_SKILLS && !supervisor ? skillType === SkillType.indemand || 'collapsed' : undefined}
            withJobVariant={supervisor ? undefined : 'filter'}
            orgFilterPlacement="beforeReset"
            requireOrg={!supervisor}
            userOrgId={user?.org?.id}
            withoutHierarchy={supervisor}
            withoutLocation={supervisor}
            withReset={!supervisor}
            extraFilters={(
              <>
                {supervisor ? (
                  <Box pb={1} pr={1.25}>
                    <ChainOfCommandSelector
                        allowEmpty
                        managers={managers}
                        value={manager}
                        onChange={handleManager}
                        disabled={disabled}
                    />
                  </Box>
                ) : undefined}
                <Box pb={1}>
                  <ScopeSelector
                      simple
                      scope={HAS_INDEMAND_SKILLS ? SKILL_TYPES : SKILL_TYPES_WITHOUT_INDEMAND}
                      unavailable={jobId ? undefined : SKILL_TYPES_JOB_ONLY}
                      value={skillType}
                      onChange={setSkillType as (item: string) => void}
                      disabled={disabled}
                  />
                </Box>
              </>
            )}
            action={(
              <ConstSelector
                  variant="years"
                  value={years}
                  onChange={handleYearsChange}
                  disabled={disabled}
              />
            )}
            onChange={handleFilters}
            uid={uid}
            rootUid={rootUid}
            // disableTopLevel={!rootUid}
            delegate={delegate}
            disabled={disabled}
        />
        <DashboardEmployeesCount totalEmployees={totalEmployees}/>
      </CardSection>
      <Box display="flex" className={container}>
        <CategoriesMenu
            required
            withoutScroll
            groups={chartGroups}
            value={chartId}
            onSelect={setChartId}
        />
        {chartId ? (
          <Box flex="1 1 0" display="flex" flexDirection="column" minHeight="40rem">
            {(groupId === DA_GROUP_SKILLS && (
              <DashboardAnalyticsSkills
                  chartId={chartId}
                  skillType={effectiveSkillType}
                  filters={effectiveFilters}
                  years={years}
                  pending={pending}
                  disabled={disabled}
                  onEmployeeCountChange={handleTotalEmployees}
                  pinned={pinned}
                  onPin={onPin}
                  pinning={pinning}
              />
            )) || (groupId === DA_GROUP_JOBS && (
              <DashboardAnalyticsJobs
                  chartId={chartId}
                  skillType={effectiveSkillType}
                  filters={effectiveFilters}
                  years={years}
                  pending={pending}
                  disabled={disabled}
                  onEmployeeCountChange={handleTotalEmployees}
                  pinned={pinned}
                  onPin={onPin}
                  pinning={pinning}
              />
            )) || (groupId === DA_GROUP_EMPLOYEES && (
              <DashboardAnalyticsEmployees
                  chartId={chartId}
                  skillType={effectiveSkillType}
                  filters={effectiveFilters}
                  years={years}
                  pending={pending}
                  disabled={disabled}
                  onEmployeeCountChange={handleTotalEmployees}
                  pinned={pinned}
                  onPin={onPin}
                  pinning={pinning}
              />
            )) || undefined}
          </Box>
        ) : undefined}
      </Box>
    </Dialog>
  ) : null;
};

DashboardAnalyticsBrowser.propTypes = DashboardAnalyticsBrowserPropTypes;

export default memo(DashboardAnalyticsBrowser);
