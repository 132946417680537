import { memo, useLayoutEffect, useState, type FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
// local imports
import useNonReducedUI from '../constants/managementLevel';
import { HASH_DEFAULT, HASH_OPEN_REQS } from '../config/params';
import { RouterLinkState } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import JobsIndex from '../components/JobsIndex';
import Screen from '../v3/Screen';
import DataStatusPoller from '../v3/DataStatusPoller';

const EmployeeRolesScreen: FunctionComponent = () => {
  const { showNonReducedUI } = useNonReducedUI();
  const { state, hash } = useLocation();
  const { fromDashboard } = (state as RouterLinkState) || {};

  const { content, user } = useVerifyUser({ employee: true });

  const [showOpenReqs, setShowOpenReqs] = useState(false);
  const [resetFilters, setResetFilters] = useState(Boolean(fromDashboard));

  useLayoutEffect(() => {
    if (hash === `#${HASH_OPEN_REQS}`) setShowOpenReqs(true);
    else if (hash === `#${HASH_DEFAULT}`) setResetFilters(true);
  }, [hash]);

  return content || (
    <Screen withSettings>
      <JobsIndex
          reducedUI={!showNonReducedUI(user)}
          showOpenReqs={showOpenReqs}
          resetFilters={resetFilters}
      />
      <DataStatusPoller/>
    </Screen>
  );
};

export default memo(EmployeeRolesScreen);
