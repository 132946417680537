import { forwardRef, memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import take from 'lodash/take';
import transform from 'lodash/transform';
import toSafeInteger from 'lodash/toSafeInteger';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
// Material Icon imports
import KeyboardArrowUpRounded from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
// EmPath UI Components
import { percentageOptions } from '@empathco/ui-components/src/common/intl';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { type EChartsMouseEvent, getSvgShadingImage } from '@empathco/ui-components/src/helpers/echarts';
import { getTruncatedTitle } from '@empathco/ui-components/src/helpers/strings';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
// local imports
import { Job } from '../models/job';
import { GlobalEChartsStyles, MAX_DASHBOARD_SKILLS_GAP_JOBS } from '../config/params';
import { toggleReducer } from '../helpers/reducers';
import Chart from '../elements/Chart';
// SCSS imports
import { chart, chartMax, button } from './TopChartBars.module.scss';

type TopChartBarsProps = {
  data: Job[];
  totalEmployees?: number | null;
  path?: string;
  pending?: boolean | null;
  testUnfolded?: boolean;
}

const TopChartBarsPropTypes = {
  // attributes
  data: PropTypes.array.isRequired,
  totalEmployees: PropTypes.number,
  path: PropTypes.string,
  pending: PropTypes.bool,
  testUnfolded: PropTypes.bool
};

// eslint-disable-next-line max-lines-per-function
const TopChartBars = forwardRef<EChartsReactCore, TopChartBarsProps>(({
  data,
  totalEmployees,
  path,
  pending = false,
  testUnfolded = false
}, ref) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const skillNameWidth = spacing(21);

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const [activeId, setActiveId] = useState<number>();
  const [folded, toggleFolded] = useReducer(toggleReducer, !testUnfolded);

  const [inferredLabel, confirmedLabel, noEmployees] = useMemo(() => [
    formatMessage({ id: 'hr.skills_gap.inferred' }),
    formatMessage({ id: 'hr.skills_gap.confirmed' }),
    formatMessage({ id: 'employees.no_employees' })
  ], [formatMessage]);

  const [xLabel, yLabel] = useMemo(() => [
    formatMessage({ id: 'hr.skills_gap.x_label' }),
    formatMessage({ id: 'hr.skills_gap.y_label' })
  ], [formatMessage]);

  const maxCount = folded ? MAX_DASHBOARD_SKILLS_GAP_JOBS / 2 : MAX_DASHBOARD_SKILLS_GAP_JOBS;

  const categories = useMemo(() => map(take(data, maxCount), ({ id, code, title }) => ({
    value: JSON.stringify({
      id,
      code,
      label: getTruncatedTitle(title)
    }),
    ...activeId && id === activeId ? {
      textStyle: {
        backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)
      }
    } : {}
  })), [activeId, data, maxCount, theme]);

  const [inferred, confirmed] = useMemo(() => transform(data, ([inf, act], { match_rates }, index) => {
    if (index < maxCount) {
      inf.push(toSafeInteger(match_rates?.by_inferred_skills));
      act.push(toSafeInteger(match_rates?.by_confirmed_skills));
    }
  }, [[], []] as number[][]), [data, maxCount]);

  const onEvents = useMemo(() => path ? {
    click: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') try {
        const { code } = JSON.parse(value);
        if (code) navigate(injectParams(path, { role_id: code }));
      } catch (_err) {
        // nothing to do
      }
    },
    mouseover: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') try {
        setActiveId(JSON.parse(value).id);
      } catch (_err) {
        setActiveId(undefined);
      }
    },
    mouseout: ({ componentType }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') setActiveId(undefined);
    }
  } : undefined, [navigate, path]);

  const xAxisLabelFormatter = useCallback(
    (value?: number | null) => formatNumber((value || 0) / 100, percentageOptions),
    [formatNumber]
  );

  const yAxisLabelFormatter = useCallback((value: string) => {
    try {
      return JSON.parse(value).label;
    } catch (_err) {
      return value;
    }
  }, []);

  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    const bar = {
      type: 'bar',
      barWidth: '27%'
    };

    const style = {
      borderColor: theme.palette.primary.main,
      borderWidth: theme.shape.thinBorderWidth,
      borderRadius: theme.shape.smallBorderRadius
    };

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      silent: Boolean(pending),
      ...totalEmployees ? {} : {
        title: {
          text: noEmployees,
          left: '50%',
          top: '42%',
          textStyle: {
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight
          }
        }
      },
      grid: {
        left: skillNameWidth + spacing(6),
        top: spacing(6),
        right: spacing(8),
        bottom: spacing(9.5),
        containLabel: true
      },
      legend: {
        type: 'scroll',
        bottom: 0,
        padding: spacing(2.5),
        icon: 'roundRect',
        itemWidth: spacing(1.75),
        itemHeight: spacing(1.75),
        itemGap: spacing(4.5),
        itemStyle: {
          borderWidth: theme.shape.thinBorderWidth
        },
        textStyle: {
          fontSize: 15,
          fontStyle: 'italic',
          color: theme.palette.info.caption,
          padding: spacing(1)
        },
        pageButtonItemGap: spacing(1.25),
        pageFormatter: ' ',
        pageIconSize: 18,
        pageIconColor: theme.palette.action.active,
        pageIconInactiveColor: theme.palette.action.disabled,
        pageTextStyle: {
          color: 'transparent',
          fontSize: 1,
          width: 0,
          height: 0
        }
      },
      xAxis: {
        type: 'value',
        name: xLabel,
        minInterval: 1,
        splitLine: {
          lineStyle: {
            color: theme.palette.chart.grid
          }
        },
        axisLabel: {
          fontSize: 14,
          color: theme.palette.text.label,
          formatter: xAxisLabelFormatter
        },
        nameTextStyle: {
          fontSize: 13.5,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.info.caption,
          align: 'right',
          verticalAlign: 'top',
          padding: [spacing(4), 0, 0, 0]
        }
      },
      yAxis: {
        type: 'category',
        name: yLabel,
        inverse: true,
        triggerEvent: Boolean(path),
        data: categories,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          inside: true,
          interval: 0,
          width: skillNameWidth,
          margin: -(skillNameWidth + spacing(4.5)),
          overflow: 'break',
          lineHeight: 17,
          fontSize: 17,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.secondary.text,
          padding: [spacing(0.375), spacing(1.5), spacing(0.375), spacing(1.5)],
          borderRadius: theme.shape.borderRadius,
          formatter: yAxisLabelFormatter
        },
        nameLocation: 'start',
        nameTextStyle: {
          fontSize: 13.5,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.info.caption,
          align: 'right',
          verticalAlign: 'bottom'
        }
      },
      series: [
        {
          ...bar,
          name: inferredLabel,
          data: inferred,
          itemStyle: {
            ...style,
            color: {
              type: 'pattern',
              repeat: 'repeat',
              // eslint-disable-next-line id-length
              x: 0,
              // eslint-disable-next-line id-length
              y: 0,
              rotation: 0,
              scaleX: 0.5,
              scaleY: 0.5,
              image: getSvgShadingImage(theme.palette.primary.main, 'rgba(0,0,0,0)')
            }
          }
        },
        {
          ...bar,
          name: confirmedLabel,
          data: confirmed,
          itemStyle: {
            ...style,
            color: {
              type: 'linear',
              // eslint-disable-next-line id-length
              x: 1,
              // eslint-disable-next-line id-length
              y: 1,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: theme.palette.secondary.dark0 // color at 0% position
                }, {
                  offset: 1,
                  color: theme.palette.success.light1 // color at 100% position
                }
              ]
            }
          }
        }
      ]
    }, true);
    echartInstance.resize();
  }, [
    categories, inferred, confirmed, totalEmployees, pending,
    formatNumber, inferredLabel, confirmedLabel, noEmployees, xLabel, yLabel, path, skillNameWidth, theme,
    xAxisLabelFormatter, yAxisLabelFormatter
  ]);

  return (
    <>
      <Chart
          ref={chartRef}
          option={GlobalEChartsStyles}
          onEvents={pending ? undefined : onEvents}
          className={folded ? chart : chartMax}
      />
      <CardFooter compact flex>
        <StandardLink onClick={toggleFolded} className={button}>
          {folded ? <KeyboardArrowDownRounded/> : <KeyboardArrowUpRounded/>}
          <FormattedMessage id={folded ? 'common.show_more' : 'common.show_less'}/>
        </StandardLink>
      </CardFooter>
    </>
  );
});

TopChartBars.displayName = 'TopChartBars';

TopChartBars.propTypes = TopChartBarsPropTypes;

export default memo(TopChartBars);
