/* eslint-disable max-lines */
import {
  forwardRef, memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState, useReducer,
  type Component, type ReactNode
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import keys from 'lodash/keys';
import find from 'lodash/find';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import toString from 'lodash/toString';
import toSafeInteger from 'lodash/toSafeInteger';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
// Material Icon imports
import FormatListChecks from 'mdi-material-ui/FormatListChecks';
// EmPath UI Components
import { getIds, getStringifiedIds } from '@empathco/ui-components/src/helpers/strings';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import SkillLevelSelector from '@empathco/ui-components/src/elements/SkillLevelSelector';
import ScopeSelector from '@empathco/ui-components/src/elements/ScopeSelector';
// local imports
import { ILookupItem } from '../models/lookupItem';
import { Manager } from '../models/manager';
import { getLeaderId } from '../models/user';
import { EmployeeManagementLevel, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST } from '../constants/managementLevel';
import {
  BestMatchesSort, BEST_MATCHES_SORTS, BEST_MATCHES_SORT_UNDEFINED, isValidSortBy, isValidSortParam
} from '../constants/tbBestMatchesSort';
import { isValidRoleScope, RoleScope, ROLE_SCOPES } from '../constants/scopes';
import { JobCategory, Org } from '../graphql/types';
import { MAX_MANAGER_DASHBOARD_ITEMS } from '../config/params';
import useModels, { getDefaultManager, sanitizeLookup } from '../helpers/models';
import { getSettingsBoolValue, getSettingsIntValue, getSettingsStrValue } from '../helpers/context';
import { toggleReducer } from '../helpers/reducers';
import { DataContext, GlobalContext, LookupContext, SupervisorContext } from '../context';
import { TalentFinderFilterValues } from '../context/supervisor';
import { getLeaderOrg, PersistentContext } from '../context/persistent';
import SortSelector from '../elements/SortSelector';
import ChainOfCommandSelector from '../v3/ChainOfCommandSelector';
import JobSearch, { JobLookupItem, JobSearchVariant } from '../v3/JobSearch';
import EditJobAdvancedSkills from '../components/EditJobAdvancedSkills';
// SCSS imports
import { orgLabel, orgFilter, employeeRatedFilter, foldingButton, jobSearch } from './DashboardFilters.module.scss';

const NO_ORGS = [] as Org[];
const NO_LOOKUP_ITEMS = [] as ILookupItem[];
const NO_MANAGERS = [] as Manager[];
const NO_LEVELS = [] as EmployeeManagementLevel[];
const NO_SKILL_IDS = {} as Record<string, boolean>;

export type OrgFilterPlacement = 'beforeReset' | 'overlap' | 'bottom' | 'simple';

export type DashboardFilterValues = TalentFinderFilterValues & {
  job_category?: JobCategory | null;
  skill_ids?: number[] | null;
};

type DashboardFiltersProps = {
  settingsId?: string;
  init?: DashboardFilterValues | null;
  action?: ReactNode;
  withScope?: boolean;
  withLevels?: boolean | null;
  withOrg?: boolean | 'disabled' | null;
  requireOrg?: boolean | null;
  withOrgSkills?: boolean | 'collapsed';
  withEmployeeRated?: boolean | null;
  withJobVariant?: JobSearchVariant | null;
  withSort?: boolean | null;
  withReset?: boolean | null;
  compact?: boolean | null;
  uid?: string | null;
  rootUid?: string | null;
  userOrgId?: number | null;
  delegate?: boolean | null;
  withDirectReports?: boolean;
  onChange: (filters: DashboardFilterValues) => void;
  onReset?: () => void;
  disabled?: boolean | null;
  disableTopLevel?: boolean | null;
  orgFilterPlacement?: OrgFilterPlacement;
  withoutHierarchy?: boolean | null;
  withoutLocation?: boolean | null;
  extraFilters?: ReactNode | ReactNode[] | null;
}

const DashboardFiltersPropTypes = {
  // attributes
  settingsId: PropTypes.string,
  init: PropTypes.object,
  action: PropTypes.node,
  withScope: PropTypes.bool,
  withLevels: PropTypes.bool,
  withOrg: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['disabled']) as Validator<'disabled'>
  ]),
  requireOrg: PropTypes.bool,
  withOrgSkills: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['collapsed']) as Validator<'collapsed'>
  ]),
  withEmployeeRated: PropTypes.bool,
  withJobVariant: PropTypes.string as Validator<JobSearchVariant>,
  withSort: PropTypes.bool,
  withReset: PropTypes.bool,
  compact: PropTypes.bool,
  uid: PropTypes.string,
  rootUid: PropTypes.string,
  userOrgId: PropTypes.number,
  delegate: PropTypes.bool,
  withDirectReports: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
  disableTopLevel: PropTypes.bool,
  orgFilterPlacement: PropTypes.string as Validator<OrgFilterPlacement>,
  withoutHierarchy: PropTypes.bool,
  withoutLocation: PropTypes.bool,
  extraFilters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const DashboardFilters = forwardRef<Component<BoxProps>, DashboardFiltersProps>(({
  settingsId,
  init,
  action,
  withScope = false,
  withLevels = false,
  withOrg: parentWithOrg,
  requireOrg,
  withOrgSkills: parentWithOrgSkills = false,
  withEmployeeRated = false,
  withJobVariant,
  withSort = false,
  withReset = false,
  compact = false,
  uid,
  rootUid,
  userOrgId,
  delegate = false,
  withDirectReports = false,
  onChange,
  onReset,
  disabled: parentDisabled = false,
  disableTopLevel = false,
  orgFilterPlacement = 'bottom',
  withoutHierarchy = false,
  withoutLocation = false,
  extraFilters
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { isDomesticCountryId } = useModels();

  const withOrg = Boolean(parentWithOrg);
  const orgsDisabled = parentWithOrg === 'disabled';

  const { leaderOrg, updateLeaderOrg } = useContext(PersistentContext);
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const {
    orgs: { data: orgsData, pending: orgsPending, failed: orgsFailed }, requireOrgs,
    countries: { data: countriesData, pending: countriesPending, failed: countriesFailed }, requireCountries,
    states: { data: statesData, pending: statesPending, failed: statesFailed }, requireStates
  } = useContext(LookupContext);
  const {
    hierarchy: { data: hierarchy, pending: hierarchyPending, failed: hierarchyFailed }, requireHierarchy
  } = useContext(SupervisorContext);

  const orgs = (((!withOrg && !requireOrg) || orgsFailed) && NO_ORGS) || (orgsPending ? null : orgsData);
  const countries = ((withoutLocation || countriesFailed) && NO_LOOKUP_ITEMS) || (countriesPending ? null : countriesData);
  const states = ((withoutLocation || statesFailed) && NO_LOOKUP_ITEMS) || (statesPending ? null : statesData);
  const managers = ((withoutHierarchy || hierarchyFailed) && NO_MANAGERS) || (!hierarchyPending && (
    rootUid ? getLeaderOrg(rootUid, leaderOrg) : hierarchy
  )) || null;

  const settingsLoaded = (!settingsId && Boolean(init)) ||
    (pendingSettings === false && failedSettings === false && Boolean(settingsData));
  const settings = settingsId && settingsLoaded ? settingsData : null;

  const settingsManager = settingsId
    ? getSettingsStrValue(settings, `${settingsId}__manager`)
    : init?.manager_id || undefined;
  const settingsCountry = settingsId
    ? getSettingsIntValue(settings, `${settingsId}__country`)
    : init?.country_id || undefined;
  const settingsState = settingsId
    ? getSettingsIntValue(settings, `${settingsId}__state`)
    : init?.state_id || undefined;
  const settingsScope = settingsId && withScope
    ? getSettingsStrValue(settings, `${settingsId}__scope`)
    : (init?.job_category === JobCategory.technology && 'tech') || init?.job_category || undefined;
  const settingsLevels: EmployeeManagementLevel[] | undefined = useMemo(() => settingsId && withLevels
    ? getIds(getSettingsStrValue(settings, `${settingsId}__levels`), MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST)
    : init?.job_levels || undefined, [settings, init, settingsId, withLevels]);
  const settingsRated = settingsId && withEmployeeRated
    ? getSettingsBoolValue(settings, `${settingsId}__rated`)
    : init?.employee_rated_only || undefined;
  const settingsSort = settingsId && withSort ? getSettingsStrValue(settings, `${settingsId}__sort`) : undefined;

  const [defaultManager, resetManager] = useMemo(() => withoutHierarchy ? [{ id: uid }, { id: uid }] as Manager[] : [
    getDefaultManager(uid, managers, withDirectReports, settingsManager),
    getDefaultManager(uid, managers, withDirectReports)
  ], [uid, settingsManager, managers, withDirectReports, withoutHierarchy]);
  const defaultOrgId = (requireOrg && (userOrgId || orgs?.[0]?.id)) || 0;
  const resetOrgId = (requireOrg !== false && resetManager?.org_id) || defaultOrgId;

  const [manager, setManager] = useState(defaultManager?.id);
  const [org, setOrg] = useState<number>(
    ((withOrg || requireOrg) && requireOrg !== false && defaultManager?.org_id) || defaultOrgId);
  const [country, setCountry] = useState<number>(withoutLocation ? 0 : sanitizeLookup(settingsCountry, countries));
  const [state, setState] = useState<number>(withoutLocation ? 0 : sanitizeLookup(settingsState, states));
  const [scope, setScope] = useState<RoleScope | ''>(
    withScope && isValidRoleScope(settingsScope) ? settingsScope as RoleScope : ''
  );
  const [levels, setLevels] = useState<EmployeeManagementLevel[]>(settingsLevels || NO_LEVELS);
  const [rated, setRated] = useState<boolean | undefined>(settingsRated);
  const [job, setJob] = useState<JobLookupItem | null>(null);
  const [sort, setSort] = useState<BestMatchesSort>(
    isValidSortBy(settingsSort) ? settingsSort as BestMatchesSort : BEST_MATCHES_SORT_UNDEFINED);

  const isDomestic = useMemo(() => !withoutLocation && isDomesticCountryId(country, countries),
    [countries, country, isDomesticCountryId, withoutLocation]);

  const withOrgSkills = Boolean(parentWithOrgSkills);
  const orgSkills = useMemo(() => withOrgSkills && orgs && org ? find(orgs, ['id', org])?.skills : undefined,
    [org, orgs, withOrgSkills]);

  const [skillsUnfolded, toggleSkillsUnfolded] = useReducer(toggleReducer, false);
  const skillsPanelDisabled = size(orgSkills) <= MAX_MANAGER_DASHBOARD_ITEMS;
  const skillsPanelOpen = skillsUnfolded && parentWithOrgSkills !== 'collapsed' && !skillsPanelDisabled;
  
  const [appliedSkillIds, setAppliedSkillIds] = useState(NO_SKILL_IDS);
  const [selectedSkillIds, setSelectedSkillIds] = useState(NO_SKILL_IDS);
  const orgSkillsChanged = useMemo(() => size(appliedSkillIds) >= 1 && !isEqual(appliedSkillIds, NO_SKILL_IDS),
    [appliedSkillIds]);
  const canApplySelectedSkills = useMemo(() => size(selectedSkillIds) >= 1 && !isEqual(selectedSkillIds, appliedSkillIds),
    [selectedSkillIds, appliedSkillIds]);
  useEffect(() => {
    if (withOrgSkills && org) {
      setAppliedSkillIds(NO_SKILL_IDS);
      setSelectedSkillIds(NO_SKILL_IDS);
    }
  }, [org, withOrgSkills]);

  const lookupsLoaded = Boolean(orgs && countries && managers) && !isUndefined(manager);
  const disabled = parentDisabled || pendingSettingsUpdate ? true : undefined;
  const filtersDisabled = disabled || !lookupsLoaded ? true : undefined;

  const handleSelectedSkillsApply = useCallback(() => {
    setAppliedSkillIds(selectedSkillIds);
  }, [selectedSkillIds]);

  const handleSelectedSkillsReset = useCallback(() => {
    setSelectedSkillIds(NO_SKILL_IDS);
    setAppliedSkillIds(NO_SKILL_IDS);
  }, []);

  const handleSelectSkill = useCallback((skillId: number) => {
    const idStr = toString(skillId);
    setSelectedSkillIds((prevIds) => prevIds[idStr] ? omit(prevIds, idStr) : {
      ...prevIds,
      [idStr]: true
    });
  }, []);

  const handleManager = useCallback((value: string, _hasTeams?: boolean, orgId?: number | null) => {
    setManager(value);
    if (withOrg || requireOrg) setOrg(sanitizeLookup(orgId, orgs) || defaultOrgId);
    if (settingsId && settingsManager !== value) updateSettings?.({ [`${settingsId}__manager`]: value });
  }, [orgs, settingsManager, defaultOrgId, withOrg, requireOrg, settingsId, updateSettings]);

  const handleOrg = useCallback((value: number) => setOrg(
    sanitizeLookup(value, orgs) || defaultOrgId
  ), [orgs, defaultOrgId]);

  const handleCountry = useCallback((value: number) => {
    const val = sanitizeLookup(value, countries);
    setCountry(val);
    if (settingsId && settingsCountry !== val) updateSettings?.({ [`${settingsId}__country`]: val });
  }, [countries, settingsCountry, settingsId, updateSettings]);

  const handleState = useCallback((value: number) => {
    const val = sanitizeLookup(value, states);
    setState(val);
    if (settingsId && settingsState !== val) updateSettings?.({ [`${settingsId}__state`]: val });
  }, [states, settingsState, settingsId, updateSettings]);

  const handleScope = useCallback((value: string) => {
    const val = value === scope || !isValidRoleScope(value) ? '' : value as RoleScope;
    setScope(val);
    if (settingsId && settingsScope !== val) updateSettings?.({ [`${settingsId}__scope`]: val });
  }, [scope, settingsId, settingsScope, updateSettings]);

  const handleLevels = useCallback((value: EmployeeManagementLevel[]) => {
    setLevels(value);
    if (settingsId && !isEqual(settingsLevels, value)) updateSettings?.({
      [`${settingsId}__levels`]: getStringifiedIds(value)
    });
  }, [settingsId, settingsLevels, updateSettings]);

  const handleRated = useCallback((value: boolean) => {
    const val = Boolean(value);
    setRated(val);
    if (settingsId && Boolean(settingsRated) !== val) updateSettings?.({ [`${settingsId}__rated`]: val });
  }, [settingsId, settingsRated, updateSettings]);

  const handleSort = useCallback((sortValue: string, _newDir?: boolean | null) => {
    if (isValidSortBy(sortValue)) {
      setSort(sortValue as BestMatchesSort);
      if (settingsId && settingsSort !== sortValue) updateSettings?.({ [`${settingsId}__sort`]: sortValue });
    }
  }, [settingsSort, updateSettings, settingsId]);

  const handleResetFilters = useCallback(() => {
    setManager(resetManager?.id || '0');
    if (!withoutLocation) {
      setCountry(0);
      setState(0);
    }
    if (withScope) setScope('');
    if (withLevels) setLevels([]);
    if (withEmployeeRated) setRated(false);
    if (withSort) setSort(BEST_MATCHES_SORT_UNDEFINED);
    if (withOrg || requireOrg) {
      setOrg(resetOrgId);
      if (withOrgSkills) {
        setAppliedSkillIds(NO_SKILL_IDS);
        setSelectedSkillIds(NO_SKILL_IDS);
      }
    }
    if (withJobVariant) setJob(null);
    if (settingsId) updateSettings?.({
      [`${settingsId}__manager`]: resetManager?.id || '0',
      ...withoutLocation ? {} : {
        [`${settingsId}__country`]: 0,
        [`${settingsId}__state`]: 0
      },
      ...withScope ? { [`${settingsId}__scope`]: '' } : {},
      ...withLevels ? { [`${settingsId}__levels`]: '' } : {},
      ...withEmployeeRated ? { [`${settingsId}__rated`]: false } : {},
      ...withSort ? { [`${settingsId}__sort`]: BEST_MATCHES_SORT_UNDEFINED } : {}
    });
    onReset?.();
  }, [
    resetOrgId, resetManager,
    withScope, withLevels, withEmployeeRated, withSort, withOrg, requireOrg, withOrgSkills, withJobVariant, withoutLocation,
    onReset, updateSettings, settingsId
  ]);

  useEffect(() => {
    if (!withoutHierarchy) requireHierarchy?.();
  }, [withoutHierarchy, requireHierarchy]);

  useEffect(() => {
    if (rootUid && hierarchy) updateLeaderOrg(hierarchy, rootUid);
  }, [rootUid, hierarchy, updateLeaderOrg]);

  useEffect(() => {
    if (!isUndefined(defaultManager)) {
      setManager((prevManager) => isUndefined(prevManager) ? defaultManager?.id : prevManager);
      if ((withOrg || requireOrg) && requireOrg !== false) setOrg(
        (prevOrg) => prevOrg || defaultManager?.org_id || defaultOrgId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrgId, defaultManager]); // we do not need to track `withOrg` and `requireOrg` changes

  useEffect(() => {
    if (!withoutLocation) requireCountries?.();
  }, [withoutLocation, requireCountries]);

  useEffect(() => {
    if (!withoutLocation && isDomestic) requireStates?.({ country_id: country });
  }, [country, isDomestic, withoutLocation, requireStates]);

  useEffect(() => {
    if (withOrg || requireOrg) requireOrgs?.();
  }, [requireOrgs, withOrg, requireOrg]);

  useEffect(() => {
    if (settingsId && settingsLoaded && !pendingSettingsUpdate) {
      if (!withoutLocation) {
        setCountry(sanitizeLookup(getSettingsIntValue(settings, `${settingsId}__country`), countries));
        setState(sanitizeLookup(getSettingsIntValue(settings, `${settingsId}__state`), states));
      }
      if (withScope) {
        const scopeFromSettings = getSettingsStrValue(settings, `${settingsId}__scope`);
        setScope(isValidRoleScope(scopeFromSettings) ? scopeFromSettings as RoleScope : '');
      }
      if (withLevels) setLevels(getIds(
        getSettingsStrValue(settings, `${settingsId}__levels`), MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST
      ));
      if (withEmployeeRated) setRated(Boolean(getSettingsBoolValue(settings, `${settingsId}__rated`)));
      if (withSort) {
        const sortFromSettings = getSettingsStrValue(settings, `${settingsId}__sort`);
        setSort(isValidSortBy(sortFromSettings) ? sortFromSettings as BestMatchesSort : BEST_MATCHES_SORT_UNDEFINED);
      }
    }
  }, [
    settingsLoaded, pendingSettingsUpdate, countries, states, settings,
    withScope, withLevels, withEmployeeRated, withSort, withoutLocation, settingsId
  ]);

  useLayoutEffect(() => {
    if (init) {
      if (!withoutLocation) {
        setCountry(init.country_id || 0);
        setState(init.state_id || 0);
      }
      if (withScope) setScope((init.job_category === JobCategory.technology ? 'tech' : init.job_category) || '');
      if (withLevels) setLevels((init.job_levels || []) as EmployeeManagementLevel[]);
      if (withEmployeeRated) setRated(Boolean(init.employee_rated_only));
    }
  }, [init, withScope, withLevels, withEmployeeRated, withoutLocation]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (settingsLoaded && lookupsLoaded && onChange) {
      const newParams: DashboardFilterValues = {
        manager_id: manager === '0' ? managers?.[0]?.topId : manager,
        direct_reports_only: withDirectReports && manager === '0',
        ...withoutLocation ? {} : { country_id: country },
        ...!withoutLocation && isDomestic ? { state_id: state } : {},
        ...withOrg || (requireOrg && org) ? { org_id: org } : {},
        ...withOrg && withOrgSkills && orgSkillsChanged && size(appliedSkillIds) >= 1
          ? { skill_ids: sortBy(map(keys(appliedSkillIds), toSafeInteger)) } : {},
        ...withScope && scope ? { job_category: scope === 'tech' ? JobCategory.technology : scope as JobCategory } : {},
        ...withLevels ? { job_levels: levels } : {},
        ...withEmployeeRated ? { employee_rated_only: rated } : {},
        ...withJobVariant && job?.id ? { job_id: job.id } : {},
        ...withSort && isValidSortParam(sort) ? { sort_by: sort } : {}
      };
      onChange(newParams);
    }
  }, [
    country, state, manager, org, scope, levels, rated, job, sort, appliedSkillIds, orgSkillsChanged, onChange,
    settingsLoaded, lookupsLoaded, isDomestic, managers,
    withScope, withLevels, withEmployeeRated, withSort, withOrg, withOrgSkills, requireOrg,
    withJobVariant, withDirectReports, withoutLocation
  ]);

  const orgInput = withOrg && (!orgs || size(orgs) >= 1) ? (
    <Box
        pl={orgFilterPlacement === 'beforeReset' ? 2 : undefined}
        pr={1.25}
        pb={1}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
    >
      {orgFilterPlacement === 'simple' ? undefined : (
        <BoxTypography pr={1.5} variant="caption" color="info.caption" className={orgLabel}>
          <FormattedMessage id="hr.dashboard.org"/>
        </BoxTypography>
      )}
      <FilterSelector
          type="skills_org"
          choices={orgs}
          value={org}
          onChange={handleOrg}
          required={requireOrg}
          disabled={filtersDisabled || orgsDisabled}
      />
      {withOrgSkills ? (
        <Button
            disableElevation
            onClick={toggleSkillsUnfolded}
            variant={skillsPanelOpen || orgSkillsChanged ? 'contained' : 'text'}
            color="primary"
            aria-label={formatMessage({ id: 'hr.dashboard.button.refine' })}
            disabled={filtersDisabled || orgsDisabled || skillsPanelDisabled}
            className={foldingButton}
        >
          <FormatListChecks/>
        </Button>
      ) : undefined}
    </Box>
  ) : undefined;

  const orgBox = orgInput ? (orgFilterPlacement === 'simple' && orgInput) || (
    <Box
        flexGrow={orgFilterPlacement === 'beforeReset' ? undefined : 1}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        pt={orgFilterPlacement === 'beforeReset' ? undefined : 1}
        className={orgFilterPlacement === 'overlap' ? orgFilter : undefined}
    >
      {orgInput}
    </Box>
  ) : undefined;

  return (
    <>
      <Box
          ref={ref}
          flexGrow={1}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          pt={compact ? 1 : undefined}
      >
        {withScope ? (
          <Box pr={1.25} pb={1} display="flex">
            <ScopeSelector
                simple
                scope={ROLE_SCOPES}
                value={scope}
                onChange={handleScope}
                disabled={disabled ? true : undefined}
            />
          </Box>
        ) : undefined}
        {withoutHierarchy ? undefined : (
          <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <ChainOfCommandSelector
                uid={defaultManager?.id}
                me={resetManager?.id}
                leader={getLeaderId(user)}
                delegate={delegate}
                disableTopLevel={disableTopLevel}
                expandable
                allowEmpty={withDirectReports}
                managers={managers}
                value={manager || ''}
                onChange={handleManager}
                disabled={filtersDisabled}
                placement="left"
            />
          </Box>
        )}
        {withoutLocation ? undefined : (
          <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <FilterSelector
                type="country"
                choices={countries}
                value={country}
                onChange={handleCountry}
                disabled={filtersDisabled}
            />
          </Box>
        )}
        {withoutLocation ? undefined : (
          <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <FilterSelector
                type="state"
                choices={isDomestic ? states : undefined}
                value={isDomestic ? state : 0}
                onChange={handleState}
                disabled={filtersDisabled || !isDomestic}
            />
          </Box>
        )}
        {withLevels ? (
          <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <SkillLevelSelector
                job
                multiple
                nonZero
                values={levels}
                onChangeMulpiple={handleLevels}
                disabled={filtersDisabled ? true : undefined}
            />
          </Box>
        ) : undefined}
        {withSort ? (
          <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <SortSelector
                variant="best_matches"
                value={sort}
                onChange={handleSort}
                disabled={filtersDisabled ? true : undefined}
                values={BEST_MATCHES_SORTS}
                defaultValue={BEST_MATCHES_SORT_UNDEFINED}
            />
          </Box>
        ) : undefined}
        {withJobVariant ? (
          <Box flexGrow={1} pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <JobSearch
                variant={withJobVariant}
                withSkills
                fullWidth
                value={job}
                onChange={setJob}
                disabled={filtersDisabled}
                className={jobSearch}
            />
          </Box>
        ) : undefined}
        {orgFilterPlacement === 'simple' ? orgBox : undefined}
        {extraFilters}
        {orgFilterPlacement === 'beforeReset' ? orgBox : undefined}
        {withReset ? (
          <Box pb={1}>
            <Button
                color="primary"
                variant="text"
                disabled={disabled || hierarchyPending || countriesPending || statesPending ||
                  (country === 0 && state === 0 && size(levels) === 0 && manager === resetManager?.id &&
                  sort === BEST_MATCHES_SORT_UNDEFINED && (!withJobVariant || isNil(job)) && (!withScope || !scope) &&
                  (!withOrg || org === resetOrgId) && !orgSkillsChanged && !onReset)}
                onClick={handleResetFilters}
            >
              <FormattedMessage id="hr.talentfinder.reset"/>
            </Button>
          </Box>
        ) : undefined}
        {withEmployeeRated ? (
          <Box pl={1} pb={1} flex="1 1 0" display="flex" justifyContent="flex-end" className={employeeRatedFilter}>
            <OnOffSwitch
                label="hr.dashboard.employee_rated"
                value={Boolean(rated)}
                onChange={handleRated}
                disabled={filtersDisabled ? true : undefined}
            />
          </Box>
        ) : undefined}
        {action ? (
          <Box pl={1} pb={1} flex="1 1 0" display="flex" justifyContent="flex-end">
            {action}
          </Box>
        ) : undefined}
      </Box>
      {orgFilterPlacement === 'beforeReset' || orgFilterPlacement === 'simple' ? undefined : orgBox}
      {withOrgSkills && orgSkills && selectedSkillIds ? (
        <Collapse in={skillsPanelOpen}>
          <EditJobAdvancedSkills
              variant="analytics"
              skills={orgSkills}
              advancedIds={selectedSkillIds}
              disabled={filtersDisabled}
              disableUnselected={size(selectedSkillIds) >= MAX_MANAGER_DASHBOARD_ITEMS}
              onActivate={handleSelectSkill}
              onApply={canApplySelectedSkills ? handleSelectedSkillsApply : undefined}
              onReset={orgSkillsChanged ? handleSelectedSkillsReset : undefined}
          />
        </Collapse>
      ) : undefined}
    </>
  );
});

DashboardFilters.displayName = 'DashboardFilters';

DashboardFilters.propTypes = DashboardFiltersPropTypes;

export default memo(DashboardFilters);
