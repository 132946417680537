import { memo, useContext, useEffect, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
import SkillsGapLegend from '@empathco/ui-components/src/elements/SkillsGapLegend';
// local imports
import useNonReducedUI from '../constants/managementLevel';
import { PATH_JOB } from '../config/paths';
import { DataContext } from '../context';
import { getJobAnalyticsData, useMixpanel } from '../context/analytics';
import SkillsGrid from '../v3/SkillsGrid';
// SCSS imports
import { dialogTitle, dialogLoading, dialogContent, moreButton, legend } from './SkillsGapForRole.module.scss';

type SkillsGapForRoleProps = {
  code: string;
  fullName: string;
  roleId?: string;
  isOpen?: boolean;
  onClose: () => void;
};

const SkillsGapForRolePropTypes = {
  code: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  roleId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

const SkillsGapForRole: FunctionComponent<SkillsGapForRoleProps> = ({
  code,
  fullName,
  roleId,
  isOpen = false,
  onClose
}) => {
  const { showNonReducedUI } = useNonReducedUI();
  const mixpanel = useMixpanel();
  const { role: { data: role, pending, failed }, requireEmployeeRole } = useContext(DataContext);
  const { title, match_rate, skills_with_gap, skills } = role || {};

  const allSkills = useMemo(() => [...skills_with_gap || [], ...skills || []], [skills_with_gap, skills]);

  useEffect(() => {
    if (roleId) requireEmployeeRole?.({ role_id: roleId });
  }, [requireEmployeeRole, roleId]);

  useEffect(() => {
    if (isOpen && role) mixpanel.track('JobSkillsGap', {
      ...getJobAnalyticsData(role),
      employeeCode: code,
      employeeName: fullName
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, mixpanel]); // do not track `role` changes

  const link = useMemo(
    () => roleId ? injectParams(PATH_JOB, { role_id: roleId }) : undefined,
    [roleId]
  );

  const loading = pending || !role || !roleId;

  if (!loading && !showNonReducedUI(role)) return null;

  return (
    <Dialog
        disableEnforceFocus
        maxWidth="lg"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={onClose}
    >
      <CloseIconButton onClick={onClose}/>
      <DialogTitle component="div" className={loading || failed ? dialogLoading : dialogTitle}>
        {!failed && <MatchIndicator value={toSafeInteger(match_rate)} pending={loading} pendingColor="inherit"/>}
        <BoxTypography
            pl={2}
            flexGrow={1}
            variant="h3"
        >
          {(loading && !failed && <Skeleton variant="text" width="16rem" height="2rem"/>) || (
            <FormattedMessage
                id="supervisor.job_related_skills"
                values={{
                  name: fullName,
                  role: failed || !title ? <FormattedMessage id="supervisor.default_job_title"/> : title
                }}
            />
          )}
        </BoxTypography>
        {link ? (
          <Button
              color="primary"
              variant="outlined"
              component={RouterLink}
              to={link}
              disabled={pending || failed ? true : undefined}
              className={moreButton}
          >
            <FormattedMessage id="supervisor.more_about_this_job"/>
          </Button>
        ) : undefined}
      </DialogTitle>
      <DialogContent className={dialogContent}>
        {!loading && !failed ? <SkillsGapLegend className={legend}/> : undefined}
        <SkillsGrid
            supervisor
            withExpectedLevel
            skills={allSkills}
            pending={loading}
            failed={failed}
            // breakAfter={size(skills_with_gap)}
        />
      </DialogContent>
    </Dialog>
  );
};

SkillsGapForRole.propTypes = SkillsGapForRolePropTypes;

export default memo(SkillsGapForRole);
