import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import { longDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
// local imports
import { SkillLevel } from '../models/skill';
import { DevPlanCompletedCourse, DevPlanCourse, EmployeeDevPlanCourse } from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
import { MAX_COURSE_TITLE_LENGTH } from '../config/params';
import { PATH_SKILL } from '../config/paths';
import CourseProviderLogo from '../elements/CourseProviderLogo';
import CourseFeatures from '../elements/CourseFeatures';
// SCSS imports
import { card, divider } from './DevPlanCourseCard.module.scss';

type DevPlanCourseCardProps = {
  item: DevPlanCourse | EmployeeDevPlanCourse | DevPlanCompletedCourse;
  disabled?: boolean | null;
  isSelected?: boolean;
  withCompletedState?: boolean;
  onSelect?: (course: DevPlanCourse) => void;
  onRemove?: (course: DevPlanCourse) => void;
  onComplete?: (course: DevPlanCourse) => void;
  onCompletePending?: boolean;
}

const DevPlanCourseCardPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<DevPlanCourse>,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  withCompletedState: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onComplete: PropTypes.func,
  onCompletePending: PropTypes.bool
};

// eslint-disable-next-line complexity
const DevPlanCourseCard = forwardRef<HTMLDivElement, DevPlanCourseCardProps>(({
  item,
  disabled = false,
  isSelected,
  withCompletedState = false,
  onSelect,
  onRemove,
  onComplete,
  onCompletePending = false
}, ref) => {
  const { getCourseUrl } = useCustomerSettings();
  const { code, title, link, provider, external_url, covered_skills, description } = item || {};
  const { is_selected } = (item || {}) as DevPlanCourse;
  const { is_complete, completed_at } = (item || {}) as DevPlanCompletedCourse;

  const handleChange = useCallback(() => onSelect?.(item as DevPlanCourse), [item, onSelect]);
  const handleRemove = useCallback(() => onRemove?.(item as DevPlanCourse), [item, onRemove]);
  const handleComplete = useCallback(() => onComplete?.(item as DevPlanCourse), [item, onComplete]);

  const pleLink = link || external_url || getCourseUrl(code);

  return (
    <ItemCard
        ref={ref}
        light
        header={<TagLabel variant="course"/>}
        onSelect={onSelect ? handleChange : undefined}
        onRemoveClick={onRemove && !is_selected ? handleRemove : undefined}
        selected={isSelected || (onSelect ? Boolean(is_selected) : undefined)}
        disabled={disabled}
        status={withCompletedState && is_complete && completed_at ? (
          <BoxTypography pt={1} pl={0.25} variant="body2" fontStyle="italic">
            <FormattedMessage
                id="hr.dev_plan.course_completed"
                // eslint-disable-next-line react/jsx-props-no-spreading
                values={{ date: <FormattedDate value={getJsDateFromISO(completed_at)} {...longDateOptions}/> }}
            />
          </BoxTypography>
        ) : (onComplete && !is_complete && (
          <Box pt={1} display="flex" alignItems="flex-end" justifyContent="center">
            <Button
                color="secondary"
                variant="text"
                onClick={handleComplete}
                disabled={disabled || onCompletePending ? true : undefined}
                startIcon={onCompletePending ? <CircularProgress size={16} color="inherit"/> : undefined}
            >
              <FormattedMessage id="hr.dev_plan.button.mark_as_completed"/>
            </Button>
          </Box>
        )) || undefined}
        className={card}
    >
      <CourseProviderLogo provider={provider}/>

      <Divider className={divider}/>

      <Box py={1}>
        <TruncatedTextLink
            text={title}
            href={pleLink}
            variant="subtitle1"
            dark
            length={MAX_COURSE_TITLE_LENGTH}
            disabled={disabled ? true : undefined}
        />
      </Box>

      <Divider className={divider}/>

      <Box
          flex="1 1 0"
          py={1}
      >
        <CourseFeatures course={item as DevPlanCourse}/>
      </Box>

      {description ? (
        <Box pb={1}>
          <TruncatedTextLink
              text={description}
              plain
              regular
              color="text.secondary"
              variant="body2"
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}

      {size(covered_skills) >= 1 && (
        <>
          <Divider className={divider}/>

          <Box pt={1.125} display="flex" alignItems="center" flexWrap="wrap">
            {map(covered_skills, (skill) => (
              <Box
                  key={skill.id}
                  my={0.375}
                  mr={0.75}
                  px={1}
                  py={0.25}
                  bgcolor="background.card"
                  borderRadius={shape.tinyBorderRadius}
              >
                <SkillName
                    skill={skill}
                    level={skill.current_level as SkillLevel}
                    skillPath={PATH_SKILL}
                    variant="body1"
                    maxLines={1}
                    disabled={disabled}
                />
              </Box>
            ))}
          </Box>
        </>
      )}

    </ItemCard>
  );
});

DevPlanCourseCard.displayName = 'DevPlanCourseCard';

DevPlanCourseCard.propTypes = DevPlanCourseCardPropTypes;

export default memo(DevPlanCourseCard);
