import { memo, useMemo, type FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
// EmPath UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
// local imports
import { PATH_ADMIN_EMPLOYEES, PATH_ADMIN_JOBS, PATH_ADMIN_REPORTS, PATH_ADMIN_SETTINGS } from '../config/paths';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import AdminEmployees from '../panels/AdminEmployees';
import AdminJobs from '../panels/AdminJobs';

const AdminScreen: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { content } = useVerifyUser({ admin: true });
  const isEmployees = pathname === PATH_ADMIN_EMPLOYEES;
  const isJobs = pathname === PATH_ADMIN_JOBS;
  const isReports = pathname === PATH_ADMIN_REPORTS;
  const isSettings = pathname === PATH_ADMIN_SETTINGS;

  const breadcrumbs = useMemo(() =>
    (isEmployees && ['admin.employees']) ||
    (isJobs && ['admin.jobs']) ||
    (isReports && ['admin.reports']) ||
    (isSettings && ['admin.settings']) ||
    undefined, [isEmployees, isJobs, isReports, isSettings]);

  const items = useMemo(() => [
    {
      title: 'admin.employees',
      link: PATH_ADMIN_EMPLOYEES
    },
    {
      title: 'admin.jobs',
      link: PATH_ADMIN_JOBS
    },
    {
      title: 'admin.reports'
      // TODO: link: PATH_ADMIN_REPORTS
    },
    {
      title: 'admin.settings'
      // TODO: link: PATH_ADMIN_SETTINGS
    }
  ], []);

  return content || (
    <Screen
        admin
        withSettings
        breadcrumbs={breadcrumbs}
    >
      <ContentCard>
        <CardTabbar items={items}>
          <AdminEmployees/>
          <AdminJobs/>
          <div>
            {'\u00A0'}
          </div>
          <div>
            {'\u00A0'}
          </div>
        </CardTabbar>
      </ContentCard>
    </Screen>
  );
};

export default memo(AdminScreen);
