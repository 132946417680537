import { memo, useContext, useEffect, useRef, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// Empath UI Components
import useCurrentPath from '@empathco/ui-components/src/hooks/useCurrentPath';
// local imports
import useNonReducedUI from '../constants/managementLevel';
import usePathConfig, { PATH_MY, PATH_MY_WELCOME } from '../config/paths';
import { DataContext } from '../context';
import useVerifyUser from '../hooks/useVerifyUser';
import WelcomeMessage from '../components/WelcomeMessage';
import ProfileBuilder from '../components/ProfileBuilder';

type ProfileBuilderScreenProps = {
  // for Storybook only
  skipResumeCheck?: boolean;
};

const ProfileBuilderScreenPropTypes = {
  skipResumeCheck: PropTypes.bool
};

const ProfileBuilderScreen: FunctionComponent<ProfileBuilderScreenProps> = ({
  skipResumeCheck
}) => {
  const isMounted = useRef(true);
  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  const { showNonReducedUI } = useNonReducedUI();
  const { ROUTES } = usePathConfig();
  const path = useCurrentPath(ROUTES);

  const { content, user } = useVerifyUser({ employee: true });
  const { updateDataStatus } = useContext(DataContext);

  const willRedirect = !showNonReducedUI(user);

  useEffect(() => () => {
    // pay attention that this is not `useEffect` body, but cleanup handler!
    if (!content && !willRedirect && !isMounted.current) updateDataStatus?.({ force: true });
  }, [content, willRedirect, updateDataStatus]);

  return content || (willRedirect && <Navigate replace to={PATH_MY}/>) ||
    (path === PATH_MY_WELCOME && <WelcomeMessage/>) || (
      <ProfileBuilder skipResumeCheck={skipResumeCheck}/>
    );
};

ProfileBuilderScreen.propTypes = ProfileBuilderScreenPropTypes;

export default memo(ProfileBuilderScreen);
