import toSafeInteger from 'lodash/toSafeInteger';
import toString from 'lodash/toString';
import toLower from 'lodash/toLower';
import replace from 'lodash/replace';
import reject from 'lodash/reject';
import get from 'lodash/get';
// EmPath UI Components
import { getSecondsFromISO, getCurrentSeconds } from '@empathco/ui-components/src/helpers/datetime';
// local imports
import { OrgUnit } from '../models/orgUnit';
import { MIN_MATCH_RATE, MAX_MATCH_RATE } from './params';
import customerSettings from '../customers/settings.json';

export type Feature = 'salary_ranges';

let RUNTIME_CONFIG: {
  // new config
  admin_ui?: boolean;
  export_my_plan?: boolean;
  courses?: boolean;
  skill_development_resources?: boolean;
  team_builder?: boolean;
  dev_plan?: boolean;
  opportunities?: boolean;
  devplan_opportunities?: boolean;
  redeployment?: boolean;
  dashboard_analytics?: boolean;
  tickers?: Record<string, boolean>;

  // old config
  disable_team_builder?: boolean;
  enable_courses?: boolean;
  disable_courses?: boolean;
  disable_skill_development_resources?: boolean;
  disable_dev_plan?: boolean;
  disable_opportunities?: boolean;
  disable_redeployment?: boolean;
  disable_dashboard_analytics?: boolean;

  // config for features
  release_all_features?: boolean;
  feature_release_date?: Record<Feature, string>;
} = {};

const defaultMatchRate = toSafeInteger(window.MATCH_RATE_DEFAULT);

try {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = window.RUNTIME_CONFIG || '{}';
  RUNTIME_CONFIG = JSON.parse(textArea.value) || {};
} catch (_error) {
  // nothing to do
}

// Customer specific configuration parameters

export interface ISkillDevelopmentResource {
  text: string;
  hover: string;
  link?: string | null;
}
export type SkillDevelopmentRes = ISkillDevelopmentResource | null;
export type CustomerSuccessStory = {
  avatarNum: number;
  name: string;
  title: string;
  story: string;
  link?: string;
};
export interface CustomerLink {
  text: string;
  link?: string | null;
}
export interface OtherResource extends CustomerLink {
  intl?: CustomerLink | null;
}
export interface OtherResources {
  support?: OtherResource[] | null;
  resources: OtherResource[];
}
export interface CustomerPopup extends CustomerLink {
  title?: string | null;
  inline_link?: string | null;
  button: string;
}
export interface CustomerTicker {
  id: number;
  ticker: CustomerLink | null;
  popup: CustomerPopup | null;
  valid_from?: string;
  valid_till?: string;
  supervisor_only?: boolean;
  leader_only?: boolean;
  employee_only?: boolean;
  is_warning?: boolean;
  show_on_job_screen?: boolean;
}
export type DashboardAnalyticsChart = keyof (typeof customerSettings.dashboard_analytics);
export type DashboardAnalyticsTabs = Record<DashboardAnalyticsChart, boolean>;

const HAS_COURSES = (customerSettings.has_courses ||
  Boolean(RUNTIME_CONFIG?.enable_courses) || RUNTIME_CONFIG?.courses === true
) && !RUNTIME_CONFIG?.disable_courses && RUNTIME_CONFIG?.courses !== false;
const HAS_MENTORING = customerSettings.has_mentoring as boolean;
const HAS_OPPORTUNITIES = (customerSettings.has_opportunities || RUNTIME_CONFIG?.opportunities === true) &&
  !RUNTIME_CONFIG?.disable_opportunities && RUNTIME_CONFIG?.opportunities !== false;
const SKILL_DEVELOPMENT_RESOURCES_DISABLED = Boolean(RUNTIME_CONFIG?.disable_skill_development_resources) ||
  RUNTIME_CONFIG?.skill_development_resources === false;

const DEFAULT_MATCH_RATE = (MIN_MATCH_RATE <= defaultMatchRate && defaultMatchRate < MAX_MATCH_RATE
  ? defaultMatchRate : undefined) || MIN_MATCH_RATE;

const isFeatureReleased = (feature: Feature): boolean => {
  if (RUNTIME_CONFIG?.release_all_features) return true;
  const releaseDate = RUNTIME_CONFIG.feature_release_date?.[feature] ||
    (customerSettings.feature_release_date as (Record<Feature, string> | undefined))?.[feature];
  return !releaseDate || getSecondsFromISO(releaseDate) <= getCurrentSeconds();
};

const getOrgUnitSplash = (orgUnit?: OrgUnit | null): boolean => Boolean(
  customerSettings.org_unit_splash && orgUnit?.code &&
  get(customerSettings.org_unit_splash as Record<string, boolean>, toLower(orgUnit.code))
);

const getCourseUrl = (code?: number | string | null) => code && customerSettings.course_url
  ? replace(customerSettings.course_url, '{id}', encodeURIComponent(code)) : undefined;

const injectParams = /\{(\w+)\}/gu;

const getSkillCoursesUrl = (params?: Record<string, string | number | null | undefined> | null | undefined) => {
  if (!params || !params.abbr || !params.title || !customerSettings.skill_courses_url) return undefined;
  return replace(customerSettings.skill_courses_url, injectParams,
    (_match, p1) => encodeURIComponent(toString(params[p1])));
};

const getOpenReqUrl = (code?: number | null) => code && customerSettings.open_req_url
  ? replace(customerSettings.open_req_url, '{id}', encodeURIComponent(code)) : undefined;

const getEmployeeContactUrl = (code?: string | null, email?: string | null) =>
  (code && customerSettings.employee_contact_url &&
    replace(customerSettings.employee_contact_url, '{id}', encodeURIComponent(code))
  ) || (email && `mailto:${email}`) || undefined;

const CUSTOMER_TICKERS = (RUNTIME_CONFIG?.tickers
  ? reject(customerSettings.tickers, ({ id }) => RUNTIME_CONFIG.tickers?.[toString(id)] === false)
  : customerSettings.tickers
) as CustomerTicker[];

// Global
const setHasLogin = (_hasLogin: boolean) => null;
const setHasLogout = (_hasLogout: boolean) => null;
// Employee
const setHasJobsChart = (_hasJobsChart: boolean) => null;
const setHasJobsScope = (_hasJobsScope: boolean) => null;
const setHasInDemandSkills = (_hasInDemandSkills: boolean) => null;
const setHasProfileBuilder = (_hasProfileBuilder: boolean) => null;
const setHasResumeUploader = (_hasResumeUploader: boolean) => null;
const setHasQuickTour = (_hasQuickTour: boolean) => null;
const setHasReducedUI = (_hasReducedUI: boolean) => null;
const setHasExportMyPlan = (_hasExportMyPlan: boolean) => null;
const setHasCompensation = (_hasCompensation: boolean) => null;
const setHasMentoring = (_hasMentoring: boolean) => null;
const setHasCourses = (_hasCourses: boolean) => null;
const setHasCoursesReadOnly = (_hasCoursesReadOnly: boolean) => null;
const setHasDevPlan = (_hasDevPlan: boolean) => null;
const setHasOpportunities = (_hasOpportunities: boolean) => null;
const setHasDevPlanOpportunities = (_hasDevPlanOpportunities: boolean) => null;
const setHasRedeployment = (_hasRedeployment: boolean) => null;
// HRBP
const setHasHRPJobs = (_hasHRPJobs: boolean) => null;
const setHasTalentFinder = (_hasTalentFinder: boolean) => null;
const setHasTeamBuilder = (_hasTeamBuilder: boolean) => null;
const setHasCandidates = (_hasCandidates: boolean) => null;
const setHasJobSkillsEditor = (_hasJobSkillsEditor: boolean) => null;
const setHasJobsSkillsGapChart = (_hasJobsSkillsGapChart: boolean) => null;
const setHasInDemandSkillsEditor = (_hasInDemandSkillsEditor: boolean) => null;
// Admin
const setHasAdminUI = (_hasAdminUI: boolean) => null;
// Other UI tweaks
const setShowWelcomeDataPrivacy = (_showWelcomeDataPrivacy: boolean) => null;
// Dashboard Analytics Tabs
const setDATabs = (_daTabs?: Partial<DashboardAnalyticsTabs> | null | undefined) => null;

const SKILL_DEVELOPMENT_RESOURCES_EMPTY: SkillDevelopmentRes[][] = [];

const customerSettingsMemo = {
  DEFAULT_MATCH_RATE,

  // Global settings:
  HAS_LOGIN: customerSettings.has_login as boolean,
  HAS_LOGOUT: customerSettings.has_logout as boolean,

  // Employee UI: as boolean
  HAS_JOBS_CHART: customerSettings.has_jobs_chart as boolean,
  HAS_JOBS_SCOPE: customerSettings.has_jobs_scope as boolean,
  HAS_INDEMAND_SKILLS: customerSettings.has_indemand_skills as boolean,
  HAS_PROFILE_BUILDER: customerSettings.has_profile_builder as boolean,
  HAS_RESUME_UPLOADER: customerSettings.has_resume_uploader as boolean,
  HAS_QUICK_TOUR: customerSettings.has_quick_tour as boolean,
  HAS_REDUCED_UI: customerSettings.has_reduced_ui as boolean,
  HAS_EXPORT_MY_PLAN: RUNTIME_CONFIG?.export_my_plan !== false,
  HAS_COMPENSATION: customerSettings.has_compensation as boolean,
  HAS_MENTORING,
  HAS_COURSES,
  HAS_COURSES_READONLY: HAS_COURSES || customerSettings.has_courses_readonly,
  HAS_DEV_PLAN: (customerSettings.has_dev_plan || RUNTIME_CONFIG?.dev_plan === true) &&
    !RUNTIME_CONFIG?.disable_dev_plan && RUNTIME_CONFIG?.dev_plan !== false && (HAS_COURSES || HAS_MENTORING),
  HAS_OPPORTUNITIES,
  HAS_DEV_PLAN_OPPORTUNITIES: HAS_OPPORTUNITIES &&
    (customerSettings.has_devplan_opportunities || RUNTIME_CONFIG?.devplan_opportunities === true) &&
    RUNTIME_CONFIG?.devplan_opportunities !== false,
  HAS_REDEPLOYMENT: (customerSettings.has_redeployment || RUNTIME_CONFIG?.redeployment === true) &&
    !RUNTIME_CONFIG?.disable_redeployment && RUNTIME_CONFIG?.redeployment !== false,
  HAS_INTERNAL_COURSE_LOGO: customerSettings.has_internal_course_logo as boolean,

  // HRBP UI:
  HAS_HRP_JOBS: customerSettings.has_hrp_jobs as boolean,
  HAS_TALENT_FINDER: customerSettings.has_talent_finder as boolean,
  HAS_TEAM_BUILDER: (customerSettings.has_team_builder || RUNTIME_CONFIG?.team_builder === true) &&
    !RUNTIME_CONFIG?.disable_team_builder && RUNTIME_CONFIG?.team_builder !== false,
  HAS_CANDIDATES: customerSettings.has_candidates as boolean,
  HAS_JOB_SKILLS_EDITOR: customerSettings.has_job_skills_editor as boolean,
  HAS_JOBS_SKILLS_GAP_CHART: customerSettings.has_jobs_skills_gap_chart as boolean,
  HAS_INDEMAND_SKILLS_EDITOR: customerSettings.has_indemand_skills_editor as boolean,

  // Admin UI:
  HAS_ADMIN_UI: RUNTIME_CONFIG?.admin_ui === true, // TODO: when ready change to `!== false,`

  // Other UI tweaks
  SHOW_WELCOME_DATA_PRIVACY: customerSettings.show_welcome_data_privacy as boolean,

  DOMESTIC_COUNTRIES: customerSettings.domestic_countries as string[],

  CUSTOM_MESSAGES: customerSettings.messages as { en: Record<string, string> },

  isFeatureReleased,
  getOrgUnitSplash,
  getCourseUrl,
  getSkillCoursesUrl,
  getOpenReqUrl,
  getEmployeeContactUrl,

  FIND_YOUR_GEO_ZONE_URL: customerSettings.find_your_geo_zone_url as string,
  INTERNATIONAL_SALARY_URL: customerSettings.international_salary_url as string,

  PRIVACY_NOTICE_URL: customerSettings.privacy_notice_url as string,
  HELP_URL: customerSettings.help_url as string,

  SKILL_DEVELOPMENT_RESOURCES_DISABLED,
  SKILL_DEVELOPMENT_RESOURCES: (
    SKILL_DEVELOPMENT_RESOURCES_DISABLED ? SKILL_DEVELOPMENT_RESOURCES_EMPTY : customerSettings.skill_development_resources
  ) as SkillDevelopmentRes[][] | null,

  SUCCESS_STORIES: customerSettings.success_stories as CustomerSuccessStory[] | null,
  CUSTOMER_OTHER_RESOURCES: customerSettings.other_resources as OtherResources,
  CUSTOMER_TICKERS,

  DA_TABS: (
    RUNTIME_CONFIG?.disable_dashboard_analytics || RUNTIME_CONFIG?.dashboard_analytics === false
    ? null : customerSettings.dashboard_analytics
  ) as Partial<DashboardAnalyticsTabs> | null | undefined,

  // for specs and Storybook only:
  // Global
  setHasLogin,
  setHasLogout,
  // Employee
  setHasJobsChart,
  setHasJobsScope,
  setHasInDemandSkills,
  setHasProfileBuilder,
  setHasResumeUploader,
  setHasQuickTour,
  setHasReducedUI,
  setHasExportMyPlan,
  setHasCompensation,
  setHasMentoring,
  setHasCourses,
  setHasCoursesReadOnly,
  setHasDevPlan,
  setHasOpportunities,
  setHasDevPlanOpportunities,
  setHasRedeployment,
  // HRBP
  setHasHRPJobs,
  setHasTalentFinder,
  setHasTeamBuilder,
  setHasCandidates,
  setHasJobSkillsEditor,
  setHasJobsSkillsGapChart,
  setHasInDemandSkillsEditor,
  // Admin
  setHasAdminUI,
  // Other UI tweaks
  setShowWelcomeDataPrivacy,
  // Dashboard Analytics Tabs
  setDATabs
};

function useCustomerSettings() {
  return customerSettingsMemo;
}

export default useCustomerSettings;
