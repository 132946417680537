import { memo, useEffect, useState, useMemo, useContext, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { useLazyQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
// EmPath UI Components
import { percentageOptions } from '@empathco/ui-components/src/common/intl';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import SkillsGapLegend from '@empathco/ui-components/src/elements/SkillsGapLegend';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { ADMIN_JOB_QUERY } from '../graphql/AdminJob';
import { AdminJob, AdminJobDocument } from '../graphql/types';
import { SKILL_LEVEL_MAX, Skill } from '../models/skill';
import { Job } from '../models/job';
import { DataContext } from '../context';
import JobDetailsBand from '../v3/JobDetailsBand';
import RoleDescription from '../v3/RoleDescription';
import JobDetailsItem from '../v3/JobDetailsItem';
import SkillsGrid from '../v3/SkillsGrid';
import MostCommonJobMoves from '../components/MostCommonJobMoves';
import TechnicalCareerPath from '../components/TechnicalCareerPath';
// SCSS imports
import { lightBg } from './AdminJobDialog.module.scss';

const dividerSx = { mt: 3 };

type AdminJobDialogProps = {
  job?: AdminJob | null;
  onClick?: (code: string, job?: Job | AdminJob) => void;
  onClose: () => void;
  // for Strorybook only
  testPending?: boolean;
}

const AdminJobDialogPropTypes = {
  // attributes
  job: PropTypes.object as Validator<AdminJob>,
  onClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  // for Strorybook only
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity
const AdminJobDialog: FunctionComponent<AdminJobDialogProps> = ({
  job: parentJob,
  onClick,
  onClose,
  testPending
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const {
    jobMoves: { data: jobMoves, pending: pendingJobMoves, failed: failedJobMoves }, requireJobMoves
  } = useContext(DataContext);

  const { query: getJob, pending: pendingJob, failed, results: job } = useQueryObject({
    data: undefined as unknown as AdminJob,
    key: 'adminJob',
    flatResults: true,
    lazyQuery: useLazyQuery(ADMIN_JOB_QUERY as typeof AdminJobDocument)
  });
  const pending = pendingJob || !job || testPending;

  const { id, title: parentTitle } = parentJob || {};
  const {
    title, unique_job,
    // management_level, management_sublevel, // duplicate `display_management_level`
    job_type, category, family, sub_family, discipline, career_ladder, career_step,
    salary_min, salary_max, salary_currency, org, sti_target_pct, employee_count, skills,
    description, description_overall_purpose, description_key_roles_and_responsibilities,
    description_experience, description_education, description_environmental_requirements
  } = job || {};
  const skillsCount = size(skills);

  useEffect(() => {
    if (id) getJob?.({ variables: { job_id: id } });
  }, [id, getJob]);

  useEffect(() => {
    if (id) requireJobMoves?.({ job_id: id });
  }, [id, requireJobMoves]);

  const isOpen = Boolean(parentJob);

  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const disabled = pending || testPending ? true : undefined;

  const notAvailableText = useMemo(() => formatMessage({ id: 'role.admin.not_available' }), [formatMessage]);

  const requiresSkills = useMemo(() => sortBy(skills,
    ({ title: skillTitle, expected_level }) => `${SKILL_LEVEL_MAX - (expected_level || 0)}${skillTitle}`), [skills]);

  // eslint-disable-next-line complexity
  const details = useMemo(() => [
    // Duplicate `display_management_level`:
    // ...isNil(management_level) && isNil(management_sublevel) ? [] : [
    //   {
    //     label: 'role.admin.management_level', // "role.admin.management_level": "Level\u00A0&\u00A0Sublevel:",
    //     value: `${
    //       isNil(management_level) ? '—' : management_level
    //     } / ${
    //       isNil(management_sublevel) ? '—' : management_sublevel
    //     }`
    //   }
    // ],
    ...isNil(job_type) ? [] : [{ label: 'role.admin.job_type', value: job_type }],
    ...isNil(category) ? [] : [{ label: 'role.admin.category', value: category.title }],
    ...isNil(family) && isNil(sub_family) ? [] : [
      {
        label: 'role.admin.family',
        value: `${isNil(family) ? '—' : family} / ${isNil(sub_family) ? '—' : sub_family.title}`
      }
    ],
    ...isNil(discipline) ? [] : [{ label: 'role.admin.discipline', value: discipline.title }],
    ...unique_job?.title ? [{ label: 'role.admin.unique_job', value: unique_job.title }] : [],
    ...isNil(org) ? [] : [{ label: 'role.admin.org', value: org.title }],
    ...isNil(career_ladder) ? [] : [{ label: 'role.admin.career_ladder', value: career_ladder.title }],
    ...isNil(career_step) ? [] : [{ label: 'role.admin.career_step', value: career_step }],
    ...isNil(salary_min) && isNil(salary_max) && isNil(salary_currency) ? [] : [
      {
        label: 'role.admin.salary',
        value: `${isNil(salary_currency) ? '' : salary_currency} ${
          isNil(salary_min) ? notAvailableText : salary_min
        } – ${
          isNil(salary_max) ? notAvailableText : salary_max
        }`
      }
    ],
    ...isNil(sti_target_pct) ? [] : [
      {
        label: 'role.admin.sti_target_pct',
        value: formatNumber(sti_target_pct / 100, percentageOptions)
      }
    ],
    ...skillsCount < 1 ? [{ label: 'role.admin.skills', value: 0 }] : [],
    { label: 'role.admin.employees', value: employee_count || 0 }
  ] as { label: string; value: number | string; }[], [
    unique_job, // management_level, management_sublevel,
    job_type, category, family, sub_family, discipline, career_ladder, career_step,
    salary_min, salary_max, salary_currency, org, sti_target_pct, employee_count, skillsCount, notAvailableText,
    formatNumber
  ]);

  return mounted ? (
    <Dialog
        disableEnforceFocus
        maxWidth="xl"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={disabled ? undefined : onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose}/>
      <CardTitle
          title={(failed && (parentTitle || 'role.default_role_name')) ||
            (pending && (parentTitle || <Skeleton variant="text" width="12rem"/>)) ||
            title || 'role.default_role_name'}
          withDivider
      />
      {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (mounted ? (
        <>
          <JobDetailsBand
              admin
              role={job as AdminJob}
          />
          {description || description_overall_purpose || description_key_roles_and_responsibilities ||
          description_experience || description_education || description_environmental_requirements ? (
            <>
              <CardSection>
                {description ? (
                  <>
                    <BoxTypography pt={2.5} pb={1} variant="h5">
                      <FormattedMessage id="role.description"/>
                    </BoxTypography>
                    <RoleDescription autofit description={description || '–'}/>
                  </>
                ) : undefined}
                {description_overall_purpose ? (
                  <>
                    <BoxTypography pt={2.5} pb={1} variant="h5">
                      <FormattedMessage id="role.admin.description.purpose"/>
                    </BoxTypography>
                    <RoleDescription autofit description={description_overall_purpose || '–'}/>
                  </>
                ) : undefined}
                {description_key_roles_and_responsibilities ? (
                  <>
                    <BoxTypography pt={2.5} pb={1} variant="h5">
                      <FormattedMessage id="role.admin.description.responsibilities"/>
                    </BoxTypography>
                    <RoleDescription autofit description={description_key_roles_and_responsibilities || '–'}/>
                  </>
                ) : undefined}
                {description_experience ? (
                  <>
                    <BoxTypography pt={2.5} pb={1} variant="h5">
                      <FormattedMessage id="role.admin.description.experience"/>
                    </BoxTypography>
                    <RoleDescription autofit description={description_experience || '–'}/>
                  </>
                ) : undefined}
                {description_education ? (
                  <>
                    <BoxTypography pt={2.5} pb={1} variant="h5">
                      <FormattedMessage id="role.admin.description.education"/>
                    </BoxTypography>
                    <RoleDescription autofit description={description_education || '–'}/>
                  </>
                ) : undefined}
                {description_environmental_requirements ? (
                  <>
                    <BoxTypography pt={2.5} pb={1} variant="h5">
                      <FormattedMessage id="role.admin.description.environmental"/>
                    </BoxTypography>
                    <RoleDescription autofit description={description_environmental_requirements || '–'}/>
                  </>
                ) : undefined}
              </CardSection>
              <Divider sx={dividerSx}/>
            </>
          ) : undefined}
          {details.length >= 1 ? (
            <>
              <CardSection compact flex className={lightBg}>
                <Box
                    py={2.5}
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                >
                  {map(details, ({ label, value }, idx) => (
                    <JobDetailsItem
                        key={label}
                        prompt={<FormattedMessage id={label}/>}
                        value={value}
                        divider={idx < details.length - 1}
                    />
                  ))}
                </Box>
              </CardSection>
              <Divider/>
            </>
          ) : undefined}
          {skillsCount >= 1 ? (
            <>
              <CardSection top>
                <BoxTypography pt={1} variant="subtitle1">
                  <FormattedMessage id="role.admin.required_skills"/>
                </BoxTypography>
                <SkillsGapLegend depersonalized/>
              </CardSection>
              <SkillsGrid
                  depersonalised
                  manualOnly
                  withExpectedLevel
                  withLink={false}
                  skills={(requiresSkills || []) as Skill[]}
              />
            </>
          ) : undefined}
          {pendingJobMoves || failedJobMoves || !jobMoves || size(jobMoves.prev_jobs) >= 1 || size(jobMoves.next_jobs) >= 1 ? (
            <>
              <CardSection>
                <BoxTypography pt={1} variant="subtitle1">
                  <FormattedMessage id="role.most_common_job_moves"/>
                </BoxTypography>
              </CardSection>
              <Divider/>
              <MostCommonJobMoves jobId={id} onClick={onClick}/>
              <Divider/>
            </>
          ) : undefined}
          {id && discipline?.id ? (
            <>
              <CardSection top>
                <BoxTypography pt={1} variant="subtitle1">
                  <FormattedMessage id="role.technical_career_path"/>
                </BoxTypography>
              </CardSection>
              <TechnicalCareerPath jobId={id} disciplineId={discipline.id} onClick={onClick}/>
            </>
          ) : undefined}
        </>
      ) : undefined)}
    </Dialog>
  ) : null;
};

AdminJobDialog.propTypes = AdminJobDialogPropTypes;

export default memo(AdminJobDialog);
