import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
// import isNull from 'lodash/isNull';
// import transform from 'lodash/transform';
// local imports
import { DataObjectId, IDataState } from '../context/dataContext';
import { ContextState, ContextEntity, ContextObject } from '../models/contextEntity';

export const toggleReducer = (state: boolean) => !state;

export const updateEntity = <T, E extends string = string, D = ContextState>(
  state: D,
  entity: E,
  replacement: (data: T | null) => T | null
) => {
  if (
    !isPlainObject(state) ||
    !isPlainObject((state as unknown as ContextState)[entity]) ||
    !isFunction(replacement)
  ) return {};
  const { data } = (state as unknown as ContextState)[entity] as { data: T; };
  const newData = replacement(data);
  return data === newData ? {} : {
    [entity]: {
      ...(state as unknown as ContextState)[entity] as ContextEntity<T>,
      data: newData
    }
  } as unknown as Partial<D>;
};

export const updateEntityAttr = <T, A>(
  state: IDataState,
  entity: DataObjectId,
  attrs: string[],
  replacement: (data: A[] | null) => A[]
) => {
  if (
    !state || !attrs || !replacement || !state[entity] ||
    !isPlainObject(state) || !isPlainObject(state[entity]) || !isFunction(replacement) ||
    (!isString(attrs) && !isArray(attrs))
  ) return {};
  const { data } = state[entity];
  if (!data || !isPlainObject(data)) return {};
  const updated = {} as Partial<IDataState>;
  forEach(isArray(attrs) ? attrs : [attrs], (attr) => {
    const items = (data as Record<string, A[]>)[attr];
    if (!isArray(items)) return;
    const newItems = replacement(items);
    if (items === newItems) return;
    if (updated[entity]) {
      (updated[entity]?.data as Record<string, A[]>)[attr] = newItems;
    } else {
      (updated[entity] as ContextObject<T>) = {
        ...state[entity] as ContextObject<T>,
        data: {
          ...data,
          [attr]: newItems
        } as T
      };
    }
  });
  return updated;
};

// export const updateEntitiesAttr = (state, entity, attr, replacement) => {
//   if (!isPlainObject(state) || !isPlainObject(state[entity]) || !isFunction(replacement)) return {};
//   const { data } = state[entity];
//   const { data: newData } = transform(data, (result, entityItem, index) => {
//     if (!isPlainObject(entityItem)) return;
//     const { [attr]: attrValue } = entityItem;
//     if (!entityItem) return;
//     const newAttrValue = replacement(attrValue);
//     // We may need a way to reset the parent entity:
//     // if (isNull(newAttrValue) && !isNull(attrValue)) {
//     //   result.data = null;
//     //   // eslint-disable-next-line consistent-return
//     //   return false;
//     // }
//     if (attrValue !== newAttrValue) {
//       if (result.data === data) result.data = [...data];
//       result.data[index] = { ...entityItem, [attr]: newAttrValue };
//     }
//   }, { data });
//   return newData === data ? {} : {
//     [entity]: {
//       ...state[entity],
//       data: newData
//     }
//   };
// };
