import { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import sum from 'lodash/sum';
import transform from 'lodash/transform';
import toSafeInteger from 'lodash/toSafeInteger';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
// EmPath UI Components
import { svgPath as RecordCircle } from '@empathco/ui-components/src/icons/RecordCircle';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { MySkillsCounts, MY_SKILLS_COUNT_KEYS } from '../models/board';
import { GlobalEChartsStyles } from '../config/params';
import Chart from '../elements/Chart';
// SCSS imports
import { chart } from './DashboardChart.module.scss';

type DashboardChartProps = {
  counts?: Partial<MySkillsCounts> | null;
}

const DashboardChartPropTypes = {
  // attributes
  counts: PropTypes.object as Validator<MySkillsCounts>
};

const DashboardChart = forwardRef<EChartsReactCore, DashboardChartProps>(({
  counts
}, ref) => {
  const theme = useTheme();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const labels = useMemo(() => MY_SKILLS_COUNT_KEYS.map((key) => formatMessage({ id: `board.skill.${key}` })), [formatMessage]);

  const [values, newCount, total] = useMemo(() => {
    const vals = MY_SKILLS_COUNT_KEYS.map((key) => counts ? toSafeInteger(counts[key]) : 0);
    return [vals, counts ? toSafeInteger(counts.new) : 0, sum(vals)];
  }, [counts]);

  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    const styles = [
      // newly inferred
      { color: theme.palette.info.main },
      // levels: 1-4
      { color: theme.palette.primary.lightest },
      { color: theme.palette.primary.main },
      { color: theme.palette.secondary.text },
      { color: theme.palette.secondary.darker }
    ];

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      legend: {
        bottom: '6.5%',
        padding: [0, spacing(2)],
        formatter: newCount >= 1 ? undefined : '{name}',
        data: [
          ...transform(values, (result, value, idx) => {
            if (idx >= 1 && value >= 1) result.push(labels[idx]);
          }, [] as string[]),
          ...values[0] >= 1 ? [labels[0]] : []
        ],
        icon: RecordCircle,
        selectedMode: false,
        itemWidth: 16,
        itemHeight: 16,
        textStyle: {
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: 15,
          color: theme.palette.text.label
        }
      },
      series: {
        silent: true,
        name: 'skills',
        type: 'pie',
        clockwise: newCount >= 1,
        radius: ['33%', '48%'],
        center: ['50%', '25.5%'],
        avoidLabelOverlap: false,
        label: { show: false },
        labelLine: { show: false },
        data: [
          ...map(values, (value, idx) => value < 1 ? {} : {
            value,
            name: labels[idx],
            itemStyle: styles[idx]
          }),
          total < 1 ? {
            value: 1,
            name: 'empty',
            itemStyle: { color: theme.palette.action.disabledBackground }
          } : {}
        ]
      }
    }, true);
    echartInstance.resize();
  }, [labels, values, newCount, total, theme]);

  return <Chart ref={chartRef} option={GlobalEChartsStyles} className={chart}/>;
});

DashboardChart.displayName = 'DashboardChart';

DashboardChart.propTypes = DashboardChartPropTypes;

export default memo(DashboardChart);
