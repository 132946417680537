import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_JOB } from '../config/api';

export const ADMIN_JOB_QUERY = gql`
  query AdminJob(
    $job_id: Int!
  ) {
    adminJob(
      job_id: $job_id
    )
    @rest(
      type: "AdminJob",
      path: "${API_ADMIN_JOB}"
    ) {
      id
      title
      code
      location {
        id
        title
        city
        state
        country
        country_id
        state_id
      }
      career_ladder {
        id
        title
      }
      category {
        id
        title
      }
      discipline {
        id
        title
        name
        sub_family {
          id
          title
        }
      }

      # internal data
      is_pilot
      management_level
      management_sublevel
      salary_min
      salary_max
      salary_currency
      family
      job_type
      career_step
      sti_target_pct
      is_supervisory
      is_index_display
      source
      display_management_level
      unique_job {
        id
        title
      }
      sub_family {
        id
        title
      }

      # counts
      open_req_count
      new_req_count
      skill_count
      employee_count

      # more details
      description
      description_overall_purpose
      description_key_roles_and_responsibilities
      description_education
      description_experience
      description_environmental_requirements
      org {
        id
        title
      }
      skills {
        id
        title
        abbr
        expected_level
      }
      open_reqs {
        id
        code
        title
        dept
        is_new
        location {
          id
          title
          city
          state
          country
        }
      }
    }
  }
`;
