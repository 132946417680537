import { memo, useCallback, useContext, useMemo, type FunctionComponent, type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// EmPath UI Components
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import { getName } from '@empathco/ui-components/src/helpers/strings';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ActionSucceededMessage from '@empathco/ui-components/src/elements/ActionSucceededMessage';
// local imports
import useBuilderSteps, { STEP_WELCOME_ID } from '../constants/builder';
import useCustomerSettings from '../config/customer';
import { PATHS_MY_PROFILE_BUILDER } from '../config/paths';
import { openMessage, setOpenMessage } from '../helpers/storage';
import { DataContext, GlobalContext } from '../context';
import SplashText from '../elements/SplashText';
import Screen from '../v3/Screen';

export const PRIVACY_NOTICE_ID = 'privacyNotice' as const;

const WelcomeMessage: FunctionComponent = () => {
  const { PRIVACY_NOTICE_URL, SHOW_WELCOME_DATA_PRIVACY } = useCustomerSettings();
  const { getDefaultStep } = useBuilderSteps();
  const navigate = useNavigate();
  const { user: { data: user } } = useContext(GlobalContext);
  const { preferencesUpdate: { pending, failed }, updatePreferences } = useContext(DataContext);

  const { first_name, is_international, code } = user || {};

  const handleRedirect = useCallback(() => {
    navigate(PATHS_MY_PROFILE_BUILDER[getDefaultStep(user) - 1]);
  }, [user, navigate, getDefaultStep]);

  const handleSplashClick = useCallback(() => updatePreferences?.({
    onboarding_step: STEP_WELCOME_ID,
    onSuccess: handleRedirect
  }), [handleRedirect, updatePreferences]);

  const handlePrivacyNotice = useCallback(() => setOpenMessage(PRIVACY_NOTICE_ID, code), [code]);

  const welcomeValues = useMemo(() => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink href={PRIVACY_NOTICE_URL}>
        {mapChunks(chunks)}
      </StandardLink>
    )
  }), [PRIVACY_NOTICE_URL]);

  return (
    <Screen appbar="empty" splash>
      <SplashText
          variant="welcome"
          name={getName(first_name) || '…'}
          privacy={SHOW_WELCOME_DATA_PRIVACY}
          onClick={handleSplashClick}
          pending={pending}
      />
      {PRIVACY_NOTICE_URL && is_international ? (
        <ActionSucceededMessage
            open={openMessage(PRIVACY_NOTICE_ID, code)}
            onOK={handlePrivacyNotice}
            compact
            maxWidth="md"
            title="welcome.privacy_notice.title"
            message="welcome.privacy_notice.message"
            values={welcomeValues}
        />
      ) : undefined}
      <ActionFailedAlert
          message="splash.welcome.error"
          open={failed}
      />
    </Screen>
  );
};

export default memo(WelcomeMessage);
