import { forwardRef, memo, useContext, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// EmPath UI Components
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { Employee } from '../models/employee';
import { GlobalContext } from '../context';
import EmployeeCard from '../v3/EmployeeCard';
import CardsGrid from '../v3/CardsGrid';
// SCSS imports
import { footer } from './TbRosterList.module.scss';

type TbRosterListProps = {
  roster?: Employee[];
}

const TbRosterListPropTypes = {
  // attributes
  roster: PropTypes.array as Validator<Employee[]>
};

const TbRosterList = forwardRef<HTMLDivElement, TbRosterListProps>(({
  roster
}, ref) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);
  const all = size(roster);

  const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = useMemo(() => ({
    avatarVariant: 'grey',
    route: supvEmplPath,
    withZoomInSkills: true
  }), [supvEmplPath]);

  return (
    <>
      <CardsGrid
          ref={ref}
          items={roster}
          notFoundMessage="hr.teambuilder.no_roster"
          variant="dark"
          withoutTopPadding
          blendFilters
          component={EmployeeCard}
          ComponentProps={componentProps}
      />
      {all > 0 && (
        <CardSection flex dark className={footer}>
          <FormattedMessage
              id="hr.teambuilder.review.allroster"
              values={{ all }}
          />
        </CardSection>)}
    </>
  );
});

TbRosterList.displayName = 'TbRosterList';

TbRosterList.propTypes = TbRosterListPropTypes;

export default memo(TbRosterList);
