import { forwardRef, memo, useContext, useMemo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material Icon imports
import { type AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
// EmPath UI Components
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { ILookupItem, ILookupSkill } from '../models/lookupItem';
import { LookupContext } from '../context';
// SCSS imports
import { root } from './SkillSearch.module.scss';

export type SkillSearchProps = {
  supervisor?: boolean;
  limit?: number;
  exclude?: number[];
  withoutExisting?: boolean;
  value?: ILookupItem | null;
  onChange: (skill: ILookupSkill | null) => void;
  disabled?: boolean | null;
  fullWidth?: boolean;
  library?: boolean;
  short?: boolean;
  renderOption?: (option: ILookupSkill, state: AutocompleteRenderOptionState) => ReactNode;
}

const SkillSearchPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  limit: PropTypes.number,
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  withoutExisting: PropTypes.bool,
  value: PropTypes.object as Validator<ILookupItem>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  library: PropTypes.bool,
  short: PropTypes.bool,
  renderOption: PropTypes.func
};

const SkillSearch = forwardRef<HTMLDivElement, SkillSearchProps>(({
  supervisor = false,
  limit,
  exclude,
  withoutExisting = false,
  value,
  onChange,
  disabled = false,
  fullWidth = false,
  library = false,
  short = false,
  renderOption
}, ref) => {
  const {
    skls: { data: skls, pending, failed, params },
    requireSkls
  } = useContext(LookupContext);

  const fetchParams = useMemo(() => ({
    supervisor,
    limit,
    exclude_ids: exclude,
    exclude_existing_skills: Boolean(withoutExisting)
  }), [exclude, supervisor, limit, withoutExisting]);

  return requireSkls ? (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type={(library && 'skills_lib') || (short && 'skills') || 'skill_search'}
        className={root}
        fetched={skls}
        pending={pending}
        failed={failed}
        params={params}
        fetch={requireSkls}
        fetchParams={fetchParams}
        exclude={exclude}
        value={value as ILookupSkill}
        onChange={onChange}
        disabled={disabled}
        popupIcon={library || short ? <SearchIcon/> : undefined}
        renderOption={renderOption}
    />
  ) : null;
});

SkillSearch.displayName = 'SkillSearch';

SkillSearch.propTypes = SkillSearchPropTypes;

export default memo(SkillSearch);
