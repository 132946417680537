/* eslint-disable max-statements */
import React, { useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// local imports
import { SET_ACTIONS, UNAUTHENTICATED, APP_ONLINE, NAVIGATION } from '../constants/actionTypes';
import useCustomerSettings from '../config/customer';
import { PATH_HOME } from '../config/paths';
import { history } from '../config/history';
import { getEmployeeId } from '../helpers/routerParams';
import { requireEditableSkills, requireSkl, updateSkills, updateTargetSkills } from './editableSkills';
import { GlobalContext, DataContext } from '.';
import useDataReducer, { initialDataState } from './dataReducer';
import {
  requirePreferences, requireSettings, requireNotifications, updatePreferences, updateSettings,
  requireCoC, requireUnreadCount, requireTalentData
} from './user';
import { requireDataStatus, requireJobStatus, updateDataStatus } from './dataStatus';
import {
  requireJobsIndex, requireRecommendedSkills, requireSkillsGap,
  requireTargetSkills, requireLearningProgress, requireMentorship,
  requireBoardSkills, requireBoardSuggested, requireBoardJobs, requireInDemandSkills
} from './employeeSkills';
import {
  requireDevPlan, requireEmployeeSkill, requireSkillEmployees,
  updateCourse, updateSkill, updateTargetSkill
} from './employeeSkill';
import {
  requireEmployeeRole, requireEmployeesToConnect, requireOpenReqs,
  requireJobMoves, requireMatchRate, requireTargetRole, requireCareerPath,
  updateTargetRole
} from './employeeRole';
import { suggestedSkillAction } from './supervisorSuggestions';

const employeeParams = {};

// eslint-disable-next-line max-lines-per-function
export const DataProvider = ({ state, initState, children }) => {
  const { HAS_MENTORING } = useCustomerSettings();
  const { dataReducer } = useDataReducer();

  const [dataState, dispatch] = useReducer(dataReducer, initState
    ? { ...initialDataState, ...initState } : initialDataState
  );
  const { online, token, updateOnboardingSteps, unauthenticate } = useContext(GlobalContext);
  employeeParams.selected_employee_id = getEmployeeId(useParams());

  // COMMON ACTIONS
  useEffect(() => {
    if (!token) dispatch({ type: UNAUTHENTICATED });
  }, [token]);

  useEffect(() => {
    if (online === true) dispatch({ type: APP_ONLINE });
  }, [online]);

  useEffect(() => {
    history.listen(({ location: { pathname } }) => {
      if (pathname && pathname !== PATH_HOME) dispatch({ type: NAVIGATION });
    });
  }, []);

  const actualState = state || dataState;
  const {
    // USER
    preferences, settings, notifications, coc, unreadCount, talentData,
    preferencesUpdate: { pending: preferencesUpdatePending },
    settingsUpdate: { pending: settingsUpdatePending },
    // SKILLS
    boardSkills, boardSuggested, boardJobs,
    recommendedSkills, targetSkills,
    learningProgress, skillsGap, jobsIndex, mentorship, skillsInDemand,
    suggestedSkillAdd: { pending: addSuggestedSkillPending },
    suggestedSkillRemove: { pending: removeSuggestedSkillPending },
    // SKILL
    skill, devPlan, skillEmployees,
    skillAdd: { pending: skillAddPending },
    skillUpdate: { pending: skillUpdatePending },
    targetSkillUpdate: { pending: targetUpdatePending },
    courseUpdate: { pending: courseUpdatePending },
    // ROLE
    role, matchRate, employeesToConnect, jobMoves, targetRole, openReqs, careerPath,
    targetRoleUpdate: { pending: targetRoleUpdatePending },
    // EDITABLE SKILLS
    skl, editableSks,
    skillsUpdate: { pending: skillsUpdatePending },
    targetSkillsUpdate: { pending: targetSkillsUpdatePending },
    // DATA STATUS
    dataStatus, jobStatus,
    dataStatusUpdate: { pending: dataStatusUpdatePending }
  } = actualState;


  // USER
  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requirePreferences: requirePreferences(token, online, unauthenticate, dispatch, preferences)
    }});
  }, [preferences, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireSettings: requireSettings(token, online, unauthenticate, dispatch, settings)
    }});
  }, [settings, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireNotifications: requireNotifications(token, online, unauthenticate, dispatch, notifications)
    }});
  }, [notifications, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireCoC: requireCoC(token, online, unauthenticate, dispatch, coc)
    }});
  }, [coc, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireUnreadCount: requireUnreadCount(token, online, unauthenticate, dispatch, unreadCount)
    }});
  }, [unreadCount, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireTalentData: requireTalentData(token, online, unauthenticate, dispatch, talentData)
    }});
  }, [talentData, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updatePreferences: updatePreferences(token, online, dispatch, preferencesUpdatePending, HAS_MENTORING)
    }});
  }, [preferencesUpdatePending, online, token, HAS_MENTORING]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateSettings: updateSettings(token, online, dispatch, settingsUpdatePending)
    }});
  }, [settingsUpdatePending, online, token]);

  // SKILLS
  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireBoardSkills: requireBoardSkills(token, online, unauthenticate, dispatch, boardSkills)
    }});
  }, [boardSkills, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireBoardSuggested: requireBoardSuggested(token, online, unauthenticate, dispatch, boardSuggested)
    }});
  }, [boardSuggested, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireBoardJobs: requireBoardJobs(token, online, unauthenticate, dispatch, boardJobs)
    }});
  }, [boardJobs, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireInDemandSkills: requireInDemandSkills(token, online, unauthenticate, dispatch, skillsInDemand, employeeParams)
    }});
  }, [skillsInDemand, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireRecommendedSkills: requireRecommendedSkills(
        token, online, unauthenticate, dispatch, recommendedSkills, employeeParams
      )
    }});
  }, [recommendedSkills, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireTargetSkills: requireTargetSkills(token, online, unauthenticate, dispatch, targetSkills, employeeParams)
    }});
  }, [targetSkills, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireLearningProgress: requireLearningProgress(
        token, online, unauthenticate, dispatch, learningProgress, employeeParams
      )
    }});
  }, [learningProgress, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireSkillsGap: requireSkillsGap(token, online, unauthenticate, dispatch, skillsGap)
    }});
  }, [skillsGap, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireJobsIndex: requireJobsIndex(token, online, unauthenticate, dispatch, jobsIndex, employeeParams)
    }});
  }, [jobsIndex, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireMentorship: HAS_MENTORING
        ? requireMentorship(token, online, unauthenticate, dispatch, mentorship, employeeParams)
        : undefined
    }});
  }, [mentorship, online, token, unauthenticate, HAS_MENTORING]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      addSuggestedSkill: suggestedSkillAction(token, 'ADD', online, dispatch, addSuggestedSkillPending)
    }});
  }, [addSuggestedSkillPending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      removeSuggestedSkill: suggestedSkillAction(token, 'REMOV', online, dispatch, removeSuggestedSkillPending)
    }});
  }, [removeSuggestedSkillPending, online, token]);


  // SKILL
  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireEmployeeSkill: requireEmployeeSkill(token, online, unauthenticate, dispatch, skill)
    }});
  }, [skill, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireDevPlan: requireDevPlan(token, online, unauthenticate, dispatch, devPlan)
    }});
  }, [devPlan, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireSkillEmployees: requireSkillEmployees(token, online, unauthenticate, dispatch, skillEmployees)
    }});
  }, [skillEmployees, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      addSkill: updateSkill(token, online, dispatch, true, skillAddPending, HAS_MENTORING)
    }});
  }, [skillAddPending, online, token, HAS_MENTORING]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateSkill: updateSkill(token, online, dispatch, false, skillUpdatePending, HAS_MENTORING)
    }});
  }, [skillUpdatePending, online, token, HAS_MENTORING]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateTargetSkill: updateTargetSkill(token, online, dispatch, targetUpdatePending)
    }});
  }, [targetUpdatePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateCourse: updateCourse(token, online, dispatch, courseUpdatePending)
    }});
  }, [courseUpdatePending, online, token]);


  // ROLE
  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireEmployeeRole: requireEmployeeRole(token, online, unauthenticate, dispatch, role, employeeParams)
    }});
  }, [role, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireMatchRate: requireMatchRate(token, online, unauthenticate, dispatch, matchRate)
    }});
  }, [matchRate, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireEmployeesToConnect: requireEmployeesToConnect(token, online, unauthenticate, dispatch, employeesToConnect)
    }});
  }, [employeesToConnect, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireCareerPath: requireCareerPath(token, online, unauthenticate, dispatch, careerPath)
    }});
  }, [careerPath, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireJobMoves: requireJobMoves(token, online, unauthenticate, dispatch, jobMoves)
    }});
  }, [jobMoves, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireTargetRole: requireTargetRole(token, online, unauthenticate, dispatch, targetRole)
    }});
  }, [targetRole, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateTargetRole: updateTargetRole(token, online, dispatch, targetRoleUpdatePending)
    }});
  }, [targetRoleUpdatePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireOpenReqs: requireOpenReqs(token, online, unauthenticate, dispatch, openReqs)
    }});
  }, [openReqs, online, token, unauthenticate]);

  // EDITABLE SKILLS
  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireSkl: requireSkl(token, online, unauthenticate, dispatch, skl)
    }});
  }, [skl, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireEditableSkills: requireEditableSkills(token, online, unauthenticate, dispatch, editableSks, employeeParams)
    }});
  }, [editableSks, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateSkills: updateSkills(token, online, dispatch, skillsUpdatePending, updateOnboardingSteps)
    }});
  }, [skillsUpdatePending, online, token, updateOnboardingSteps]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateTargetSkills: updateTargetSkills(
        token, online, dispatch, targetSkillsUpdatePending, employeeParams, updateOnboardingSteps
      )
    }});
  }, [targetSkillsUpdatePending, online, token, updateOnboardingSteps]);


  // DATA STATUS
  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireJobStatus: requireJobStatus(token, online, unauthenticate, dispatch, jobStatus)
    }});
  }, [jobStatus, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireDataStatus: requireDataStatus(token, online, unauthenticate, dispatch, dataStatus)
    }});
  }, [dataStatus, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      updateDataStatus: updateDataStatus(token, online, dispatch, dataStatusUpdatePending)
    }});
  }, [dataStatusUpdatePending, online, token]);


  return (
    <DataContext.Provider value={actualState}>
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  // for Storybook only
  state: PropTypes.object,
  // for Jest specs only
  initState: PropTypes.object
};

DataProvider.defaultProps = {
  initState: null,
  state: null
};
