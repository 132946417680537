import { memo, useMemo, useReducer, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import floor from 'lodash/floor';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import MatchIndicator, { type MatchIndicatorVariant } from '@empathco/ui-components/src/elements/MatchIndicator';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { AdminEmployee } from '../graphql/types';
import { TalentEmployeeObject, DevPlanEmployee, TalentEmployeeDetails } from '../graphql/customTypes';
import { Employee } from '../models/employee';
import useModels from '../helpers/models';
import { toggleReducer } from '../helpers/reducers';
import EmployeeName from '../elements/EmployeeName';
import EmployeeDetailsPopup from '../v3/EmployeeDetailsPopup';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { label, alone, avatar } from './EmployeeTalentBand.module.scss';

type TalentBandVariant = 'standalone';

type EmployeeTalentBandProps = {
  employee?: TalentEmployeeObject | DevPlanEmployee | TalentEmployeeDetails | AdminEmployee;
  variant?: TalentBandVariant;
  matchIndicatorVariant?: MatchIndicatorVariant;
  route?: string | null;
  disabled?: boolean | null;
  pending?: boolean | null;
  failed?: boolean | null;
  isMyPlan?: boolean;
  withReloading?: boolean;
  withoutDivider?: boolean;
}

const EmployeeTalentBandPropTypes = {
  // attributes
  employee: PropTypes.object as Validator<TalentEmployeeObject | DevPlanEmployee | TalentEmployeeDetails | AdminEmployee>,
  variant: PropTypes.string as Validator<TalentBandVariant>,
  matchIndicatorVariant: PropTypes.string as Validator<MatchIndicatorVariant>,
  route: PropTypes.string,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  withReloading: PropTypes.bool,
  withoutDivider: PropTypes.bool
};

// eslint-disable-next-line complexity
const EmployeeTalentBand: FunctionComponent<EmployeeTalentBandProps> = ({
  employee,
  variant,
  matchIndicatorVariant,
  route,
  disabled = false,
  pending = false,
  failed = false,
  isMyPlan = false,
  withReloading = false,
  withoutDivider = false
}) => {
  const { getLocationStr } = useModels();
  const { performance_rating, years_on_job, current_job, location, manager } = employee || {};
  const { match_rate } = employee as Employee || {};
  const { current_match_rate, skills } = employee as DevPlanEmployee || {};
  const { title } = current_job || {};
  const isStandalone = variant === 'standalone';
  const withMatchIndicator = Boolean(matchIndicatorVariant);

  const [popupOpen, togglePopupOpen] = useReducer(toggleReducer, false);

  const matchValues = useMemo(() => withMatchIndicator ? {
    satisfied: size(filter(skills, 'is_satisfied')),
    total: size(skills)
  } : undefined, [skills, withMatchIndicator]);

  const reloading = Boolean(withReloading && pending && employee);

  const content = failed || (pending && !reloading) ? undefined : (
    <GridBox
        container
        spacing={2}
        pt={isStandalone ? 2 : 3}
        pb={isStandalone ? (withMatchIndicator && 1) || 4 : 2}
        px={isStandalone ? (withMatchIndicator && 3) || 6 : undefined}
        className={isStandalone ? alone : undefined}
    >
      {isMyPlan || !employee ? (
        <GridBox
            item
            container
            xs={withMatchIndicator ? 8 : undefined}
            sm={withMatchIndicator ? 9 : undefined}
            md={withMatchIndicator ? 10 : undefined}
            alignItems="center"
        >
          <BoxTypography pl={2.5} variant="h3">
            <FormattedMessage id="dev_plans.match_rate"/>
          </BoxTypography>
        </GridBox>
      ) : (
        <>
          <Grid
              item
              container
              xs={isStandalone ? 8 : 7}
              sm={12}
              md={4}
              lg={3}
              alignItems="center"
          >
            <AccountCircleAlt color="inherit" className={avatar}/>
            <Box pl={1}>
              <EmployeeName
                  employee={employee}
                  variant="h4"
                  manager
                  route={route}
                  disabled={disabled ? true : undefined}
              />
              <BoxTypography variant="body2" color="text.label">
                {title}
              </BoxTypography>
              <BoxTypography variant="body2" color="text.label" pb={0.25}>
                {getLocationStr(location) || <FormattedMessage id="employees.not_available"/>}
              </BoxTypography>
            </Box>
          </Grid>
          <GridBox
              item
              xs={isStandalone ? 4 : 5}
              sm={withMatchIndicator ? 3 : 4}
              md={withMatchIndicator ? 2 : 3}
              lg={withMatchIndicator ? 3 : undefined}
              container
              flexDirection="column"
              alignItems="center"
          >
            <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
              <FormattedMessage id="hr.talentfinder.column.manager"/>
            </BoxTypography>
            <Box pt={2.25} pb={1.5}>
              {manager ? (
                <EmployeeName
                    variant="subtitle2"
                    employee={manager}
                    manager
                    disabled={disabled ? true : undefined}
                />
              ) : '—'}
            </Box>
          </GridBox>
          <GridBox
              item
              xs={withMatchIndicator ? 4 : 6}
              sm={withMatchIndicator ? 3 : 4}
              md={withMatchIndicator ? 2 : 3}
              container
              flexDirection="column"
              alignItems="center"
          >
            <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
              <FormattedMessage id="hr.talentfinder.column.rating"/>
            </BoxTypography>
            <BoxTypography variant="h4" py={1.5}>
              {isNil(performance_rating) ? '—' : <FormattedNumber value={round(performance_rating)}/>}
            </BoxTypography>
          </GridBox>
          <GridBox
              item
              xs={withMatchIndicator ? 4 : 6}
              sm={withMatchIndicator ? 3 : 4}
              md={2}
              lg={withMatchIndicator ? undefined : 3}
              container
              flexDirection="column"
              alignItems="center"
          >
            <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
              <FormattedMessage id="hr.talentfinder.column.years"/>
            </BoxTypography>
            <BoxTypography variant="h4" py={1.5}>
              {isNil(years_on_job) ? '—' : <FormattedNumber value={floor(years_on_job)}/>}
            </BoxTypography>
          </GridBox>
        </>
      )}
      {withMatchIndicator ? (
        <GridBox
            item
            xs={4}
            sm={3}
            md={2}
            container
            flexDirection="column"
            alignItems="center"
        >
          <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
            <FormattedMessage id="hr.talentfinder.column.match_skills" values={matchValues}/>
          </BoxTypography>
          <IconButton
              size="small"
              disabled={disabled ? true : undefined}
              onClick={togglePopupOpen}
          >
            <MatchIndicator
                value={toSafeInteger(match_rate || current_match_rate)}
                variant={matchIndicatorVariant}
            />
          </IconButton>
        </GridBox>
      ) : undefined}
    </GridBox>
  );

  return (failed && <FetchFailedAlert flat={isStandalone}/>) || (
    pending && !reloading && <LoadingPlaceholder flat={isStandalone}/>
  ) || (
    <>
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      {withoutDivider ? undefined : <Divider/>}
      {withMatchIndicator ? (
        <EmployeeDetailsPopup
            isMyPlan={isMyPlan}
            employee={employee as TalentEmployeeObject}
            isOpen={popupOpen}
            onClose={togglePopupOpen}
            route={route}
        />
      ) : undefined}
    </>
  );
};

EmployeeTalentBand.propTypes = EmployeeTalentBandPropTypes;

export default memo(EmployeeTalentBand);
