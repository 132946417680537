// extracted by mini-css-extract-plugin
var _1 = "CourseCard_card__7IxpT";
var _2 = "CourseCard_circle__KYgW0";
var _3 = "CourseCard_circular__YOwsX";
var _4 = "CourseCard_divider__gfTG6";
var _5 = "CourseCard_features__bkTm0";
var _6 = "CourseCard_item__jjqeJ";
var _7 = "CourseCard_link__HReUX";
var _8 = "CourseCard_skillName__KbfY7";
var _9 = "CourseCard_videoBtn__bdxBY";
export { _1 as "card", _2 as "circle", _3 as "circular", _4 as "divider", _5 as "features", _6 as "item", _7 as "link", _8 as "skillName", _9 as "videoBtn" }
