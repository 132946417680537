import { useContext, useLayoutEffect, useMemo } from 'react';
import size from 'lodash/size';
import find from 'lodash/find';
import { Navigate, useLocation } from 'react-router-dom';
// Material UI imports
import LinearProgress from '@mui/material/LinearProgress';
// local imports
import { UserRole } from '../constants/userRoles';
import useUserModel from '../models/user';
import { VALID_HASHES } from '../config/params';
import { PATH_MY_SPLASH } from '../config/paths';
import { savePath } from '../helpers/storage';
import { GlobalContext } from '../context';
import useRedirects from '../helpers/redirect';
import LoginRedirect from '../elements/LoginRedirect';

// eslint-disable-next-line complexity
function useVerifyUser(roles: Partial<Record<UserRole, boolean>>) {
  const {
    getNonAdminPath, getNonEmployeeNonLeaderPath, getNonEmployeeNonManagerPath,
    getNonEmployeePath, getNonHRPath, getNonSupervisorNonHRPath, getNonSupervisorPath
  } = useRedirects();
  const { hasNoAccess } = useUserModel();

  const { pathname, search, hash } = useLocation();
  const { token, user: { data: user, pending, failed } } = useContext(GlobalContext);
  const login = !token || !user || failed;
  const rolesCount = size(roles);
  const path = (!pending && !login && ((
    rolesCount === 0 && hasNoAccess(user) && PATH_MY_SPLASH
  ) || (rolesCount === 1 && (
    (roles.employee && getNonEmployeePath(user)) ||
    (roles.manager && getNonSupervisorPath(user)) ||
    (roles.hr && getNonHRPath(user)) ||
    (roles.admin && getNonAdminPath(user))
  )) || (rolesCount === 2 && (
    (roles.employee && roles.manager && getNonEmployeeNonManagerPath(user)) ||
    (roles.employee && roles.leader && getNonEmployeeNonLeaderPath(user)) ||
    (roles.manager && roles.hr && getNonSupervisorNonHRPath(user))
    // TODO: extend to support all combinations of roles
  )))) || undefined;

  useLayoutEffect(() => {
    const savedHash = find(VALID_HASHES, (validHash) => search?.includes(validHash) || hash?.includes(validHash));
    savePath(path || (savedHash ? `${pathname}#${savedHash}` : pathname));
  }, [path, pathname, search, hash]);

  return useMemo(() => ({
    content: path
      ? <Navigate replace to={path}/>
      : (pending && <LinearProgress/>) || (login && <LoginRedirect/>) || undefined,
    user
  }), [login, path, pending, user]);
}

export default useVerifyUser;
