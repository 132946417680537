import {
  forwardRef, memo, useRef, useCallback, useEffect, useState,
  type MouseEventHandler, type ChangeEvent
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import FileUploadRounded from '@mui/icons-material/FileUploadRounded';
// EmPath UI Components
import usePost from '@empathco/ui-components/src/hooks/usePost';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ActionSucceededMessage from '@empathco/ui-components/src/elements/ActionSucceededMessage';
// local imports
import { User } from '../models/user';
import { MAX_UPLOAD_SIZE } from '../config/params';
import useCustomerSettings from '../config/customer';
import { axiosInstance, API_MY_RESUME_UPLOAD } from '../config/api';
import { getAuthHeaders } from '../helpers/storage';
// SCSS imports
import { subtitle, hiddenInput } from './BuilderSubheader.module.scss';

type BuilderSubheaderProps = {
  user?: User | null;
  title: string;
  info: string;
  infoLink?: string;
  infoText?: string;
  disabled?: boolean;
  pending?: boolean | null;
  pendingResume?: boolean | null;
  onAdd?: MouseEventHandler<HTMLButtonElement>;
  onUpload?: () => void;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
  // for Storybook only
  testUploading?: boolean;
};

const BuilderSubheaderPropTypes = {
  user: PropTypes.object as Validator<User>,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  infoLink: PropTypes.string,
  infoText: PropTypes.string,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  pendingResume: PropTypes.bool,
  onAdd: PropTypes.func,
  onUpload: PropTypes.func,
  onUploadStart: PropTypes.func,
  onUploadEnd: PropTypes.func,
  // for Storybook only
  testUploading: PropTypes.bool
};

// eslint-disable-next-line complexity
const BuilderSubheader = forwardRef<HTMLElement, BuilderSubheaderProps>(({
  user,
  title,
  info,
  infoLink,
  infoText,
  disabled = false,
  pending = false,
  pendingResume = false,
  onAdd,
  onUpload,
  onUploadStart,
  onUploadEnd,
  testUploading
}, ref) => {
  const { HAS_RESUME_UPLOADER } = useCustomerSettings();

  const fileInput = useRef<HTMLInputElement>(null);

  const [err, setErr] = useState(false);

  const { post, loading, failed, succeeded } = usePost(axiosInstance, getAuthHeaders);
  const uploading = loading || testUploading;

  const hasUpload = Boolean(HAS_RESUME_UPLOADER && onUpload);

  const handleFileInput = useCallback(() => {
    if (hasUpload) {
      setErr(false);
      fileInput.current?.click();
    }
  }, [hasUpload]);

  const handleChange = useCallback((event?: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files?.[0];
    if (hasUpload && selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      if (selectedFile.size > MAX_UPLOAD_SIZE || (
        selectedFile.type !== 'application/pdf' && selectedFile.type !== 'text/plain'
      )) {
        if (fileInput.current) fileInput.current.value = '';
        setErr(true);
        return;
      }
      setErr(false);
      onUploadStart?.();
      post(API_MY_RESUME_UPLOAD, formData);
    }
  }, [hasUpload, post, onUploadStart]);

  const handleOK = useCallback(() => {
    // no-op
  }, []);

  useEffect(() => {
    if (hasUpload && fileInput.current && !loading && (failed || succeeded)) {
      fileInput.current.value = '';
      onUploadEnd?.();
    }
  }, [hasUpload, loading, failed, succeeded, onUploadEnd]);

  useEffect(() => {
    if (hasUpload && succeeded) onUpload?.();
  }, [hasUpload, succeeded, onUpload]);

  const addButton = onAdd ? (
    <PlusButton
        label="builder.add_skill"
        disabled={disabled || pending || loading}
        onClick={onAdd}
    />
  ) : undefined;

  return (
    <>
      <CardHeader
          ref={ref}
          disableTypography
          title={(
            <Box px={4} display="flex" flexDirection="column">
              <BoxTypography pb={1} variant="h4">
                <FormattedMessage
                    id={title}
                    defaultMessage={title}
                    values={{
                      company: <FormattedMessage id="header.brand"/>,
                      org: user?.org?.title || null,
                      has_skills: Boolean(user?.has_current_job_skills || user?.has_in_demand_skills)
                    }}
                />
                {infoText ? (
                  <>
                    {' '}
                    <InfoButton
                        textVariant="subtitle2"
                        text={infoLink}
                        help={infoText}
                    />
                  </>
                ) : undefined}
              </BoxTypography>
              <Box className={subtitle}>
                <FormattedMessage id={info} defaultMessage={info} values={{ br: <br/> }}/>
              </Box>
            </Box>
          )}
          action={hasUpload ? undefined : addButton}
      />
      {hasUpload ? (
        <>
          <CardSection compact>
            <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" pl={4} pb={1.5}>
              <BoxTypography variant="subtitle2" pr={1.5} py={1.5}>
                <FormattedMessage id="builder.resume"/>
              </BoxTypography>
              {addButton ? (
                <Box display="flex" alignItems="center" px={4} py={1.5}>
                  {addButton}
                </Box>
              ) : undefined}
              <BoxTypography variant="subtitle2" pr={4} py={1.5}>
                <FormattedMessage id="builder.resume.or"/>
              </BoxTypography>
              <Box display="flex" alignItems="center">
                <Button
                    color="secondary"
                    variant="text"
                    disabled={disabled || pending || uploading || pendingResume || !onAdd}
                    onClick={handleFileInput}
                    startIcon={uploading || (pendingResume && !pending)
                      ? <CircularProgress size={26} color={pendingResume ? 'inherit' : 'secondary'}/>
                      : <FileUploadRounded fontSize="inherit" color="inherit"/>}
                >
                  <FormattedMessage
                      id={(pendingResume && !pending && 'builder.resume.processing') ||
                        (uploading && 'builder.resume.progress') ||
                        'builder.resume.button'}
                  />
                </Button>
                <input
                    ref={fileInput}
                    type="file"
                    accept=".txt,.pdf,text/plain,application/pdf"
                    onChange={handleChange}
                    className={hiddenInput}
                />
                <InfoButton
                    small
                    help="builder.resume.supported_files"
                    placement="bottom-end"
                />
              </Box>
            </Box>
          </CardSection>
          {err ? (
            <ActionFailedAlert
                open
                message="builder.resume.unsupported_file"
            />
          ) : undefined}
          <ActionFailedAlert
              open={failed}
              message="builder.resume.error"
          />
          <ActionSucceededMessage
              open={succeeded}
              title="builder.resume.success.title"
              message="builder.resume.success.message"
              onOK={handleOK}
              compact
          />
        </>
      ) : undefined}
      {hasUpload && ((pendingResume && !pending) || uploading) ? undefined : <Divider/>}
    </>
  );
});

BuilderSubheader.displayName = 'BuilderSubheader';

BuilderSubheader.propTypes = BuilderSubheaderPropTypes;

export default memo(BuilderSubheader);
