import { type FunctionComponent, memo, type ReactNode, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import pThrottle from 'p-throttle';
import { ApolloClient, ApolloProvider, from, type ServerError, type ServerParseError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
// EmPath UI Components
import { typePatcherFactory } from '@empathco/ui-components/src/helpers/typePatcherFactory';
// local imports
import { API_BASE, API_HR_ANALYTICS } from '../config/api';
import { typeDefinitions } from '../graphql/typeDefinitions';
import useCustomizedApolloCache from '../graphql/cache';
import { GlobalContext } from '.';

type ApolloContextProps = {
  children?: ReactNode | ReactNode[];
};

const ApolloContextPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

// global error handling
let logout: (() => void) | null | undefined = null;
const errorLink = onError(({ networkError }) => {
  if ((networkError as ServerParseError | ServerError)?.statusCode === 401) logout?.();
});

// Complex type definitions for RestLink
const typePatcher = typePatcherFactory(typeDefinitions);

// Throttled Fetch for Dashboard Analytics endpoints
const throttledFetch = pThrottle({
  limit: 2, // Max. concurrent Requests
  interval: 1000 // Min. delay between calls
})(fetch);

const customFetch = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> =>
  isString(input) && input.includes(API_HR_ANALYTICS) ? throttledFetch(input, init) : fetch(input, init);

const ApolloContext: FunctionComponent<ApolloContextProps> = ({
  children
}) => {
  const { createCache } = useCustomizedApolloCache();
  const { token, unauthenticate } = useContext(GlobalContext);
  logout = unauthenticate;

  // Apollo Client setup
  const client = useMemo(() => new ApolloClient({
    cache: createCache(),
    name: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_VERSION,
    link: from([
      errorLink,
      new RestLink({
        // set API V2 endpoints root URL
        uri: API_BASE,
        typePatcher,
        headers: token ? {
          Authorization: `Token ${token}`
        } : undefined,
        credentials: 'same-origin',
        customFetch
      })
    ])
  }), [token, createCache]);

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

ApolloContext.propTypes = ApolloContextPropTypes;

export default memo(ApolloContext);
