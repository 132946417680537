import { gql } from '@apollo/client';
// local imports
import { API_SUPV_EMPLOYEE_ACTIVITIES } from '../config/api';

export const EMPLOYEE_ACTIVITIES_QUERY = gql`
  query EmployeeActivities(
    $selected_employee_id: String
    $limit: Int
    $offset: Int
  ) {
    employeeActivities(
      selected_employee_id: $selected_employee_id,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "EmployeeActivitiesPayload",
      path: "${API_SUPV_EMPLOYEE_ACTIVITIES}?{args}"
    ) {
      count
      results {
        id
        status
        created_at
        updated_at
        activity {
          id
          code
          name
          activity_type
          description
          activity_link
          development_area
          field_support
          store
          education_style
          is_complete
          completed_at
        }
        skills {
          id
          abbr
          title
          skill_proficiency_level
        }
      }
    }
  }
`;
