import { memo, useContext, type FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
// Material UI imports
import LinearProgress from '@mui/material/LinearProgress';
// local imports
import useRedirects from '../helpers/redirect';
import { GlobalContext } from '../context';
import LoginRedirect from '../elements/LoginRedirect';

const HomeScreen: FunctionComponent = () => {
  const { getRedirectionPath } = useRedirects();
  const { token, user: { data: user, pending, failed } } = useContext(GlobalContext);
  if (token && user && !pending && !failed) {
    return <Navigate replace to={getRedirectionPath(user)}/>;
  }
  return pending ? <LinearProgress/> : <LoginRedirect/>;
};

export default memo(HomeScreen);
