import { useCallback, useMemo } from 'react';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import every from 'lodash/every';
import round from 'lodash/round';
// local imports
import { User } from '../models/user';
import useCustomerSettings from '../config/customer';

export const STEP_CURRENT_JOB = 1 as const;
export const STEP_IN_DEMAND = 2 as const;
export const STEP_ADDITION = 3 as const;
export const STEP_TARGET = 4 as const;
export const STEP_COMPLETED = 5 as const;

const STEP_SCOPE_CURRENT_JOB = 'current_job' as const;
const STEP_SCOPE_INDEMAND = 'in_demand' as const;
const STEP_SCOPE_ADDITIONAL = 'addition' as const;
export const STEP_SCOPE = [
  STEP_SCOPE_CURRENT_JOB,
  STEP_SCOPE_INDEMAND,
  STEP_SCOPE_ADDITIONAL
] as const;
export type BuilderScope = typeof STEP_SCOPE[number];

export const STEP_WELCOME_ID = 'welcome' as const;

export const STEP_TARGET_ID = 'target' as const;
export const BUILDER_STEPS = [
  STEP_SCOPE_CURRENT_JOB,
  STEP_SCOPE_INDEMAND,
  STEP_SCOPE_ADDITIONAL,
  STEP_TARGET_ID
] as const;
export type BuilderStep = typeof BUILDER_STEPS[number];

const ALL_BUILDER_STEPS = [
  STEP_WELCOME_ID,
  ...BUILDER_STEPS
] as const;

export const isValidInferredScope = (scope?: string) => scope && includes(STEP_SCOPE, scope);

export const isValidBuilderStep = (step?: string) => step && includes(BUILDER_STEPS, step);

export const isValidOnboardingStep = (step?: string) => step && includes(ALL_BUILDER_STEPS, step);

function useBuilderSteps() {
  const { HAS_INDEMAND_SKILLS } = useCustomerSettings();

  const allStepsCompleted = useCallback((user?: User | null) => {
    if (!user || !user.onboarding_steps) return false;
    const { onboarding_steps, has_current_job_skills, has_in_demand_skills } = user;
    return every(BUILDER_STEPS, (step) =>
      Boolean(onboarding_steps[step]) ||
      (!has_current_job_skills && step === STEP_SCOPE_CURRENT_JOB) ||
      ((!HAS_INDEMAND_SKILLS || !has_in_demand_skills) && step === STEP_SCOPE_INDEMAND)
    );
  }, [HAS_INDEMAND_SKILLS]);

  const noStepCompleted = useCallback((user?: User | null) => {
    if (!user || !user.onboarding_steps) return true;
    const { onboarding_steps, has_current_job_skills, has_in_demand_skills } = user;
    return every(ALL_BUILDER_STEPS, (step) =>
      !onboarding_steps[step] ||
      (!has_current_job_skills && step === STEP_SCOPE_CURRENT_JOB) ||
      ((!HAS_INDEMAND_SKILLS || !has_in_demand_skills) && step === STEP_SCOPE_INDEMAND)
    );
  }, [HAS_INDEMAND_SKILLS]);

  const isValidStep = useCallback(
    (hasCurrentJobSkills?: boolean | null, hasInDemandSkills?: boolean | null, step = 0): boolean =>
      step >= STEP_CURRENT_JOB && step <= STEP_COMPLETED &&
      (hasCurrentJobSkills || step !== STEP_CURRENT_JOB) &&
      ((HAS_INDEMAND_SKILLS && hasInDemandSkills) || step !== STEP_IN_DEMAND),
    [HAS_INDEMAND_SKILLS]);

  const getDefaultStep = useCallback((user?: User | null): number =>
    (user?.has_current_job_skills && STEP_CURRENT_JOB) ||
    (HAS_INDEMAND_SKILLS && user?.has_in_demand_skills && STEP_IN_DEMAND) || STEP_ADDITION,
    [HAS_INDEMAND_SKILLS]);

  const getNextIncompleteStep = useCallback((user?: User | null, fromIndex?: number): number => {
    const { onboarding_steps, has_current_job_skills, has_in_demand_skills } = user || {};
    if (!user || !onboarding_steps) return getDefaultStep(user);
    const idx = findIndex(
      BUILDER_STEPS,
      (step) => !onboarding_steps[step] &&
        (has_current_job_skills || step !== STEP_SCOPE_CURRENT_JOB) &&
        ((HAS_INDEMAND_SKILLS && has_in_demand_skills) || step !== STEP_SCOPE_INDEMAND),
      fromIndex
    );
    return idx < 0 ? STEP_COMPLETED : idx + 1;
  }, [HAS_INDEMAND_SKILLS, getDefaultStep]);

  const getStepNumber = useCallback((user?: User | null, step = 1): number => step -
    (step > STEP_CURRENT_JOB && !user?.has_current_job_skills ? 1 : 0) -
    ((!HAS_INDEMAND_SKILLS || !user?.has_in_demand_skills) && step > STEP_IN_DEMAND ? 1 : 0),
    [HAS_INDEMAND_SKILLS]);

  const getStepsCount = useCallback((user?: User | null): number =>
    BUILDER_STEPS.length -
    (user?.has_current_job_skills ? 0 : 1) -
    (!HAS_INDEMAND_SKILLS || !user?.has_in_demand_skills ? 1 : 0),
    [HAS_INDEMAND_SKILLS]);

  const getStepProgress = useCallback((user?: User | null, step = 1): number =>
    round(100 * (getStepNumber(user, step) - 1) / getStepsCount(user)),
    [getStepNumber, getStepsCount]);
    
  const builderStepsHook = useMemo(() => ({
    allStepsCompleted,
    noStepCompleted,
    isValidStep,
    getDefaultStep,
    getNextIncompleteStep,
    getStepNumber,
    getStepsCount,
    getStepProgress
  }), [
    allStepsCompleted, getDefaultStep, getNextIncompleteStep, getStepNumber, getStepProgress, getStepsCount,
    isValidStep, noStepCompleted
  ]);

  return builderStepsHook;
}

export default useBuilderSteps;
