import {
  memo, useCallback, useContext, useEffect, useMemo, useRef, useState, type FunctionComponent, type ChangeEvent
} from 'react';
import toString from 'lodash/toString';
import { Navigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import { isValidEmail } from '@empathco/ui-components/src/helpers/strings';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import useRedirects from '../helpers/redirect';
import { GlobalContext } from '../context';
import SplashText from '../elements/SplashText';
import Screen from '../v3/Screen';

const LoginScreen: FunctionComponent = () => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { getRedirectionPath } = useRedirects();

  const inputRef = useRef<HTMLInputElement>();
  const { current } = inputRef;

  const {
    token,
    user: { data: user, pending, failed },
    authEmail: { pending: pendingAuthEmail, failed: failedAuthEmail, params }, sendAuthEmail
  } = useContext(GlobalContext);

  const [email, setEmail] = useState<string>(params?.email || '');
  const [waiting, setWaiting] = useState(pendingAuthEmail === false && failedAuthEmail === false);

  const emailLabel = useMemo(() => formatMessage({ id: 'login.email' }), [formatMessage]);

  useEffect(() => {
    current?.focus?.();
  }, [current]);

  const handleEmailChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event?.preventDefault();
    setEmail(toString(event?.target?.value));
  }, []);

  const handleClick = useCallback(() => sendAuthEmail?.({
    email,
    onSuccess: () => setWaiting(true)
  }), [email, sendAuthEmail]);

  if (token && user && !pending && !failed) return <Navigate replace to={getRedirectionPath(user)}/>;

  return pending ? <LinearProgress/> : (
    <>
      <Screen appbar="empty" splash>
        <SplashText
            variant={waiting ? 'logging_in' : 'login'}
            name={email}
            onClick={waiting ? undefined : handleClick}
            pending={pendingAuthEmail}
            disabled={!isValidEmail(email)}
        >
          {!waiting && (
            <Box width="28rem" mx="auto" pt={4} px={6}>
              <TextField
                  inputRef={inputRef}
                  variant="standard"
                  color="secondary"
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  label={emailLabel}
                  onChange={handleEmailChange}
                  value={email}
                  disabled={pendingAuthEmail ? true : undefined}
              />
            </Box>
          )}
        </SplashText>
      </Screen>
      <ActionFailedAlert
          long
          message="splash.login.error"
          open={failedAuthEmail}
      />
    </>
  );
};

export default memo(LoginScreen);
