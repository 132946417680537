import { memo, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isSafeInteger from 'lodash/isSafeInteger';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { fontWeightBold } from '@empathco/ui-components/src/styles/themeOptions';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { AdminJob } from '../graphql/types';
import { Job } from '../models/job';
import { DataContext } from '../context';
import JobLevel from '../elements/JobLevel';
import RoleName from '../v3/RoleName';

type TechnicalCareerPathProps = {
  jobId?: number | null;
  disciplineId?: number | null;
  isEmployee?: boolean;
  onClick?: (code: string, job?: Job | AdminJob) => void;
}

const TechnicalCareerPathPropTypes = {
  // attributes
  jobId: PropTypes.number,
  disciplineId: PropTypes.number,
  isEmployee: PropTypes.bool,
  onClick: PropTypes.func
};

const TechnicalCareerPath: FunctionComponent<TechnicalCareerPathProps> = ({
  jobId,
  disciplineId: jobDisciplineId,
  isEmployee = false,
  onClick
}) => {
  const {
    careerPath: { data: path, pending, failed }, requireCareerPath
  } = useContext(DataContext);

  const [disciplineId, setDisciplineId] = useState<number>(jobDisciplineId || 0);

  useEffect(() => {
    if (jobDisciplineId && jobDisciplineId >= 1 && isSafeInteger(jobDisciplineId)) setDisciplineId(jobDisciplineId);
  }, [jobDisciplineId]);

  useEffect(() => {
    if (disciplineId) requireCareerPath?.({ discipline_id: disciplineId });
  }, [disciplineId, requireCareerPath]);

  const data = useMemo(() => map(path, (role) => {
    const { id, code, is_index_display, title, career_step } = role;
    const thisRole = jobId === id;
    return {
      selected: false,
      values: [
        /* eslint-disable react/jsx-key */
        thisRole ? (
          <Box fontWeight={fontWeightBold}>
            {code}
          </Box>
        ) : code,
        <Box textAlign="left">
          <RoleName
              isEmployee={isEmployee}
              bold={thisRole}
              code={is_index_display || onClick ? code : undefined}
              title={title}
              role={role}
              onClick={onClick}
          />
        </Box>,
        thisRole ? (
          <Box fontWeight={fontWeightBold}>
            <JobLevel role={role}/>
          </Box>
        ) : <JobLevel role={role}/>,
        thisRole ? (
          <Box fontWeight={fontWeightBold}>
            {career_step}
          </Box>
        ) : career_step
        /* eslint-enable react/jsx-key */
      ]
    };
  }), [path, jobId, isEmployee, onClick]);

  return (
    <CardSection>
      <DataTable
          tableSize="medium"
          titles={[
            'tcp.job_key',
            'tcp.job_title',
            'tcp.management_level',
            'tcp.career_step'
          ]}
          lastLeftAlignedTitle={1}
          empty="jobs_index.empty"
          data={data}
          pending={pending || !path}
          failed={failed}
      />
    </CardSection>
  );
};

TechnicalCareerPath.propTypes = TechnicalCareerPathPropTypes;

export default memo(TechnicalCareerPath);
