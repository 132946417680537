import { gql } from '@apollo/client';
// local imports
import { API_MY_SELECTED_ACTIVITIES } from '../config/api';

export const MY_ACTIVITIES_QUERY = gql`
  query MyActivities(
    $limit: Int
    $offset: Int
  ) {
    myActivities(
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "EmployeeActivitiesPayload",
      path: "${API_MY_SELECTED_ACTIVITIES}?{args}"
    ) {
      count
      results {
        id
        status
        created_at
        updated_at
        activity {
          id
          code
          name
          activity_type
          description
          activity_link
          development_area
          field_support
          store
          education_style
          is_complete
          completed_at
        }
        skills {
          id
          abbr
          title
          skill_proficiency_level
        }
      }
    }
  }
`;
