import { createContext } from 'react';
// local imports
import { getInitialActionState } from '../helpers/context';
import { IGlobalState } from './global';
import { ILookupsState, initialLookupsState } from './lookups';
import { ISupervisorState, initialSupervisorState } from './supervisor';
import { IDataState } from './dataContext';
import { initialDataState } from './dataReducer';

export const initialGlobalState: IGlobalState = {
  online: true,
  fontsLoaded: false,
  token: null,
  authErrorCode: null,
  user: {
    data: null,
    pending: true, // we are waiting for `useEffect` hook that checks `isLoggedIn()`
    failed: null,
    params: null
  },
  paths: {},
  authEmail: getInitialActionState()
};

export const GlobalContext = createContext<IGlobalState>(initialGlobalState);

export const DataContext = createContext<IDataState>(initialDataState as unknown as IDataState);

export const SupervisorContext = createContext<ISupervisorState>(initialSupervisorState);

export const LookupContext = createContext<ILookupsState>(initialLookupsState);
