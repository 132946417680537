import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { type AxiosResponse } from 'axios';
// EmPath UI Components
import { getCurrentSeconds } from '@empathco/ui-components/src/helpers/datetime';
// local imports
import { getJobStatus, isCompleted, isFailed, isInProgress } from '../constants/jobStatuses';
import { getDataStatus, hasComputed, isComputing } from '../constants/dataStatuses';
import { JobStatusObject } from '../models/jobStatusObject';
import { ContextObject } from '../models/contextEntity';
import { axiosInstance as axios, API_MY_DATA_STATUS } from '../config/api';
import { POLLING_INTERVAL, POLLING_ATTEMPTS } from '../config/params';
import { getFetchedObjectState, getInitialObjectState, getPendingObjectState, getRequestHeaders } from '../helpers/context';
import { DataContext, GlobalContext } from '../context';
import { JobStatusParams } from '../context/dataContext';

type JobStatusContextObject = ContextObject<JobStatusObject, JobStatusParams>;
const JOB_STATUS_NULL: JobStatusContextObject = getInitialObjectState();

// eslint-disable-next-line complexity
function useStatusPoller(jobId?: number | null, url: string = API_MY_DATA_STATUS, omitJobId = false) {
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const { token } = useContext(GlobalContext);
  const {
    dataStatus: { data: dataStatus, pending: dataPending, failed: dataFailed }, requireDataStatus
  } = useContext(DataContext);

  // eslint-disable-next-line react/hook-use-state
  const [
    { data: jobStatus, pending: jobPending, failed: jobFailed, params },
    setJobStatus
  ] = useState<JobStatusContextObject>(JOB_STATUS_NULL);

  const [attempt, setAttempt] = useState(isNull(jobId) ? getCurrentSeconds() : 1);

  const active = !isUndefined(jobId);

  useLayoutEffect(() => {
    // clear attempts count on `jobId` or `active` state change
    setAttempt(isNull(jobId) ? getCurrentSeconds() : 1);
  }, [jobId, active]);

  const thisJob = params?.id === jobId;
  const status = (active && (jobId
    ? getJobStatus(thisJob ? jobStatus : undefined)
    : getDataStatus(dataStatus)
  )) || '';

  const computing = active && (jobId ? isInProgress(status) && attempt <= POLLING_ATTEMPTS : isComputing(status));
  const computed = active && (jobId ? isCompleted(status) : hasComputed(status));

  useEffect(() => () => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }, []);

  useEffect(() => {
    if (token && active && jobId) {
      setJobStatus(getPendingObjectState<JobStatusObject, JobStatusParams>({ id: jobId }));
      axios.request<JobStatusParams, AxiosResponse<JobStatusObject>>({
        method: 'GET',
        url,
        ...omitJobId ? {} : { params: { id: jobId } },
        headers: getRequestHeaders(token)
      })
      .then(({ status: statusCode, data }) => setJobStatus(getFetchedObjectState<JobStatusObject, JobStatusParams>(
        (statusCode === 200 && data) || null,
        { id: jobId }
      )))
      .catch(() => setJobStatus(getFetchedObjectState<JobStatusObject, JobStatusParams>(null, { id: jobId })));
    }
  }, [active, attempt, jobId, token, url, omitJobId]);

  useEffect(() => {
    if (token && active && !jobId) requireDataStatus?.({ timestamp: attempt });
  }, [active, token, jobId, attempt, requireDataStatus]);

  useEffect(() => {
    if (computing) {
      if (!interval.current) interval.current = setInterval(() => setAttempt((prev) => (prev || 0) + 1), POLLING_INTERVAL);
    } else if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [computing]);

  return useMemo(() => ({
    status,
    data: active && jobId && thisJob ? jobStatus : undefined,
    pending: active && (jobId ? thisJob && jobPending : dataPending),
    failed: active && (jobId
      ? thisJob && (jobFailed || isFailed(status) || attempt > POLLING_ATTEMPTS)
      : dataFailed
    ),
    computing,
    computed
  }), [
    active, attempt, computed, computing, dataFailed, dataPending,
    jobFailed, jobId, jobPending, jobStatus, status, thisJob
  ]);
}

export default useStatusPoller;
