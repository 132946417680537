import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const DEV_PLAN_ADVISORS_QUERY = gql`
  query DevPlanAdvisors(
    $devplan_id: Int!
    $type: DevPlanAdvisorType!
    $exclude_ids: String!
    $target_skill_ids: String!
  ) {
    devplanAdvisors(
      devplan_id: $devplan_id,
      type: $type,
      exclude_ids: $exclude_ids,
      target_skill_ids: $target_skill_ids
    )
    @rest(
      type: "DevPlanAdvisorsPayload",
      path: "${API_DEV_PLAN}{args.type}/?target_skill_ids={args.target_skill_ids}&exclude_ids={args.exclude_ids}"
    ) {
      count
      results {
        id
        code
        first_name
        last_name
        email
        is_selected # local field used in DevPlanEditor
        current_job {
          id
          title
          code
        }
        location {
          id
          title
          city
          state
          country
        }
        advisory_skills {
          id
          title
          abbr
          current_level
        }
      }
    }
  }
`;
