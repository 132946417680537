import { memo, type ReactNode, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { AdminJob } from '../graphql/types';
import { Job } from '../models/job';
import { JobSortExt } from '../constants/jobSort';
import useNonReducedUI from '../constants/managementLevel';
import useCustomerSettings from '../config/customer';
import { hasFilters, JobsIndexFiltersComponent } from './JobsIndexFilters';
import { hasPagination, PaginationControlsComponent } from './PaginationControls';
import ComputeUpdatesOverlay from '../v3/ComputeUpdatesOverlay';
import RoleCard from '../v3/RoleCard';
import JobsTable from '../v3/JobsTable';
import JobMatch from '../widgets/JobMatch';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { topPadding, bottomPadding, dirtyBox } from '@empathco/ui-components/src/styles/modules/ItemsGrid.module.scss';

type RolesGridProps = {
  roles?: (Job | AdminJob)[] | null;
  currentRole?: Job | null;
  pending?: boolean | null;
  failed?: boolean | null;
  dirty?: boolean | null;
  title?: string | null;
  filters?: ReactNode | ReactNode[] | null;
  pagination?: ReactNode | ReactNode[] | null;
  dark?: boolean | null;
  table?: boolean | null;
  sortBy?: JobSortExt | null;
  direction?: boolean | null;
  changeSort?: ((sort: JobSortExt, direction: boolean) => void) | null;
  sortDisabled?: boolean | null;
  // in Supervisor mode `onClick` is fired only for jobs with management_level >= 1
  onClick?: ((code: string, job?: Job | AdminJob) => void) | null;
  withOpenReqsPopup?: boolean;
  withReloading?: boolean;
  admin?: boolean;
  hrbp?: boolean;
  supervisor?: boolean;
  matchRate?: number | null;
  matchRatePending?: boolean | null;
}

const RolesGridPropTypes = {
  // attributes
  roles: PropTypes.array,
  currentRole: PropTypes.object as Validator<Job>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  dirty: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  pagination: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dark: PropTypes.bool,
  table: PropTypes.bool,
  sortBy: PropTypes.string as Validator<JobSortExt>,
  direction: PropTypes.bool,
  changeSort: PropTypes.func,
  sortDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  withOpenReqsPopup: PropTypes.bool,
  withReloading: PropTypes.bool,
  admin: PropTypes.bool,
  hrbp: PropTypes.bool,
  supervisor: PropTypes.bool,
  matchRate: PropTypes.number,
  matchRatePending: PropTypes.bool
};

// eslint-disable-next-line complexity
const RolesGrid: FunctionComponent<RolesGridProps> = ({
  roles,
  currentRole,
  pending = false,
  failed = false,
  dirty = false,
  title,
  filters,
  pagination,
  dark = false,
  table = false,
  sortBy,
  direction,
  changeSort,
  sortDisabled = false,
  onClick,
  withOpenReqsPopup = false,
  withReloading = false,
  admin = false,
  hrbp = false,
  supervisor = false,
  matchRate,
  matchRatePending
}) => {
  const { HAS_JOBS_CHART } = useCustomerSettings();
  const { showNonReducedUI } = useNonReducedUI();

  const withFilters = hasFilters(filters as JobsIndexFiltersComponent);
  const withPagination = hasPagination(pagination as PaginationControlsComponent);
  const loading = withReloading ? pending && !roles : pending || !roles;
  const reloading = Boolean(withReloading && pending && roles);

  const filtersContent = withFilters ? (
    <CardSection
        flex
        top={(!dark && (
          // not JobMatch component is displayed:
          failed || loading || !roles || size(roles) < 1 || !HAS_JOBS_CHART || !currentRole
        )) || (
          changeSort && table && !title
        )}
    >
      {filters}
    </CardSection>
  ) : filters;

  const content = (
    <>
      {(changeSort && table && (
        <JobsTable
            admin={admin}
            hrbp={hrbp}
            supervisor={supervisor}
            onClick={onClick}
            withOpenReqsPopup={withOpenReqsPopup}
            title={title}
            data={roles}
            pending={loading}
            failed={failed}
            sortBy={sortBy}
            direction={direction}
            changeSort={changeSort}
            disabled={sortDisabled}
        />
      )) || (failed || loading || !roles || size(roles) < 1
        ? (
          <Box
              className={clsx({
                [topPadding]: withFilters && (!dark || (changeSort && table)),
                [bottomPadding]: withPagination && (!dark || (changeSort && table)),
                [dirtyBox]: dirty
              })}
          >
            {(failed && <FetchFailedAlert flat/>) ||
            ((loading || !roles) && <LoadingPlaceholder flat/>) || (
              <>
                {withFilters ? <Divider/> : undefined}
                <Alert severity="info" variant="standard">
                  <FormattedMessage id="jobs_index.empty"/>
                </Alert>
              </>
            )}
          </Box>
        ) : (HAS_JOBS_CHART && currentRole && (
          <>
            {withFilters ? <Divider/> : undefined}
            <JobMatch
                title={title}
                data={roles as Job[]}
                currentRole={currentRole}
            />
          </>
        )) || (
          <CardDeck dark={dark}>
            {title ? (
              <BoxTypography
                  width="100%"
                  flexGrow={1}
                  px={2}
                  pb={2}
                  variant="body1"
                  fontStyle="italic"
                  color="text.label"
              >
                <FormattedMessage id={title} defaultMessage={title}/>
              </BoxTypography>
            ) : undefined}
            {map(roles, (role) => {
              const forceOpenReqsPopup = !admin && (supervisor || hrbp) && !showNonReducedUI(role);
              return (
                <RoleCard
                    key={role.id}
                    onClick={forceOpenReqsPopup ? undefined : onClick}
                    withOpenReqsPopup={withOpenReqsPopup || forceOpenReqsPopup}
                    role={role}
                    isEmployee={!supervisor && !hrbp && !admin}
                    depersonalized={hrbp || admin}
                    matchRate={matchRate}
                    matchRatePending={matchRatePending}
                />
              );
            })}
          </CardDeck>
        )
      )}
      {withPagination ? (
        <CardSection bottom={!dark || (changeSort && table)} flex>
          {pagination}
        </CardSection>
      ) : pagination}
    </>
  );

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" position="relative">
      {filtersContent}
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      {dirty ? <ComputeUpdatesOverlay/> : undefined}
    </Box>
  );
};

RolesGrid.propTypes = RolesGridPropTypes;

export default memo(RolesGrid);
