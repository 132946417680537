import { memo, useContext, useEffect, useState, useLayoutEffect, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import { useLazyQuery } from '@apollo/client';
// EmPath UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
// local imports
import { ADMIN_JOBS_QUERY } from '../graphql/AdminJobs';
import {
  AdminJob, AdminJobsDocument, AdminJobsQueryVariables, AdminJobsSort, JobCategory, SortDirection
} from '../graphql/types';
import { ADMIN_JOBS_SORT, DEFAULT_ADMIN_JOBS_DIRECTION } from '../graphql/customTypes';
import { Job } from '../models/job';
import { ROLE_SCOPE_TECH } from '../constants/scopes';
import { JobSortExt } from '../constants/jobSort';
import useCustomerSettings from '../config/customer';
import { DataContext } from '../context';
import RolesGrid from '../v3/RolesGrid';
import JobsIndexFilters, { JobsIndexFilterValues } from '../v3/JobsIndexFilters';
import PaginationControls from '../v3/PaginationControls';
import AdminJobDialog from '../widgets/AdminJobDialog';

type AdminJobsProps = {
  // for Storybook only
  testPending?: boolean;
}

const AdminJobsPropTypes = {
  testPending: PropTypes.bool
};

const AdminJobs: FunctionComponent<AdminJobsProps> = ({
  testPending
}) => {
  const { HAS_INDEMAND_SKILLS, HAS_JOBS_SCOPE } = useCustomerSettings();
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);
  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);

  // lazy load jobs
  const { query: getJobs, pending: pendingJobs, failed, count, results: jobs, variables: prevVars } = useQueryCounted({
    data: undefined as unknown as AdminJob,
    key: 'adminJobs',
    lazyQuery: useLazyQuery(ADMIN_JOBS_QUERY as typeof AdminJobsDocument)
  });
  const pending = pendingJobs || testPending;

  const [filters, setFilters] = useState<JobsIndexFilterValues>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const [defaultSort] = ADMIN_JOBS_SORT;

  // eslint-disable-next-line react/hook-use-state
  const [{ sort, dir }, setSort] = useState<{
    sort: AdminJobsSort;
    dir: boolean;
  }>({
    sort: defaultSort,
    dir: DEFAULT_ADMIN_JOBS_DIRECTION[defaultSort]
  });
  useLayoutEffect(() => {
    setSort({ sort: defaultSort, dir: DEFAULT_ADMIN_JOBS_DIRECTION[defaultSort] });
  }, [defaultSort]);

  const [job, setJob] = useState<AdminJob>();

  const handleSort = useCallback((sortValue?: JobSortExt, newDir?: boolean | null) => {
    if (sortValue) setSort({
      sort: sortValue as AdminJobsSort,
      dir: isBoolean(newDir) ? newDir : DEFAULT_ADMIN_JOBS_DIRECTION[sortValue as AdminJobsSort]
    });
  }, []);

  const handleClick = useCallback((_code: string, role?: Job | AdminJob) => {
    setJob(role as AdminJob);
  }, []);

  const handleClose = useCallback(() => {
    setJob(undefined);
  }, []);

  useEffect(() => {
    if (settingsLoaded && filters && getJobs && !isNil(pageSize)) {
      const variables: AdminJobsQueryVariables = {
        org_id: filters.org_id || null,
        country_id: filters.country_id || null,
        state_id: filters.state_id || null,
        location_id: filters.location_id || null,
        management_level: filters.management_level || null,
        supervisory_jobs_only: filters.supervisory_jobs_only || null,
        open_reqs_only: filters.open_reqs_only || null,
        job_category: filters.scope === ROLE_SCOPE_TECH ? JobCategory.technology : (filters.scope as JobCategory) || null,
        direction: dir ? SortDirection.ascending : SortDirection.descending,
        sort_by: sort,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getJobs({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort, dir, pageSize, currentPage, getJobs, settingsLoaded]); // only filter values are monitored here

  const pendingAll = !settingsLoaded || !filters || pending;
  const loading = pendingAll || !jobs;
  const failedAny = failed || failedSettings;
  const disabled = loading || failedAny || pendingSettingsUpdate;

  return (
    <>
      <RolesGrid
          admin
          table
          withOpenReqsPopup
          withReloading
          roles={jobs}
          pending={loading}
          failed={failedAny}
          onClick={handleClick}
          sortBy={sort as JobSortExt}
          direction={dir}
          changeSort={handleSort}
          filters={(
            <JobsIndexFilters
                settingsId="admin_jobs"
                visible={settingsLoaded}
                dropdownsFirst
                onChange={setFilters}
                disabled={pendingAll}
                withOrg={HAS_INDEMAND_SKILLS}
                withScope={HAS_JOBS_SCOPE}
                withSupervisory
                withCategory={!HAS_JOBS_SCOPE}
                withLadder
                withCountry
                withState
                withOpenReqs
                withLevel
            />
          )}
          pagination={(
            <PaginationControls
                settingsId="admin_jobs"
                loaded={Boolean(jobs)}
                pending={pending}
                loading={pendingAll}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={disabled}
            />
          )}
      />
      <AdminJobDialog
          job={job}
          onClick={handleClick}
          onClose={handleClose}
      />
    </>
  );
};

AdminJobs.propTypes = AdminJobsPropTypes;

export default memo(AdminJobs);
