import { memo, useState, useCallback, useEffect, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
// EmPath UI Components
import { fontWeightMedium, themeOptions } from '@empathco/ui-components/src/styles/themeOptions';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
// local imports
import { JobWithSkills, Opportunity } from '../graphql/types';
import { ILookupSkill } from '../models/lookupItem';
import { Skill, SkillLevel, SKILL_LEVEL_FIRST } from '../models/skill';
import JobSearch, { JobLookupItem } from '../v3/JobSearch';
import OpportunitySearch from '../v3/OpportunitySearch';
import SkillLevelDialog from './SkillLevelDialog';
import SkillSearch from './SkillSearch';
// SCSS imports
import { section, button, longButton } from './AddJobDialog.module.scss';

const sxDisabledOpacity = { opacity: themeOptions.palette.action.disabledOpacity };

type AddJobDialogProps = {
  exclude?: number[] | null;
  isOpen: boolean;
  currentJob?: JobWithSkills | null;
  onAdd: (job: JobLookupItem) => void;
  onAddSkill?: (skill: Skill, level: SkillLevel) => void;
  onAddOpportunity?: (opp: Opportunity) => void;
  onCancel: () => void;
  disabled?: boolean | null;
  devplan?: boolean;
  isMyPlan?: boolean;
  redeployment?: boolean;
  buttonText?: string;
  // for Storybook only
  testCurrentJob?: boolean;
};

const AddJobDialogPropTypes = {
  // attributes
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  isOpen: PropTypes.bool.isRequired,
  currentJob: PropTypes.object as Validator<JobWithSkills>,
  onAdd: PropTypes.func.isRequired,
  onAddSkill: PropTypes.func,
  onAddOpportunity: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  devplan: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  redeployment: PropTypes.bool,
  buttonText: PropTypes.string,
  testCurrentJob: PropTypes.bool
};

// eslint-disable-next-line complexity
const AddJobDialog: FunctionComponent<AddJobDialogProps> = ({
  exclude,
  isOpen,
  currentJob,
  onAdd,
  onAddSkill,
  onAddOpportunity,
  onCancel,
  disabled = false,
  devplan = false,
  isMyPlan = false,
  redeployment = false,
  buttonText,
  testCurrentJob = false
}) => {
  const withSkills = Boolean(onAddSkill);
  const withOpps = Boolean(devplan && onAddOpportunity);
  const [curJob, setCurJob] = useState(testCurrentJob);
  const [job, setJob] = useState<JobLookupItem | null>(null);
  const [opp, setOpp] = useState<Opportunity | null>(null);
  const [visible, setVisible] = useState(isOpen);

  const [open, setOpen] = useState(false);
  const [skl, setSkl] = useState<Skill | null>(null);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setCurJob(testCurrentJob);
      setJob(null);
      setOpp(null);
    }
  }, [isOpen, testCurrentJob]);

  const handleCurJob = useCallback((curJobSelected: boolean) => {
    setCurJob(curJobSelected);
    if (curJobSelected) {
      setJob(null);
      setOpp(null);
    }
  }, []);

  const handleJob = useCallback((selectedJob: JobLookupItem | null) => {
    setJob(selectedJob);
    if (selectedJob) {
      setCurJob(false);
      setOpp(null);
    }
  }, []);

  const handleOpp = useCallback((selectedOpp: Opportunity | null) => {
    setOpp(selectedOpp);
    if (selectedOpp) {
      setCurJob(false);
      setJob(null);
    }
  }, []);

  const handleConfirm = useCallback(() => {
    if (curJob) onAdd(currentJob as JobLookupItem);
    else if (job) onAdd(job);
    else if (opp) onAddOpportunity?.(opp);
  }, [curJob, job, opp, currentJob, onAdd, onAddOpportunity]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setVisible(false);
  } }), []);

  const handleSkill = useCallback((selectedSkill: Skill | ILookupSkill | null) => {
    setSkl(selectedSkill
      ? { ...selectedSkill, inferred_level: SKILL_LEVEL_FIRST, is_inference_newer: true } as Skill
      : null);
    setOpen(Boolean(selectedSkill));
  }, []);

  const handleAddSkill = useCallback((level: SkillLevel | null) => {
    if (skl?.id && level) onAddSkill?.(skl, level);
    setOpen(false);
  }, [onAddSkill, skl]);

  const handleSkillClose = useCallback(() => setOpen(false), []);
  const handleSkillExited = useCallback(() => setSkl(null), []);

  const jobSearchMain = visible ? (
    <JobSearch
        variant={redeployment || devplan ? 'search' : undefined}
        withSkills
        fullWidth
        value={job}
        onChange={handleJob}
        exclude={exclude}
        disabled={disabled}
    />
  ) : undefined;

  const jobSearch = jobSearchMain && currentJob ? (
    <Box flex="1 0 0" display="flex" flexDirection="column">
      {jobSearchMain}
      <Box display="flex" alignItems="center">
        <CheckboxButton small checked={curJob} onChange={handleCurJob} disabled={disabled ? true : undefined}/>
        <Box
            color={curJob ? 'secondary.main' : undefined}
            fontWeight={fontWeightMedium}
            sx={disabled ? sxDisabledOpacity : undefined}
        >
          {currentJob.title}
        </Box>
        <BoxTypography pl={1.25} color="info.light" fontStyle="italic" variant="body2">
          <FormattedMessage id="common.current_role_supv"/>
        </BoxTypography>
      </Box>
    </Box>
  ) : jobSearchMain;

  return (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth={devplan && !withOpps ? 'sm' : 'md'}
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={onCancel}
          TransitionProps={transitionProps}
      >
        <CloseIconButton small onClick={onCancel}/>
        <CardTitle
            title={(redeployment && 'hr.redeployment.search_job') ||
              (devplan && `hr.dev_plan.search_target${withOpps ? '' : '.opp_off'}`) ||
              (withSkills && 'hr.teambuilder.add_role_or_skill') ||
              'hr.teambuilder.add_role'}
            withDivider
        />
        <CardSection flex={!withSkills && !withOpps} className={withSkills || withOpps ? undefined : section}>
          {visible ? (
            (withSkills || withOpps) && (
              <GridBox py={withOpps ? 2 : 1} container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={5} container alignItems="center" justifyContent="center">
                  {jobSearch}
                </Grid>
                <GridBox
                    item
                    py={1}
                    pb={currentJob ? 5 : undefined}
                    xs={12} md={2}
                    container alignItems="center" justifyContent="center"
                >
                  <FormattedMessage id="hr.talentfinder.or"/>
                </GridBox>
                <Grid item xs={12} md={5} pb={currentJob ? 5 : undefined} container alignItems="center" justifyContent="center">
                  {withSkills ? (
                    <SkillSearch
                        short
                        fullWidth
                        onChange={handleSkill}
                        disabled={disabled}
                    />
                  ) : undefined}
                  {withOpps ? (
                    <OpportunitySearch
                        fullWidth
                        isMy={isMyPlan}
                        value={opp}
                        onChange={handleOpp}
                        disabled={disabled}
                    />
                  ) : undefined}
                </Grid>
              </GridBox>
            )) || jobSearch : undefined}
          <Box
              pt={withSkills || withOpps ? 2.5 : undefined}
              pl={withSkills || withOpps ? undefined : 3}
              pb={currentJob ? 5 : undefined}
              display="flex"
              alignItems="center"
              justifyContent="center"
          >
            <Button
                onClick={handleConfirm}
                color="primary"
                variant="contained"
                disableElevation
                disabled={disabled || ((!withOpps || !opp) && !job && !curJob)}
                className={redeployment ? longButton : button}
            >
              <FormattedMessage
                  id={buttonText || (redeployment && 'hr.redeployment.button.create') ||
                    (devplan && 'hr.dev_plan.button.select') || 'common.button.add'}
              />
            </Button>
          </Box>
        </CardSection>
      </Dialog>
      {withSkills && skl ? (
        <SkillLevelDialog
            isOpen={open}
            skill={skl}
            plain
            depersonalized
            onUpdate={handleAddSkill}
            onCancel={handleSkillClose}
            onExited={handleSkillExited}
            disabled={disabled}
        />
      ) : undefined}
    </>
  );
};

AddJobDialog.propTypes = AddJobDialogPropTypes;

export default memo(AddJobDialog);
