import { memo, forwardRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import { useLazyQuery, useMutation, type ApolloCache } from '@apollo/client';
// EmPath UI Components
import { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { MY_ACTIVITIES_QUERY } from '../graphql/MyActivities';
import { EMPLOYEE_ACTIVITIES_QUERY } from '../graphql/EmployeeActivities';
import { UNSELECT_ACTIVITY } from '../graphql/UnselectActivity';
import { UPDATE_ACTIVITY } from '../graphql/UpdateActivity';
import {
  SkillActivity, EmployeeActivity, EmployeeActivityStatus,
  MyActivitiesDocument, MyActivitiesQueryVariables,
  EmployeeActivitiesDocument, EmployeeActivitiesQueryVariables,
  UnselectActivityDocument, UpdateActivityDocument
} from '../graphql/types';
import PaginationControls from '../v3/PaginationControls';
import ActivityCard from '../v3/ActivityCard';
import CardsGrid from '../v3/CardsGrid';

// TODO: optimistic update
const updateActivities = (cache: ApolloCache<unknown>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myActivities' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkill' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
  // only if Manager can see self Skills Profile in Manager UI:
  // cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeActivities' });
};

type EmployeeSelectedActivitiesProps = {
  supervisor?: boolean | null;
  uid?: string | null;
  // for Storybook only
  testPending?: boolean;
}

const EmployeeSelectedActivitiesPropTypes = {
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  testPending: PropTypes.bool
};

const EmployeeSelectedActivities = forwardRef<HTMLDivElement, EmployeeSelectedActivitiesProps>(({
  supervisor = false,
  uid,
  testPending = false
}, ref) => {
  // lazy load selected activities
  const myActivities = useQueryCounted({
    data: undefined as unknown as EmployeeActivity,
    key: 'myActivities',
    lazyQuery: useLazyQuery(MY_ACTIVITIES_QUERY as typeof MyActivitiesDocument)
  });
  const employeeActivities = useQueryCounted({
    data: undefined as unknown as EmployeeActivity,
    key: 'employeeActivities',
    lazyQuery: useLazyQuery(EMPLOYEE_ACTIVITIES_QUERY as typeof EmployeeActivitiesDocument)
  });
  const {
    query: getActivities, pending: pendingActivities, failed, count, results, variables: prevVars
  } = supervisor ? employeeActivities : myActivities;
  const pending = pendingActivities || testPending;

  const { mutate: unselectActivity, loading: unselectPending, failed: unselectFailed } = useMutationMethod({
    mutation: useMutation(UNSELECT_ACTIVITY as typeof UnselectActivityDocument)
  });
  const { mutate: updateActivity, loading: updatePending, failed: updateFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_ACTIVITY as typeof UpdateActivityDocument)
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const [pendingId, setPendingId] = useState<number>();

  const componentProps: Partial<GetComponentProps<typeof ActivityCard>> = useMemo(() => ({
    supervisor: supervisor ? true : undefined,
    withoutTag: true,
    withCompletedState: true,
    isSelected: !supervisor,
    ...supervisor ? {} : {
      onRemove: (activity: SkillActivity) => {
        const { id: activity_id, employee_activity_id, is_complete } = activity;
        if (is_complete || !activity_id || !employee_activity_id) return;
        setPendingId(activity.id);
        unselectActivity({
          variables: { activity_id: employee_activity_id },
          // TODO: optimistic response
          update: updateActivities,
          onCompleted: () => setPendingId(undefined),
          onError: () => setPendingId(undefined)
        });
      },
      onComplete: (activity: SkillActivity) => {
        const { id: activity_id, employee_activity_id, is_complete } = activity;
        if (is_complete || !activity_id || !employee_activity_id) return;
        setPendingId(activity_id);
        updateActivity({
          variables: { activity_id: employee_activity_id, input: { status: EmployeeActivityStatus.completed } },
          // TODO: optimistic response
          update: updateActivities,
          onCompleted: () => setPendingId(undefined),
          onError: () => setPendingId(undefined)
        });
      },
      onSelectPendingId: unselectPending ? pendingId : undefined,
      onCompletePendingId: updatePending ? pendingId : undefined
    }
  }), [pendingId, unselectPending, updatePending, supervisor, unselectActivity, updateActivity]);

  const activities = useMemo(() => results ? map(results, ({ id, activity, status, skills, updated_at }) => ({
    ...activity,
    employee_activity_id: id,
    skills,
    is_selected: false,
    is_complete: status === EmployeeActivityStatus.completed,
    completed_at: status === EmployeeActivityStatus.completed ? updated_at : undefined
  })) : results, [results]);

  useEffect(() => {
    if (pageSize && getActivities) {
      const variables: MyActivitiesQueryVariables & EmployeeActivitiesQueryVariables = {
        ...supervisor && uid ? { selected_employee_id: toLower(uid) } : {},
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getActivities({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, supervisor, uid, getActivities]); // ignoring `prevVars` changes

  return (
    <>
      <CardsGrid
          ref={ref}
          items={activities}
          variant={supervisor ? 'dark' : 'white'}
          withReloading
          pending={pending}
          failed={failed}
          // disabled={...}
          blendNotFound
          blendPagination
          component={ActivityCard}
          ComponentProps={componentProps}
          notFoundMessage="hr.dev_plan.selected_activities.empty"
          pagination={(
            <PaginationControls
                settingsId={supervisor ? 'supv_activities' : 'activities'}
                loaded={Boolean(activities)}
                pending={pending}
                loading={pending}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={pending || failed}
                totalMessage="hr.dev_plan.selected_activities.pagination"
            />
          )}
      />
      <ActionFailedAlert
          message="skill.development.activity.error"
          open={unselectFailed || updateFailed}
      />
    </>
  );
});

EmployeeSelectedActivities.displayName = 'EmployeeSelectedActivities';

EmployeeSelectedActivities.propTypes = EmployeeSelectedActivitiesPropTypes;

export default memo(EmployeeSelectedActivities);
