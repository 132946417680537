import { memo } from 'react';
// local imports
import { CustomerLogoType } from '../../helpers/types';
// Media and SCSS imports
import { ReactComponent as Logo } from './logo.svg';
import { logo } from './CustomerLogo.module.scss';

const CustomerLogo: CustomerLogoType = () => <Logo className={logo}/>;

export default memo(CustomerLogo);
