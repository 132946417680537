import { gql } from '@apollo/client';
// local imports
import { API_DA_CHARTS_AVAILABILITY } from '../config/api';

export const DA_CHARTS_AVAILABILITY_QUERY = gql`
  query DAChartsAvailability {
    daChartsAvailability
    @rest(
      type: "ChartsAvailabilityPayload",
      path: "${API_DA_CHARTS_AVAILABILITY}"
    ) {
      results {
        # Manager Dashboard only:
        in_demand_skills
        targeted_skills
        targeted_jobs
        most_frequent_jobs
        # Manager Dashboard + Dashboard Analytics:
        employeeconfirmed
        # Dashboard Analytics only:
        avgnumberskill
        closematch
        skillorcourse
        employeevelocity
        currentrolematch
        jobrelatedskill
        skillsupplydemand
        skillgrowth
        skilldevelopment
        mosteffectivecourse
        coursecompletion
        mosteffectivementors
        skillinsight
        jobmovement
        jobhire
        jobmovementskill
        employeevelocityupskill
      }
    }
  }
`;
