/* eslint-disable max-lines */
import {
  forwardRef, memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState, type Component
} from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// EmPath UI Components
import { ReactComponent } from '@empathco/ui-components/src/helpers/react';
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import ExportButton from '@empathco/ui-components/src/elements/ExportButton';
import SkillLevelSelector from '@empathco/ui-components/src/elements/SkillLevelSelector';
import ScopeSelector from '@empathco/ui-components/src/elements/ScopeSelector';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
// local imports
import { isValidRoleScope, RoleScope, ROLE_SCOPES } from '../constants/scopes';
import { EmployeeManagementLevel } from '../constants/managementLevel';
import { SkillLevel } from '../models/skill';
import { ILookupItem } from '../models/lookupItem';
import useCustomerSettings from '../config/customer';
import useModels, { sanitizeLevel, sanitizeLookup, sanitizeMatchRate } from '../helpers/models';
import { getSettingsBoolValue, getSettingsIntValue, getSettingsNumValue, getSettingsStrValue } from '../helpers/context';
import { DataContext, LookupContext } from '../context';
import { JobsIndexParams } from '../context/dataContext';
import MatchRateSlider from '../elements/MatchRateSlider';
// SCSS imports
import { openReqsFilter, supervisoryFilter, matchRateFilter } from './JobsIndexFilters.module.scss';

export interface JobsParams /* extends LocationParams, PaginationParams */ {
  scope?: RoleScope | null;
  job_category_id?: number | null;
  career_ladder_id?: number | null;
  org_id?: number | null;
  open_reqs_only?: boolean | null;
  supervisory_jobs_only?: boolean | null;
  country_id?: number | null;
  state_id?: number | null;
  location_id?: number | null;
  management_level?: EmployeeManagementLevel | null;
  limit?: number | null;
  offset?: number | null;
}

const JOBS_FILTERS_DISPLAY_NAME = 'JobsIndexFilters' as const;

export type JobsIndexFiltersComponent = ReactComponent<JobsIndexFiltersProps>;

export const hasFilters = (filters?: JobsIndexFiltersComponent | null) => {
  if (!filters) return false;
  if (filters.type?.displayName !== JOBS_FILTERS_DISPLAY_NAME) return true;
  return Boolean(filters.props?.visible);
};

const NO_LOOKUP_ITEMS = [] as ILookupItem[];

export type JobsIndexFilterValues = Omit<JobsIndexParams, 'sort_by' | 'direction' | 'limit' | 'offset'> & JobsParams;

type JobsIndexFiltersProps = {
  settingsId?: string;
  init?: JobsIndexFilterValues | null;
  visible: boolean;
  onChange: (filters: JobsIndexFilterValues) => void;
  onExport?: (format: ExportFormat) => void;
  dropdownsFirst?: boolean | null;
  showOpenReqs?: boolean;
  resetFilters?: boolean;
  withOpenReqs?: boolean;
  withLevel?: boolean;
  withScope?: boolean;
  withSupervisory?: boolean;
  withMatchRate?: boolean;
  withOrg?: boolean;
  withCategory?: boolean;
  withLadder?: boolean;
  withCountry?: boolean;
  withState?: boolean;
  domesticCountriesOnly?: boolean;
  isInternational?: boolean | null;
  disabled?: boolean | null;
  exportPending?: boolean | null;
  exportDisabled?: boolean | null;
}

const JobsIndexFiltersPropTypes = {
  // attributes
  settingsId: PropTypes.string,
  init: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  dropdownsFirst: PropTypes.bool,
  showOpenReqs: PropTypes.bool,
  resetFilters: PropTypes.bool,
  withOpenReqs: PropTypes.bool,
  withLevel: PropTypes.bool,
  withScope: PropTypes.bool,
  withSupervisory: PropTypes.bool,
  withMatchRate: PropTypes.bool,
  withOrg: PropTypes.bool,
  withCategory: PropTypes.bool,
  withLadder: PropTypes.bool,
  withCountry: PropTypes.bool,
  withState: PropTypes.bool,
  domesticCountriesOnly: PropTypes.bool,
  isInternational: PropTypes.bool,
  disabled: PropTypes.bool,
  exportPending: PropTypes.bool,
  exportDisabled: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const JobsIndexFilters = forwardRef<Component<BoxProps>, JobsIndexFiltersProps>(({
  settingsId,
  init,
  visible,
  onChange,
  onExport,
  dropdownsFirst = false,
  showOpenReqs: parentShowOpenReqs,
  resetFilters = false,
  withOpenReqs = false,
  withLevel = false,
  withScope = false,
  withSupervisory = false,
  withMatchRate = false,
  withOrg = false,
  withCategory = false,
  withLadder = false,
  withCountry = false,
  withState = false,
  domesticCountriesOnly = false,
  isInternational = false,
  disabled: parentDisabled = false,
  exportPending = false,
  exportDisabled = false
}, ref) => {
  const { DEFAULT_MATCH_RATE } = useCustomerSettings();
  const { getDomesticCountryId, isDomesticCountryId } = useModels();
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const {
    orgs: { data: orgsData, pending: orgsPending, failed: orgsFailed }, requireOrgs,
    jobCategories: { data: categoriesData, pending: categoriesPending, failed: categoriesFailed }, requireJobCategories,
    careerLadders: { data: laddersData, pending: laddersPending, failed: laddersFailed }, requireCareerLadders,
    countries: { data: countriesData, pending: countriesPending, failed: countriesFailed }, requireCountries,
    states: { data: statesData, pending: statesPending }, requireStates
  } = useContext(LookupContext);
  const orgs = ((!withOrg || orgsFailed) && NO_LOOKUP_ITEMS) || (orgsPending ? null : orgsData);
  const categories = ((!withCategory || categoriesFailed) && NO_LOOKUP_ITEMS) || (categoriesPending ? null : categoriesData);
  const ladders = ((!withLadder || laddersFailed) && NO_LOOKUP_ITEMS) || (laddersPending ? null : laddersData);
  const countries = (!withCountry && !domesticCountriesOnly && NO_LOOKUP_ITEMS) || (countriesPending ? null : countriesData);
  const states = statesPending ? null : statesData;

  const settingsLoaded = (!settingsId && Boolean(init)) || (
    pendingSettings === false && failedSettings === false && Boolean(settingsData));
  const settings = settingsId && settingsLoaded ? settingsData : null;

  const [showOpenReqs, setShowOpenReqs] = useState(parentShowOpenReqs);
  const [resetScope, setResetScope] = useState(Boolean(resetFilters));
  const [resetOpenReqs, setResetOpenReqs] = useState(Boolean(resetFilters));
  const [resetSupervisory, setResetSupervisory] = useState(Boolean(resetFilters));
  const [resetMatchRate, setResetMatchRate] = useState(Boolean(resetFilters));
  const [resetLevel, setResetLevel] = useState(Boolean(resetFilters));
  const [resetOrg, setResetOrg] = useState(Boolean(resetFilters));
  const [resetCategory, setResetCategory] = useState(Boolean(resetFilters));
  const [resetLadder, setResetLadder] = useState(Boolean(resetFilters));
  const [resetCountry, setResetCountry] = useState(Boolean(resetFilters));
  const [resetState, setResetState] = useState(Boolean(resetFilters));

  const settingsScope = settingsId && withScope
    ? getSettingsStrValue(settings, `${settingsId}__scope`)
    : init?.scope || undefined;
  const settingsOpenReqs = settingsId && withOpenReqs
    ? getSettingsBoolValue(settings, `${settingsId}__open_reqs`)
    : init?.open_reqs_only || undefined;
  const settingsSupervisory = settingsId && withSupervisory
    ? getSettingsBoolValue(settings, `${settingsId}__supervisory`)
    : init?.supervisory_jobs_only || undefined;
  const settingsMatchRate = settingsId && withMatchRate
    ? getSettingsNumValue(settings, `${settingsId}__match_rate`)
    : init?.min_match_rate || undefined;
  const settingsOrg = settingsId && withOrg
    ? getSettingsIntValue(settings, `${settingsId}__org`)
    : init?.org_id || undefined;
  const settingsCategory = settingsId && withCategory
    ? getSettingsIntValue(settings, `${settingsId}__category`)
    : init?.job_category_id || undefined;
  const settingsLadder = settingsId && withLadder
    ? getSettingsIntValue(settings, `${settingsId}__ladder`)
    : init?.career_ladder_id || undefined;
  const settingsCountry = settingsId && withCountry
    ? getSettingsIntValue(settings, `${settingsId}__country`)
    : init?.country_id || undefined;
  const settingsState = settingsId && withState
    ? getSettingsIntValue(settings, `${settingsId}__state`)
    : init?.state_id || undefined;
  const settingsLevel = settingsId && withLevel
    ? sanitizeLevel(getSettingsIntValue(settings, `${settingsId}__level`))
    : init?.management_level || undefined;

  const [scope, setScope] = useState<RoleScope | ''>(withScope && !resetScope && isValidRoleScope(settingsScope)
    ? settingsScope as RoleScope : '');
  const [openReqs, setOpenReqs] = useState(withOpenReqs && !resetOpenReqs ? Boolean(showOpenReqs || settingsOpenReqs) : false);
  const [supervisory, setSupervisory] = useState(withSupervisory ? Boolean(!resetSupervisory && settingsSupervisory) : false);
  const [matchRate, setMatchRate] = useState(withMatchRate && !resetMatchRate
    ? sanitizeMatchRate(settingsMatchRate) : DEFAULT_MATCH_RATE);
  const [level, setLevel] = useState<SkillLevel>((!resetLevel && settingsLevel) || 0);
  const [org, setOrg] = useState<number>(withOrg && !resetOrg ? sanitizeLookup(settingsOrg, orgs) : 0);
  const [category, setCategory] = useState<number>(withCategory && !resetCategory
    ? sanitizeLookup(settingsCategory, categories) : 0);
  const [ladder, setLadder] = useState<number>(withLadder && !resetLadder ? sanitizeLookup(settingsLadder, ladders) : 0);
  const [country, setCountry] = useState<number>(withCountry && !resetCountry ? sanitizeLookup(settingsCountry, countries) : 0);
  const [state, setState] = useState<number>(withState && !resetState ? sanitizeLookup(settingsState, states) : 0);

  useLayoutEffect(() => {
    if (parentShowOpenReqs) {
      setShowOpenReqs(true);
      setOpenReqs(true);
    }
  }, [parentShowOpenReqs]);

  useLayoutEffect(() => {
    if (resetFilters) {
      setResetScope(true);
      setScope('');
      setResetOpenReqs(true);
      setOpenReqs(false);
      setResetSupervisory(true);
      setSupervisory(false);
      setResetMatchRate(true);
      setMatchRate(DEFAULT_MATCH_RATE);
      setResetLevel(true);
      setLevel(0);
      setResetOrg(true);
      setOrg(0);
      setResetCategory(true);
      setCategory(0);
      setResetLadder(true);
      setLadder(0);
      setResetCountry(true);
      setCountry(0);
      setResetState(true);
      setState(0);
    }
  }, [resetFilters, DEFAULT_MATCH_RATE]);

  const isDomestic = useMemo(() => isDomesticCountryId(country, countries), [countries, country, isDomesticCountryId]);

  const local = domesticCountriesOnly || !isInternational;
  const disabled = parentDisabled || pendingSettingsUpdate || countriesFailed;

  const handleScope = useCallback((value: string) => {
    const val = value === scope || !isValidRoleScope(value) ? '' : value as RoleScope;
    setResetScope(false);
    setScope(val);
    if (settingsId && settingsScope !== val) updateSettings?.({ [`${settingsId}__scope`]: val });
  }, [scope, settingsId, settingsScope, updateSettings]);

  const handleOpenReqs = useCallback((value: boolean) => {
    const val = Boolean(value);
    setResetOpenReqs(false);
    setOpenReqs(val);
    setShowOpenReqs(false);
    if (settingsId && Boolean(settingsOpenReqs) !== val) updateSettings?.({ [`${settingsId}__open_reqs`]: val });
  }, [settingsId, settingsOpenReqs, updateSettings]);

  const handleSupervisory = useCallback((value: boolean) => {
    const val = Boolean(value);
    setResetSupervisory(false);
    setSupervisory(val);
    if (settingsId && Boolean(settingsSupervisory) !== val) updateSettings?.({ [`${settingsId}__supervisory`]: val });
  }, [settingsId, settingsSupervisory, updateSettings]);

  const handleMatchRate = useCallback((value?: number) => {
    const val = sanitizeMatchRate(value);
    if (val) {
      setResetMatchRate(false);
      setMatchRate(val);
      if (settingsId && sanitizeMatchRate(settingsMatchRate) !== val) updateSettings?.({
        [`${settingsId}__match_rate`]: val
      });
    }
  }, [settingsId, settingsMatchRate, updateSettings]);

  const handleOrg = useCallback((value: number) => {
    const val = sanitizeLookup(value, orgs);
    setResetOrg(false);
    setOrg(val);
    if (settingsId && settingsOrg !== val) updateSettings?.({ [`${settingsId}__org`]: val });
  }, [orgs, settingsOrg, settingsId, updateSettings]);

  const handleCategory = useCallback((value: number) => {
    const val = sanitizeLookup(value, categories);
    setResetCategory(false);
    setCategory(val);
    if (settingsId && settingsCategory !== val) updateSettings?.({ [`${settingsId}__category`]: val });
  }, [categories, settingsCategory, settingsId, updateSettings]);

  const handleLadder = useCallback((value: number) => {
    const val = sanitizeLookup(value, ladders);
    setResetLadder(false);
    setLadder(val);
    if (settingsId && settingsLadder !== val) updateSettings?.({ [`${settingsId}__ladder`]: val });
  }, [ladders, settingsLadder, settingsId, updateSettings]);

  const handleCountry = useCallback((value: number) => {
    const val = sanitizeLookup(value, countries);
    setResetCountry(false);
    setCountry(val);
    if (settingsId && settingsCountry !== val) updateSettings?.({ [`${settingsId}__country`]: val });
  }, [countries, settingsCountry, settingsId, updateSettings]);

  const handleState = useCallback((value: number) => {
    const val = sanitizeLookup(value, states);
    setResetState(false);
    setState(val);
    if (settingsId && settingsState !== val) updateSettings?.({ [`${settingsId}__state`]: val });
  }, [states, settingsState, settingsId, updateSettings]);

  const handleLevel = useCallback((value: SkillLevel) => {
    const val = sanitizeLevel(value);
    setResetLevel(false);
    setLevel(val);
    if (settingsId && settingsLevel !== val) updateSettings?.({ [`${settingsId}__level`]: val });
  }, [settingsId, settingsLevel, updateSettings]);

  useEffect(() => {
    if (withOrg) requireOrgs?.();
  }, [requireOrgs, withOrg]);

  useEffect(() => {
    if (withCategory) requireJobCategories?.();
  }, [requireJobCategories, withCategory]);

  useEffect(() => {
    if (withLadder) requireCareerLadders?.();
  }, [requireCareerLadders, withLadder]);

  useEffect(() => {
    if (withCountry || domesticCountriesOnly) requireCountries?.({ local });
  }, [domesticCountriesOnly, local, requireCountries, withCountry]);

  useEffect(() => {
    if (withState && isDomestic) requireStates?.({ country_id: country });
  }, [country, isDomestic, requireStates, withState]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (settingsId && settingsLoaded && !pendingSettingsUpdate) {
      if (withScope) {
        const scopeFromSettings = getSettingsStrValue(settings, `${settingsId}__scope`);
        setScope(!resetScope && isValidRoleScope(scopeFromSettings) ? scopeFromSettings as RoleScope : '');
      }
      if (withOpenReqs) setOpenReqs(Boolean(
        !resetOpenReqs && (showOpenReqs || getSettingsBoolValue(settings, `${settingsId}__open_reqs`))));
      if (withSupervisory) setSupervisory(Boolean(
        !resetSupervisory && getSettingsBoolValue(settings, `${settingsId}__supervisory`)));
      if (withMatchRate) setMatchRate(resetMatchRate ? DEFAULT_MATCH_RATE
        : sanitizeMatchRate(getSettingsNumValue(settings, `${settingsId}__match_rate`)) || DEFAULT_MATCH_RATE
      );
      if (withOrg) setOrg(sanitizeLookup(resetOrg ? 0 : getSettingsIntValue(settings, `${settingsId}__org`), orgs));
      if (withCategory) setCategory(sanitizeLookup(resetCategory ? 0
        : getSettingsIntValue(settings, `${settingsId}__category`), categories));
      if (withLadder) setLadder(sanitizeLookup(resetLadder ? 0
        : getSettingsIntValue(settings, `${settingsId}__ladder`), ladders));
      if (withCountry) setCountry(sanitizeLookup(resetCountry ? 0
        : getSettingsIntValue(settings, `${settingsId}__country`), countries));
      if (withState) setState(sanitizeLookup(resetState ? 0 : getSettingsIntValue(settings, `${settingsId}__state`), states));
      if (withLevel) setLevel(sanitizeLevel(resetLevel ? 0 : getSettingsIntValue(settings, `${settingsId}__level`)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    settingsLoaded, pendingSettingsUpdate, orgs, categories, ladders, countries, states, settings,
    settingsId, withOpenReqs, withLevel, withScope, withSupervisory, withMatchRate,
    withOrg, withCategory, withLadder, withCountry, withState
    // ignore `showOpenReqs` and `reset*`
  ]);

  useEffect(() => {
    if (init) {
      if (withScope) setScope(init.scope || '');
      if (withOpenReqs) setOpenReqs(Boolean(init.open_reqs_only));
      if (withSupervisory) setSupervisory(Boolean(init.supervisory_jobs_only));
      if (withMatchRate) setMatchRate(sanitizeMatchRate(init.min_match_rate) || DEFAULT_MATCH_RATE);
      if (withOrg) setOrg(init.org_id || 0);
      if (withCategory) setCategory(init.job_category_id || 0);
      if (withLadder) setLadder(init.career_ladder_id || 0);
      if (withCountry) setCountry(init.country_id || 0);
      if (withState) setState(init.state_id || 0);
      if (withLevel) setLevel(init.management_level || 0);
    }
  }, [
    init, withLevel, withScope, withOpenReqs, withSupervisory,
    withMatchRate, withOrg, withCategory, withLadder, withCountry, withState, DEFAULT_MATCH_RATE
  ]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (settingsLoaded && countries && orgs && categories && ladders && onChange && matchRate) {
      const newParams: JobsIndexFilterValues = {
        ...withOpenReqs ? { open_reqs_only: openReqs } : {},
        ...withLevel ? { management_level: level } : {},
        ...withCountry || domesticCountriesOnly ? {
          country_id: domesticCountriesOnly ? getDomesticCountryId(countries) : country
        } : {},
        ...withState && isDomestic ? { state_id: state } : {},
        ...withScope && scope ? { scope } : {},
        ...withOrg ? { org_id: org } : {},
        ...withCategory ? { job_category_id: category } : {},
        ...withLadder ? { career_ladder_id: ladder } : {},
        ...withMatchRate ? { min_match_rate: matchRate } : {},
        ...withSupervisory ? { supervisory_jobs_only: supervisory } : {}
      };
      onChange(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // only filter values are monitored here
    scope, openReqs, supervisory, matchRate, org, category, ladder, country, state, level,
    onChange, settingsLoaded, orgs, categories, ladders, countries, isDomestic
  ]);

  if (!visible) return null;

  const orgUnit = withOrg && (!orgs || size(orgs) >= 1) ? (
    <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
      <FilterSelector
          type="org"
          choices={orgs}
          value={org}
          onChange={handleOrg}
          disabled={disabled}
      />
    </Box>
  ) : null;

  const jobCategory = withCategory && (!categories || size(categories) >= 1) ? (
    <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
      <FilterSelector
          type="job_category"
          choices={categories}
          value={category}
          onChange={handleCategory}
          disabled={disabled}
      />
    </Box>
  ) : null;

  const careerLadder = withLadder && (!ladders || size(ladders) >= 1) ? (
    <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
      <FilterSelector
          type="career_ladder"
          choices={ladders}
          value={ladder}
          onChange={handleLadder}
          disabled={disabled}
      />
    </Box>
  ) : null;

  const dropdowns = (
    <>
      {orgUnit}
      {jobCategory}
      {careerLadder}
      {withCountry ? (
        <>
          <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <FilterSelector
                type="country"
                choices={countries}
                value={country}
                onChange={handleCountry}
                disabled={disabled}
            />
          </Box>
          {withState ? (
            <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
              <FilterSelector
                  type="state"
                  choices={isDomestic ? states : undefined}
                  value={isDomestic ? state : 0}
                  onChange={handleState}
                  disabled={disabled || !isDomestic}
              />
            </Box>
          ) : undefined}
        </>
      ) : undefined}
      {withLevel ? (
        <Box pb={1} display="flex" justifyContent="flex-end">
          <SkillLevelSelector
              job
              search
              value={level}
              onChange={handleLevel}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
    </>
  );

  return (
    <Box
        ref={ref}
        flexGrow={1}
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
    >
      {withScope ? (
        <Box py={0.5} pr={1.25} display="flex">
          <ScopeSelector
              scope={ROLE_SCOPES}
              value={scope}
              onChange={handleScope}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {dropdownsFirst ? dropdowns : undefined}
      {withOpenReqs ? (
        <Box
            pl={3.25}
            pt={1}
            pb={0.5}
            display="flex"
            alignItems="flex-start"
            justifyContent="flex"
            className={openReqsFilter}
        >
          <OnOffSwitch
              label="jobs_index.open_reqs_only"
              value={openReqs}
              onChange={handleOpenReqs}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {withSupervisory ? (
        <Box pl={1} pt={1} pb={0.5} display="flex" justifyContent="center" className={supervisoryFilter}>
          <OnOffSwitch
              label="jobs_index.supervisory_only"
              value={supervisory}
              onChange={handleSupervisory}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {withMatchRate ? (
        <Box flexGrow={3} pl={1} pr={3.25} py={0.5} display="flex" className={matchRateFilter}>
          <MatchRateSlider
              value={matchRate}
              onChange={handleMatchRate}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {dropdownsFirst ? undefined : (
        <Box
            flexGrow={1}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-end"
        >
          {dropdowns}
        </Box>
      )}
      {onExport ? (
        <Box pl={1} pt={0.5} pb={1.5} alignSelf="center">
          <ExportButton
              pending={exportPending}
              disabled={disabled || exportDisabled}
              onExport={onExport}
          />
        </Box>
      ) : undefined}
    </Box>
  );
});

JobsIndexFilters.displayName = JOBS_FILTERS_DISPLAY_NAME;

JobsIndexFilters.propTypes = JobsIndexFiltersPropTypes;

const JobsIndexFiltersMemo = memo(JobsIndexFilters);

JobsIndexFiltersMemo.displayName = JOBS_FILTERS_DISPLAY_NAME;

export default JobsIndexFiltersMemo;
