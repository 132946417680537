import { forwardRef, memo, useCallback, useState, type MouseEvent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import { longDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
// local imports
import { SkillActivity } from '../graphql/types';
import { SkillLevel } from '../models/skill';
import { MAX_COURSE_TITLE_LENGTH } from '../config/params';
import { PATH_SKILL } from '../config/paths';
import { getActivityIcon } from '../helpers/icons';
import SkillActivityDialog from './SkillActivityDialog';
// SCSS imports
import { card, icon, itemRelative } from './ActivityCard.module.scss';

type ActivityCardProps = {
  supervisor?: boolean;
  item: SkillActivity;
  small?: boolean;
  disabled?: boolean | null;
  isSelected?: boolean;
  withCompletedState?: boolean;
  onSelect?: (activity: SkillActivity) => void;
  onRemove?: (activity: SkillActivity) => void;
  onComplete?: (activity: SkillActivity) => void;
  onSelectPendingId?: number;
  onCompletePendingId?: number;
  withoutTag?: boolean;
}

const ActivityCardPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  item: PropTypes.object.isRequired as Validator<SkillActivity>,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  withCompletedState: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onComplete: PropTypes.func,
  onSelectPendingId: PropTypes.number,
  onCompletePendingId: PropTypes.number,
  withoutTag: PropTypes.bool
};

// eslint-disable-next-line complexity
const ActivityCard = forwardRef<HTMLDivElement, ActivityCardProps>(({
  supervisor = false,
  item,
  small = false,
  disabled: parentDisabled = false,
  isSelected,
  withCompletedState = false,
  onSelect,
  onRemove,
  onComplete,
  onSelectPendingId,
  onCompletePendingId,
  withoutTag = false
}, ref) => {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => setOpen(false), []);

  const { id, name, activity_type, description, is_selected, is_complete, completed_at, skills } = item || {};
  const ActivityIcon = getActivityIcon(activity_type);

  const handleChange = useCallback(() => onSelect?.(item), [item, onSelect]);
  const handleRemove = useCallback(() => onRemove?.(item), [item, onRemove]);
  const handleComplete = useCallback(() => onComplete?.(item), [item, onComplete]);

  const isSelectedSelf = onSelect ? Boolean(is_selected) : undefined;
  const selected = isSelected || (!supervisor && is_complete === true) || isSelectedSelf;

  const disabled = parentDisabled || onSelectPendingId === id || onCompletePendingId === id ? true : undefined;

  const itemCard = (
    <ItemCard
        ref={small ? undefined : ref}
        light
        small={small}
        header={(
          <Box flex="1 1 0" display="flex" alignItems="center" justifyContent="space-between">
            {withoutTag ? undefined : <TagLabel variant="started" title="hr.dev_plan.activity"/>}
            <BoxTypography
                variant="subtitle2"
                color={selected ? 'primary.contrastText' : 'greys.popupBorder'}
                display="flex"
                alignItems="center"
            >
              {ActivityIcon ? <ActivityIcon fontSize="medium" color="inherit" className={icon}/> : undefined}
              <FormattedMessage id="skill.development.activity" values={{ type: toLower(activity_type) || null }}/>
            </BoxTypography>
          </Box>
        )}
        onSelect={onSelect && !small && !is_complete ? handleChange : undefined}
        onRemoveClick={onRemove && !is_complete && !isSelectedSelf ? handleRemove : undefined}
        selected={selected}
        disabled={disabled}
        status={withCompletedState && is_complete && completed_at ? (
          <BoxTypography pt={small ? 0.75 : 1} pl={0.25} variant="body2" fontStyle="italic">
            <FormattedMessage
                id="skill.development.activity.completed"
                // eslint-disable-next-line react/jsx-props-no-spreading
                values={{ date: <FormattedDate value={getJsDateFromISO(completed_at)} {...longDateOptions}/> }}
            />
          </BoxTypography>
        ) : (onComplete && !is_complete && (
          <Box pt={1} display="flex" alignItems="flex-end" justifyContent="center">
            <Button
                color="secondary"
                variant="text"
                onClick={handleComplete}
                disabled={disabled}
                startIcon={onCompletePendingId === id ? <CircularProgress size={16} color="inherit"/> : undefined}
            >
              <FormattedMessage id="hr.dev_plan.button.mark_as_completed"/>
            </Button>
          </Box>
        )) || undefined}
        className={card}
    >

      <Box py={1}>
        <TruncatedTextLink
            text={name}
            onClick={handleOpen}
            variant="subtitle2"
            dark
            length={MAX_COURSE_TITLE_LENGTH}
            disabled={parentDisabled ? true : undefined}
        />
      </Box>

      {description && !small ? (
        <Box pb={1}>
          <TruncatedTextLink
              text={description}
              plain
              regular
              color="text.secondary"
              variant="body2"
              withoutTooltip
              disabled={parentDisabled ? true : undefined}
          />
        </Box>
      ) : undefined}

      {size(skills) >= 1 ? (
        <Box pt={1.125} display="flex" alignItems="center" flexWrap="wrap">
          {map(skills, (skill) => (
            <Box
                key={skill.id}
                my={0.375}
                mr={0.75}
                px={1}
                py={0.25}
                bgcolor="background.card"
                borderRadius={shape.tinyBorderRadius}
            >
              <SkillName
                  skill={skill}
                  level={skill.skill_proficiency_level as SkillLevel}
                  skillPath={PATH_SKILL}
                  variant="body1"
                  maxLines={1}
                  disabled={disabled}
              />
            </Box>
          ))}
        </Box>
      ) : undefined}

      {onSelect && small && !is_complete ? (
        <Box display="flex" justifyContent="flex-end">
          <Button
              color="primary"
              variant={selected ? 'outlined' : 'contained'}
              onClick={handleChange}
              disabled={disabled}
              startIcon={onSelectPendingId === id ? <CircularProgress size={16} color="inherit"/> : undefined}
          >
            <FormattedMessage id={selected ? 'hr.dev_plan.button.unselect' : 'hr.dev_plan.button.select'}/>
          </Button>
        </Box>
      ) : undefined}

    </ItemCard>
  );

  return (
    <>
      {small ? (
        <Box ref={ref} className={itemRelative}>
          {itemCard}
        </Box>
      ) : itemCard}
      <SkillActivityDialog
          skillActivity={item}
          open={open}
          onClose={handleClose}
          selected={selected}
          onSelect={is_complete ? undefined : (onSelect && handleChange) || (onRemove && handleRemove) || undefined}
          onComplete={is_complete || !onComplete ? undefined : handleComplete}
          onSelectPending={onSelectPendingId === id}
          onCompletePending={onCompletePendingId === id}
      />
    </>
  );
});

ActivityCard.displayName = 'ActivityCard';

ActivityCard.propTypes = ActivityCardPropTypes;

export default memo(ActivityCard);
