import { memo, useCallback, useContext, useMemo, useState, type FunctionComponent, type MouseEvent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { type Item } from '@empathco/ui-components/src/models/item';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { Job } from '../models/job';
import { Skill, SkillLevel } from '../models/skill';
import { JobLookupItem } from '../v3/JobSearch';
import { SupervisorContext } from '../context';
import TbItemChip from '../elements/TbItemChip';
import AddJobDialog from '../widgets/AddJobDialog';
import AddSkillDialog from '../widgets/AddSkillDialog';
import SkillLevelDialog from '../widgets/SkillLevelDialog';
// SCSS imports
import { scrollable, chip, reset } from './TbTeamItems.module.scss';

export type TbItemsType = 'jobs' | 'skills';
export const TbItemsTypeProp = PropTypes.oneOf(['jobs', 'skills']);

type TbTeamItemsProps = {
  teamId: number;
  type: TbItemsType;
  items?: Job[] | Skill[] | null;
  pending?: boolean | null;
  disabled?: boolean | null;
  withSatisfied?: boolean;
};

const TbTeamItemsPropTypes = {
  teamId: PropTypes.number.isRequired,
  type: TbItemsTypeProp.isRequired as Validator<TbItemsType>,
  items: PropTypes.array,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  withSatisfied: PropTypes.bool
};

const TbTeamItems: FunctionComponent<TbTeamItemsProps> = ({
  teamId,
  type,
  items,
  pending = false,
  disabled: parentDisabled = false,
  withSatisfied = false
}) => {
  const {
    [type === 'jobs' ? 'tbTeamJobAdd' : 'tbTeamSkillAdd']: { pending: addPending, failed: addFailed },
    tbTeamAddJob, tbTeamAddSkill,
    [type === 'jobs' ? 'tbTeamJobDelete' : 'tbTeamSkillDelete']: { pending: deletePending, failed: deleteFailed },
    tbTeamDeleteJob, tbTeamDeleteSkill,
    tbTeamSkillUpdate: { pending: updatePending, failed: updateFailed }, tbTeamUpdateSkill,
    tbTeamSkillsReset: { pending: resetPending, failed: resetFailed }, tbTeamResetSkills
  } = useContext(SupervisorContext);

  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);
  const [withLevels, setWithLevels] = useState(false); // TODO: connect to user settings

  const [open, setOpen] = useState(false);
  const [skl, setSkl] = useState<Skill | null>(null);

  const handleUpdateOpen = useCallback((id: number, level?: SkillLevel | null) => {
    const sk = find(items as Skill[], { id });
    if (sk && level) {
      setOpen(true);
      setSkl({ ...sk, current_level: level });
    }
  }, [items]);

  const handleUpdateClose = useCallback(() => setOpen(false), []);
  const handleUpdateExited = useCallback(() => setSkl(null), []);

  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  const handleAddJob = useCallback((job?: JobLookupItem | null) => {
    setAnchorAddBtn(null);
    if (job?.id) tbTeamAddJob?.({
      team_id: teamId,
      job_id: job.id,
      job
    });
  }, [tbTeamAddJob, teamId]);

  const handleDeleteJob = useCallback((id: number) => {
    tbTeamDeleteJob?.({ team_id: teamId, job_id: id });
  }, [tbTeamDeleteJob, teamId]);

  const handleAddSkill = useCallback((skill?: Skill | null, level?: SkillLevel) => {
    setAnchorAddBtn(null);
    if (skill?.id && level) tbTeamAddSkill?.({
      team_id: teamId,
      skill_id: skill.id,
      skill_proficiency_level: level,
      skill
    });
  }, [tbTeamAddSkill, teamId]);

  const handleUpdateSkill = useCallback((level: SkillLevel | null) => {
    if (skl?.id && skl.current_level && level) {
      tbTeamUpdateSkill?.({
        team_id: teamId,
        skill_id: skl.id,
        skill_proficiency_level: level
      });
      setOpen(false);
    }
  }, [skl, tbTeamUpdateSkill, teamId]);

  const handleDeleteSkill = useCallback((id: number, level?: SkillLevel | null) => {
    if (id && level) tbTeamDeleteSkill?.({
      team_id: teamId,
      skill_id: id,
      skill_proficiency_level: level
    });
  }, [tbTeamDeleteSkill, teamId]);

  const handleSkillsReset = useCallback(() => tbTeamResetSkills?.({ team_id: teamId }), [tbTeamResetSkills, teamId]);

  const excludeIds = useMemo(() => map(items as Item[], 'id'), [items]);

  const disabled = parentDisabled || addPending || deletePending || (type === 'skills' && (
    resetPending || updatePending
  )) ? true : undefined;

  return (
    <>
      <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          pb={1.5}
      >
        <BoxTypography pr={1} variant="subtitle1">
          <FormattedMessage id={`hr.teambuilder.team.${type}`}/>
        </BoxTypography>
        {type === 'skills' && (
          <>
            <Button
                variant="text"
                color="secondary"
                disabled={disabled || !tbTeamResetSkills}
                onClick={handleSkillsReset}
                className={reset}
            >
              <FormattedMessage id="hr.teambuilder.team.skills_reset"/>
            </Button>
            <Box pl={2.5}>
              <OnOffSwitch
                  label="hr.teambuilder.team.with_levels"
                  value={withLevels}
                  onChange={setWithLevels}
                  disabled={disabled}
              />
            </Box>
          </>
        )}
      </Box>
      {pending ? (
        <Box pt={1} pb={1} pl={1} pr={2} color="action.disabled">
          <CircularProgress size={28} color="inherit"/>
        </Box>
      ) : (
        <Box pt={1} pb={1} className={scrollable}>
          <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              py={0.5}
          >
            {map(items, (item) => (
              <TbItemChip
                  key={(item as Skill | Job).id}
                  item={item as Skill | Job}
                  onClick={type === 'skills' ? handleUpdateOpen : undefined}
                  onDelete={type === 'jobs' ? handleDeleteJob : handleDeleteSkill}
                  disabled={disabled}
                  withLevel={type === 'skills' && withLevels}
                  satisfied={withSatisfied ? (item as Skill).satisfied : undefined}
                  className={chip}
              />
            ))}
          </Box>
        </Box>
      )}
      <Box pt={1} pb={5}>
        <PlusButton
            label={`hr.teambuilder.team.${type}_add`}
            pending={addPending ? true : undefined}
            disabled={disabled}
            onClick={handleAddOpen}
        />
      </Box>
      {type === 'jobs' ? (
        <AddJobDialog
            isOpen={Boolean(anchorAddBtn)}
            onAdd={handleAddJob}
            onCancel={handleAddClose}
            disabled={disabled}
            exclude={excludeIds}
        />
      ) : (
        <>
          <AddSkillDialog
              supervisor
              depersonalized
              exclude={excludeIds}
              anchorEl={anchorAddBtn}
              onAdd={handleAddSkill}
              onCancel={handleAddClose}
              disabled={disabled}
          />
          {skl ? (
            <SkillLevelDialog
                isOpen={open}
                skill={skl}
                plain
                depersonalized
                onUpdate={handleUpdateSkill}
                onCancel={handleUpdateClose}
                onExited={handleUpdateExited}
                disabled={disabled}
                pending={updatePending}
            />
          ) : undefined}
        </>
      )}
      <ActionFailedAlert
          message={`hr.teambuilder.${type}_add_error`}
          open={addFailed}
      />
      <ActionFailedAlert
          message={`hr.teambuilder.${type}_delete_error`}
          open={deleteFailed}
      />
      {type === 'skills' && (
        <>
          <ActionFailedAlert
              message="hr.teambuilder.skills_update_error"
              open={updateFailed}
          />
          <ActionFailedAlert
              message="hr.teambuilder.skills_reset_error"
              open={resetFailed}
          />
        </>
      )}
    </>
  );
};

TbTeamItems.propTypes = TbTeamItemsPropTypes;

export default memo(TbTeamItems);
