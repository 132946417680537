import { forwardRef, memo, useCallback, useMemo, type Ref } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import Box from '@mui/material/Box';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { AdminJob } from '../graphql/types';
import { Job } from '../models/job';
import { CareerPath } from '../models/careerPath';
import { PATH_JOB } from '../config/paths';

type RoleNameProps = {
  isEmployee?: boolean;
  code?: string | null;
  title: string;
  isCurrent?: boolean | null;
  isTarget?: boolean | null;
  withoutLink?: boolean;
  variant?: Variant | 'inherit';
  bold?: boolean;
  onClick?: ((code: string, job?: Job | AdminJob) => void) | null;
  role?: Job | CareerPath | AdminJob;
  className?: string;
}

const RoleNamePropTypes = {
  // attributes
  isEmployee: PropTypes.bool,
  code: PropTypes.string,
  title: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
  isTarget: PropTypes.bool,
  withoutLink: PropTypes.bool,
  variant: PropTypes.string as Validator<Variant | 'inherit'>,
  bold: PropTypes.bool,
  onClick: PropTypes.func,
  role: PropTypes.object as Validator<Job | CareerPath | AdminJob>,
  className: PropTypes.string
};

const RoleName = forwardRef<HTMLAnchorElement | HTMLDivElement, RoleNameProps>(({
  isEmployee = false,
  code,
  title,
  isCurrent = false,
  isTarget = false,
  withoutLink = false,
  variant,
  bold,
  onClick,
  role,
  className
}, ref) => {
  const link = useMemo(() => withoutLink || !code || onClick ? undefined
    : injectParams(PATH_JOB, { role_id: code }), [withoutLink, code, onClick]);

  const handleClick = useCallback(() => {
    if (code && onClick) onClick(code, role as Job | AdminJob);
  }, [code, role, onClick]);

  return isCurrent || isTarget ? (
    <Box ref={ref as Ref<HTMLDivElement>} display="flex" alignItems="center" flexWrap="wrap">
      <Box pr={2} color={code ? undefined : 'secondary.main'} className={className}>
        {withoutLink ? title : (
          <StandardLink variant={variant} bold={bold} to={link} onClick={code && onClick ? handleClick : undefined}>
            {title}
          </StandardLink>
        )}
      </Box>
      <BoxTypography color="info.light" fontStyle="italic" variant="body2">
        <FormattedMessage
            id={isCurrent
              ? (isEmployee && 'common.current_role') || 'common.current_role_supv'
              : 'common.target_role'}
        />
      </BoxTypography>
    </Box>
  ) : (withoutLink && (
    <Box ref={ref as Ref<HTMLDivElement>} color="secondary.main" className={className}>
      {title}
    </Box>
  )) || (
    <StandardLink
        ref={ref as Ref<HTMLAnchorElement>}
        variant={variant}
        bold={bold}
        to={link}
        onClick={code && onClick ? handleClick : undefined}
    >
      {title}
    </StandardLink>
  );
});

RoleName.displayName = 'RoleName';

RoleName.propTypes = RoleNamePropTypes;

export default memo(RoleName);
