import { memo, useCallback, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import map from 'lodash/map';
import times from 'lodash/times';
import constant from 'lodash/constant';
import filter from 'lodash/filter';
import transform from 'lodash/transform';
import isUndefined from 'lodash/isUndefined';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
// EmPath UI Components
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
// local imports
import { DevPlan } from '../models/devPlan';
import { Course } from '../models/course';
import { LookupItem } from '../models/lookupItem';
import { SKILL_LEVEL_MIN, SKILL_LEVEL_MAX } from '../models/skill';
import useCustomerSettings from '../config/customer';
import { getCourseMaxLevel, getSkillCurrentLevel, sanitizeLookup } from '../helpers/models';
import { DataContext, LookupContext } from '../context';
import CourseCard from '../v3/CourseCard';
import SkillGrowthChart from '../widgets/SkillGrowthChart';

const NO_LOOKUP_ITEMS = [] as LookupItem[];

function getSelected(courses: Course[]) {
  return transform(courses, (result, { id, is_selected }) => {
    result[id] = Boolean(is_selected);
  }, {} as Record<number, boolean>);
}

type EmployeeSkillCoursesProps = {
  skillId?: string | null;
}

const EmployeeSkillCoursesPropTypes = {
  skillId: PropTypes.string
};

// eslint-disable-next-line complexity, max-statements
const EmployeeSkillCourses: FunctionComponent<EmployeeSkillCoursesProps> = ({
  skillId
}) => {
  const { getSkillCoursesUrl } = useCustomerSettings();
  const {
    courseProviders: { data: providersData, pending: providersPending, failed: providersFailed }, requireCourseProviders
  } = useContext(LookupContext);
  const {
    skill: { data: skill, pending: skillPending, failed: skillFailed },
    devPlan: { data: devPlan, pending, failed }, requireDevPlan,
    courseUpdate: { pending: updatePending, failed: updateFailed }, updateCourse
  } = useContext(DataContext);
  const providers = (providersFailed && NO_LOOKUP_ITEMS) || (providersPending ? null : providersData);
  const canSelectProvider = Boolean(providers) && size(providers) > 1;
  const loadingProviders = providersPending || !providers;

  const currentLevel = getSkillCurrentLevel(skill);

  const dPlan = (!pending && !failed && devPlan) || ({} as DevPlan);
  const { id: skill_id, title, abbr, suggested_courses, growth_in_quarters } = dPlan;

  const pleLink = useMemo(() => getSkillCoursesUrl({ title, abbr }), [abbr, title, getSkillCoursesUrl]);

  const [provider, setProvider] = useState<number>(0);

  useEffect(() => {
    requireCourseProviders?.();
  }, [requireCourseProviders]);

  useEffect(() => {
    if (skillId && providers) requireDevPlan?.({
      skill_id: skillId,
      provider_id: provider
    });
  }, [provider, providers, requireDevPlan, skillId]);

  const courses = useMemo(
    () => suggested_courses ? filter(suggested_courses, ({ level }) => level > currentLevel) : [],
    [currentLevel, suggested_courses]
  );

  const [selected, setSelected] = useState(getSelected(courses));

  const maxLevel = useMemo(
    () => getCourseMaxLevel(currentLevel, growth_in_quarters, suggested_courses, selected),
    [currentLevel, growth_in_quarters, suggested_courses, selected]
  );

  useEffect(() => {
    setSelected(getSelected(courses));
  }, [courses]);

  useEffect(() => {
    if (updateFailed) setSelected(getSelected(courses));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFailed]); // only track update failures here

  const handleProvider = useCallback((value: number) => {
    const val = sanitizeLookup(value, providers);
    setProvider(val);
  }, [providers]);

  const handleSelect = useCallback(({ id: courseId, level: courseLevel }: Course) => {
    const { ids: course_ids, selection: selectedIds } = transform(courses, ({ ids, selection }, course) => {
      const { id, level } = course;
      const is_selected = selected[id];
      if ((is_selected && id !== courseId && level !== courseLevel) || (!is_selected && id === courseId)) {
        ids.push(id);
        selection[id] = true;
      } else {
        selection[id] = false;
      }
    }, {
      ids: [],
      selection: {}
    } as {
      ids: number[];
      selection: Record<number, boolean>;
    });
    setSelected(selectedIds);
    updateCourse?.({ skill_id, course_ids });
  }, [skill_id, courses, selected, updateCourse]);

  const { selected: selectedCourse, growth: acceleratedGrowth } = useMemo(
    () => transform(courses, (result, { id, title: courseTitle, level, level_growth_in_quarters }) => {
      const index = level - 1;
      const quarters = result.growth[index];
      const nextQuarters = toSafeInteger(level_growth_in_quarters);
      if (selected[id] && (level <= maxLevel) && (quarters < 1 || nextQuarters < quarters)) {
        result.growth[index] = nextQuarters;
        if (isUndefined(result.selected)) result.selected = courseTitle;
        else result.selected = null;
      }
    }, {
      selected: undefined,
      growth: times(SKILL_LEVEL_MAX - SKILL_LEVEL_MIN, constant(0))
    } as {
      selected?: string | null;
      growth: number[]
    }),
    [courses, selected, maxLevel]
  );

  const values = useMemo(() => ({ title }), [title]);

  const hasCourses = size(courses) >= 1;
  const loadingDevPlan = pending || !devPlan;
  const loading = (loadingDevPlan && !canSelectProvider) || skillPending || !skill || loadingProviders;
  const disabled = Boolean(pending || updatePending);

  return skillId && !skillFailed && (!failed || canSelectProvider) && (
    loadingDevPlan || loading || devPlan || (pleLink && title)
  ) ? (
    <ContentCard pending={loading}>
      <CardTitle
          title={
            (failed && 'skill.courses.default') ||
            (loadingDevPlan && <Skeleton variant="text" width="12rem"/>) ||
            (title && 'skill.courses') ||
            'skill.courses.default'
          }
          values={values}
          action={(canSelectProvider ? (
            <FilterSelector
                type="course_provider"
                choices={providers}
                value={provider}
                onChange={handleProvider}
                disabled={disabled}
            />
          ) : (pleLink && title && (
            <Button
                color="secondary"
                variant="text"
                disableElevation
                href={pleLink}
                target="_blank"
                rel="noopener noreferrer"
            >
              <FormattedMessage id="skill.view_all_courses" values={values}/>
            </Button>
          )) || undefined)}
          withDivider
      />
      {(failed && <FetchFailedAlert flat/>) ||
      (loadingDevPlan && <LoadingPlaceholder flat/>) || (
        <>
          {(hasCourses && (
            <CardDeck centered>
              {map(courses, (course) => (
                <CourseCard
                    key={course.id}
                    course={course}
                    selected={selected[course.id] && course.level <= maxLevel ? true : undefined}
                    disabled={disabled}
                    onSelect={course.level <= maxLevel ? handleSelect : undefined}
                />
              ))}
            </CardDeck>
          )) || (
            <Alert severity="info" variant="standard">
              <FormattedMessage id="skill.courses.empty"/>
            </Alert>
          )}
          <CardTitle
              following
              title="skill.growth.title"
          />
          <SkillGrowthChart
              level={currentLevel}
              selectedCourse={selectedCourse}
              accelerated={isUndefined(selectedCourse) ? null : acceleratedGrowth}
              organic={growth_in_quarters}
          />
        </>
      )}
      <ActionFailedAlert
          message="skill.courses.select_error"
          open={updateFailed}
      />
    </ContentCard>
  ) : null;
};

EmployeeSkillCourses.propTypes = EmployeeSkillCoursesPropTypes;

export default memo(EmployeeSkillCourses);
