import { memo, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { DevPlanTargetSkill } from '../graphql/types';
import { DevPlanEmployee, DevPlanProgress, EmployeeDevPlanProgress } from '../graphql/customTypes';
import { Skill, SkillLevel } from '../models/skill';
import { getActualActivities } from '../helpers/graphql';
import SkillDevelopmentResource from '../elements/SkillDevelopmentResource';
// SCSS imports
import { header } from './SkillActivities.module.scss';

type SkillActivitiesProps = {
  skills?: (DevPlanTargetSkill | Skill)[] | null;
  selectedLevel?: SkillLevel | null;
  employees?: (DevPlanEmployee | DevPlanProgress | EmployeeDevPlanProgress)[] | null;
  section?: boolean;
  accordion?: boolean;
  withTopPadding?: boolean;
  disabled?: boolean | null;
}

const SkillActivitiesPropTypes = {
  skills: PropTypes.array,
  selectedLevel: PropTypes.number as Validator<SkillLevel>,
  employees: PropTypes.array,
  section: PropTypes.bool,
  accordion: PropTypes.bool,
  withTopPadding: PropTypes.bool,
  disabled: PropTypes.bool
};

const SkillActivities: FunctionComponent<SkillActivitiesProps> = ({
  skills,
  selectedLevel,
  employees,
  section = false,
  accordion = false,
  withTopPadding = false,
  disabled = false
}) => {
  const actualActivities = useMemo(() => getActualActivities(skills, selectedLevel, employees),
    [skills, selectedLevel, employees]);

  if (size(actualActivities) < 1) return null;

  const content = (
    <>
      <BoxTypography
          pt={withTopPadding ? 2 : undefined}
          variant={accordion ? 'h6' : undefined}
          className={accordion ? undefined : header}
      >
        <FormattedMessage id={accordion ? 'skill.development.activities' : 'skill.development.activities_to_develop'}/>
      </BoxTypography>
      <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          py={accordion ? undefined : 2}
          pt={accordion ? 1 : undefined}
      >
        {map(actualActivities, (skillActivity, index) => (
          <SkillDevelopmentResource
              key={index}
              small
              skillActivity={skillActivity}
              disabled={disabled}
          />
        ))}
      </Box>
    </>
  );

  return (section && (
    <CardSection compact>
      {content}
    </CardSection>
  )) || (accordion && (
    <>
      <Box width="100%"/>
      <Box pt={1} px={1.25} flexGrow={1}>
        {content}
      </Box>
    </>
  )) || content;
};

SkillActivities.propTypes = SkillActivitiesPropTypes;

export default memo(SkillActivities);
