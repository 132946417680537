import { memo, useMemo, useContext, type FunctionComponent } from 'react';
// EmPath UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import ExportButton, { type ExportParams } from '@empathco/ui-components/src/elements/ExportButton';
// local imports
import { API_SUPV_TEAM_EXPORT } from '../config/api';
import { GlobalContext } from '../context';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import TeamView from '../components/TeamView';

const SupervisorTeamScreen: FunctionComponent = () => {
  const { token } = useContext(GlobalContext);
  const exportParams = useMemo(() => token ? { token: token as string } as ExportParams : undefined, [token]);

  const { content } = useVerifyUser({ manager: true });

  return content || (
    <Screen withSettings>
      <ContentCard>
        <CardTitle
            title="supervisor.my_team.title"
            action={(
              <ExportButton
                  title="supervisor.my_team.export"
                  params={exportParams}
                  endpoint={API_SUPV_TEAM_EXPORT}
              />
            )}
        />
        <TeamView/>
      </ContentCard>
    </Screen>
  );
};

export default memo(SupervisorTeamScreen);
